import classNames from 'classnames';
import { generateUniqueKey, redirectTo } from '@r-and-a-shared-ui/utils';
import { ArticleProps } from '../Article/Article';
import classnames from 'classnames';
import ArticleListicle, {
  ArticleListicleProps,
} from '../ArticleListicle/ArticleListicle';
import ArticleHighlight from '../ArticleHighlight/ArticleHighlight';
import styles from './RecommendedArticles.module.scss';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import { useRouter } from 'next/router';
import { useFallbackArticles } from './useEmptyArticleList';

export interface RecommendedArticlesProps {
  highLightedArticle: ArticleProps;
  articlesList: ArticleListicleProps[];
  maxListItems?: number;
  ctaAllArticlesText?: string;
  title?: string;
  filters: { [key: string]: string[] };
  highlightArticlePosition?: string | 'left' | 'right';
  isLoading: boolean;
  isEmpty: boolean;
}

export function RecommendedArticles({
  articlesList,
  highLightedArticle,
  ctaAllArticlesText,
  title,
  maxListItems,
  filters,
  highlightArticlePosition,
  isEmpty,
}: RecommendedArticlesProps) {
  const router = useRouter();

  const { articles, highlighted, loading, error } = useFallbackArticles(articlesList, highLightedArticle, isEmpty);

  const showViewAllBtn =
    router.asPath === '/articles' ? false : !!ctaAllArticlesText;

  if (loading) {
    return <p>Loading articles...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['intro']}>
        {title && <h3 className={styles['title']}>{title}</h3>}
      </div>
      <div
        className={classNames(styles['grid'], {
          [styles['grid-right']]: highlightArticlePosition === 'right',
          [styles['grid__columns']]: !highlighted,
        })}
      >
        {highlighted && <ArticleHighlight {...highlighted} />}
        <div
          className={classNames({
            [styles['grid__articles-list']]: !!highlighted,
          })}
        >
          <div className={styles['articles-list']}>
            {articles?.length
              ? articles.map((article: ArticleListicleProps) => (
                <ArticleListicle
                  key={`${article.title}-${generateUniqueKey()}`}
                  image={article.image}
                  title={article.title}
                  mobileTitle={article.mobileTitle}
                  description={article.description}
                  access={article.access as keyof typeof RestrictionLevel}
                  urlSlug={article.urlSlug}
                />
              ))
              : null}
          </div>
        </div>
      </div>
      {showViewAllBtn && (
        <div className={styles['cta-all-articles-mobile']}>
          <div
            onClick={() => redirectTo('articles')}
            className={classnames(styles.btn, styles['btn--primary'])}
            role="button"
            aria-label={ctaAllArticlesText}
            tabIndex={0}
          >
            {ctaAllArticlesText}
          </div>
        </div>
      )}
      {showViewAllBtn && (
        <div className={styles['cta-all-articles-desktop']}>
          <div
            onClick={() => redirectTo('articles')}
            className={classnames(styles.btn, styles['btn--primary'])}
            role="button"
            aria-label={ctaAllArticlesText}
            tabIndex={0}
          >
            {ctaAllArticlesText}
          </div>
        </div>
      )}
    </div>
  );
}


export default RecommendedArticles;
