import React from 'react';
import { hasValue } from '@r-and-a-shared-ui/utils';
import { FormCTA, FormCTAProps } from '../FormCTA/FormCTA';
import styles from './FormHeader.module.scss';
import { RichTextParser } from '@r-and-a-shared-ui/common-ui';

export interface FormHeaderProps {
  headerTitle?: string,
  headerSubTitle?: string,
  titleColor?: string,
  subTitleColor?: string,
  headerCTAs?: FormCTAProps[],
  isMembership?: boolean;
}

export function FormHeader(props: FormHeaderProps) {
  const {
    headerTitle,
    headerSubTitle,
    titleColor,
    subTitleColor,
    headerCTAs,
    isMembership,
  } = props;

  const renderFormHeaderCTAs = (ctasProps: FormCTAProps[] | undefined): React.JSX.Element | undefined => {
    if (!hasValue(ctasProps) || typeof ctasProps === 'undefined') {
      return undefined;
    }
    return (
      <div className={`${styles['form__header-buttons']}`}>
        {
          ctasProps.map((ctasProps) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <FormCTA {...ctasProps} isMembership={isMembership} />
            );
          })
        }
      </div>
    );
  };
  
  const removeHTMLtag = (content: string | undefined): boolean => {
    if (!content) return false;
    const strippedContent = content.replace(/(<([^>]+)>)/gi, "").trim();
    return strippedContent.length > 0;
  };

  return (
    <div className={`${styles['form__header']} ${isMembership ? styles['form__header-membership'] : ''}`}>
      <div className={styles['form__header-content']}>
        {isMembership ? (
          <>
            {headerTitle && (
              <div className={styles['form__header-subtitle']}>
                <span style={{ color: titleColor }}>{headerTitle}</span>
              </div>
            )}
            {removeHTMLtag(headerSubTitle) && (
              <div className={styles['form__header-title']} style={{ color: subTitleColor }}>
                <RichTextParser richText={headerSubTitle} />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles['form__header-subtitle']}>
              <span style={{ color: titleColor }}>{headerTitle}</span>
            </div>
            <div className={styles['form__header-title']} style={{ color: subTitleColor }}>
              <RichTextParser richText={headerSubTitle} />
            </div>
          </>
        )}
      </div>
      {renderFormHeaderCTAs(headerCTAs)}
    </div>
  );
}

export default FormHeader;
