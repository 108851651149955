import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedAssetOrDefault,
  getFirstLinkedItemOrDefault,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const call_out_box = (entry: MEMBERSHIP_MODELS.CallOutBox) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  return {
    description: elements?.description?.value,
    backgroundImage: getFirstLinkedAssetOrDefault(
      elements?.background_image,
    )?.url,
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    link: getFirstMappedLinkedItemOrDefault(elements?.link),
  };
};

export default call_out_box;
