import {
  getFirstLinkedAssetOrDefault,
  getSingleChoiceValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import {
  getFirstMappedLinkedItemOrDefault,
  getMappedLinkedItems,
} from './utils/component-mapping-helpers';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const hero_tile = (entry: MEMBERSHIP_MODELS.HeroTile) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  const backgroundImage = getFirstLinkedAssetOrDefault(
    elements?.desktop_background_image,
  );
  const mobileBackgroundImage = getFirstLinkedAssetOrDefault(
    elements?.mobile_background_image,
  );
  
  return {
    title: elements?.title?.value,
    subtitle: elements?.subtitle?.value,
    type: getSingleChoiceValue(elements?.type),
    redirectTo: getFirstMappedLinkedItemOrDefault(elements?.redirect_to),
    desktopBackgroundImage: backgroundImage,
    mobileBackgroundImage: mobileBackgroundImage || backgroundImage,
    access: getSingleChoiceValue(
      elements?.membership_based_access__select_access,
    ),
    tags: getMappedLinkedItems(elements?.tags) || [],
    ctaLabel: elements?.cta_label?.value
  };
};

export default hero_tile;
