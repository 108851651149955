import type { FC } from 'react';
import classnames from 'classnames';
import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../lib/RenderOn/RenderOn';
import styles from './SSOButtons.module.scss';

export const SSOSignUpButton: FC = () => {
  const {
    methods: { signUp },
    userState: { isAuthenticated },
  } = useAuthContext();
  return (
    <RenderOn condition={!isAuthenticated} key={'sign-in-button'}>
      <span
        onClick={() => signUp()}
        data-testid={'RestrictedContentLoginButton'}
        className={classnames(styles.btn, styles['btn--primary'])}
      >
        Sign Up
      </span>
    </RenderOn>
  );
};
