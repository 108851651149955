/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { AssetProps, SEOWrapperProps } from '@r-and-a-shared-ui/common-ui';
import { isNullOrUndefined, redirectTo } from '@r-and-a-shared-ui/utils';
import { Form } from '../Form/Form';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import styles from './FormPage.module.scss';
import { FormProps } from '../types';
import Image from 'next/image';
import { NO_ACCESS_PAGE, getBearerToken } from '@r-and-a-shared-ui/auth-client';

export interface FormPageProps {
  seoData?: SEOWrapperProps;
  form?: FormProps;
  backGroundColor?: string;
  desktopAsset?: AssetProps,
  mobileAsset?: AssetProps,
}

export function FormPage(props: FormPageProps) {
  const { form, desktopAsset, mobileAsset } = props;
  const [bearerToken, setBearerToken] = useState<string | undefined>(undefined);

  const defaultValuesCallbackUrl = form?.defaultValuesCallbackUrl;

  useEffect(() => {
    const fetchToken = async () => {
      const tokenResult = await getBearerToken();
      
      if(!tokenResult && defaultValuesCallbackUrl) {
        redirectTo(NO_ACCESS_PAGE);
        return;
      }

      setBearerToken(tokenResult);
    };
    if(defaultValuesCallbackUrl) {
      fetchToken();
    }
  }, [defaultValuesCallbackUrl]);


  const renderForm = (
    formProps: FormProps | undefined,
  ): React.JSX.Element | undefined => {
    if (isNullOrUndefined(formProps)) {
      return undefined;
    }

    if(form?.hasReCaptcha) {
      return <GoogleReCaptchaProvider reCaptchaKey={form?.reCaptchaSiteKey || ''}>
        <Form {...formProps} />
      </GoogleReCaptchaProvider>;
    }

    return <Form {...formProps} bearerToken={bearerToken} />;
  };

  return (
    <div className={styles['form-page-layout']}>
      <div
        className={`${styles['form-page-layout__column']} ${styles['form-page-layout__left']}`}
      >
        {!isNullOrUndefined(mobileAsset) && mobileAsset.width && mobileAsset.height && (
          <div className={styles['form-page-layout__banner--wrapper']}>
            <Image
              src={mobileAsset.url}
              className={styles['form-page-layout__banner--mobile']}
              data-sizes="auto"
              alt={mobileAsset.description}
              width={mobileAsset.width || 100}
              height={mobileAsset.height || 100}
            />
          </div>
        )}
        <div className={styles['form-page-layout__form']}>
          {/* {
            isSaving || loading ?
            <FormSpinner spinnerRef={this.spinnerRef}>/</FormSpinner>
            : null
          } */}
          {renderForm(form)}
        </div>
      </div>
      <div
        className={`${styles['form-page-layout__column']} ${styles['form-page-layout__right']}`}
      >
        {!isNullOrUndefined(desktopAsset) && desktopAsset.width && desktopAsset.height && (
          <Image
            src={desktopAsset.url}
            className={styles['form-page-layout__banner--desktop']}
            data-sizes="auto"
            alt={desktopAsset.description}
            width={desktopAsset.width}
            height={desktopAsset.height}
          />
        )}
      </div>
    </div>
  );
}

export default FormPage;
