import styles from './Playlist.module.scss';
import { usePlaylist } from './usePlaylist';
import { secondsToTime } from '@r-and-a-shared-ui/utils';
import { SliderConfig } from '../ContentList/Slider/Slider';
import { ListDisplay } from '../ContentList/ContentList.types';
import ContentList from '../ContentList/ContentList';
import { RenderOn } from '../RenderOn/RenderOn';
import classnames from 'classnames';
import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useScreenDetector } from '../../hooks';

export interface PlaylistProps {
  playlistId: string;
  playlistTitle?: string;
  carouselConfig?: SliderConfig;
}

export function PlaylistTile({
  thumbnailUrl,
  title,
  duration,
  id,
  accessLevel,
  posterUrl,
}: any) {
  const externalVideoUrl = process.env.NEXT_PUBLIC_ENDEAVOR_VIDEO_LINK;

  const {
    methods: { federatedSignIn, upgradeAccount },
    config,
  } = useAuthContext();

  const { isTablet , isMobile } = useScreenDetector();

  const handleSignUp = () => {
    federatedSignIn();
  };

  const handleUpgrade = () => {
    upgradeAccount(config);
  };

  const imageUrl = isTablet || isMobile ? posterUrl : thumbnailUrl;

  if (accessLevel === 'GRANTED') {
    return (
      <a href={`${externalVideoUrl}${id}`} target="_blank" rel="noreferrer">
        <div className={styles.tile}>
          {imageUrl && (
            <img
              className={styles.image}
              src={imageUrl}
              alt={title || 'Playlist Title Image'}
            />
          )}
          <div className={styles['tile__info']}>
            {title && <h3 className={styles['tile__info__title']}>{title}</h3>}
            <div className={styles['tile__info__misc']}>
              <div className={styles['tile__info__misc__video-icon']}>
                <FontAwesomeIcon icon={faPlay} />
              </div>
              <div className={styles['tile__info__misc__duration']}>
                {duration ? secondsToTime(duration) : null}
              </div>
            </div>
          </div>
          <div className={styles.tile__shadow}></div>
        </div>
      </a>
    );
  } else {
    return (
      <div className={styles.tile}>
        {imageUrl && (
          <img
            className={styles.image}
            src={imageUrl}
            alt={title || 'Playlist Title Image'}
          />
        )}
        <div className={styles['tile__info']}>
          {title && <h3 className={styles['tile__info__title']}>{title}</h3>}
          <div className={styles['tile__info__misc']}>
            <div className={styles['tile__info__misc__video-icon']}>
              <FontAwesomeIcon icon={faPlay} />
            </div>
            <div className={styles['tile__info__misc__duration']}>
              {duration ? secondsToTime(duration) : null}
            </div>
          </div>

          <RenderOn condition={accessLevel === 'GRANTED_ON_SIGN_IN'}>
            <span
              onClick={handleSignUp}
              className={classnames(styles.btn, styles['btn--secondary'])}
            >
              Sign In to Watch
            </span>
          </RenderOn>
          <RenderOn condition={accessLevel === 'DENIED'}>
            <span
              onClick={handleUpgrade}
              className={classnames(styles.btn, styles['btn--secondary'])}
            >
              Upgrade to Watch
            </span>
          </RenderOn>
        </div>
        <div className={styles.tile__shadow}></div>
      </div>
    );
  }
}

export function Playlist({
  playlistId,
  playlistTitle,
  carouselConfig,
}: PlaylistProps) {
  const { playlist } = usePlaylist({ playlistId });

  return (
    <div className={styles.wrapper} data-testid="playlist">
      {playlist && (
        <>
          {playlistTitle && (
            <h2 className={`${styles.container} ${styles.title}`}>
              {playlistTitle}
            </h2>
          )}
          <ContentList
            type="slider"
            elements={playlist?.data?.vods}
            listDisplay={ListDisplay.PLAY_LIST}
            carouselConfig={carouselConfig}
          />
        </>
      )}
    </div>
  );
}

export default Playlist;
