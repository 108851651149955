import { deleteItemsFromSessionStorage, getSessionStorageItems, hasValue, isFunction } from "@r-and-a-shared-ui/utils";
import { TEMP_PASSWORD } from "../constants";
import { FieldError, FieldValues, FormState } from "react-hook-form";
import { FormFieldProps } from "../fields";
import { defaultValidationMessage } from "../fields/config/validators/validators";
import { ZodIssueCode } from "zod";


export const getInitialStateFromSessionStorage = () => {
  const keys = [TEMP_PASSWORD];

  const initialState = getSessionStorageItems(keys);
  deleteItemsFromSessionStorage(keys);

  return initialState;
};

export const setFormStateFromSessionStorage = (setValue: (...args: any[]) => any) => {
  if (!isFunction(setValue)){
    return;
  }

  const sessionStorageFormValues = getInitialStateFromSessionStorage();

  if (!hasValue(sessionStorageFormValues)){
    return;
  }
    
  Object.keys(sessionStorageFormValues)?.forEach(key => {
    setValue(key, sessionStorageFormValues?.[key as keyof typeof sessionStorageFormValues]);
  });
};

export const getErrorMessageForCurrentField = (
  getFieldState: (name: string, formState?: FormState<FieldValues> | undefined) => {
        invalid: boolean;
        isDirty: boolean;
        isTouched: boolean;
        error?: FieldError | undefined;
    },
  fieldProps: FormFieldProps) => {
  const {
    name,
    dependencyValidationMessage,
    validationMessage,
  } = fieldProps;
  const currentFieldState = getFieldState(name);

  const error = ((currentFieldState?.error as any)?.[name] as FieldError) || currentFieldState?.error;

  return error?.type === ZodIssueCode.invalid_intersection_types && hasValue(dependencyValidationMessage)
    ? dependencyValidationMessage
    : validationMessage || defaultValidationMessage;
};
