import {
  getPageUrlWithoutQueryParams,
  hasValue,
  redirectTo,
} from '@r-and-a-shared-ui/utils';
import {
  buildCustomOauthState,
  clearForceAuthParams,
  getBearerToken,
  getOauthChangePasswordReturnUrl,
  getOauthDeleteReturnUrl,
  getOauthFlowReturnUrl,
  getOauthSignInReturnUrl,
  getOauthSignOutReturnUrl,
  getOauthUpdateReturnUrl,
  getOauthUpgradeReturnUrl,
  getUserCreatedDateUrl,
  setOauthFlowReturnUrl,
  setOauthSignInReturnUrl,
  setOauthSignOutReturnUrl,
} from '../utils/auth-helpers';
import {
  signInWithRedirect,
  signOut,
  fetchAuthSession,
} from 'aws-amplify/auth';
import { AuthConfig } from '@aws-amplify/core';
import { UserJourneyTypes } from '../types';
import axios from 'axios';

export const handleOauthSignedOut = () => {
  const redirectUri = getOauthSignOutReturnUrl(true);

  if (
    getPageUrlWithoutQueryParams().toString() !== redirectUri?.toLowerCase()
  ) {
    redirectTo(redirectUri);
  }
};

export const handleOauthSignedIn = () => {
  const redirectUri = getOauthSignInReturnUrl(true);

  if (
    getPageUrlWithoutQueryParams().toString().toLowerCase() !==
    redirectUri?.toLowerCase()
  ) {
    redirectTo(redirectUri);
  }
};

export const federatedSignIn = (customState?: string, forceSignOut = true) => {
  try {
    fetchAuthSession().then((authSession) => {
      clearForceAuthParams();
      if (!authSession?.tokens) {
        amplifySignInWithRedirect(customState);
      }
    }).catch(() => {
      amplifySignInWithRedirect(customState);
    });
  } catch (error) {
    console.error('Error signing in:', error);
  } finally {
    if (!forceSignOut) {
      return;
    }

    signOut();
    amplifySignInWithRedirect(customState);
  }
};

const amplifySignInWithRedirect = (customState?: string) => {
  setOauthSignInReturnUrl();
  if (hasValue(customState)) {
    signInWithRedirect({ customState });
  } else {
    signInWithRedirect();
  }
};

export const federatedSignOut = async (global = false) => {
  try {
    setOauthSignOutReturnUrl();
    signOut({ global });
  } catch (error) {
    console.error('Error signing out:', error);
  }
};

export const signUp = (
  userJourneyType?: UserJourneyTypes,
  forceSignOut = true
) => {
  try {
    setOauthFlowReturnUrl();
    const registerRoute =
    process.env.NEXT_PUBLIC_MEMBER_REGISTER_ROUTE || 'member-register';
    const customState = buildCustomOauthState(registerRoute,
      userJourneyType);
    federatedSignIn(customState, forceSignOut);

  } catch (error) {
    console.error(error);
  }
};

export const updateAccountData = (
  config: AuthConfig,
  userJourneyType?: UserJourneyTypes,
) => {
  try {    
    const targetUrl = getOauthUpdateReturnUrl(config, userJourneyType);

    redirectTo(targetUrl);
  } catch (error) {
    console.error(error);
  }
};

export const upgradeAccount = (
  config: AuthConfig,
  userJourneyType?: UserJourneyTypes,
) => {
  try {
    const targetUrl = getOauthUpgradeReturnUrl(config, userJourneyType);

    redirectTo(targetUrl);
  } catch (error) {
    console.error(error);
  }
};

export const fetchCurrentSession = async () => {
  try {
    const tokens = (await fetchAuthSession()).tokens ?? {};
    return tokens;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const handleOauthCompleteFlow = () => {
  const redirectUri = getOauthFlowReturnUrl() || window.location.origin;

  if (!hasValue(redirectUri)) {
    return;
  }

  redirectTo(redirectUri);
};

export const changePassword = (
  config: AuthConfig,
  userJourneyType?: UserJourneyTypes,
) => {
  try {
    setOauthFlowReturnUrl();
    const targetUrl = getOauthChangePasswordReturnUrl(config, userJourneyType);

    redirectTo(targetUrl);
  } catch (error) {
    console.error(error);
  }
};

export const deleteAccount = (
  config: AuthConfig,
  username?: string
) => {
  try {
    const targetUrl = getOauthDeleteReturnUrl(config, username);

    redirectTo(targetUrl);
  } catch (error) {
    console.error(error);
  }
};


export const fetchCreatedDate = async (config: AuthConfig, username?: string) => {
  try {
    const url = getUserCreatedDateUrl(config);
    const bearerToken = await getBearerToken();
    if(!bearerToken || !url) {
      return null;
    }

    const createdDate = await axios.get(url, {
      params: {
        username
      },
      headers: {
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: bearerToken,
      },
    });
    return createdDate;
  } catch (err) {
    return null;
  }
};