/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useId, useState } from 'react';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { config as configs } from '../../src/index';
import { api } from '@r-and-a-shared-ui/randa-trpc-client';
import { hasValue } from '@r-and-a-shared-ui/utils';

type AsyncComponentProps = {
  kontentItem: IContentItem<any>;
  contentType?: keyof typeof configs;
  locale?: string;
  codename?: string;
  children?: any;
  depthParameter?: number
};

export const AsyncComponent = ({
  kontentItem,
  contentType,
  locale,
  children,
  depthParameter = 2
}: AsyncComponentProps) => {
  const uniqKey = useId();
  const [item, setItem] = useState<any>(kontentItem);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);  
  const config = contentType ? configs?.[contentType] : undefined;

  const { data } = api.kontent.itemWithLinkedItems.useQuery({
    codename: isDataFetched ? '' : kontentItem?.system?.codename,
    // @ts-ignore
    depthParameter: config?.depthParameter || depthParameter
  });

  if (hasValue(data)) {
    setItem(data);
    setIsDataFetched(true);
  }

  useEffect(() => {

    const elements = kontentItem?.elements || {};
    const elementsKeys = Object.keys(elements);

    for(let i = 0; i < elementsKeys.length; i++) {
      const key = elementsKeys[i];
      if(!elements[key]?.linkedItems?.length && elements[key]?.value?.length > 0) {
        setIsDataFetched(false);
        return;
      }
      i++;
    }
  }, [kontentItem]);

  if (!kontentItem || !config || !config.component) {
    return undefined;
  }

  const Component = config.component();

  if (!Component) {
    return undefined;
  }

  try {
    const mappedProps = config.mapEntryToProps(item || kontentItem as  IContentItem<any>);
    const kontentItemId = item?.system?.id;
    const key = `${uniqKey}-${contentType}-${kontentItemId}`;
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component key={key} {...mappedProps}>
        {children}
      </Component>
    );
  } catch (e) {
    console.log('error', e);
    return undefined;
  }
};

export default AsyncComponent;
