import { IContentItem } from "@kontent-ai/delivery-sdk";
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from "@r-and-a-shared-ui/core-caching";
import { deliveryClient } from "../config/kontent-delivery-client";
import { adjustSystemProperties } from "../utils";

export const getPageTemplateByCodeName = async <Type extends IContentItem> (
  codename: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.PageTemplate.valueOf(),
  cacheStatus?: CacheStatus
): Promise<Type | undefined> => {
  
  const cacheKey = `getPageTemplateByCodeName-${codename}`;
  try {
    const cachedItem = getCachedItem<Type>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }
  
    const response = await deliveryClient.item<Type>(codename).depthParameter(3).toPromise();
  
    const responseItem = response?.data?.item as Type;

    adjustSystemProperties(responseItem);
  
    setCacheItem<Type>(cacheKey, responseItem, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });
  
    return responseItem;
  
  } catch (error) {
    console.error('Error in getPageTemplateByCodeName', error);
    throw error;
  }
};