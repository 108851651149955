import React, { FC, useEffect, useState } from 'react';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';
import { SEOWrapper, SEOWrapperProps } from '../SEOWrapper/SEOWrapper';
import { useScreenDetector } from '../../hooks';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { HeaderProps } from '../Header/Header';
import { FooterProps } from '../Footer/Footer';
import { SubHeader } from './SubHeader';
import { PreFooter } from './PreFooter';
import Image from 'next/image';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import styles from './Coach.module.scss';

export interface CoachProps {
  title?: string;
  description?: IContentItem<any>;
  primaryImage?: string;
  primaryMobileImage?: string;
  pageTemplate?: {
    header: HeaderProps;
    footer: FooterProps;
  };
  preFooter: IContentItem<any>[];
  sidebar: any[];
  subheader: IContentItem<any>[];
  subtitle: string;
  seo: SEOWrapperProps;
}

export const Coach: FC<CoachProps> = ({
  title,
  description,
  primaryImage,
  primaryMobileImage,
  preFooter,
  sidebar,
  subheader,
  seo,
}) => {
  const { isMobile, isTablet, isClient } = useScreenDetector();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!isClient) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  const imageSource = isMobile && primaryMobileImage ? primaryMobileImage : primaryImage;

  return (
    <main data-testid="Coach" className={styles.wrapper}>
      <SEOWrapper
        title={seo?.title}
        description={seo?.description}
        keywords={seo?.keywords}
        noindex={seo?.noindex}
        nofollow={seo?.nofollow}
        urlSlug={seo?.urlSlug}
      />
      {subheader && <SubHeader subheader={subheader} />}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content__left}>
            {imageSource && (
              <div className={styles.hero}>
                <Image
                  src={imageSource}
                  alt={title || 'Coach image'}
                  fill
                  style={{ objectFit: 'cover' }}
                />
                <div className={styles.hero__shadow}></div>
              </div>
            )}
            {title && <h1>{title}</h1>}
            {description && (
              <AsyncComponent
                kontentItem={description}
                contentType="rich_text"
              />
            )}
          </div>
          <div className={styles.content__right}>
            {sidebar
              .map(item => {
                if (isTablet && item.system.type !== 'web_banner_placeholder') {
                  return null;
                }
                return (
                  <AsyncComponent
                    key={`sidebar-${item.system.id}`}
                    kontentItem={item}
                    contentType={item.system.type}
                  />
                );
              })
              .filter(Boolean)}
          </div>
        </div>
        {preFooter && <PreFooter preFooter={preFooter} />}
      </div>
    </main>
  );
};

export default Coach;
