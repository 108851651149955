import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const option_with_rich_text = (data: SSO_MODELS.OptionWithRichText) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    label: elements?.description?.value,
    value: elements?.value?.value,
  };
};

export default option_with_rich_text;
