import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedAssetOrDefault,
  getFirstLinkedItemOrDefault,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const callout_panel_list = (
  entry: MEMBERSHIP_MODELS.CalloutPanelList,
) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  return {
    backgroundImage: getFirstLinkedAssetOrDefault(elements?.background_image)
      ?.url,
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    calloutItems: getFirstMappedLinkedItemOrDefault(elements.callout_items),
    description: elements?.description?.value,
    title: elements?.title?.value,
    codename: entry?.system?.codename,
  };
};

export default callout_panel_list;
