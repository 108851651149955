import React from 'react';
import PropTypes from 'prop-types';

import { ToastContainer, ToastOptions, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './toastify.scss';

// Update Styles in the toastify.css file.
type Options = {
    autoClose?: number,
    draggable: boolean,
    position: string,
    hideProgressBar: boolean,
    closeButton?: boolean,
    className?: string,
}
const options: Options = {
  //autoClose: 4500,
  draggable: false,
  position: 'top-left',
  hideProgressBar: true,
};
const optionsSmall: Options = { ...options, closeButton: true, className: 'small-toast' };
const optionsBig: Options = { ...options, closeButton: true, className: 'big-toast' };

export const ToastComponent = ({ message }: {message: string}) => {
  return <span className="toast-message">{message}</span>;
};

const notifications = {
  success: (message: string) => toast.success(<ToastComponent message={message} />, options as ToastOptions<Options>),
  error: (message: string) => toast.error(<ToastComponent message={message} />, options as ToastOptions<Options>),
  info: (message: string) => toast.info(<ToastComponent message={message} />, options as ToastOptions<Options>),
  successSmall: (message: string) => toast.success(message, optionsSmall as ToastOptions<Options>),
  errorSmall: (message: string) => toast.error(message, optionsSmall as ToastOptions<Options>),
  successBig: (message: string) => toast.success(<ToastComponent message={message} />, optionsBig as ToastOptions<Options>),
  errorBig: (message: string) => toast.error(<ToastComponent message={message} />, optionsBig as ToastOptions<Options>),
};

ToastComponent.propTypes = {
  message: PropTypes.string.isRequired,
};

export { ToastContainer as NotificationContainer, notifications };
