import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const option = (data: SSO_MODELS.Option) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    label: elements?.label?.value,
    value: elements?.value?.value,
  };
};

export default option;
