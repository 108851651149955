import React, { FC, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from '../Link/Link';
import type { MenuItemProps, MenuItem } from './Menu.types';
import styles from './MainMenu.module.scss';
import { useMainMenu } from './useMainMenu';
import Image, { ImageProps } from '../Image/Image';
import { MenuFooterProps } from '../MenuFooter/MenuFooter';
import MenuFooter from '../MenuFooter/MenuFooter';
import { LogoProps } from '../Logo/Logo';
import { sendGAEvent } from '@next/third-parties/google';
import { getPageTrackingDetails } from '@r-and-a-shared-ui/utils';

type MainMenuProps = {
  menu: MenuItem[];
  backgroundImage?: ImageProps;
  children?: React.ReactNode | React.ReactNode[];
  footer?: MenuFooterProps[];
  extraItems?: React.ReactNode[];
};
export const MainMenuItem: FC<MenuItemProps> = ({ item, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = () => {
    if (item.children) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <li onClick={() => onClick(item)}>
      {item.children ? (
        <button onClick={handleOnClick}>
          {isOpen ? (
            <FontAwesomeIcon icon={faPlus} />
          ) : (
            <FontAwesomeIcon icon={faMinus} />
          )}
          {item.title}
        </button>
      ) : (
        <Link title={item.title} href={item.href} internal={item.internal} codename={item.codename} />
      )}
      {item.children && isOpen && (
        <ul>
          {item.children.map((childItem, index) => (
            <MainMenuItem
              key={index}
              item={childItem}
              onClick={() => onClick(item)}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export const MainMenu: FC<MainMenuProps> = ({
  menu,
  extraItems,
  children,
  backgroundImage,
  footer,
}) => {
  const { isOpen, onToggle, onClose } = useMainMenu();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu__open');
      const { pageUrl, pageTitle } = getPageTrackingDetails();
      sendGAEvent({ event: 'open_burger_menu', value: { pageUrl, pageTitle } });
    } else {
      document.body.classList.remove('menu__open');
    }
  }, [isOpen]);

  const handleMenuItemClick = (item: {href?: string,
    internal?: boolean, title: string}) => {
    onClose();

    const { pageUrl, pageTitle } = getPageTrackingDetails();
    sendGAEvent({ event: 'burger_menu_click', value: {
      pageUrl,
      pageTitle,
      clickUrl: item?.href,
      clickText: item.title} });
  };

  return (
    <div className={styles['nav-container']}>
      <nav data-testid="MainMenu">
        <input
          id="toggle-menu"
          className={styles['hidden-toggle-button']}
          type="checkbox"
          checked={isOpen}
          onChange={onToggle}
        />
        <label
          className={styles['new-toggler-container']}
          htmlFor="toggle-menu"
        >
          <div className={styles['new-toggler-container__button']}></div>
        </label>
        <div className={styles['menu']}>
          {backgroundImage && (
            <Image
              className={styles['menu__bg']}
              imageUrl={backgroundImage.imageUrl}
              alt={backgroundImage.alt || "Main Menu Background"}
              width={backgroundImage.width}
              height={backgroundImage.height}
            />
          )}
          <div className={styles.container}>
            <ul className={styles['menu__list']}>
              {menu.map((item, index) => (
                <MainMenuItem key={index} item={item} onClick={() => handleMenuItemClick(item)} />
              ))}
              {extraItems &&
                extraItems.map((item, index) => <li key={index}>{item}</li>)}
              <li className={styles.footer__menu}>
                {footer?.length &&
                  footer.map((footerItem, index) => (
                    <MenuFooter key={index} logos={[footerItem as LogoProps]} />
                  ))}
              </li>
            </ul>
            {children}
          </div>
        </div>
      </nav>
    </div>
  );
};
