import { FC, useEffect, useState } from 'react';
import styles from './HomePage.module.scss';
import {
  AsyncComponent,
  ConfiguredComponents,
  DynamicComponent,
} from '@r-and-a-shared-ui/core-component-config';
import type { HomePageProps } from './types';
import classnames from 'classnames';

export const HomePageMobile: FC<Pick<HomePageProps, 'mobile'>> = ({
  mobile,
}) => {
  const [inBrowser, setInBrowser] = useState(false);
  useEffect(() => {
    setInBrowser(true);
  },[]);
  return (
    <main data-testid={'homePageMobile'} className={classnames(styles.wrapper,inBrowser?styles['wrapper_visible']:'')}>
      <div
        data-testid={'body-content'}
        className={styles.container}
        key={`home-page-mobile`}
      >
        <div className={styles.content}>
          {mobile.map(x => {
            // if (x.dynamic) {
            //   return (
            //     <DynamicComponent
            //       key={x.system.id}
            //       codename={x.system.codename}
            //       contentType={x.system.type}
            //       locale={x.system.language}
            //     />
            //   );
            // }
            return (
              <AsyncComponent
                kontentItem={x}
                contentType={x.system.type as ConfiguredComponents}
                key={x.system.id}
              />
            );
          })}
        </div>
      </div>
    </main>
  );
};
