import { deliveryClient } from '../config/kontent-delivery-client';
import { getItemWithLinkedItemsByCodename } from './get-item-with-linked-items-by-codename';
import { MEMBERSHIP_MODELS } from '../models';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { adjustSystemProperties } from '../utils';

type GetEventPageBySlugResponse = {
  event: MEMBERSHIP_MODELS.EventPage | undefined;
};

export const getEventPageBySlug = async (
  slug: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
) => {
  const cacheKey = `getEventPageBySlug-attend/${slug}`;
  try {
    const cachedItem = getCachedItem<GetEventPageBySlugResponse>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const item = await deliveryClient
      .items<MEMBERSHIP_MODELS.EventPage>()
      .type('event_page')
      .equalsFilter('elements.url_slug', slug)
      .toPromise()
      .then(r => r.data.items[0]);

    adjustSystemProperties(item);

    if (!item || !item?.system.codename) {
      return undefined;
    }

    const event = await getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.EventPage>({
      codename: item.system.codename,
      depthParameter: 1,
      cacheTTL: cacheTTL - 1
    });

    const response = {
      event
    };

    setCacheItem<GetEventPageBySlugResponse>(cacheKey, response, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });
    
    return response;
  } catch (error) {
    console.error('Error in getEventPageBySlug', error);
    throw error;
  }
};
