import type {
  SSO_MODELS,
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
import { getCtaHrefValue } from './utils/component-mapping-helpers';
import {
  getFirstLinkedItemOrDefault,
  getSingleChoiceValue,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const cta = (data: SSO_MODELS.CTA | MEMBERSHIP_MODELS.CTA) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    key: data?.system?.codename,
    label: elements?.label?.value,
    resource: getCtaHrefValue(getFirstLinkedItemOrDefault(elements?.resource)),
    type: getSingleChoiceValue(elements?.type),
    theme: getSingleChoiceValue(elements?.theme),
    borderColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.border_color),
    ),
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    opacity: elements?.opacity?.value as number | undefined,
    textColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.text_color),
    ),
  };
};

export default cta;
