import { safeParseToInt } from '@r-and-a-shared-ui/utils';

export const CacheConfig = Object.freeze({
  TTL: {
    ttlDelta: safeParseToInt(process.env['NEXT_PUBLIC_CACHE_TTL_DELTA'] ?? '3'),
    Default: safeParseToInt(
      // eslint-disable-next-line no-undef
      process?.env?.['NEXT_PUBLIC_DEFAULT_CACHE_TTL'] ?? '600', // 9 seconds
    ),
    PageElement: safeParseToInt(process.env['NEXT_PUBLIC_PAGE_ELEMENT_CACHE_TTL']) || 597,
    PageContent: safeParseToInt(process.env['NEXT_PUBLIC_PAGE_CONTENT_CACHE_TTL']) || 598,
    PageTemplate: safeParseToInt(process.env['NEXT_PUBLIC_PAGE_TEMPLATE_CACHE_TTL']) || 599,
    Page: safeParseToInt(process.env['NEXT_PUBLIC_PAGE_CACHE_TTL']) || 600,
    ArticleAuthors: safeParseToInt(process.env['NEXT_PUBLIC_ARTICLE_AUTHORS_CACHE_TTL']) || 600,
    Config: safeParseToInt(process.env['NEXT_PUBLIC_CONFIG_CACHE_TTL']) || 600,
    LatestArticles: safeParseToInt(process.env['NEXT_PUBLIC_LATEST_ARTICLES_CACHE_TTL']) || 600,
  },
  keyPrefix: process?.env?.['CACHE_KEY_PREFIX'] ?? '',
  Hit: 'HIT',
  Miss: 'MISS',
});
