import { useCallback, useState } from 'react';

export const useMainMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const onClose = useCallback(() => { setIsOpen(false); }, []);
  return {
    isOpen,
    onToggle,
    onClose,
  };
};
