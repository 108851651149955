import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type {
  SSO_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
export default {
  component: undefined,
  mapEntryToProps: (entry: SSO_MODELS.CognitoUserAttribute) =>
    kontentAiDataMapping.cognito_user_attribute(entry),
};
