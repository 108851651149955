import { SEOWrapper } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type {
  SSO_MODELS,
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
export default {
  component: ()=>SEOWrapper,
  mapEntryToProps: (entry: SSO_MODELS.SEOData | MEMBERSHIP_MODELS.SEOData) =>
    kontentAiDataMapping.seo_data(entry),
};
