import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault, getFirstLinkedItemOrDefault, getTrueFalseSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';

export const coach = (entry: MEMBERSHIP_MODELS.Coach) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};
  const preFooter = elements?.pre_foot_elements?.linkedItems ?? [];
  const sidebar = elements?.sidebar?.linkedItems ?? [];
  const subheader = elements?.subheader_elements?.linkedItems ?? [];
  const seo = {
    title: elements?.seo__meta_title?.value,
    description: elements?.seo__meta_description?.value,
    keywords: elements?.seo__key_words?.value,
    noindex: getTrueFalseSingleChoiceValue(elements?.seo__no_index),
    nofollow: getTrueFalseSingleChoiceValue(elements?.seo__no_follow),
    urlSlug: elements?.url_slug?.value,
  };
  
  return {
    title: elements?.title?.value,
    description: getFirstLinkedItemOrDefault(elements?.description),
    primaryImage: getFirstLinkedAssetOrDefault(elements?.primary_image)
      ?.url,
    primaryMobileImage: getFirstLinkedAssetOrDefault(elements?.primary_mobile_image)
      ?.url,
    preFooter,
    sidebar,
    subheader,
    subtitle: elements?.subtitle?.value,
    seo
  };
};

export default coach;
