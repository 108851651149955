import { z } from "zod";

export const OneClubQuestionSchema = z.object({
  fromEmail: z.string().email(),
  toEmail: z.string().email(),
  emailKey: z.string(),
  subject: z.string(),
  text: z.string().optional(),
  html: z.string().optional(),
  userEmail: z.string(),
  submitData: z.record(z.string().optional(), z.string().optional()).optional(),
});
  