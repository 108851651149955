import { getItemByCodeName } from './get-item-by-code-name';
import { HomePage } from '../models/MEMBERSHIP';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { MEMBERSHIP_MODELS } from '../models';

type GetPageResponse = {
  page: MEMBERSHIP_MODELS.HomePage | MEMBERSHIP_MODELS.Page | undefined;
};
export const getPageByCodename = async (
  codename = '',
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
): Promise<GetPageResponse | undefined> => {
  const cacheKey = `getPageByCodename-${codename}`;
  const cachedItem = getCachedItem<GetPageResponse>(cacheKey, cache);

  if (cachedItem) {
    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Hit,
      cacheTTL
    });
    return cachedItem;
  }

  try {
    const page = await getItemByCodeName<HomePage>(
      codename,
      false,
      cache,
      cacheTTL - 1
    );

    if (!page) {
      return undefined;
    }

    const result = {
      page,
    };

    setCacheItem<GetPageResponse>(cacheKey, result, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return result;
  } catch (error) {
    console.error('Error in getFullHomePage', error);
    throw error;
  }
};
