import type { MEMBERSHIP_MODELS, SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getCtaHrefValue,
  getFirstMappedLinkedItemOrDefault,
  getMappedLinkedItems,
} from './utils/component-mapping-helpers';
import {
  getFirstLinkedAssetOrDefault,
  getFirstLinkedItemOrDefault,
  getSingleChoiceValue,
  getTextItemValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const form = (data: SSO_MODELS.Form & MEMBERSHIP_MODELS.Form) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;
  
  const reCaptchaSiteKey = getTextItemValue(
    getFirstLinkedItemOrDefault(elements?.recaptcha),
  );
  const reCaptchaAttributeKey = elements.recaptcha_attribute_key?.value;
  const hasReCaptcha = !!(
    reCaptchaSiteKey &&
    reCaptchaAttributeKey &&
    getSingleChoiceValue(elements?.is_recaptcha_enabled) === 'yes'
  );
  const isMembership = !!(elements.membership_form?.value?.[0]?.codename);
  
  return {
    formCodename: data.system.codename,
    formHeaderData: getFirstMappedLinkedItemOrDefault(elements?.form_header),
    steps: getMappedLinkedItems(elements?.form_content),
    formFooterData: getFirstMappedLinkedItemOrDefault(elements?.form_footer),
    backgroundImage: getFirstLinkedAssetOrDefault(elements?.background_image)?.url,
    hasReCaptcha,
    reCaptchaSiteKey,
    reCaptchaAttributeKey,
    errorsDictionary: getMappedLinkedItems(elements?.errors_messages),
    successMessagesDictionary: getMappedLinkedItems(elements?.success_messages),
    nextStepsAfterSubmit: getMappedLinkedItems(elements?.next_steps),
    defaultValuesCallbackUrl: getCtaHrefValue(
      getFirstLinkedItemOrDefault(elements?.default_values_callback_url),
    ),
    isMembership,
    toEmail: elements?.to_email?.value,
    fromEmail: elements?.from_email?.value,
    subject: elements?.subject?.value,
    emailKey: elements?.key?.value,
  };
};

export default form;
