import type { DictionaryItemProps } from '../lib/types';
import { isNullOrUndefined } from '@r-and-a-shared-ui/utils';

export const tryGetDictionaryItemValue = (
  dictionaryItems: DictionaryItemProps[] | undefined,
  key: string,
): string | undefined => {
  if (isNullOrUndefined(dictionaryItems)) {
    return undefined;
  }
  return dictionaryItems.find(item => item.key === key)?.value;
};
export type ErrorSetterDetails = {
  setError: ((...args: any[]) => any) | undefined;
  errorKey: string;
  statusCode: string;
  errorsDictionary: DictionaryItemProps[] | undefined;
  defaultMessage: string;
};
export const setServerError = (errorSetter: ErrorSetterDetails) => {
  const { errorsDictionary, errorKey, defaultMessage, setError, statusCode } =
    errorSetter;
  const errMessage =
    tryGetDictionaryItemValue(errorsDictionary, errorKey) || defaultMessage;
  if (!isNullOrUndefined(setError)) {
    setError('root.serverError', {
      type: statusCode,
      message: errMessage,
    });
  }
};
