import { ContentList } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: ()=>ContentList,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.ContentListFixedLength) =>
    kontentAiDataMapping.content_list___fixed_length(entry),
  depthParameter: 3
};
