import { AesEncrypt, ResponseModel, getQueryParamByKey } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { AdminResetPasswordInputSchema, VerifyEmailInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { OauthQuery } from "@r-and-a-shared-ui/auth-client";

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {
  const resetPasswordToken = getQueryParamByKey(OauthQuery.TOKEN);
  const { submitData } = params

  params.submitData = {
    ...submitData,
    resetPasswordToken,
    password: AesEncrypt(submitData?.password)
  };

  return await genericMutationHandlerWithFormValidation(
    params,
    AdminResetPasswordInputSchema,
  );
};


export default handle;