import {
  createContext,
  useContext
} from 'react';
import { SiteConfigContextType } from './SiteConfigContext';

export type MembershipConfigContextType = SiteConfigContextType

export const MembershipConfigContext = createContext<MembershipConfigContextType | undefined>(undefined);

export const useMembershipConfigContext = () => {
  const context = useContext(MembershipConfigContext);
  return context;
};
