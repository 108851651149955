import {
  AsyncComponent,
  ConfiguredComponents,
} from '@r-and-a-shared-ui/core-component-config';
import { RenderOn } from '../RenderOn/RenderOn';
import type { PageProps } from './types';

import styles from './Page.module.scss';

export const PreFooter = ({ preFooter }: Pick<PageProps, 'preFooter'>) => {
  return (
    <div className={styles.container}>
      <RenderOn condition={preFooter.length > 0}>
        <section
          className={styles['page-pre-footer']}
          data-testid={'page-prefooter'}
        >
          {preFooter.map(x => (
            <AsyncComponent
              kontentItem={x}
              contentType={x.system.type as ConfiguredComponents}
              key={x.system.id}
            />
          ))}
        </section>
      </RenderOn>
    </div>
  );
};
