import React, { FC, useContext } from 'react';
import classnames from 'classnames';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';
import { FeatureCardProps } from '../FeatureCard.types';
import { useFeatureCardTOC } from './useFeatureCardTOC';

import styles from './FeatureCardTOC.module.scss';
import { EnvironmentContext } from '@r-and-a-shared-ui/common-ui';

export const FeatureCardTOC: FC<FeatureCardProps> = props => {
  const { Image } = useContext(EnvironmentContext);
  const { title, description, image, ctas } = props;
  const { parsedTitle, parsedDescription } = useFeatureCardTOC({
    title,
    description,
  });
  return (
    <div className={styles.wrapper}>
      {image && <Image src={image} alt={title || "Feature Card"} />}
      <h2>{parsedTitle}</h2>
      {description && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: parsedDescription }}
        />
      )}
      {ctas && ctas.length > 0 && (
        <div
          className={classnames(
            styles['buttons-wrapper'],
            styles[ctas.length === 1 ? 'feature-card-toc__full-width' : ''],
          )}
        >
          {ctas.map((cta, index) => (
            <AsyncComponent
              key={cta.system.id}
              kontentItem={cta}
              contentType={cta.system.type}
            />
          ))}
        </div>
      )}
    </div>
  );
};
