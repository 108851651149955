import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getItemWithLinkedItemsByCodename } from './get-item-with-linked-items-by-codename';
import { getDynamicThreshold } from '../utils';
import { CacheConfig } from '@r-and-a-shared-ui/core-caching';

type GetLinkedItemListProps = {
  linkedItems: IContentItem[];
  depthParameter?: number;
  dynamicThreshold?: number;
  cache?: boolean;
  cacheTTL?: number;
};

export const getLinkedItemList = async ({
  linkedItems,
  depthParameter = 3,
  dynamicThreshold = getDynamicThreshold(),
  cache = true,
  cacheTTL = CacheConfig.TTL.PageElement.valueOf()
}: GetLinkedItemListProps) => {
  const linkedItemsPromises: Promise<any>[] = [];
  linkedItems?.forEach((linkedItem: IContentItem, index) => {
    const isBelowThreshold =
      !dynamicThreshold || (dynamicThreshold && index < dynamicThreshold);
    if (isBelowThreshold) {
      linkedItemsPromises.push(
        getItemWithLinkedItemsByCodename({
          codename: linkedItem.system.codename,
          depthParameter,
          cache,
          cacheTTL,
        }),
      );
    } else {
      linkedItemsPromises.push(Promise.resolve(linkedItem));
    }
  });
  return await Promise.all(linkedItemsPromises);
};
