import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../RenderOn/RenderOn';
import classnames from 'classnames';
import styles from './Header.module.scss';
import { sendGAEvent } from '@next/third-parties/google';
import { getPageTrackingDetails } from '@r-and-a-shared-ui/utils';

export const HeaderSignInButton = () => {
  const {
    methods: { federatedSignIn },
    userState: { isAuthenticated },
  } = useAuthContext();

  const handleClick = () => {
    sendGAEvent({ event: 'log_in_click', value: getPageTrackingDetails() });
    federatedSignIn();
  };
  
  return (
    <RenderOn condition={!isAuthenticated} key={'sign-in-button'}>
      <span
        onClick={handleClick}
        data-testid={'HeaderSignInButton'}
        className={classnames(styles.btn, styles['btn--primary'])}
      >
        Login
      </span>
    </RenderOn>
  );
};
