import React, { JSX } from 'react';
import { Link, LinkProps } from '../Link/Link';

type WithOrWithoutLinkProps = {
  url?: LinkProps;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
};

export const WithOrWithoutLink = ({
  url,
  children,
}: WithOrWithoutLinkProps) => {
  if (url) {
    return <Link {...url}>{children}</Link>;
  }

  return children;
};
