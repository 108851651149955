type MembershipLinkPrefixesType = {
  page: string;
  article: string;
  event_page: string;
  home_page: string;
  coach: string,
  reward: string,
  my_account_page: string,
  [key: string]: string;
};

const MEMBERSHIP_LINK_PREFIXES: MembershipLinkPrefixesType = {
  page: '',
  article: 'articles/',
  event_page: 'attend/',
  home_page: '',
  coach: 'coaches/',
  reward: 'rewards-and-benefits/',
  my_account_page: '',
};

export const getPrefixedMembershipInternalLink = (
  pageType?: string,
  urlSlug?: string,
) => {
  const internalPrefix = pageType ? MEMBERSHIP_LINK_PREFIXES[pageType] : '';
  const parsedUrlSlug = pageType === 'home_page' ? '/' : urlSlug;

  const internalHref = parsedUrlSlug
    ? `${internalPrefix}${parsedUrlSlug}`
    : undefined;

  return internalHref;
};