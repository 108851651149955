import { getFirstLinkedItemOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import mapImage from './map-image';
import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const web_banner = (entry: MEMBERSHIP_MODELS.WebBanner) => {
  if (!entry || !entry.elements) {
    return undefined;
  }
  const { elements } = entry;

  const mobile_image_raw = getFirstLinkedItemOrDefault(elements?.mobile_image);
  const window_image_raw = getFirstLinkedItemOrDefault(elements?.window_image);
  const portrait_image_raw = getFirstLinkedItemOrDefault(elements?.portrait_image);
  const mobile_image = mapImage(mobile_image_raw as MEMBERSHIP_MODELS.Image);
  const window_image = mapImage(window_image_raw as MEMBERSHIP_MODELS.Image);
  const portrait_image = mapImage(portrait_image_raw as MEMBERSHIP_MODELS.Image);

  return {
    mobile_image,
    window_image,
    portrait_image,
    link: getFirstMappedLinkedItemOrDefault(elements?.link),
  };
};

export default web_banner;