import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedItemOrDefault,
  getSingleChoiceValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import mapImage from './map-image';
export const article_listicle = (entry: MEMBERSHIP_MODELS.Article) => {
  const imageRaw = getFirstLinkedItemOrDefault(entry.elements.primary_image);
  const image = mapImage(imageRaw as MEMBERSHIP_MODELS.Image);
  
  return {
    image,
    title: entry.elements.title.value,
    mobileTitle: entry.elements.mobile_title.value,
    description: entry.elements.subtitle.value,
    access: getSingleChoiceValue(
      entry?.elements?.membership_based_access__select_access,
    ),
    urlSlug: entry?.elements?.url_slug?.value,
  };
};

export default article_listicle;
