import { WebBanner } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
    component: () => WebBanner,
    mapEntryToProps: (entry: MEMBERSHIP_MODELS.WebBanner) =>
        kontentAiDataMapping.web_banner(entry),
};
