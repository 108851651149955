import { CallOutPanel } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: ()=>CallOutPanel,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.CallOutPanel) =>
    kontentAiDataMapping.call_out_panel(entry),
};
