import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault, getMappedLinkedItems } from '@r-and-a-shared-ui/core-data-mapping';

export const cookie_settings = (data: MEMBERSHIP_MODELS.CookiesSettings) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const ctaProps = {
    title: data.elements.title?.value,
    description: data.elements?.description?.value,
    detailsLink: getFirstMappedLinkedItemOrDefault(data.elements?.details_link),
    detailsDescription: data.elements?.details_description?.value,
    detailsUpdatedDate: data.elements?.details_updated_date?.value,
    acceptAllButtonLabel: data.elements?.accept_all_button_label?.value,
    toggleOnLabel: data.elements?.toggle_on_label?.value,
    toggleOffLabel: data.elements?.toggle_off_label?.value,
    consentCookiesExpiryDate: data.elements?.consent_cookies_expiry_days?.value,

    necessaryCookiesLabel: data.elements?.necessary_cookies_label?.value,
    necessaryCookiesTitle: data.elements?.necessary_cookies_title?.value,
    necessaryCookiesDescription: data.elements?.necessary_cookies_description?.value,
    necessaryCookies: getMappedLinkedItems(data.elements.necessary_cookies_names)?.map((elem: any) => elem?.value),

    performanceCookiesLabel: data.elements?.performance_cookies_label?.value,
    performanceCookiesTitle: data.elements?.performance_cookies_title?.value,
    performanceCookiesDescription: data.elements?.performance_cookies_description?.value,
    performanceCookies: getMappedLinkedItems(data.elements.performance_cookies_names)?.map((elem: any) => elem?.value),
    performanceCookiesLawfulBasis: getSingleChoiceValue(data.elements?.performance_cookies_lawful_basis),
    performanceCookiesGTMID: data.elements?.performance_cookies_google_tag_manager_id?.value,

    functionalCookiesLabel: data.elements?.functional_cookies_label?.value,
    functionalCookiesTitle: data.elements?.functional_cookies_title?.value,
    functionalCookiesDescription: data.elements?.functional_cookies_description?.value,
    functionalCookies: getMappedLinkedItems(data.elements?.functional_cookies_names)?.map((elem: any) => elem?.value),
    functionalCookiesLawfulBasis: getSingleChoiceValue(data.elements?.functional_cookies_lawful_basis)
  };

  return ctaProps;
};

export default cookie_settings;
