import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getMappedLinkedItems, getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';
import {
  getFirstLinkedAssetOrDefault,
  getFirstLinkedItemOrDefault,
  getColorValue,
  getSingleChoiceValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const call_out_panel = (entry: MEMBERSHIP_MODELS.CallOutPanel) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  return {
    backgroundImage: getFirstLinkedAssetOrDefault(elements?.background_image)
      ?.url,
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    mobileBackgroundImage: getFirstLinkedAssetOrDefault(elements?.mobile_background_image)
      ?.url,
    title: elements.title?.value,
    description: elements.description?.value,
    tags: getMappedLinkedItems(elements?.tags),
    link: getFirstMappedLinkedItemOrDefault(elements?.link),
    access: getSingleChoiceValue(
      elements?.membership_based_access__select_access,
    ),
  };
};

export default call_out_panel;
