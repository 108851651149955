import { z } from "zod";
import { userProfileZodObject } from './user-profile-zod-object';

export const SignInInputSchema = z.object({
  username: z.string(),
  password: z.string(),
  clientId: z.string(),
});

export const RegisterInputSchema = z.object({
  callBackUrl: z.string(),
  data: z.object({
    clientId: z.string(),
    password: z.string(),
    redirectUri: z.string().optional(),
    reCaptchaToken: z.string(),
    ...userProfileZodObject
  })
});

export const UpdateInputSchema = z.object({
  callBackUrl: z.string(),
  data: z.object(userProfileZodObject),
  bearerToken: z.string(),
});

export const StoreTokensInputSchema = z.object({
  authorizationCode: z.string(),
  accessToken: z.string(),
  idToken: z.string(),
  refreshToken: z.string(),
});

export const ForgotPasswordInputSchema = z.object({
  callBackUrl: z.string(),
  data: z.object({
    email: z.string(),
    redirectUri: z.string(),
    authorizationCode: z.string(),
    clientId: z.string(),
    state: z.string(),
  })
});

export const AdminResetPasswordInputSchema = z.object({
  callBackUrl: z.string(),
  data: z.object({
    email: z.string(),
    password: z.string(),
    resetPasswordToken: z.string()
  })
});

export const ResetPasswordInputSchema = z.object({
  callBackUrl: z.string(),
  data: z.object({
    email: z.string(),
    confirmationCode: z.string(),
    password: z.string(),
    authorizationCode: z.string(),
    redirectUri: z.string(),
    clientId: z.string()
  })
});

export const NewPasswordRequiredInputSchema = z.object({
  email: z.string(),
  password: z.string(),
  oldPassword: z.string()
});

export const ChangePasswordInputSchema = z.object({
  oldPassword: z.string(),
  newPassword: z.string(),
});

export const VerifyClientInputSchema = z.object({
  clientId: z.string(),
  logoutUri: z.string().optional()
});

export const GenericInputSchema = z.object({
  callBackUrl: z.string(),
  token: z.string(),
});

export const VerifyEmailInputSchema = z.object({
  callBackUrl: z.string(),
  data: z.object({
    kontentItemCodename: z.string(),
    user: z.object({email: z.string()})
  })
});

export const DeleteAccountInputSchema = z.object({
  username: z.string(),
  redirectUri:z.string().optional(),
  clientId: z.string(),
  accessToken:z.string().optional()
});


export const AddUserToAddressBookInputSchema = z.object({
  UserDetails: z.array(z.object({
    Key: z.string(),
    Value: z.string()
  })),
  Email: z.string(),
  AddressBookID: z.number().nullable().optional()
});
