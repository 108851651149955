import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type {
  MEMBERSHIP_MODELS,
  SSO_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: undefined,
  mapEntryToProps: (
    entry: SSO_MODELS.DictionaryItem | MEMBERSHIP_MODELS.DictionaryItem,
  ) => kontentAiDataMapping.dictionary_item(entry),
};
