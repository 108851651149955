import { StringComparisonCriteria, hasValue, isFunction, isNullOrUndefined, stringComparators } from "@r-and-a-shared-ui/utils";
import { BaseFormFieldProps } from "../../../FormField/FormField";
import * as z from 'zod';

export type RefineParams = {
    fieldData: BaseFormFieldProps,
    fieldToCompare?: BaseFormFieldProps,
    dependencyCriteria?: string,
    pattern: RegExp,
  };
  
  
export type CtxRefineParams = {
    value?: string,
    ctx: z.RefinementCtx
  };
  
  
export const superRefineHelper = (ctxRefineParams: CtxRefineParams, refineParams: RefineParams) => {
  const {value, ctx} = ctxRefineParams;
  const {fieldData, fieldToCompare, dependencyCriteria, pattern} = refineParams;
  if (fieldData?.fieldRef?.current === null)
  return z.OK;
  if (!value && !fieldData.isRequired && isNullOrUndefined(fieldToCompare))
    return z.OK;
  
  if (!isNullOrUndefined(fieldToCompare) && hasValue(dependencyCriteria)) {
    const comparator = stringComparators[dependencyCriteria as StringComparisonCriteria];
  
    if (isFunction(comparator)) {
      const elemToCompare = fieldToCompare.fieldRef.current?.firstChild as HTMLInputElement;
    
      if (!comparator(value, elemToCompare?.value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_intersection_types,
          path: [fieldData.name],
          fatal: true,
        });

        return z.INVALID;
      }
    }
  }
  
  if (!pattern.test(value as string)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: [fieldData.name],
      fatal: true
    });
  
    return z.INVALID;
  }  
  
  return z.OK;
};