import { useContext, CSSProperties } from 'react';
import { EnvironmentContext } from '../../contexts';
import { useScreenDetector } from '../../hooks';
import { Link, LinkProps } from '../Link/Link';
import Logo, { LogoProps } from '../Logo/Logo';
import styles from './Footer.style.module.scss';
import { RenderOn } from '../RenderOn/RenderOn';

type Image = { url?: string; alt?: string };

export type FooterProps = {
  allRightReserved?: string;
  backgroundColor?: string;
  backgroundImage?: Image;
  masterLogo?: Image;
  navigationLinks?: LinkProps[];
  navigationTitle?: string;
  oneClubLogo?: Image;
  organizationLogos?: LogoProps[];
  otherLinks?: LinkProps[];
  socialContentLinks?: LinkProps[];
  socialContentTitle?: string;
  socialIconsLinks?: LogoProps[];
};

export const Footer = ({
  oneClubLogo,
  organizationLogos,
  navigationTitle,
  navigationLinks,
  socialContentLinks,
  socialContentTitle,
  otherLinks,
  allRightReserved,
  socialIconsLinks,
  backgroundColor,
  backgroundImage,
  masterLogo,
}: FooterProps) => {
  const { Image } = useContext(EnvironmentContext);
  const { isMobile, isTablet } = useScreenDetector();

  const inlineStyle: CSSProperties = {
    backgroundColor,
    backgroundImage: backgroundImage?.url && `url(${backgroundImage.url})`,
  };

  return (
    <footer data-testid="Footer" className={styles.footer} style={inlineStyle}>
      <div className={styles.container}>
        {(isMobile || isTablet) ? (
          <>
            <LogoBox oneClubLogo={oneClubLogo} />
            <Divider />
            <OrganizationLogosBox organizationLogos={organizationLogos} />
            <Divider />
            <FooterLinksBox
              title={socialContentTitle}
              links={socialContentLinks}
            />
            <Divider />
            <SocialIconLinks socialIconsLinks={socialIconsLinks} />
            <Divider />
            <OtherLinksBox otherLinks={otherLinks} />
            <RenderOn condition={!!masterLogo}>
              <Image
                className={styles['footer-end-logo']}
                src={masterLogo?.url || ''}
                alt={masterLogo?.alt || ''}
                width={149}
                height={26}
              />
            </RenderOn>
            <RenderOn condition={!!allRightReserved}>
              <div className={styles['copyright-box']}>{allRightReserved}</div>
            </RenderOn>
          </>
        ) : (
          <>
            <div className={styles['desktop-top-box']}>
              <div data-testid="desktop-top-box--left">
                <LogoBox oneClubLogo={oneClubLogo} />
                <OrganizationLogosBox organizationLogos={organizationLogos} />
              </div>
              <FooterLinksBox links={navigationLinks} title={navigationTitle} />
              <FooterLinksBox
                title={socialContentTitle}
                links={socialContentLinks}
              />
            </div>
            <Divider />
            <div className={styles['desktop-bottom-box']}>
              <div className={styles['desktop-bottom-box--left']}>
                <OtherLinksBox otherLinks={otherLinks} />
                <RenderOn condition={!!allRightReserved}>
                  <div className={styles['copyright-box']}>
                    {allRightReserved}
                  </div>
                </RenderOn>
              </div>
              <SocialIconLinks socialIconsLinks={socialIconsLinks} />
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

const OtherLinksBox = ({ otherLinks }: { otherLinks?: LinkProps[] }) =>
  otherLinks?.length ? (
    <div className={styles['other-links']}>
      {otherLinks.map((link, index) => (
        <div className={styles['box']} key={`${link?.title} ${index}`}>
          <Link {...link} />
          {index !== otherLinks.length - 1 && (
            <div className={styles['box__divider']} />
          )}
        </div>
      ))}
    </div>
  ) : null;

const SocialIconLinks = ({
  socialIconsLinks,
}: {
  socialIconsLinks?: LogoProps[];
}) =>
  socialIconsLinks?.length ? (
    <div className={styles['icon-links']}>
      {socialIconsLinks.map((iconLink, index) =>
        iconLink ? (
          <Logo {...iconLink} key={`${iconLink?.resource?.title} ${index}`} />
        ) : (
          ''
        ),
      )}
    </div>
  ) : null;

const LogoBox = ({ oneClubLogo }: { oneClubLogo?: Image }) => {
  const { Image } = useContext(EnvironmentContext);

  if (!oneClubLogo) {
    return null;
  }

  return (
    <div className={styles['logo-img']}>
      <Image
        src={oneClubLogo.url || ''}
        alt={oneClubLogo.alt || 'One club'}
        width={195}
        height={70}
        sizes="100vh"
      />
    </div>
  );
};

const OrganizationLogosBox = ({
  organizationLogos,
}: {
  organizationLogos?: LogoProps[];
}) =>
  organizationLogos?.length ? (
    <div className={styles['organization-box']}>
      {organizationLogos?.map(logo => (
        <Logo
          key={logo?.logoDescription}
          resource={logo?.resource}
          logoDescription={logo?.logoDescription}
          logoImage={logo?.logoImage}
        />
      ))}
    </div>
  ) : null;

const Divider = () => <div className={styles['divider']} />;

const FooterLinksBox = ({
  title,
  links,
}: {
  title?: string;
  links?: LinkProps[];
}) => {
  const { isTablet } = useScreenDetector();

  return (
    <RenderOn condition={!!title || !!links?.length}>
      <div className={styles['links-box']}>
        {title && !isTablet && <p>{title}</p>}
        {links?.length && (
          <div className={styles['links-box--grid']}>
            {links.map((link, index) => (
              <Link key={`${link?.title} ${index}`} {...link} />
            ))}
          </div>
        )}
      </div>
    </RenderOn>
  );
};