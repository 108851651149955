import { getItemByCodeName } from './get-item-by-code-name';
import { DictionaryItem, HomePage } from '../models/MEMBERSHIP';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { deliveryClient } from '../config/kontent-delivery-client';
import { adjustSystemProperties } from '../utils';

type GetHomePageNewResponse = {
  pageCodeName: string;
  templateName:string;
};

export const getHomePageNew = async (
  cache = true,
  cacheTTL = CacheConfig.TTL.Page,
  cacheStatus?: CacheStatus
): Promise<GetHomePageNewResponse | undefined> => {
  const cacheKey = 'home_page_slug';

  if (cache) {
    const cachedItem = getCachedItem<GetHomePageNewResponse>(cacheKey, cache);
    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }
  }

  try {
    // we fetch the dictionary containing the home page codename
    const homePageDictionary = await getItemByCodeName<DictionaryItem>(
      'home_page_codename',
    );

    if (!homePageDictionary) {
      return undefined;
    }

    const response = await deliveryClient
      .item<HomePage>(homePageDictionary.elements.value.value)
      .depthParameter(0)
      .elementsParameter(['template'])
      .toPromise();

    adjustSystemProperties(response);

    const result = {
      pageCodeName: homePageDictionary.elements.value.value,
      templateName: response?.data.item.elements.template.value[0],
    };

    if (result.pageCodeName && result.templateName) {
    setCacheItem<GetHomePageNewResponse>(cacheKey, result, cacheTTL, cache);
  }

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return result;
  } catch (error) {
    console.error('Error in getFullHomePage', error);
    throw error;
  }
};
