import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const rich_text = (entry: MEMBERSHIP_MODELS.RichText) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  return {
    content: elements?.content,
  };
};

export default rich_text;
