/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const contentTypeSnippets = {
    /**
     * Field Types
     */
    field_type: {
        codename: 'field_type',
        id: '5e00d8de-bb55-43e9-af24-51966ad8e042',
        externalId: undefined,
        name: 'Field Types',
        elements: {
            /**
             * Type (multiple_choice)
             */
            field_type__type: {
                codename: 'field_type__type',
                id: '1dbe8611-edff-4c5d-b027-0a229af033ae',
                externalId: undefined,
                name: 'Type',
                required: true,
                type: 'multiple_choice'
            }
        }
    },

    /**
     * Form CTA Action Types
     */
    form_cta_action_types: {
        codename: 'form_cta_action_types',
        id: '9d309133-d2eb-4390-9c16-02776cc4bf2c',
        externalId: undefined,
        name: 'Form CTA Action Types',
        elements: {
            /**
             * Action Type (multiple_choice)
             */
            form_cta_action_types__action_type: {
                codename: 'form_cta_action_types__action_type',
                id: '0ac6ad5c-e61f-4b61-892d-9ec24fab47e7',
                externalId: undefined,
                name: 'Action Type',
                required: true,
                type: 'multiple_choice'
            }
        }
    },

    /**
     * SEO
     */
    seo: {
        codename: 'seo',
        id: '9ff1d224-85f3-4453-8830-ad746f2cd237',
        externalId: undefined,
        name: 'SEO',
        elements: {
            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'd9a0c69e-781b-4757-b0c7-ee9b1c89c39a',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'b3c70fb6-1294-4878-a0c9-c118179e780e',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text'
            },

            /**
             * Key Words (text)
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '2c7c7857-24a4-43ff-9b7c-f9004b3775f2',
                externalId: undefined,
                name: 'Key Words',
                required: false,
                type: 'text'
            },

            /**
             * No Index (multiple_choice)
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'bb27d4d7-60c6-4f13-b3d7-0a6b5e1df026',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice'
            },

            /**
             * No Follow (multiple_choice)
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: 'e6e86212-2718-40c6-b044-8939976f0a9e',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice'
            }
        }
    }
} as const;
