/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from 'react';
import {
  Attributes,
  SSOCtaAction,
  useAuthContext,
} from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../RenderOn/RenderOn';
import classnames from 'classnames';
import styles from './MyAccount.module.scss';
import {
  getPageTrackingDetails,
  safeParseJSON,
} from '@r-and-a-shared-ui/utils';
import { sendGAEvent } from '@next/third-parties/google';
import { SSOCta } from '../SSOCTA/SSOCta';

// Remove this after adding all of thes ein kontent ai
const TempDictionaryChamps = {
  womensOpen: "AIG Women's Open",
  theOpen: 'The Open',
  AmateurChampionship: 'R&A Amateur Championships',
  theSeniorOpen: 'The Senior Open',
};
// eslint-disable-next-line @typescript-eslint/ban-types
export type MyAccountPlaceholderProps = {};

export function renderItems(user: any) {
  const preferencesParsed = safeParseJSON(user?.[Attributes.Preferences]);
  const interestsParsed = safeParseJSON(user?.[Attributes.Interests]);

  return (
    <div className={styles.preferences}>
      <div className={styles.preferences__row}>
        <h4 className={styles.preferences__title}>City</h4>
        {user?.[Attributes.City] ? (
          <div className={styles.preferences__label}>
            {user?.[Attributes.City]}
          </div>
        ) : (
          <div className={styles.preferences__label}>-</div>
        )}
      </div>

      <div className={styles.preferences__row}>
        <h4 className={styles.preferences__title}>Country Code</h4>
        {user?.[Attributes.CountryCode] ? (
          <div className={styles.preferences__label}>
            {user?.[Attributes.CountryCode]}
          </div>
        ) : (
          <div className={styles.preferences__label}>-</div>
        )}
      </div>

      <div className={styles.preferences__row}>
        <h4 className={styles.preferences__title}>Favourite Player</h4>
        {interestsParsed?.favoritePlayer ? (
          <div className={styles.preferences__label}>
            {interestsParsed?.favoritePlayer}
          </div>
        ) : (
          <div className={styles.preferences__label}>-</div>
        )}
      </div>

      <div className={styles.preferences__row}>       
        {interestsParsed?.favoritePlayerOther ? (
          <><h4 className={styles.preferences__title}>Favourite Player (Other)</h4><div className={styles.preferences__label}>
            {interestsParsed?.favoritePlayerOther}
          </div></>
        ) : (
           <div ></div>
        )}
      </div>


      <div className={styles.preferences__row}>
        <h4 className={styles.preferences__title}>I&apos;m interested in...</h4>
        {preferencesParsed?.championships?.length > 0 ? (
          <div className={styles.preferences__list}>
            {preferencesParsed?.championships.map(
              (elem: { id: string; value: boolean }) =>
                elem.id && (
                  <div
                    className={styles.preferences__label}
                    key={`${elem?.id}_championships`}
                  >
                    {/* @ts-ignore */}
                    {TempDictionaryChamps[elem?.id]}
                  </div>
                ),
            )}
          </div>
        ) : (
          <div className={styles.preferences__label}>-</div>
        )}
      </div>

      {preferencesParsed !== null && preferencesParsed !== undefined && (
        <div className={styles.preferences__row}>
          <h4 className={styles.preferences__title}>Contact Preferences</h4>
          <div className={styles.preferences__item}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={preferencesParsed?.channels?.directMail}
                disabled
              />
              <span className={styles.slider}></span>
            </label>
            Direct Mail
          </div>

          <div className={styles.preferences__item}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={preferencesParsed?.channels?.sms}
                disabled
              />
              <span className={styles.slider}></span>
            </label>
            SMS
          </div>

          <div className={styles.preferences__item}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={preferencesParsed?.channels?.email}
                disabled
              />
              <span className={styles.slider}></span>
            </label>
            Email
          </div>
        </div>
      )}
      <div className={styles.preferences__row}>
        <h4 className={styles.preferences__title}>I&apos;m here for...</h4>
        <div className={styles.preferences__item}>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={preferencesParsed?.interests?.content}
              disabled
            />
            <span className={styles.slider}></span>
          </label>
          New and Exclusive Content and News / Competitions and Prizes
        </div>

        <div className={styles.preferences__item}>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={preferencesParsed?.interests?.hospitality}
              disabled
            />
            <span className={styles.slider}></span>
          </label>
          Hospitality News and Information
        </div>

        <div className={styles.preferences__item}>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={preferencesParsed?.interests?.retail}
              disabled
            />
            <span className={styles.slider}></span>
          </label>
          Retail and Merchandise Offers
        </div>

        <div className={styles.preferences__item}>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={preferencesParsed?.interests?.tickets}
              disabled
            />
            <span className={styles.slider}></span>
          </label>
          Ticketing
        </div>
      </div>
      <div className={styles.preferences__list}>
        <SSOCta
          action={SSOCtaAction.UPDATE}
          type="button"
          label="Edit Details"
        />
        <SSOCta
          action={SSOCtaAction.CHANGE_PASSWORD}
          type="button"
          label="Change Password"
        />
        <SSOCta
          action={SSOCtaAction.DELETE_ACCOUNT}
          type="button"
          label="Delete Account"
        />
      </div>
    </div>
  );
}

export const MyAccountPlaceholder: FC<MyAccountPlaceholderProps> = () => {
  const {
    methods: { federatedSignIn },
    userState: { isAuthenticated, user },
  } = useAuthContext();

  const handleClick = () => {
    sendGAEvent({ event: 'log_in_click', value: getPageTrackingDetails() });
    federatedSignIn();
  };

  return (
    <>
      <RenderOn condition={isAuthenticated}>{renderItems(user)}</RenderOn>

      <RenderOn condition={!isAuthenticated}>
        <div className={styles.preferences}>
          <div className={styles.preferences__row}>
            <h4>Please click on Login to see your preferences.</h4>
            <span
              onClick={handleClick}
              className={classnames(styles.btn, styles['btn--primary'])}
            >
              Login
            </span>
          </div>
        </div>
      </RenderOn>
    </>
  );
};
