import { useFormContext } from 'react-hook-form';
import FormDropdown from '../FormDropdown/FormDropdown';
import { FormFieldProps } from '../FormField/FormField';
import  React, { useEffect, useState } from 'react';
import FormTextInput from '../FormTextInput/FormTextInput';
import { OTHERFAVORITEPLAYER_IDENTIFIER, OTHEROPTION } from '@r-and-a-shared-ui/utils';

export function FavoritePlayerFormField(props: FormFieldProps) {
  const { watch, setValue,  getValues, formState: { errors } } = useFormContext();
  const { name: favoritePlayerName } = props;

  const favoritePlayerOtherField = props.subfields?.find((field) => field?.name?.includes(OTHERFAVORITEPLAYER_IDENTIFIER)) as FormFieldProps;
  const [inputValue, setInputValue] = useState(getValues(favoritePlayerOtherField?.name) || '');
 
  const favoritePlayerSelected = watch(favoritePlayerName);

  useEffect(() => {
    if (favoritePlayerSelected && (favoritePlayerSelected.value === OTHEROPTION ||favoritePlayerSelected === OTHEROPTION )) {
      setInputValue(getValues(favoritePlayerOtherField?.name) || '');
    }
  }, [favoritePlayerSelected, getValues, favoritePlayerOtherField?.name]);

  useEffect(() => {
    if (favoritePlayerSelected && (favoritePlayerSelected.value === OTHEROPTION ||favoritePlayerSelected === OTHEROPTION )) {
      setValue(favoritePlayerOtherField?.name, inputValue);
    }
  }, [inputValue, setValue, favoritePlayerOtherField?.name, favoritePlayerSelected]);

  return (
    <>
      <FormDropdown {...props} entireObjectSelection menuPlacement="bottom" />
      { favoritePlayerSelected && (favoritePlayerSelected.value === OTHEROPTION ||favoritePlayerSelected === OTHEROPTION ) &&
      (
        <FormTextInput
          {...favoritePlayerOtherField}
          triggerError={!!errors?.[favoritePlayerOtherField.name]}
         
        />
      )
      }
    </>
  );
}

export default FavoritePlayerFormField;
