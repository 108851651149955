import { ResourcesConfig } from 'aws-amplify';

// replace the user pool region, id, and app client id details

export const awsExports: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID || 'eu-west-2_pi5xcjzek',
      userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_CLIENT_ID || '4llgekb7npb4m6u1j6lieoqhl5',
    },
  },
};
