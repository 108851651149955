import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';

export const section_title = (entry: MEMBERSHIP_MODELS.SectionTitle) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }

  const { elements } = entry || {};

  return {
    title: elements?.title?.value,
    backgroundImage: getFirstLinkedAssetOrDefault(
      elements?.background_image,
    )?.url,
  };
};

export default section_title;
