import classnames from 'classnames';
import {
  MembershipTypes,
  useAuthContext,
} from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../RenderOn/RenderOn';
import styles from './Header.module.scss';

export const HeaderUpgradeButton = () => {
  const {
    methods: { upgradeAccount },
    config,
    userState: { membership, isAuthenticated },
  } = useAuthContext();
  const canUpgrade =
    isAuthenticated &&
    (membership.type === MembershipTypes.OneClub ||
      membership.type === MembershipTypes.None);
  return (
    <RenderOn condition={canUpgrade} key={'upgrade-button'}>
      <span
        onClick={() => upgradeAccount(config)}
        data-testid={'HeaderUpgradeButton'}
        className={classnames(styles.btn, styles['btn--primary'])}
      >
        Upgrade
      </span>
    </RenderOn>
  );
};
