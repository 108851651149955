import { addQueryParams, ensureValidUri, hasValue, redirectTo, setSessionStorageItem } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../types";
import { NextStepsAfterSignIn, TEMP_PASSWORD } from "../constants";
import { OauthQuery } from "@r-and-a-shared-ui/auth-client";

export const handleSignInNextSteps = (params: ActionHandlerParams, nextStepAfterSignIn: string | undefined) => {
    const { nextStepsAfterSubmit } = params;
  
    if (
      hasValue(nextStepAfterSignIn) &&
      hasValue(nextStepsAfterSubmit)
    ) {
      let redirectUri = nextStepsAfterSubmit?.find(
        item => item.key === nextStepAfterSignIn,
      )?.item?.urlSlug;
  
      switch (nextStepAfterSignIn) {
        case NextStepsAfterSignIn.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED:
          setSessionStorageItem(TEMP_PASSWORD, params?.submitData?.password);
          break;
        case NextStepsAfterSignIn.MISSING_REQUIRED_MEMBER_FIELDS:
          redirectUri = addQueryParams(ensureValidUri(redirectUri), [
            {
              key: OauthQuery.STORE_TOKENS,
              value: 'true'
            }
          ]);
          break;
        default:
          break;
      }
  
      if (hasValue(redirectUri)){
        redirectTo(redirectUri, true);
        return true;
      }
    }
  
    return false;
  }