import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../RenderOn/RenderOn';
import { UserIconReactSvg } from './UserIconReactSvg';
import { Link, LinkProps } from '../Link/Link';

export const HeaderUserLogo = ({
  myAccountLink,
}: {
  myAccountLink?: LinkProps;
}) => {
  const {
    userState: { isAuthenticated },
  } = useAuthContext();

  return (
    <RenderOn condition={isAuthenticated}>
      <Link
        href={myAccountLink?.href || ''}
        internal={!!myAccountLink?.internal}
        title={myAccountLink?.title || ''}
      >
        <UserIconReactSvg />
      </Link>
    </RenderOn>
  );
};
