import React, { useMemo } from 'react';
import { Elements, IContentItem } from '@kontent-ai/delivery-sdk';
import { ArticleSchemaOrgModel, formatMembershipDate } from '@r-and-a-shared-ui/utils';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';
import { ImageProps, Image } from '../Image/Image';
import SEOWrapper, { SEOWrapperProps } from '../SEOWrapper/SEOWrapper';
import MembershipTypesLogos from '../MembershipTypeLogos/MembershipTypesLogos';
import { RenderOn } from '../RenderOn/RenderOn';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import { RestrictedContentPrompt } from '../RestrictedContentPrompt/RestrictedContentPrompt';
import { useAccessRestrictedContent, useScreenDetector } from '../../hooks';
import { SubHeader } from '../Page/SubHeader';
import { PreFooter } from '../Page/PreFooter';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import styles from './Article.module.scss';
import RichText from '../RichText/RichText';
import useSchemaOrg from '../../hooks/useSchemaOrg';

export interface ArticleProps {
  author: string;
  publishDate: string | null;
  title?: string;
  mobileTitle?: string;
  subtitle?: string;
  primaryImage: ImageProps;
  primaryImageMobile: ImageProps;
  articleTextBody?: any[];
  seo?: SEOWrapperProps;
  access?: keyof typeof RestrictionLevel;
  subheaderElements: IContentItem<any>[];
  sidebar?: any[];
  elementsBelowArticleBody: IContentItem<any>[];
  articlePreview: Elements.RichTextElement;
  schemaOrgContent?: ArticleSchemaOrgModel;
}

const WEB_BANNER_KEY = 'web_banner_placeholder';

export const Article = ({
  author,
  publishDate,
  title,
  subtitle,
  primaryImage,
  primaryImageMobile,
  articleTextBody,
  seo,
  access,
  subheaderElements,
  sidebar,
  elementsBelowArticleBody,
  articlePreview,
  schemaOrgContent
}: ArticleProps) => {

  useSchemaOrg(schemaOrgContent);
  const { isDesktop, isClient } = useScreenDetector();

  const { userHasAccess = true } = useAccessRestrictedContent({
    access,
  });

  const sidebarWebBanners = sidebar?.filter(
    element => element?.system?.type === WEB_BANNER_KEY,
  );

  const formattedDate = publishDate && formatMembershipDate(publishDate);

  const subtitleAndDate =
    subtitle && publishDate
      ? `${subtitle} - ${formattedDate}`
      : publishDate
        ? formattedDate
        : subtitle;

  const articlePrimaryImage = useMemo(() => {
    return isDesktop
      ? primaryImage
      : !isDesktop && primaryImageMobile
        ? primaryImageMobile
        : primaryImage;
  }, [primaryImage, primaryImageMobile, isDesktop]);

  if (!isClient) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <main className={styles.page}>
      <SEOWrapper
        title={seo?.title}
        description={seo?.description}
        keywords={seo?.keywords}
        noindex={seo?.noindex}
        nofollow={seo?.nofollow}
        urlSlug={seo?.urlSlug}
      />
      <SubHeader subheader={subheaderElements} />
      <RenderOn condition={Boolean(primaryImage)}>
        <div className={styles.header}>
          <div className={styles.container}>
            <div className={styles.header__hero}>
              <Image
                {...articlePrimaryImage}
                className={styles.header__hero__image}
              />
              <MembershipTypesLogos
                restrictionLevel={access as keyof typeof RestrictionLevel}
                className={styles['membership-type']}
              />
              <div className={styles.header__hero__shadow}></div>
            </div>
            <div className={styles.header__info}>
              <div className={styles.header__info__content}>
                {title && <h1>{title}</h1>}
                {author && (
                  <div className={styles.header__info__author}>
                    {author && author}
                  </div>
                )}
                <div className={styles.header__info__misc}>
                  {subtitleAndDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </RenderOn>

      <div className={styles.body}>
        <div className={styles.container}>
          <div className={styles.body__content}>
            <div className={styles.body__content__richtext}>
              <RichText content={articlePreview} />
              {!userHasAccess && (
                <div className={styles['article__restricted--overlay']}></div>
              )}
              <RenderOn condition={!!articleTextBody?.length}>
                {userHasAccess &&
                  articleTextBody
                    ?.map(x => {
                      return (
                        <AsyncComponent
                          key={x.system.id}
                          kontentItem={x}
                          contentType={x.system.type}
                        />
                      );
                    })
                    .filter(Boolean)}
                <RestrictedContentPrompt access={access} />
              </RenderOn>
            </div>
            <RenderOn condition={!!sidebar?.length}>
              <div className={styles.body__sidebar}>
                {sidebar?.map(x => {
                  return (
                    <AsyncComponent
                      key={x.system.id}
                      kontentItem={x}
                      contentType={x.system.type}
                    />
                  );
                })}
              </div>
            </RenderOn>
          </div>
        </div>
      </div>
      <PreFooter preFooter={elementsBelowArticleBody} />
      <RenderOn condition={!!sidebarWebBanners?.length}>
        <div className={styles['mobile-banners-container']}>
          {sidebarWebBanners?.map(x => {
            return (
              <AsyncComponent
                key={x.system.id}
                kontentItem={x}
                contentType={x.system.type}
              />
            );
          })}
        </div>
      </RenderOn>
    </main>
  );
};

export default Article;