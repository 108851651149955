import type {
  SSO_MODELS,
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';

export const dictionary_item = (
  data: SSO_MODELS.DictionaryItem | MEMBERSHIP_MODELS.DictionaryItem,
) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    key: elements?.key?.value,
    value: elements?.value?.value,
  };
};

export default dictionary_item;
