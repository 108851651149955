import { useMemo } from 'react';
import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import { getContentMap } from './getContentMap';

export const useTOCMembership = () => {
  const {
    userState: { membership, user },
  } = useAuthContext();

  const { title, subtitle } = useMemo(() => {
    const contentMap = getContentMap(user, membership);
    const level = membership ? membership.type.toLowerCase() : RestrictionLevel.none;

    return contentMap[level];
  }, [membership, user]);

  return { title, subtitle };
};
