import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { CacheConfig, getCachedItem, setCacheItem } from '@r-and-a-shared-ui/core-caching';
import { deliveryClient } from '../../config/kontent-delivery-client';
import { prefixDictionary } from '../../utils/sitemap-utils';

export const getUrlSlugElements = async (
  contentTypes: string[],
  cache = true,
  cacheTTL = CacheConfig.TTL.PageElement.valueOf()
): Promise<IContentItem[] | undefined> => {
  const cacheKey = `kontent-urlSlugElements-${contentTypes.join('-')}`;

  try {
    const cachedItem = getCachedItem<IContentItem[]>(cacheKey, cache);

    if (cachedItem) {
      return cachedItem;
    }

    const response = await deliveryClient
      .items().types(contentTypes)
      .elementsParameter(['url_slug'])
      .depthParameter(0)
      .toPromise();


    const result = response?.data?.items.map((item) => {
      const slug = item?.elements?.['url_slug']?.value;
      const prefix = prefixDictionary?.[item?.system?.type];
      return prefix ? `${prefix}/${slug}` : slug;
    });

    setCacheItem<IContentItem[]>(cacheKey, result, cacheTTL, cache);
    return result;

  } catch (error) {
    console.error('Error in getUrlSlugElements', error);

    throw error;
  }
};
