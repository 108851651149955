import { hasValue, isNullOrUndefined, safeParseJSON } from "./general-utils";

export const getNumberFromLocalStorage = (key: string): number => {
  try {
    return parseInt(localStorage.getItem(key) || '');
  } catch (error) {
    return 0;
  }
};
  
export const setNumberInLocalStorage = (key: string, value: number | undefined) => {
  if(isNullOrUndefined(value)) {
    return;
  }

  try {
    localStorage.setItem(key, value.toString());
  } catch (error) {
    return;
  }
};

export const deleteFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    return;
  }
};

export const deleteItemsFromLocalStorage = (keys: string[]) => {
  if(!hasValue(keys)) {
    return;
  }

  keys.forEach(key => {
    deleteFromLocalStorage(key);
  });
};

export const getStringFromLocalStorage = (key: string): string | undefined => {
  try {
    return localStorage.getItem(key) as string | undefined;
  } catch (error) {
    return '';
  }
};

export const getNrOrNegativeFromLocalStorage = (key: string): number => {
  const value = localStorage.getItem(key) || "";
  const parsedValue = parseInt(value, 10);

  if(parsedValue!==0 && !parsedValue) {
    return -1;
  }
  return parsedValue;
};

export const setLocalStorageItem = (key: string, value: any) => {
  if(isNullOrUndefined(value)) {
    return;
  }
  
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    return;
  }
};

export const setStringLocalStorage = (key: string, value: any) => {
  if (isNullOrUndefined(value)) {
    return;
  }

  let valueToStore;
  if (typeof value === "string") {
    valueToStore = value;
  } else {
    valueToStore = JSON.stringify(value);
  }

  try {
    localStorage.setItem(key, valueToStore);
  } catch (error) {
    return;
  }
};

export const getLocalStorageItem = (key: string, deleteItem = false) => {
  try {
    const item = safeParseJSON(localStorage.getItem(key) as string | undefined);

    if (deleteItem) {
      localStorage.removeItem(key);
    }

    return item;
    
  } catch (error) {
    return undefined;
  }
};

export const getLocalStorageItems = (keys: string[]): { [key: string]: string | undefined } => {
  const result: { [key: string]: string | undefined } = {};

  if(!hasValue(keys)) {
    return {};
  }

  keys.forEach(key => {
    result[key] = getLocalStorageItem(key);
  });

  return result;
};