import { useScreenDetector } from '../../hooks';
import { RenderOn } from '../RenderOn/RenderOn';
import { SSOCTAs } from '../../SSObuttons/SSOCTAs';
import styles from './BurgerTOCComponent.module.scss';
import Image from '../Image/Image';
import OneClubMastercardFull from '../../assets/OneClubMastercardFull.svg';
import GraphicSquareLiliacBg from '../../assets/GraphicSquareLiliacBg.svg';
import { useTOCMembership } from './useTOCMembership';

export function BurgerTOCComponent() {
  const { isMobile } = useScreenDetector();
  const { title, subtitle } = useTOCMembership();

  return (
    <RenderOn condition={isMobile}>
      <div className={styles['wrapper']}>
        <div className={styles['background__image']}>
          <Image
            width={391}
            height={378}
            imageUrl={GraphicSquareLiliacBg}
            alt="Graphic Square Liliac Bg"
          />
        </div>
        <div className={styles['content']}>
          <Image
            width={300}
            height={107}
            imageUrl={OneClubMastercardFull}
            alt="One Club Mastercard Full"
          />
          <div className={styles['title']}>{title}</div>
          <div className={styles['subtitle']}>{subtitle}</div>
          <SSOCTAs />
        </div>
      </div>
    </RenderOn>
  );
}

export default BurgerTOCComponent;
