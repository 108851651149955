import { CacheConfig, type CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { deliveryClient } from '../config/kontent-delivery-client';
import { adjustSystemProperties } from '../utils';

export const getItemWithLinkedItemsByCodename = async <
  Type extends IContentItem,
>({
  codename = '',
  depthParameter = 3,
  cache = true,
  cacheTTL = CacheConfig.TTL.PageElement.valueOf()
}: {
  codename: string;
  depthParameter?: number;
  cache?: boolean;
  cacheTTL?: number;
}, 
cacheStatus?: CacheStatus): Promise<Type | undefined> => {

  const cacheKey = `kontent-itemWithLinkedItems-${codename}${depthParameter ? `-depth-${depthParameter}` : ''} `;

  try {
    const cachedItem = getCachedItem<Type>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const response = await deliveryClient
      .item<Type>(codename)
      .depthParameter(depthParameter)
      .toPromise();

    adjustSystemProperties(response?.data?.item);

    const result = response?.data?.item as Type;

    setCacheItem<Type>(cacheKey, result, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });
    return result;

  } catch (error) {
    console.error('Error in getItemWithLinkedItemsByCodename', error);
    throw error;
  }
};
