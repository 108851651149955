import { isNullOrUndefined } from "./general-utils";

export const toggleCssClass = (element: HTMLElement | null, className: string | null, criteria: boolean) => {
  if(!element || !className) {
    return;
  }

  if (criteria) {
    if (!element.classList.contains(className)) {
      element.classList.add(className);
    }
  }
  else {
    if (element.classList.contains(className)) {
      element.classList.remove(className);
    }
  }
};

export const setHrefWithQueryOnAnchor = (htmlString: string | undefined, query: string) => {
  if(isNullOrUndefined(htmlString)) {
    return undefined;
  }
  
  const elem = document.createElement('div');
  elem.innerHTML = htmlString;
  const anchorElement = elem.getElementsByTagName('a')[0];
  
  if(isNullOrUndefined(anchorElement)) {
    return undefined;
  }

  if(query) {
    anchorElement.setAttribute('href', anchorElement.href + query);
  }

  return anchorElement;
};

export const isAutoFilled = (element: HTMLElement | null): boolean | undefined => {
  try {
    return element?.matches(':autofill');

  } catch (err) {
    try {
      return element?.matches(':-webkit-autofill');

    } catch (er) {
      return false;
    }
  }
};