/* eslint-disable no-debugger */
import { hasValue, isNullOrUndefined } from "./general-utils";
import { AxiosError, AxiosResponse } from 'axios';

export interface ResponseModel {
  hasErrors?: boolean,
  statusCode?: string,
  errorMessages?: string[],
  data?: any
}

export const handleAxiosResponse = (response: AxiosResponse): ResponseModel => {
  const { data } = response;

  if (hasValue(data) && hasValue(data.errors)) {
    return {
      hasErrors: true,
      statusCode: response.status.toString(),
      errorMessages: Object.values(data.errors),
      data,
    } as ResponseModel;
  }

  return { hasErrors: false, data } as ResponseModel;
};

export function handleError(error: AxiosError) {
  console.error('AxiosError on request with path:', error.request.path);
  console.error('API call failed. ' + error.message);
  console.error('Full Error: ', error);
  const { response } = error;
  const errorsInfo = getErrorsInfo(response);

  console.log('Errors Info: ', errorsInfo);
  return {
    hasErrors: true,
    statusCode: response?.status.toString() || '500',
    errorMessages: errorsInfo,
    data: response?.data
  } as ResponseModel;
}

export const buildFormData = (data: object): FormData => {
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    if (!isNullOrUndefined(data[key as keyof typeof data])) {
      formData.append(key, data[key as keyof typeof data]);
    }
  });

  return formData;
};

export const getErrorsInfo = (response: AxiosResponse | undefined): string[] => {
  if (isNullOrUndefined(response) || isNullOrUndefined(response?.data)) {
    return ['Something went wrong.'];
  }

  const data = response.data;
  if (isNullOrUndefined(data.errors)) {
    return Array.isArray(data) ? data : [data];
  }

  const errors = data.errors;
  if (Array.isArray(errors)) {
    return errors as string[];
  }
  const stringErrors: string[] = [];
  Object.keys(errors).forEach((key) => stringErrors.push(errors[key].join('\n')));
  return stringErrors;
};

export const getSSOUrl = (path?: string): string => {
  const ssoApiDomain = process.env['NEXT_PUBLIC_SSO_API_DOMAIN'] || process.env['SSO_API_DOMAIN'] || '';

  if (!path) {
    
    if(!hasValue(ssoApiDomain))
      console.error('NEXT_PUBLIC_SSO_API_DOMAIN is not defined');

    return ssoApiDomain;
  }
  
  return `${ssoApiDomain}${path}`;
};