import { useContext, useMemo, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { EnvironmentContext } from '../../../contexts';

import styles from './EventHeroAsset.module.scss';
import { RenderOn } from '../../RenderOn/RenderOn';
import { useScreenDetector } from 'libs/common-ui/src/hooks';

export interface EventHeroAssetProps {
  backgroundAsset?: { url?: string; type?: string; alt?: string | null };
  mobileImgVideoPreview?: { url?: string; alt?: string | null };
  playVideoText?: string;
}

const EventHeroAsset = ({
  backgroundAsset,
  mobileImgVideoPreview,
  playVideoText,
}: EventHeroAssetProps) => {
  const { Image } = useContext(EnvironmentContext);
  const [showVideo, setShowVideo] = useState(false);
  const { isDesktop } = useScreenDetector();

  const primaryImage = useMemo(() => {
    return isDesktop
      ? backgroundAsset
      : !isDesktop && mobileImgVideoPreview?.url
        ? mobileImgVideoPreview
        : backgroundAsset;
  }, [backgroundAsset, mobileImgVideoPreview, isDesktop]);

  const showVideoOnMobile =
    showVideo || (!isDesktop && !mobileImgVideoPreview?.url);

  return (
    <RenderOn condition={!!backgroundAsset}>
      <div className={styles.media}>
        {backgroundAsset?.type === 'video/mp4' ? (
          <div className={styles['media--has-video']}>
            <div
              className={classnames(styles['media__video'], {
                [styles['media__video--show']]: showVideoOnMobile,
              })}
            >
              <video
                muted
                autoPlay
                playsInline
                loop
                // eslint-disable-next-line react/no-unknown-property
                x-webkit-airplay="allow"
                preload="none"
                width="100%"
                height="100%"
                src={backgroundAsset?.url}
                controls={showVideoOnMobile}
              />
              <p></p>
            </div>

            {mobileImgVideoPreview?.url && (
              <div
                className={classnames(styles['media__cover'], {
                  [styles['media__cover--hide']]: showVideo,
                })}
              >
                <Image
                  src={mobileImgVideoPreview?.url || ''}
                  alt={mobileImgVideoPreview?.alt || ''}
                  width={0}
                  height={0}
                  sizes="100vw"
                />
                <p></p>
              </div>
            )}

            {mobileImgVideoPreview?.url && (
              <button
                title={playVideoText}
                onClick={() => setShowVideo(!showVideo)}
                className={classnames(styles['media__button'], {
                  [styles['media__button--hide']]: showVideo,
                })}
              >
                <FontAwesomeIcon icon={faPlay} />
              </button>
            )}
          </div>
        ) : (
          <div className={styles['media--has-image']}>
            <div className={styles['media__hero-image']}>
              <Image
                src={primaryImage?.url || ''}
                alt={primaryImage?.alt || ''}
                width={0}
                height={0}
                sizes="100vw"
              />
              <p></p>
            </div>
          </div>
        )}
      </div>
    </RenderOn>
  );
};

export default EventHeroAsset;
