import React from 'react';
import { deleteFromLocalStorage, deleteFromSessionStorage, hasValue, isNullOrUndefined, setStringLocalStorage } from '@r-and-a-shared-ui/utils';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import {
  federatedSignIn,
  federatedSignOut,
  signUp,
  handleOauthSignedIn,
  handleOauthSignedOut,
  updateAccountData,
  upgradeAccount,
  changePassword,
  deleteAccount,
} from './authProviderMethods';
import { Attributes, CognitoUser, initialMembership } from '../types';
import {
  getAccessToken,
  getHighestMembership,
  getIdToken,
  tryGetRefreshToken,
  getMembershipStartingDate,
  USER_ID,
  ENDEAVOR_ACCESS_TOKEN,
} from '../utils';
import { fetchUserAttributes } from 'aws-amplify/auth';

export interface AuthProviderProps {
  children?: ReactNode;
  awsExports?: ResourcesConfig;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  awsExports,
}) => {
  if (!isNullOrUndefined(awsExports)) {
    Amplify.configure(awsExports, { ssr: true });
  }

  const config = awsExports!.Auth!;

  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const [membership, setMembership] = useState(initialMembership);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<string | undefined>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [idToken, setIdToken] = useState<string | undefined>();

  const setAuthState = async (cognitoUser: CognitoUser) => {
    setUser(cognitoUser);
    setMembership(getHighestMembership(cognitoUser));
    setIsAuthenticated(true);
    setRefreshToken(await tryGetRefreshToken());
    setAccessToken(await getAccessToken());
    setIdToken(await getIdToken());
    if (cognitoUser && cognitoUser[Attributes.SsoUserId]) {
      const userId = String(cognitoUser[Attributes.SsoUserId]);
      setStringLocalStorage(USER_ID, userId);
    }
  };

  const resetAuthState = () => {
    setUser(undefined);
    setMembership(initialMembership);
    setIsAuthenticated(false);
    deleteFromLocalStorage(USER_ID);
    deleteFromSessionStorage(ENDEAVOR_ACCESS_TOKEN);
  };

  const checkAuthStatus = useCallback(async () => {
    await fetchUserAttributes()
      .then(user => {
        const mappedUser = user as CognitoUser;
        if (!hasValue(mappedUser)) {
          resetAuthState();
        }

        setAuthState(mappedUser);
      })
      .catch(e => {
        resetAuthState();
      });
  }, []);

  const membershipStartedAtCallback = useCallback(async () => {
    if(!user) {
      return;
    }
    getMembershipStartingDate(user, membership.type, config)
      .then((response): void => {
        setMembership({...membership, startedAt: response});
      });
  }, [user, config, membership]);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    membershipStartedAtCallback();
  }, [user]);
  
  return (
    <AuthContext.Provider
      value={{
        methods: {
          federatedSignIn,
          federatedSignOut,
          signUp,
          updateAccountData,
          upgradeAccount,
          handleOauthSignedIn,
          handleOauthSignedOut,
          changePassword,
          deleteAccount
        },
        userState: {
          user,
          membership,
          isAuthenticated,
          refreshToken,
          accessToken,
          idToken,
          checkAuthStatus,
        },
        config,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
