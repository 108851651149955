import { ArticleHighlight } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.Article) =>
    kontentAiDataMapping.article_highlight(entry),
  component: ()=>ArticleHighlight,
  depthParameter: 4
};
