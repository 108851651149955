import * as dotenv from 'dotenv';
import {
  ElementModels,
  Elements,
  IContentItem,
  IContentItemElements,
} from '@kontent-ai/delivery-sdk';
import {
  defaultDynamicThreshold,
  hasValue,
  isNullOrUndefined,
  toCamelCase,
} from '@r-and-a-shared-ui/utils';
import * as models from '../models';
dotenv.config();

export const getDynamicThreshold = () => {
  let dynamicThreshold = defaultDynamicThreshold;
  try {
    dynamicThreshold = parseInt(
      // eslint-disable-next-line no-undef
      process?.env?.['NEXT_PUBLIC_DYNAMIC_LOAD_THRESHOLD'] ?? `${defaultDynamicThreshold}`,
    );
  } catch {
    dynamicThreshold = defaultDynamicThreshold;
  }
  
  return dynamicThreshold;
};

// Get Kontent AI Field Values
export const getTrueFalseSingleChoiceValue = (
  elementLimitedToSingleChoice: Elements.MultipleChoiceElement | undefined,
): boolean => {
  if (!elementLimitedToSingleChoice || !elementLimitedToSingleChoice.value) {
    return false;
  }

  const trueElements: string[] = ['yes', 'true'];

  return trueElements.includes(
    elementLimitedToSingleChoice.value[0]?.codename?.toLocaleLowerCase(),
  );
};

export const getSingleChoiceValue = (
  elementLimitedToSingleChoice: Elements.MultipleChoiceElement,
): string | undefined => {
  if (!elementLimitedToSingleChoice) {
    return undefined;
  }

  return elementLimitedToSingleChoice?.value
    ? elementLimitedToSingleChoice.value[0]?.codename
    : undefined;
};

export const getMultipleChoiceValues = (
  elementMultipleChoice: Elements.MultipleChoiceElement,
): string[] | undefined => {
  if (!elementMultipleChoice) {
    return undefined;
  }

  return elementMultipleChoice?.value
    ? elementMultipleChoice.value.map(element => element.codename)
    : undefined;
};

export const getTextItemValue = (
  kontentItem: IContentItem<IContentItemElements> | undefined,
) => {
  if (isNullOrUndefined(kontentItem)) {
    return undefined;
  }

  if (
    isItemOfType<
      models.MEMBERSHIP_MODELS.TextItem | models.SSO_MODELS.TextItem
    >(kontentItem, models.MEMBERSHIP_MODELS.contentTypes.text_item?.codename)
  ) {
    return kontentItem?.elements?.value?.value;
  }

  return undefined;
};

export const getTextItemsValue = <
  TypeIn extends IContentItem<IContentItemElements>,
>(
  kontentItem: Elements.LinkedItemsElement<TypeIn> | undefined,
) => {
  const textItems: TypeIn[] | undefined = kontentItem?.linkedItems;

  if (isNullOrUndefined(textItems)) {
    return undefined;
  }

  const mappedTextItems: (string | undefined)[] = [];

  textItems?.forEach(textItem => {
    mappedTextItems.push(getTextItemValue(textItem));
  });

  return mappedTextItems;
};

export const getFirstLinkedItemOrDefault = (
  linkedItemsElement?: Elements.LinkedItemsElement,
) => {
  if (
    !hasValue(linkedItemsElement) ||
    !hasValue(linkedItemsElement?.linkedItems)
  ) {
    return undefined;
  }

  return linkedItemsElement?.linkedItems[0];
};

export const getFirstLinkedAssetOrDefault = (
  assetElement: Elements.AssetsElement,
): ElementModels.AssetModel | undefined => {
  if (!hasValue(assetElement) || !hasValue(assetElement?.value)) {
    return undefined;
  }

  return assetElement?.value[0];
};

export const isItemOfType = <Type extends IContentItem<IContentItemElements>>(
  item: IContentItem<IContentItemElements> | undefined,
  typeCodeName: string,
): item is Type => {
  if (!hasValue(item) && !hasValue(typeCodeName)) {
    return false;
  }

  return item?.system?.type === typeCodeName;
};

export const convertToCamelCaseId = (
  name: string | undefined,
): string | undefined => {
  if (!name) {
    return undefined;
  }

  const unwantedCharacters = /[-_|/.,+(*)&^%$#@!'\\;:"~]/g;

  return toCamelCase(name.replace(unwantedCharacters, ' '));
};

export const getColorValue = (
  kontentItem: IContentItem<IContentItemElements> | undefined,
) => {
  if (isNullOrUndefined(kontentItem)) {
    return undefined;
  }

  if (
    isItemOfType<models.MEMBERSHIP_MODELS.Color | models.SSO_MODELS.Color>(
      kontentItem,
      models.SSO_MODELS.contentTypes.color?.codename,
    )
  ) {
    return kontentItem?.elements?.color_code?.value;
  }

  return undefined;
};

export const getCallbackURLValue = (
  kontentItem: IContentItem<IContentItemElements> | undefined,
) => {
  if (isNullOrUndefined(kontentItem)) {
    return undefined;
  }

  if (
    isItemOfType<
      models.MEMBERSHIP_MODELS.CallbackURL | models.SSO_MODELS.CallbackURL
    >(kontentItem, models.SSO_MODELS.contentTypes.callback_url?.codename)
  ) {
    return kontentItem?.elements?.value?.value;
  }

  return undefined;
};

export const adjustSystemProperties = (obj: any) => {
  // Check if the current level is an object
  if (obj !== null && typeof obj === 'object') {
    // Iterate over each property of the object
    Object.keys(obj).forEach(key => {
      // Check if the current property is 'system'
      if (key === 'system') {
        // Adjust the structure of the 'system' object by removing the unnecessary properties
        delete obj[key].lastModified;
        delete obj[key].collection;
        delete obj[key].language;
        delete obj[key].name;
        delete obj[key].sitemapLocations;
        delete obj[key].workflowStep;
        delete obj[key].workflow;
      } else if (key === 'elements') {
        for (const elementKey in obj[key]) {
          if (obj[key][elementKey].type === 'modular_content') {
            adjustSystemProperties(obj[key]['linkedItems']);
          }
        }
      }
    });
  } else if (Array.isArray(obj)) {
    // If the current level is an array, recursively adjust each element
    obj.forEach(item => adjustSystemProperties(item));
  }
};
