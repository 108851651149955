import { HomePageProps } from './types';
import { FC, useEffect, useState } from 'react';
import { SubHeader } from '../Page/SubHeader';
import { HomePageBodyContent } from './HomePageBodyContent';
import { PreFooter } from '../Page/PreFooter';
import styles from './HomePage.module.scss';
import classnames from 'classnames';

export const HomePageDesktop: FC<
  Pick<HomePageProps, 'subheader' | 'sidebar' | 'body' | 'preFooter'>
> = ({ subheader, sidebar, body, preFooter }) => {
  const [inBrowser, setInBrowser] = useState(false);
  useEffect(() => {
    setInBrowser(true);
  },[]);
  return (
    <>
      <SubHeader subheader={subheader} />
      <main data-testid={'homePageDesktop'} className={classnames(styles.wrapper, inBrowser?styles['wrapper_visible']:'')}>

        <div className={styles.container}>
          <HomePageBodyContent body={body} sidebar={sidebar} />
        </div>
        <PreFooter preFooter={preFooter} />
      </main>
    </>
  );
};
