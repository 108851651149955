import { MEMBERSHIP_MODELS } from "../../models";
import * as process from 'process';

export const getEndeavorConfig = (data?: MEMBERSHIP_MODELS.MembershipConfiguration) => {
  const url = data?.elements.base_url?.linkedItems?.[0]?.elements?.value?.value || process.env["NEXT_PUBLIC_ENDEAVOR_FRONT_OFICE_URL"];
  const realm = data?.elements.realm?.linkedItems?.[0]?.elements?.value?.value || process.env["NEXT_PUBLIC_ENDEAVOR_REALM"];
  const xApiKey = data?.elements.api_key?.linkedItems?.[0]?.elements?.value?.value || process.env["ENDEAVOR_API_KEY"];

  return {
    url, realm, xApiKey
  };
};