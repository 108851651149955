import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getParameterByName, hasValue, isAutoFilled, toggleCssClass } from '@r-and-a-shared-ui/utils';
import styles from './FormTextInput.module.scss';
import { BaseFormFieldProps } from '../FormField/FormField';
import { useFormContext } from 'react-hook-form';
import { FormFieldIconParams, formFieldIcons } from '../config/form-field-icons';
import { FormFieldTypes } from '../config/form-field-config';
import { getErrorMessageForCurrentField } from '../../helpers/form-helpers';

export type FormTextInputProps = BaseFormFieldProps;

export const FormTextInput = (props: FormTextInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    name,
    fieldRef,
    placeholder,
    label,
    isDisabled,
    fieldType,
    queryParamKey,
    isHidden
  } = props;

  const {
    register,
    formState: { errors },
    setValue,
    getFieldState,
    watch,
  } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    if (hasValue(queryParamKey)) {
      setValue(name, getParameterByName(queryParamKey));
    }
  }, [name, queryParamKey, setValue]);
  
  const checkAutoFill = useCallback(() => {
    const isFilled = isAutoFilled(inputRef?.current);
    toggleCssClass(
        inputRef?.current as HTMLElement | null,
        styles['-hasvalue'],
        isFilled || hasValue(value),
    );
  }, [inputRef, value]);

  useLayoutEffect(() => {
    // Hacked Chrome and Edge bugs on autofill
    const checkAutoFillTimeout = setTimeout(checkAutoFill, 1000);

    return () => {
      clearTimeout(checkAutoFillTimeout);
    };
  }, [checkAutoFill]);

  const { ref, ...rest } = register(name);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formFieldIconParams: FormFieldIconParams = {
    fieldHasError: hasValue(errors?.[name]),
    showPassword,
    toggleShowPassword,
    className: styles['form-element__icon'],
  };

  if (isHidden) {
    return null;
  }

  return (
    <div
      ref={fieldRef}
      className={`${styles['form-element']} ${styles['form-input']} ${
        errors?.[name] ? styles['form-element__has-error'] : ''
      }`}
    >
      <input
        id={name}
        className={`${styles['form-element__input']}`}
        type={fieldType === 'password' && showPassword ? 'text' : fieldType}
        autoComplete={value}
        defaultValue={value}
        placeholder={placeholder}
        disabled={isDisabled}
        style={{ opacity: !hasValue(label) ? 1 : undefined }}
        {...rest}
        ref={e => {
          ref(e);
          inputRef.current = e;
        }}
      />
      {
        formFieldIcons[fieldType as FormFieldTypes]?.(formFieldIconParams)
      }
      <div className={styles['form-element__bar']}></div>
      {hasValue(label) && (
        <label className={styles['form-element__label']} htmlFor={name}>
          {label}
        </label>
      )}
      {errors?.[name] ? (
        <small className={styles['form-element__hint']}>
          {getErrorMessageForCurrentField(getFieldState, props)}
        </small>
      ) : null}
    </div>
  );
};

export default FormTextInput;
