'use client';
import React, { useLayoutEffect } from 'react';
import styles from './DotDigitalForm.module.scss';

type EmbedDotDigitalFormProps = {
  scriptSrc: string;
  formId: string;
};

export const DotDigitalForm = ({
  scriptSrc,
  formId,
}: EmbedDotDigitalFormProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {

    if (!ref.current) return;

    const timeoutId = setTimeout(() => {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.id = formId;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        console.log('Dot Digital Script loaded successfully');
      };
      
      script.onerror = (error) => {
        console.error('Dot Digital Script failed to load', error);
      };

      ref.current?.appendChild(script);
    },20);

    return () => {
      clearTimeout(timeoutId);
      ref.current?.querySelectorAll('script').forEach(el => {
        el.remove();
      });
    };
  }, [formId, scriptSrc]);
  return (
    <div
      ref={ref}
      id={`dotdigital-form-${formId}`}
      className={styles['dotdigital-form-container']}
      suppressHydrationWarning
    ></div>
  );
};
