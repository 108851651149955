/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const contentTypes = {
    /**
     * Alert bar
     */
    alert_bar: {
        codename: 'alert_bar',
        id: '4dcff65c-991a-49ca-a55e-bf01423c0209',
        externalId: undefined,
        name: 'Alert bar',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: '8bcc33a1-b829-4730-9a2f-9976d36a4789',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '574f2f4e-611e-451b-b33b-f3a8349478f6',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Countdown (date_time)
             */
            countdown: {
                codename: 'countdown',
                id: '691f7208-1aa6-4fa7-a5e8-0eeb86d374de',
                externalId: undefined,
                name: 'Countdown',
                required: false,
                type: 'date_time'
            },

            /**
             * Day label (text)
             */
            day_label: {
                codename: 'day_label',
                id: '8fa37fda-bcc3-442a-8727-a8a8f0f85fff',
                externalId: undefined,
                name: 'Day label',
                required: false,
                type: 'text'
            },

            /**
             * Days label (text)
             */
            days_label: {
                codename: 'days_label',
                id: '7f6bef00-e882-4311-b0e9-774c048cca82',
                externalId: undefined,
                name: 'Days label',
                required: false,
                type: 'text'
            },

            /**
             * Hour label (text)
             */
            hour_label: {
                codename: 'hour_label',
                id: '3ba5b442-0893-4f8c-a4b9-bcac762de13a',
                externalId: undefined,
                name: 'Hour label',
                required: false,
                type: 'text'
            },

            /**
             * Hours label (text)
             */
            hours_label: {
                codename: 'hours_label',
                id: '76df4667-a291-4a64-832e-f3f118d00f58',
                externalId: undefined,
                name: 'Hours label',
                required: false,
                type: 'text'
            },

            /**
             * Minute label (text)
             */
            minute_label: {
                codename: 'minute_label',
                id: '1d322fb0-ad9c-465c-bb73-6b3508aee204',
                externalId: undefined,
                name: 'Minute label',
                required: false,
                type: 'text'
            },

            /**
             * Minutes label (text)
             */
            minutes_label: {
                codename: 'minutes_label',
                id: '75b99dcb-bedd-4b47-a030-f0f2afb79b04',
                externalId: undefined,
                name: 'Minutes label',
                required: false,
                type: 'text'
            },

            /**
             * Text (rich_text)
             */
            text: {
                codename: 'text',
                id: '2decc9e5-0cad-44cd-bb2f-4af933c0d068',
                externalId: undefined,
                name: 'Text',
                required: false,
                type: 'rich_text'
            },

            /**
             * Text Color (modular_content)
             */
            text_color: {
                codename: 'text_color',
                id: 'efb3e61a-4bed-4433-a2a3-41038b0ee151',
                externalId: undefined,
                name: 'Text Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Text Font (modular_content)
             */
            text_font: {
                codename: 'text_font',
                id: 'f2de1739-e302-4bd8-b688-bb88b3ead8b2',
                externalId: undefined,
                name: 'Text Font',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Article
     */
    article: {
        codename: 'article',
        id: 'db02d65e-1b0e-4db9-b46e-c7aef93d3b62',
        externalId: undefined,
        name: 'Article',
        elements: {
            /**
             * Article preview (rich_text)
             */
            article_preview: {
                codename: 'article_preview',
                id: 'f64977a7-d8d7-4bdf-b214-e69a9ce58308',
                externalId: undefined,
                name: 'Article preview',
                required: true,
                type: 'rich_text'
            },

            /**
             * Article text body (modular_content)
             *
             * Allowed elements: Rich text, Image
             */
            article_text_body: {
                codename: 'article_text_body',
                id: '9cbe883e-c60e-4318-b3eb-586b2061a792',
                externalId: undefined,
                name: 'Article text body',
                required: false,
                type: 'modular_content'
            },

            /**
             * Author (modular_content)
             */
            author: {
                codename: 'author',
                id: '854f7cf5-9462-49ff-8c23-ee9502afddef',
                externalId: undefined,
                name: 'Author',
                required: true,
                type: 'modular_content'
            },

            /**
             * Elements below article body (modular_content)
             *
             * Allowed elements: Recommended Articles
             */
            elements_below_article_body: {
                codename: 'elements_below_article_body',
                id: '764485cc-686e-470e-bbea-27abfa22d19b',
                externalId: undefined,
                name: 'Elements below article body',
                required: false,
                type: 'modular_content'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Mobile Title (text)
             */
            mobile_title: {
                codename: 'mobile_title',
                id: 'f5ab0e7c-3884-4e74-b003-799a5f9e5fdb',
                externalId: undefined,
                name: 'Mobile Title',
                required: false,
                type: 'text'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Primary image (modular_content)
             */
            primary_image: {
                codename: 'primary_image',
                id: 'f0fc156b-5076-4453-acfa-25eaf66e4af3',
                externalId: undefined,
                name: 'Primary image',
                required: true,
                type: 'modular_content'
            },

            /**
             * Primary Image mobile (modular_content)
             */
            primary_image_mobile: {
                codename: 'primary_image_mobile',
                id: 'c2adc052-6e40-4f2a-97ca-593d82e2ec4b',
                externalId: undefined,
                name: 'Primary Image mobile',
                required: false,
                type: 'modular_content'
            },

            /**
             * Publish date (date_time)
             */
            publish_date: {
                codename: 'publish_date',
                id: '99aeeeb4-5c4a-4b16-b171-c77db5aad4d2',
                externalId: undefined,
                name: 'Publish date',
                required: true,
                type: 'date_time'
            },

            /**
             * Select access (multiple_choice)
             *
             * If Standard or Advantage is selected the access to the content will be limited to the logged in user membership level.
             */
            membership_based_access__select_access: {
                codename: 'membership_based_access__select_access',
                id: 'b1494d90-9c32-4e37-971d-675996e05481',
                externalId: undefined,
                name: 'Select access',
                required: false,
                type: 'multiple_choice',
                options: {
                    none: {
                        name: 'No restrictions',
                        id: 'ffa1df83-f8af-480a-956d-5544ed4644a4',
                        codename: 'none',
                        externalId: undefined
                    },
                    oneclub: {
                        name: 'One Club',
                        id: '0d8f00cc-9438-4d95-843a-f007e4c3c71a',
                        codename: 'oneclub',
                        externalId: undefined
                    },
                    oneclubadvantage: {
                        name: 'One Club Advantage',
                        id: '9c47187e-b0dc-45bd-97c4-d86cd7aa65c3',
                        codename: 'oneclubadvantage',
                        externalId: undefined
                    }
                },
                snippetCodename: 'membership_based_access'
            },

            /**
             * Sidebar (modular_content)
             *
             * Elements on the right side of the article text body
             */
            sidebar: {
                codename: 'sidebar',
                id: 'faaa0ff0-950b-450b-bf03-07525da798d2',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subheader elements (modular_content)
             *
             * Elements which are below the header and above the article primary image.Allowed element: Content list of Alerts bar which is used on all pages.
             */
            subheader_elements: {
                codename: 'subheader_elements',
                id: '5e462caf-6608-4422-8a86-68ccb744216a',
                externalId: undefined,
                name: 'Subheader elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: 'e0aaa797-2f5b-4387-bfb4-127edfdd0b3a',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Template (modular_content)
             */
            template: {
                codename: 'template',
                id: '0cfa85ed-5afe-4711-9af7-ffa95a8893be',
                externalId: undefined,
                name: 'Template',
                required: true,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '18c6c046-d5a2-40b2-ae58-cea3c63b9c13',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '93e47c49-96df-4890-a716-a88da0d30531',
                externalId: undefined,
                name: 'URL slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     * Article author
     */
    article_author: {
        codename: 'article_author',
        id: '8c215d7c-bc1c-4126-8506-498a3310c674',
        externalId: undefined,
        name: 'Article author',
        elements: {
            /**
             * First Name (text)
             */
            name: {
                codename: 'name',
                id: '6178aa25-5dc1-4d02-9a46-bbd34cd5e2a2',
                externalId: undefined,
                name: 'First Name',
                required: true,
                type: 'text'
            },

            /**
             * Last Name (text)
             */
            surname: {
                codename: 'surname',
                id: 'c494b19f-0d44-4137-a46e-ea8472705489',
                externalId: undefined,
                name: 'Last Name',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Call Out Panel
     */
    call_out_panel: {
        codename: 'call_out_panel',
        id: '7311c101-581c-496e-a537-3b67aa8afefd',
        externalId: undefined,
        name: 'Call Out Panel',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: '5dab3c93-3251-4832-94c1-5d4004d0b81d',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Background image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: 'e6e4222c-d8ad-41bd-9bf4-6e9ed601b21a',
                externalId: undefined,
                name: 'Background image',
                required: false,
                type: 'asset'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: '15189c1e-b7b3-4e05-a7a4-451b046b2519',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Link (modular_content)
             */
            link: {
                codename: 'link',
                id: 'a2530af9-a936-447c-a907-aa85a1e85c92',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Mobile Background Image (asset)
             */
            mobile_background_image: {
                codename: 'mobile_background_image',
                id: 'a4fda147-7dfa-4660-b0d1-9e4415fc6df8',
                externalId: undefined,
                name: 'Mobile Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Select access (multiple_choice)
             *
             * If Standard or Advantage is selected the access to the content will be limited to the logged in user membership level.
             */
            membership_based_access__select_access: {
                codename: 'membership_based_access__select_access',
                id: 'b1494d90-9c32-4e37-971d-675996e05481',
                externalId: undefined,
                name: 'Select access',
                required: false,
                type: 'multiple_choice',
                options: {
                    none: {
                        name: 'No restrictions',
                        id: 'ffa1df83-f8af-480a-956d-5544ed4644a4',
                        codename: 'none',
                        externalId: undefined
                    },
                    oneclub: {
                        name: 'One Club',
                        id: '0d8f00cc-9438-4d95-843a-f007e4c3c71a',
                        codename: 'oneclub',
                        externalId: undefined
                    },
                    oneclubadvantage: {
                        name: 'One Club Advantage',
                        id: '9c47187e-b0dc-45bd-97c4-d86cd7aa65c3',
                        codename: 'oneclubadvantage',
                        externalId: undefined
                    }
                },
                snippetCodename: 'membership_based_access'
            },

            /**
             * Tags (modular_content)
             */
            tags: {
                codename: 'tags',
                id: '1c873240-f051-42cb-9cef-a963d9404e6b',
                externalId: undefined,
                name: 'Tags',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '43216ef4-ff08-4475-8a7c-fef96502c8bc',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Call out box
     */
    call_out_box: {
        codename: 'call_out_box',
        id: '556ab4a1-2f53-4d04-b49d-8ddc6f972636',
        externalId: undefined,
        name: 'Call-out box',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: '159fd1e6-33af-4fe7-b70e-cf710166f566',
                externalId: undefined,
                name: 'Background Color',
                required: true,
                type: 'modular_content'
            },

            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '5073544f-9cea-4b57-b22b-f672e196d874',
                externalId: undefined,
                name: 'Background Image',
                required: true,
                type: 'asset'
            },

            /**
             * Description (text)
             */
            description: {
                codename: 'description',
                id: '47b032d4-e921-413d-8826-ef652c1b9ea8',
                externalId: undefined,
                name: 'Description',
                required: true,
                type: 'text'
            },

            /**
             * Link (modular_content)
             */
            link: {
                codename: 'link',
                id: '74435cd2-3671-42f6-b379-624b6efd8bd5',
                externalId: undefined,
                name: 'Link',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * Callback URL
     */
    callback_url: {
        codename: 'callback_url',
        id: '0e7e3300-2c15-4713-b082-522430e5ae85',
        externalId: undefined,
        name: 'Callback URL',
        elements: {
            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: '7ad1d084-7df7-4f43-95a9-8471b663a524',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Callout panel list
     */
    callout_panel_list: {
        codename: 'callout_panel_list',
        id: 'f8636c2d-b30e-4a37-af97-310e8d83da2a',
        externalId: undefined,
        name: 'Callout panel list',
        elements: {
            /**
             * Background color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: '77106d1e-7c6b-4a62-ab71-b665708897d1',
                externalId: undefined,
                name: 'Background color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Background image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '34a42480-c4a0-4bb8-a726-33fa36462262',
                externalId: undefined,
                name: 'Background image',
                required: false,
                type: 'asset'
            },

            /**
             * Callout items (modular_content)
             */
            callout_items: {
                codename: 'callout_items',
                id: '170ce002-5fa9-4e5b-b625-b58027b29331',
                externalId: undefined,
                name: 'Callout items',
                required: true,
                type: 'modular_content'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: 'b3b2eb1e-d7bf-4555-919c-21f1f761885a',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'b4d0c671-3dcc-4943-a0d8-8ab44918a2ca',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Carousel Hero
     */
    carousel_hero: {
        codename: 'carousel_hero',
        id: '2569536f-9d9b-4b8b-9b26-2ebed253f834',
        externalId: undefined,
        name: 'Carousel Hero',
        elements: {
            /**
             * Autoplay timeout (number)
             *
             * Set how much time the slide will auto play (in seconds) until it stops completely.
             */
            slider_time_out: {
                codename: 'slider_time_out',
                id: 'eb3ddde8-9b21-49c7-9a33-073f73bc89a9',
                externalId: undefined,
                name: 'Autoplay timeout',
                required: false,
                type: 'number'
            },

            /**
             * Autoplay timer (number)
             *
             * Set the time interval (in seconds) between slides.
             */
            slider_change_time: {
                codename: 'slider_change_time',
                id: '0806b214-6788-4cf2-b81c-ae888fc2fb3f',
                externalId: undefined,
                name: 'Autoplay timer',
                required: false,
                type: 'number'
            },

            /**
             * Background color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: '795b87b4-46c7-4455-a4ef-28ed46c78afa',
                externalId: undefined,
                name: 'Background color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Enable Loop (multiple_choice)
             *
             * Default value is "No". If set to "Yes", the carousel will not stop when it reaches the last tile. It will be an infinite loop.
             */
            enable_loop: {
                codename: 'enable_loop',
                id: '6be11e3f-945e-48c3-a814-f2e4ca337091',
                externalId: undefined,
                name: 'Enable Loop',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: '3ee61dc9-260b-4828-a51a-e93ea16758a5',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '4ef2f75d-3d05-4416-ac85-2ac54ed03627',
                        codename: 'false',
                        externalId: undefined
                    }
                }
            },

            /**
             * Hero list (modular_content)
             */
            hero_list: {
                codename: 'hero_list',
                id: '20efbd49-9847-4138-90e6-5b84ceeae172',
                externalId: undefined,
                name: 'Hero list',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * Coach
     */
    coach: {
        codename: 'coach',
        id: '8683affc-3291-47f7-b773-37c9d14eaaf9',
        externalId: undefined,
        name: 'Coach',
        elements: {
            /**
             * Description (modular_content)
             */
            description: {
                codename: 'description',
                id: '9ae9ced6-b1ca-44a8-82f2-ac6460af7d3b',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'modular_content'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Pre Foot Elements (modular_content)
             */
            pre_foot_elements: {
                codename: 'pre_foot_elements',
                id: 'fd5c3f71-8abb-49ce-8f53-5ff644b5e599',
                externalId: undefined,
                name: 'Pre Foot Elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Primary Image (asset)
             */
            primary_image: {
                codename: 'primary_image',
                id: 'aad484d3-1072-4a64-94b6-e09ea9a4ec01',
                externalId: undefined,
                name: 'Primary Image',
                required: false,
                type: 'asset'
            },

            /**
             * Primary Mobile Image (asset)
             */
            primary_mobile_image: {
                codename: 'primary_mobile_image',
                id: 'dd27a425-e1cc-4da0-92f2-f5f466b1778a',
                externalId: undefined,
                name: 'Primary Mobile Image',
                required: false,
                type: 'asset'
            },

            /**
             * Sidebar (modular_content)
             */
            sidebar: {
                codename: 'sidebar',
                id: '059865f9-8053-4514-b31c-caca3430be56',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subheader Elements (modular_content)
             */
            subheader_elements: {
                codename: 'subheader_elements',
                id: '9b0f4554-4a10-4c62-8cf7-ba3b243fb214',
                externalId: undefined,
                name: 'Subheader Elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: '77fe4da6-2f47-43d1-b426-ce4938a9313f',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Template (modular_content)
             */
            template: {
                codename: 'template',
                id: '5965759c-7578-4227-9b8d-14a201638354',
                externalId: undefined,
                name: 'Template',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'f5e3d956-26de-4d7a-af99-a60f5393698b',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '30a79d7f-33c7-446d-b117-a10bad79e925',
                externalId: undefined,
                name: 'URL slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     * Color
     */
    color: {
        codename: 'color',
        id: '49e2b9a9-af30-4c05-a9b0-cf0d895ddb93',
        externalId: undefined,
        name: 'Color',
        elements: {
            /**
             * Color Code (text)
             */
            color_code: {
                codename: 'color_code',
                id: '2f65efbc-2a91-4b9a-b67b-544d8c1ef5f7',
                externalId: undefined,
                name: 'Color Code',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Content List   Fixed length
     */
    content_list___fixed_length: {
        codename: 'content_list___fixed_length',
        id: '18e0f14b-acaa-483b-9710-4ee2930ec57b',
        externalId: undefined,
        name: 'Content List - Fixed length',
        elements: {
            /**
             * Arrows  color (modular_content)
             */
            carousel_config__slider_arrows_color: {
                codename: 'carousel_config__slider_arrows_color',
                id: 'c4c8dbcc-1e4a-4ffb-943f-160ce6d43f02',
                externalId: undefined,
                name: 'Arrows: color',
                required: false,
                type: 'modular_content',

                snippetCodename: 'carousel_config'
            },

            /**
             * Arrows  size (multiple_choice)
             *
             * Default is set to "lg"
             */
            carousel_config__slider_arrows_size: {
                codename: 'carousel_config__slider_arrows_size',
                id: '6c137d73-9d0a-41d6-90f6-485c2a3f2e5e',
                externalId: undefined,
                name: 'Arrows: size',
                required: false,
                type: 'multiple_choice',
                options: {
                    sm: {
                        name: 'sm',
                        id: 'f617cdfb-c3b9-4041-863f-1441f701f1ae',
                        codename: 'sm',
                        externalId: undefined
                    },
                    lg: {
                        name: 'lg',
                        id: '19619166-86cd-4ada-8791-0a33285c81b6',
                        codename: 'lg',
                        externalId: undefined
                    },
                    xl: {
                        name: 'xl',
                        id: 'a07d155f-7c30-4c83-9098-5545b03474bb',
                        codename: 'xl',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Arrows  transparent background (multiple_choice)
             *
             * Default state is "No". If set to "Yes", the arrows will not have the navy blue circle container around them.
             */
            carousel_config__arrows__transparent_background: {
                codename: 'carousel_config__arrows__transparent_background',
                id: 'b7baa384-8fa1-4244-b87c-972b47717b2a',
                externalId: undefined,
                name: 'Arrows: transparent background',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: 'c2088fb8-f187-4a7a-8a66-576ca2cdcb2b',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '338248f0-f3e5-43b5-8720-59ff6ae6587a',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Autoplay  timeout (number)
             *
             * If enabled: Set how much time the slide will auto play (in seconds) until it stops completely.
             */
            carousel_config__autoplay_timeout: {
                codename: 'carousel_config__autoplay_timeout',
                id: '1c758157-031e-43d5-90f4-cecf648a12e7',
                externalId: undefined,
                name: 'Autoplay: timeout',
                required: false,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Autoplay  timer (number)
             *
             * If enabled: Set the time interval (in seconds) between slides.
             */
            carousel_config__autoplay_timer: {
                codename: 'carousel_config__autoplay_timer',
                id: '77a04154-3dd5-4b30-9109-9cbb7cd63250',
                externalId: undefined,
                name: 'Autoplay: timer',
                required: false,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: 'f2068e3b-7ba7-4be5-b867-aeddddf7c527',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Desktop  Tiles to show (number)
             *
             * Recommended setting as per design: 1.2
             */
            carousel_config__slides_to_show_desktop: {
                codename: 'carousel_config__slides_to_show_desktop',
                id: 'e6ec9ff7-8aab-4270-9d3f-1545dfd5d49a',
                externalId: undefined,
                name: 'Desktop: Tiles to show',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Desktop  Tiles to slide (number)
             *
             * Always use number without decimal. Example: If we choose to show 1.2 tiles, we will slide 1 tile
             */
            carousel_config__slides_to_scroll_desktop: {
                codename: 'carousel_config__slides_to_scroll_desktop',
                id: '81a3fa77-9a08-4bbb-a26e-886488c8cafb',
                externalId: undefined,
                name: 'Desktop: Tiles to slide',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Display (multiple_choice)
             *
             * It is used only for list type: "Plain list"
             */
            display: {
                codename: 'display',
                id: 'a24e2ee5-6138-4f53-8153-42c79bac65ef',
                externalId: undefined,
                name: 'Display',
                required: false,
                type: 'multiple_choice',
                options: {
                    force_horizontal: {
                        name: 'Force horizontal',
                        id: '269b3ab8-79f0-4544-897a-b79bbb9a7335',
                        codename: 'force_horizontal',
                        externalId: undefined
                    },
                    force_vertical: {
                        name: 'Force vertical',
                        id: '005029ad-94ea-43af-863b-a13733c985f1',
                        codename: 'force_vertical',
                        externalId: undefined
                    }
                }
            },

            /**
             * Elements (modular_content)
             */
            elements: {
                codename: 'elements',
                id: 'd4d51941-fc45-466e-b087-710ec2f00cd6',
                externalId: undefined,
                name: 'Elements',
                required: true,
                type: 'modular_content'
            },

            /**
             * Enable arrows (multiple_choice)
             */
            carousel_config__enable_arrows: {
                codename: 'carousel_config__enable_arrows',
                id: '6c19ef2f-2f9d-4722-8cb6-72ed4790b3fc',
                externalId: undefined,
                name: 'Enable arrows',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: 'bc937795-16d1-491a-8c35-5e8a131c1e8e',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '33467f46-d29b-4a52-ba1b-48abe2dc8537',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Enable Autoplay (multiple_choice)
             *
             * Default value is "No"
             */
            carousel_config__autoplay: {
                codename: 'carousel_config__autoplay',
                id: '85596b43-ed10-4f35-bfae-203678416df3',
                externalId: undefined,
                name: 'Enable Autoplay',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: 'aa8abbec-2324-4e7e-9f1f-d29089b83d06',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: 'f6d73420-559d-41f3-8883-e7f63f2c7dab',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Enable Loop (multiple_choice)
             *
             * Default value is "No". If set to "Yes", the carousel will not stop when it reaches the last tile. It will be an infinite loop.
             */
            carousel_config__infinite_loop: {
                codename: 'carousel_config__infinite_loop',
                id: 'd2e72694-b0bd-49c4-b825-829fc86c956a',
                externalId: undefined,
                name: 'Enable Loop',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: '899d34ed-5fda-4f73-b81b-1b378fc6702f',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '57cffda5-72a7-44f3-9239-1ed25784fdeb',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * List display (multiple_choice)
             */
            list_display: {
                codename: 'list_display',
                id: '69c386fb-2896-487e-9920-c2bb080ff420',
                externalId: undefined,
                name: 'List display',
                required: false,
                type: 'multiple_choice',
                options: {
                    alert_bar_list: {
                        name: 'Alert Bar List',
                        id: '26beb42c-ff53-4f95-a2da-4e5df06ddd58',
                        codename: 'alert_bar_list',
                        externalId: undefined
                    },
                    callout_panel_list: {
                        name: 'Callout Panel List',
                        id: 'ec31d348-1e5c-4fab-aa7e-076ef42594b4',
                        codename: 'callout_panel_list',
                        externalId: undefined
                    },
                    events_list: {
                        name: 'Events list',
                        id: '3a3f5a51-eccd-4682-821c-859d72be6666',
                        codename: 'events_list',
                        externalId: undefined
                    },
                    hero_list: {
                        name: 'Hero list',
                        id: '13218099-78ea-41aa-8e6a-99a97c1d8c8e',
                        codename: 'hero_list',
                        externalId: undefined
                    },
                    upgrade_experience_list: {
                        name: 'Upgrade experience list',
                        id: '87c749fc-b00e-4354-8ddd-cc53a28a646e',
                        codename: 'upgrade_experience_list',
                        externalId: undefined
                    }
                }
            },

            /**
             * Mobile  Tiles to show (number)
             *
             * Recommended setting as per design: 1.2
             */
            carousel_config__slides_to_show_mobile: {
                codename: 'carousel_config__slides_to_show_mobile',
                id: 'e7133558-b8a5-46c2-845c-d184549d97dd',
                externalId: undefined,
                name: 'Mobile: Tiles to show',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Mobile  Tiles to slide (number)
             *
             * Always use number without decimal. Example: If we choose to show 1.2 tiles, we will slide 1 tile
             */
            carousel_config__slide_to_scroll_mobile: {
                codename: 'carousel_config__slide_to_scroll_mobile',
                id: 'd22feeb5-e5ec-4e08-aa6f-ee4d7d62175a',
                externalId: undefined,
                name: 'Mobile: Tiles to slide',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Slider width (multiple_choice)
             *
             * If "Full width" selected: the carousel will take the parent's container width.If "None" selected: the carousel will be inside it's own container but the tiles will "break" out on the right side.
             */
            carousel_config__slider_width: {
                codename: 'carousel_config__slider_width',
                id: 'ecf1b958-ea96-4d9d-b5d2-363c4ba11a2b',
                externalId: undefined,
                name: 'Slider width',
                required: false,
                type: 'multiple_choice',
                options: {
                    full_width: {
                        name: 'Full width',
                        id: '707bb566-0dc9-4de0-9fd3-33bdb5db707f',
                        codename: 'full_width',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Title (text)
             *
             * If "Alert" Slider, do NOT fill this field. It will not be displayed.
             */
            title: {
                codename: 'title',
                id: 'a02f2ce4-ce98-40f3-9fd7-8767436afaef',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Total tiles to show (number)
             *
             * ability to choose the number of tiles that are enabled from a larger list in kontent.ai- meaning from 10 tiles that are in kontent.ai the content editors might choose to only enable 5 of them
             */
            total_tiles_to_show: {
                codename: 'total_tiles_to_show',
                id: '6cd69399-b90d-4d65-ab38-30ff5a066c48',
                externalId: undefined,
                name: 'Total tiles to show',
                required: false,
                type: 'number'
            },

            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: '5fa5f818-8123-43e0-b46a-c654691becf7',
                externalId: undefined,
                name: 'Type',
                required: true,
                type: 'multiple_choice',
                options: {
                    slider: {
                        name: 'Slider',
                        id: '5c4124b0-822f-4cb3-b51d-10fd82fecb73',
                        codename: 'slider',
                        externalId: undefined
                    },
                    plain_list: {
                        name: 'Plain list',
                        id: 'f5e90833-c1de-4948-ae79-09ec317f1391',
                        codename: 'plain_list',
                        externalId: undefined
                    },
                    grid: {
                        name: 'Grid',
                        id: '277d317f-a678-49e6-b1ac-2434e95aff6e',
                        codename: 'grid',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Cookies Popup
     */
    cookies_popup: {
        codename: 'cookies_popup',
        id: 'd459fbd8-6951-4626-8fa7-a7bf60ef041f',
        externalId: undefined,
        name: 'Cookies Popup',
        elements: {
            /**
             * Button Label (text)
             */
            button_label: {
                codename: 'button_label',
                id: '7baf84b3-f352-435f-b15e-2c7e086aabf5',
                externalId: undefined,
                name: 'Button Label',
                required: false,
                type: 'text'
            },

            /**
             * Cookies Page Link (modular_content)
             */
            cookies_page_link: {
                codename: 'cookies_page_link',
                id: 'b33d9459-85a6-4426-aec2-b515037f9d8f',
                externalId: undefined,
                name: 'Cookies Page Link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Description (text)
             */
            description: {
                codename: 'description',
                id: '0d2c254a-6909-4460-8085-a007a06c778d',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'text'
            },

            /**
             * Details Subscription (text)
             */
            details_subscription: {
                codename: 'details_subscription',
                id: '3348d64e-54d6-4798-b9ed-a762ac2b9395',
                externalId: undefined,
                name: 'Details Subscription',
                required: false,
                type: 'text'
            },

            /**
             * Details Subtext (text)
             */
            details_subtext: {
                codename: 'details_subtext',
                id: 'f2759e17-6dd1-4f1e-a5ac-0a0cf876ede3',
                externalId: undefined,
                name: 'Details Subtext',
                required: false,
                type: 'text'
            },

            /**
             * Logo Image (asset)
             */
            logo_image: {
                codename: 'logo_image',
                id: '8b2cbb9e-b064-458a-84f5-a0fa4c44203e',
                externalId: undefined,
                name: 'Logo Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '8cd066fb-ac3a-4b39-9910-ed334a8218e5',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Cookies Settings
     */
    cookies_settings: {
        codename: 'cookies_settings',
        id: '6b0ae4c1-129c-4754-b921-14d824ea87ac',
        externalId: undefined,
        name: 'Cookies Settings',
        elements: {
            /**
             * Accept All Button Label (text)
             */
            accept_all_button_label: {
                codename: 'accept_all_button_label',
                id: '5b6e81db-5067-42eb-bd88-f9253e475f8c',
                externalId: undefined,
                name: 'Accept All Button Label',
                required: false,
                type: 'text'
            },

            /**
             * Consent Cookies Expiry Days (number)
             */
            consent_cookies_expiry_days: {
                codename: 'consent_cookies_expiry_days',
                id: '32897408-4f64-4002-b9af-b844c8c2e058',
                externalId: undefined,
                name: 'Consent Cookies Expiry Days',
                required: false,
                type: 'number'
            },

            /**
             * Cookies (multiple_choice)
             */
            functional_cookies_cookies: {
                codename: 'functional_cookies_cookies',
                id: '61e04752-3f87-47af-8bfe-1bbe1377382e',
                externalId: undefined,
                name: 'Cookies',
                required: false,
                type: 'multiple_choice',
                options: {}
            },

            /**
             * Cookies Names (modular_content)
             */
            necessary_cookies_names: {
                codename: 'necessary_cookies_names',
                id: '491334ee-ba35-44bd-bade-ea658f9f7c21',
                externalId: undefined,
                name: 'Cookies Names',
                required: false,
                type: 'modular_content'
            },

            /**
             * Cookies Names (modular_content)
             */
            performance_cookies_names: {
                codename: 'performance_cookies_names',
                id: '093478d9-5253-457c-9b62-a35f350a5687',
                externalId: undefined,
                name: 'Cookies Names',
                required: false,
                type: 'modular_content'
            },

            /**
             * Cookies Names (modular_content)
             */
            functional_cookies_names: {
                codename: 'functional_cookies_names',
                id: '70d34a2f-d916-42f1-afa8-cd7936cb5766',
                externalId: undefined,
                name: 'Cookies Names',
                required: false,
                type: 'modular_content'
            },

            /**
             * Description (text)
             */
            description: {
                codename: 'description',
                id: 'fcbd8f48-4759-4a7a-a355-3c613daca2f7',
                externalId: undefined,
                name: 'Description',
                required: true,
                type: 'text'
            },

            /**
             * Description (text)
             */
            necessary_cookies_description: {
                codename: 'necessary_cookies_description',
                id: '5fdd3d0a-ff39-4c88-9c49-e19506b0ff6b',
                externalId: undefined,
                name: 'Description',
                required: true,
                type: 'text'
            },

            /**
             * Description (text)
             */
            performance_cookies_description: {
                codename: 'performance_cookies_description',
                id: '317afca9-1a79-4c86-84fe-39f16170b597',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'text'
            },

            /**
             * Description (text)
             */
            functional_cookies_description: {
                codename: 'functional_cookies_description',
                id: '1a417399-dadf-472d-8382-861c3acc192d',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'text'
            },

            /**
             * Details Description (text)
             */
            details_description: {
                codename: 'details_description',
                id: 'a63dac4a-ac6c-4c4e-b172-66a8ab67fb3a',
                externalId: undefined,
                name: 'Details Description',
                required: true,
                type: 'text'
            },

            /**
             * Details Link (modular_content)
             */
            details_link: {
                codename: 'details_link',
                id: 'e7945ef9-1e70-407a-942b-7c2dddd63183',
                externalId: undefined,
                name: 'Details Link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Details Updated Date (date_time)
             */
            details_updated_date: {
                codename: 'details_updated_date',
                id: 'd4d1507d-27ce-42f5-a819-517f56a9c26f',
                externalId: undefined,
                name: 'Details Updated Date',
                required: false,
                type: 'date_time'
            },

            /**
             * Google Tag Manager Id (text)
             */
            performance_cookies_google_tag_manager_id: {
                codename: 'performance_cookies_google_tag_manager_id',
                id: 'e8963b06-4431-4a7d-8ba9-b5cf7d9b51b2',
                externalId: undefined,
                name: 'Google Tag Manager Id',
                required: false,
                type: 'text'
            },

            /**
             * Label (text)
             */
            necessary_cookies_label: {
                codename: 'necessary_cookies_label',
                id: '6872bcbb-23e2-40c8-a82a-62a5abee7931',
                externalId: undefined,
                name: 'Label',
                required: true,
                type: 'text'
            },

            /**
             * Label (text)
             */
            performance_cookies_label: {
                codename: 'performance_cookies_label',
                id: '9d03d631-83e2-4725-948b-ba5ffd9c9dd3',
                externalId: undefined,
                name: 'Label',
                required: false,
                type: 'text'
            },

            /**
             * Label (text)
             */
            functional_cookies_label: {
                codename: 'functional_cookies_label',
                id: '6010a250-d29c-489e-8c08-3327b97de422',
                externalId: undefined,
                name: 'Label',
                required: false,
                type: 'text'
            },

            /**
             * Lawful Basis (multiple_choice)
             */
            performance_cookies_lawful_basis: {
                codename: 'performance_cookies_lawful_basis',
                id: 'b40c1a9e-1332-426d-99ef-f1e0ee16f214',
                externalId: undefined,
                name: 'Lawful Basis',
                required: true,
                type: 'multiple_choice',
                options: {
                    consent: {
                        name: 'consent',
                        id: '09bbf898-f593-40d8-94c8-56067c27fd12',
                        codename: 'consent',
                        externalId: undefined
                    },
                    legitimate_interest: {
                        name: 'legitimate interest',
                        id: '07ef1c9b-f4f7-48a9-a8ea-16cb4bb3017a',
                        codename: 'legitimate_interest',
                        externalId: undefined
                    }
                }
            },

            /**
             * Lawful Basis (multiple_choice)
             */
            functional_cookies_lawful_basis: {
                codename: 'functional_cookies_lawful_basis',
                id: 'f288f7ff-4e09-401c-99b5-bf9d7fb55f9e',
                externalId: undefined,
                name: 'Lawful Basis',
                required: false,
                type: 'multiple_choice',
                options: {
                    consent: {
                        name: 'consent',
                        id: '71209ead-2356-4709-aff7-c7b402837030',
                        codename: 'consent',
                        externalId: undefined
                    },
                    legitimate_interest: {
                        name: 'legitimate interest',
                        id: '721e69e1-b386-42a3-838b-9040b14ffa9c',
                        codename: 'legitimate_interest',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '19ff099d-1a29-4097-8219-624f14906ad3',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * Title (text)
             */
            necessary_cookies_title: {
                codename: 'necessary_cookies_title',
                id: 'a8f52a1e-cd5b-4ba9-84e0-9b5d45b3111b',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * Title (text)
             */
            performance_cookies_title: {
                codename: 'performance_cookies_title',
                id: '6560b69d-0996-4be9-a70e-ac3005b5c967',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            functional_cookies_title: {
                codename: 'functional_cookies_title',
                id: 'b8a4d2a9-1f85-4454-a75e-7cec3b7ab95b',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Toggle Off Label (text)
             */
            toggle_off_label: {
                codename: 'toggle_off_label',
                id: 'cfe3ab23-5c6d-43e9-b0e8-da4a672e9007',
                externalId: undefined,
                name: 'Toggle Off Label',
                required: false,
                type: 'text'
            },

            /**
             * Toggle On Label (text)
             */
            toggle_on_label: {
                codename: 'toggle_on_label',
                id: 'ae40ddb3-0e1f-4add-a769-f56094c5a811',
                externalId: undefined,
                name: 'Toggle On Label',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * CTA
     */
    cta: {
        codename: 'cta',
        id: '9ebf199e-1b7f-4093-8878-277b6f118ef0',
        externalId: undefined,
        name: 'CTA',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: 'a594254f-b39b-4c89-bb94-c1b1107e9e48',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Border Color (modular_content)
             */
            border_color: {
                codename: 'border_color',
                id: '2b03efc2-cb1e-4672-a366-e90d8d949d3a',
                externalId: undefined,
                name: 'Border Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: '8090c0c4-9fd3-43a6-a30b-6090e24c9009',
                externalId: undefined,
                name: 'Label',
                required: true,
                type: 'text'
            },

            /**
             * Opacity (number)
             */
            opacity: {
                codename: 'opacity',
                id: '6ac71ce6-e546-4957-a33a-a1315061dd0f',
                externalId: undefined,
                name: 'Opacity',
                required: false,
                type: 'number'
            },

            /**
             * Resource (modular_content)
             */
            resource: {
                codename: 'resource',
                id: 'e206ea5e-50d4-42f4-97ef-292a5021a701',
                externalId: undefined,
                name: 'Resource',
                required: true,
                type: 'modular_content'
            },

            /**
             * Text Color (modular_content)
             */
            text_color: {
                codename: 'text_color',
                id: 'cd517da0-cbce-447b-bb8a-f726889752e8',
                externalId: undefined,
                name: 'Text Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Theme (multiple_choice)
             */
            theme: {
                codename: 'theme',
                id: '075adcfd-0da8-4c00-8db8-8f830b9fb7c7',
                externalId: undefined,
                name: 'Theme',
                required: false,
                type: 'multiple_choice',
                options: {
                    bottom_border_only: {
                        name: 'Bottom Border Only',
                        id: 'ae07a089-0ba8-4d36-9f6a-84cf9df3d9a1',
                        codename: 'bottom_border_only',
                        externalId: undefined
                    },
                    outline: {
                        name: 'Outline',
                        id: '617d1fba-984d-4e02-b1b0-8f2140f0d60c',
                        codename: 'outline',
                        externalId: undefined
                    }
                }
            },

            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: '522f5d21-a840-44a4-a95b-2feb7385127d',
                externalId: undefined,
                name: 'Type',
                required: true,
                type: 'multiple_choice',
                options: {
                    button: {
                        name: 'Button',
                        id: '22894855-5b47-4f92-b4d2-258217c94611',
                        codename: 'button',
                        externalId: undefined
                    },
                    submit: {
                        name: 'Submit',
                        id: '728dbbaf-cced-41c3-9c17-808b0ba0d9c5',
                        codename: 'submit',
                        externalId: undefined
                    },
                    reset: {
                        name: 'Reset',
                        id: '582b2998-4e7b-4d29-b37d-6a2df77b6954',
                        codename: 'reset',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Dictionary Item
     */
    dictionary_item: {
        codename: 'dictionary_item',
        id: 'ff946cc6-9648-4fce-85cd-20741ad1dcdf',
        externalId: undefined,
        name: 'Dictionary Item',
        elements: {
            /**
             * Key (text)
             *
             * The key should be identically as Dictionary Item codename
             */
            key: {
                codename: 'key',
                id: '10588516-f092-4a51-932b-813523769549',
                externalId: undefined,
                name: 'Key',
                required: true,
                type: 'text'
            },

            /**
             * Value (text)
             *
             * This can be any JSON compatible value, it will be parsed with JSON.stringify()
             */
            value: {
                codename: 'value',
                id: '2126b4de-9572-4c03-8faa-6d8c7c2fb5a5',
                externalId: undefined,
                name: 'Value',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Dotdigital Form iFrame
     */
    dotdigital_form_iframe: {
        codename: 'dotdigital_form_iframe',
        id: 'd730c3dc-088e-4c89-9f73-9fe5307576cd',
        externalId: undefined,
        name: 'Dotdigital Form iFrame',
        elements: {
            /**
             * Embed script (text)
             */
            embed_script: {
                codename: 'embed_script',
                id: '1bc4f660-dafd-4f59-9eb6-9ce69f148689',
                externalId: undefined,
                name: 'Embed script',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Dynamic Filter
     */
    dynamic_filter: {
        codename: 'dynamic_filter',
        id: 'da69161a-bb83-4269-86c5-f0fb5e80d527',
        externalId: undefined,
        name: 'Dynamic Filter',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '8e1f7da7-4b75-4e16-a829-d6beab4c422e',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Component (multiple_choice)
             */
            component: {
                codename: 'component',
                id: '2fc24248-6943-45fd-869a-0caffaa2c9cb',
                externalId: undefined,
                name: 'Component',
                required: true,
                type: 'multiple_choice',
                options: {
                    article: {
                        name: 'Article',
                        id: '297511be-b1d3-48d4-8353-b2cff47a0c09',
                        codename: 'article',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Event card
     */
    event_card: {
        codename: 'event_card',
        id: '1bdcc593-12f1-4268-a703-4bc4bdae5d17',
        externalId: undefined,
        name: 'Event card',
        elements: {
            /**
             * Background image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: 'bfbc61c7-fc3c-4923-b43b-19caa3cc5203',
                externalId: undefined,
                name: 'Background image',
                required: false,
                type: 'asset'
            },

            /**
             * End date (date_time)
             *
             * Ensure that the End Date is not set before the Start Date.It's recommended to use Europe/London as time zone.
             */
            end_date: {
                codename: 'end_date',
                id: '9cd621f3-7415-49cb-a517-016a27e39f34',
                externalId: undefined,
                name: 'End date',
                required: false,
                type: 'date_time'
            },

            /**
             * Is event live (multiple_choice)
             */
            is_event_live: {
                codename: 'is_event_live',
                id: '233590f6-203b-49e3-9072-fef845680e93',
                externalId: undefined,
                name: 'Is event live',
                required: true,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: '11a2de1b-19a5-4c68-b37e-5791a3bda124',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '37fe750e-20a2-4140-b91d-9f2a738c1042',
                        codename: 'false',
                        externalId: undefined
                    }
                }
            },

            /**
             * Live text (text)
             */
            live_text: {
                codename: 'live_text',
                id: '0be15646-28a2-48cd-851a-aa8d305b155a',
                externalId: undefined,
                name: 'Live text',
                required: false,
                type: 'text'
            },

            /**
             * Live url (modular_content)
             *
             * This url can be used as internal/external link.If the event is not live, this field does not need to be completed.
             */
            live_url: {
                codename: 'live_url',
                id: '130b63c2-0c4a-43ee-847c-d6ef2b612aa8',
                externalId: undefined,
                name: 'Live url',
                required: false,
                type: 'modular_content'
            },

            /**
             * Logo image (asset)
             */
            logo_image: {
                codename: 'logo_image',
                id: '6ae7b7f0-3107-4ada-a961-27f503a558cc',
                externalId: undefined,
                name: 'Logo image',
                required: false,
                type: 'asset'
            },

            /**
             * Start date (date_time)
             *
             * It's recommended to use Europe/London as time zone.
             */
            start_date: {
                codename: 'start_date',
                id: 'e700ec38-da9d-4d6e-95f6-521bacd5fcde',
                externalId: undefined,
                name: 'Start date',
                required: true,
                type: 'date_time'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '73ea00d4-f015-4861-bc21-81468ed1d4fa',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Url (modular_content)
             *
             * This url can be used as internal/external link.If the event is live, this field does not need to be completed.If the event is used for attend event page, it is necessary to add event page link.
             */
            url: {
                codename: 'url',
                id: '1b347b3b-f2e5-4c2a-893e-40cefd1368e4',
                externalId: undefined,
                name: 'Url',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Event Hero
     */
    event_hero: {
        codename: 'event_hero',
        id: '374c2a6a-cbd1-4278-ba81-c649d28ce31e',
        externalId: undefined,
        name: 'Event Hero',
        elements: {
            /**
             * Background asset (asset)
             *
             * Upload video or imagine
             */
            background_asset: {
                codename: 'background_asset',
                id: 'ea8878fa-2917-4248-9343-20230b71c15a',
                externalId: undefined,
                name: 'Background asset',
                required: false,
                type: 'asset'
            },

            /**
             * Background asset mobile (asset)
             *
             * This image will only be displayed on mobile, as a cover for the video/image uploaded in "Background asset"
             */
            mobile_img_video_preview: {
                codename: 'mobile_img_video_preview',
                id: '96b0a4fb-4396-49aa-be9f-12d89ef10f09',
                externalId: undefined,
                name: 'Background asset mobile',
                required: false,
                type: 'asset'
            },

            /**
             * Event description (text)
             */
            description: {
                codename: 'description',
                id: '53a89893-0e41-47bc-b24c-00a2723927ee',
                externalId: undefined,
                name: 'Event description',
                required: false,
                type: 'text'
            },

            /**
             * Event end date (date_time)
             */
            event_end_date: {
                codename: 'event_end_date',
                id: 'c3abbffb-72ea-4755-bab8-640cecf56d5d',
                externalId: undefined,
                name: 'Event end date',
                required: false,
                type: 'date_time'
            },

            /**
             * Event link (modular_content)
             *
             * Link that will take the user to the event page
             */
            view_event_link: {
                codename: 'view_event_link',
                id: 'cfeff938-7e0e-4818-987d-d199ad42e01d',
                externalId: undefined,
                name: 'Event link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Event location (text)
             */
            event_location: {
                codename: 'event_location',
                id: '3a6b57c1-0061-428b-b8c5-e7cb1e278a51',
                externalId: undefined,
                name: 'Event location',
                required: false,
                type: 'text'
            },

            /**
             * Event start date (date_time)
             */
            event_start_date: {
                codename: 'event_start_date',
                id: '7155eecf-c384-4504-abdc-8f35759a7f43',
                externalId: undefined,
                name: 'Event start date',
                required: false,
                type: 'date_time'
            },

            /**
             * Event Title (text)
             */
            title: {
                codename: 'title',
                id: '6f7cac42-7d9d-4095-930d-fb50a402582b',
                externalId: undefined,
                name: 'Event Title',
                required: true,
                type: 'text'
            },

            /**
             * Play button title (text)
             *
             * *OptionalIt is used for accessibility only. Will not be visible.
             */
            play_video_text: {
                codename: 'play_video_text',
                id: '80505f70-1393-41fb-843b-654bebb15fc1',
                externalId: undefined,
                name: 'Play button title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Event page
     */
    event_page: {
        codename: 'event_page',
        id: 'fd3bb461-b664-419b-882c-59d95c933dda',
        externalId: undefined,
        name: 'Event page',
        elements: {
            /**
             * Body (modular_content)
             *
             * Elements on left side
             */
            body: {
                codename: 'body',
                id: '349b5cc2-b133-4e56-a71e-36619d0181cc',
                externalId: undefined,
                name: 'Body',
                required: false,
                type: 'modular_content'
            },

            /**
             * Event hero (modular_content)
             */
            event_hero: {
                codename: 'event_hero',
                id: 'ca67b123-d2c1-49dd-8558-322d760d6a88',
                externalId: undefined,
                name: 'Event hero',
                required: true,
                type: 'modular_content'
            },

            /**
             * Event slider (modular_content)
             *
             * Allowed element: Content list of Event Card
             */
            event_slider: {
                codename: 'event_slider',
                id: 'ef068b77-c1ab-40c6-be1b-8963ae40c65b',
                externalId: undefined,
                name: 'Event slider',
                required: true,
                type: 'modular_content'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Sidebar (modular_content)
             *
             * Elements on right side
             */
            sidebar: {
                codename: 'sidebar',
                id: 'ad5a4270-e618-4301-9c79-0ba9fd2ccb6d',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subheader elements (modular_content)
             *
             * Elements which are below the header and above the Event Slider.Allowed element: Content list of Alerts bar which is used on all pages.
             */
            subheader_elements: {
                codename: 'subheader_elements',
                id: 'dd1bed03-589a-4793-b35e-5af2d528ae53',
                externalId: undefined,
                name: 'Subheader elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Template (modular_content)
             */
            template: {
                codename: 'template',
                id: 'b7be5e49-04b6-4b4e-b4e3-ef2ddfe03e51',
                externalId: undefined,
                name: 'Template',
                required: true,
                type: 'modular_content'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '611046e6-77a3-4dcd-9c9b-e12a77884463',
                externalId: undefined,
                name: 'URL slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     * Explore Benefits
     */
    explore_benefits: {
        codename: 'explore_benefits',
        id: 'bc17b022-1b38-4a1b-8710-e51814bb6553',
        externalId: undefined,
        name: 'Explore Benefits',
        elements: {
            /**
             * Background image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: 'c7ee7d35-97b0-4a09-8182-35beb0fa4476',
                externalId: undefined,
                name: 'Background image',
                required: false,
                type: 'asset'
            },

            /**
             * Link (modular_content)
             */
            cta: {
                codename: 'cta',
                id: '5cce73a7-e589-4191-8b4d-9d2115fa61eb',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'd12623e6-8681-45a5-8ba6-dd0804bcb50e',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Feature Card
     */
    feature_card: {
        codename: 'feature_card',
        id: 'c794adb6-5e68-4b33-a00b-0990e4655f02',
        externalId: undefined,
        name: 'Feature Card',
        elements: {
            /**
             * CTAs (modular_content)
             */
            ctas: {
                codename: 'ctas',
                id: '0d1a68cd-4bf2-4f48-a79e-4bc3fc28e0f4',
                externalId: undefined,
                name: 'CTAs',
                required: false,
                type: 'modular_content'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: '4ced19e4-92c8-42f8-9327-a01457417047',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Theme (multiple_choice)
             */
            theme: {
                codename: 'theme',
                id: '123363a8-718d-4719-a735-37a911ff1396',
                externalId: undefined,
                name: 'Theme',
                required: false,
                type: 'multiple_choice',
                options: {
                    the_one_club: {
                        name: 'The one club',
                        id: 'd90f6ce9-3659-42dd-bb43-093279f79cbf',
                        codename: 'the_one_club',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'e60eab95-869e-43ed-ba31-663503f4ed75',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Footer
     */
    footer: {
        codename: 'footer',
        id: 'e4e9a4b4-98b0-4069-9c21-cf91403ce904',
        externalId: undefined,
        name: 'Footer',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: '7af8c62c-cd3e-40fc-bef9-3870eef2622b',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Background image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '55630a67-958b-44bb-9be7-5c2d0f649682',
                externalId: undefined,
                name: 'Background image',
                required: false,
                type: 'asset'
            },

            /**
             * Championship logos (modular_content)
             */
            organizations_logos: {
                codename: 'organizations_logos',
                id: '81f5b0a6-4f10-4aae-9e2e-b627301c07b3',
                externalId: undefined,
                name: 'Championship logos',
                required: false,
                type: 'modular_content'
            },

            /**
             * Copyright Statement (text)
             */
            all_rights_reserved: {
                codename: 'all_rights_reserved',
                id: '51a4e327-9133-4bf6-999a-804b6a656e1a',
                externalId: undefined,
                name: 'Copyright Statement',
                required: false,
                type: 'text'
            },

            /**
             * Master Logo (asset)
             */
            master_logo: {
                codename: 'master_logo',
                id: '1ab451cd-6ba5-413b-8d8b-8b1a7cd3f070',
                externalId: undefined,
                name: 'Master Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Navigation Links (modular_content)
             */
            navigation_links: {
                codename: 'navigation_links',
                id: 'a3f801c4-750c-4159-81ee-382c75c2e3a5',
                externalId: undefined,
                name: 'Navigation Links',
                required: false,
                type: 'modular_content'
            },

            /**
             * Navigation Title (text)
             */
            navigation_title: {
                codename: 'navigation_title',
                id: '155f7a70-f7fe-496b-bf37-b1bc745074f8',
                externalId: undefined,
                name: 'Navigation Title',
                required: false,
                type: 'text'
            },

            /**
             * One Club Logo (asset)
             */
            one_club_logo: {
                codename: 'one_club_logo',
                id: 'bde13c9b-f56c-4f09-b88c-32a897e19820',
                externalId: undefined,
                name: 'One Club Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Other grouped links (modular_content)
             */
            other_grouped_links: {
                codename: 'other_grouped_links',
                id: 'a9773fab-e950-45d0-b3f3-c76e7dad2e33',
                externalId: undefined,
                name: 'Other grouped links',
                required: false,
                type: 'modular_content'
            },

            /**
             * Social Content Links (modular_content)
             */
            social_content_links: {
                codename: 'social_content_links',
                id: 'c3669592-148d-4a8e-a78d-ff49676bf554',
                externalId: undefined,
                name: 'Social Content Links',
                required: false,
                type: 'modular_content'
            },

            /**
             * Social Content Title (text)
             */
            social_content_title: {
                codename: 'social_content_title',
                id: 'aac15d7e-ea9e-4405-b458-c562d8f5c399',
                externalId: undefined,
                name: 'Social Content Title',
                required: false,
                type: 'text'
            },

            /**
             * Social Media Icons Links (modular_content)
             */
            social_icons_links: {
                codename: 'social_icons_links',
                id: '83d8d611-1312-4517-9a49-795aa1126f38',
                externalId: undefined,
                name: 'Social Media Icons Links',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Form
     */
    form: {
        codename: 'form',
        id: '3e13d4f1-d958-41af-94d7-060de28189a3',
        externalId: undefined,
        name: 'Form',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: 'd29e2624-eae7-434a-8ab7-cbe62f68b986',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Form Content (modular_content)
             */
            form_content: {
                codename: 'form_content',
                id: 'c3cdae9d-b2a3-4763-afb8-ce79f51eb363',
                externalId: undefined,
                name: 'Form Content',
                required: false,
                type: 'modular_content'
            },

            /**
             * Form Footer (modular_content)
             */
            form_footer: {
                codename: 'form_footer',
                id: '312c1c78-3b2d-44fd-9ae6-5c83ba18d61b',
                externalId: undefined,
                name: 'Form Footer',
                required: false,
                type: 'modular_content'
            },

            /**
             * Form Header (modular_content)
             */
            form_header: {
                codename: 'form_header',
                id: 'e06807cb-19c6-4737-ad9b-7dc2b3735346',
                externalId: undefined,
                name: 'Form Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * From Email (text)
             */
            from_email: {
                codename: 'from_email',
                id: 'dfc87803-b293-4c57-9d6c-9d62e4b4f791',
                externalId: undefined,
                name: 'From Email',
                required: false,
                type: 'text'
            },

            /**
             * Is ReCaptcha Enabled (multiple_choice)
             */
            is_recaptcha_enabled: {
                codename: 'is_recaptcha_enabled',
                id: '7d2cd9c8-eefd-4a99-b47a-894730aea59a',
                externalId: undefined,
                name: 'Is ReCaptcha Enabled',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'd8bf1c9b-e116-431a-b7d2-ecaf5b1fdf37',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '84868389-86f0-4d94-86ea-73c64dc31a2b',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Key (text)
             */
            key: {
                codename: 'key',
                id: 'e793d41b-1e01-41b0-8bb3-6944adf58890',
                externalId: undefined,
                name: 'Key',
                required: false,
                type: 'text'
            },

            /**
             * Membership Form (multiple_choice)
             */
            membership_form: {
                codename: 'membership_form',
                id: '26a5e582-4f68-452f-9d4d-f22cc02faef5',
                externalId: undefined,
                name: 'Membership Form',
                required: true,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '2e0e3f81-41f8-4186-8004-9fcb16696e98',
                        codename: 'yes',
                        externalId: undefined
                    }
                }
            },

            /**
             * reCAPTCHA (modular_content)
             */
            recaptcha: {
                codename: 'recaptcha',
                id: 'c4cae745-0e5b-49ce-9e60-64b73d0197c2',
                externalId: undefined,
                name: 'reCAPTCHA',
                required: false,
                type: 'modular_content'
            },

            /**
             * reCAPTCHA Attribute Key (text)
             */
            recaptcha_attribute_key: {
                codename: 'recaptcha_attribute_key',
                id: '4cffeea6-e4e5-4067-89dc-3f4289f19694',
                externalId: undefined,
                name: 'reCAPTCHA Attribute Key',
                required: false,
                type: 'text'
            },

            /**
             * Subject (text)
             */
            subject: {
                codename: 'subject',
                id: '2eac36a3-67a3-4d6b-806e-9df912c6a03a',
                externalId: undefined,
                name: 'Subject',
                required: false,
                type: 'text'
            },

            /**
             * To Email (text)
             */
            to_email: {
                codename: 'to_email',
                id: 'd0130dc9-ec09-4bcb-936a-e157108c8b53',
                externalId: undefined,
                name: 'To Email',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Form CTA
     */
    form_cta: {
        codename: 'form_cta',
        id: 'ab2b9be8-52b0-470b-a1bc-b316eddd2dfd',
        externalId: undefined,
        name: 'Form CTA',
        elements: {
            /**
             * Action Type (multiple_choice)
             */
            form_cta_action_types__action_type: {
                codename: 'form_cta_action_types__action_type',
                id: 'b8afa756-7d9c-4f27-b3c6-b92d06c8aedb',
                externalId: undefined,
                name: 'Action Type',
                required: true,
                type: 'multiple_choice',
                options: {
                    oauth_redirect: {
                        name: 'Oauth Redirect',
                        id: 'd3a93a8f-455c-44f5-a98a-3f4c369479b2',
                        codename: 'oauth_redirect',
                        externalId: undefined
                    },
                    redirect_to: {
                        name: 'Redirect To',
                        id: 'e563a141-dd53-4925-b846-6a01349a0cb6',
                        codename: 'redirect_to',
                        externalId: undefined
                    },
                    redirect_to___preserve_query: {
                        name: 'Redirect To   Preserve Query',
                        id: '3b9a2085-605e-4bdd-98b9-f584ccbd65f3',
                        codename: 'redirect_to___preserve_query',
                        externalId: undefined
                    },
                    sign_in: {
                        name: 'Sign In',
                        id: 'c8c3654a-652d-4fe8-8ae7-1c2eb9b20e51',
                        codename: 'sign_in',
                        externalId: undefined
                    },
                    next_step: {
                        name: 'Next Step',
                        id: 'b6b0d8d3-35ff-4f3d-9ffa-0c7226fce0b0',
                        codename: 'next_step',
                        externalId: undefined
                    },
                    skip_step: {
                        name: 'Skip Step',
                        id: '9319edd0-e0f6-488c-8a10-ada50a88672a',
                        codename: 'skip_step',
                        externalId: undefined
                    },
                    previous_step: {
                        name: 'Previous Step',
                        id: '85a22bcc-7ea8-4dc2-ac0c-c10a31340c66',
                        codename: 'previous_step',
                        externalId: undefined
                    },
                    register: {
                        name: 'Register',
                        id: '2c2a36ca-8159-4b38-8e96-8a65d862861b',
                        codename: 'register',
                        externalId: undefined
                    },
                    register___next_step: {
                        name: 'Register   Next Step',
                        id: '819e4976-93d8-45da-92d2-075f7727c70f',
                        codename: 'register___next_step',
                        externalId: undefined
                    },
                    update: {
                        name: 'Update',
                        id: '2599cc21-13cf-451d-b625-172b63f00bc5',
                        codename: 'update',
                        externalId: undefined
                    },
                    update___next_step: {
                        name: 'Update   Next Step',
                        id: '88d069f3-4392-4c78-8001-bcc9e1e26d1f',
                        codename: 'update___next_step',
                        externalId: undefined
                    },
                    update___redirect: {
                        name: 'Update   Redirect',
                        id: '209cef9e-68a6-4fc9-8bb4-91e22cb3960b',
                        codename: 'update___redirect',
                        externalId: undefined
                    },
                    forgot_password: {
                        name: 'Forgot Password',
                        id: 'bb373876-8a2b-4951-93a6-d7d32fd56154',
                        codename: 'forgot_password',
                        externalId: undefined
                    },
                    reset_password: {
                        name: 'Reset Password',
                        id: 'b590da1a-0676-4093-b435-883120cb1296',
                        codename: 'reset_password',
                        externalId: undefined
                    },
                    new_password_required: {
                        name: 'New Password Required',
                        id: 'e45c2b6a-feae-4d7e-b55a-5f598e21be66',
                        codename: 'new_password_required',
                        externalId: undefined
                    },
                    change_password: {
                        name: 'Change Password',
                        id: '9b9c20ff-0a1e-49ee-92a6-ae10bc952180',
                        codename: 'change_password',
                        externalId: undefined
                    },
                    verify_email_exists: {
                        name: 'Verify Email Exists',
                        id: '642fa6e4-28b3-44e3-b663-7b16c3e71594',
                        codename: 'verify_email_exists',
                        externalId: undefined
                    },
                    verify_email_exists___next_step: {
                        name: 'Verify Email Exists   Next Step',
                        id: 'dcb0995b-413e-49d3-a937-ff955d0ac3c5',
                        codename: 'verify_email_exists___next_step',
                        externalId: undefined
                    },
                    one_club_question: {
                        name: 'One Club Question',
                        id: '6f2a6d49-5c10-4d31-91a3-c6579c33d612',
                        codename: 'one_club_question',
                        externalId: undefined
                    }
                },
                snippetCodename: 'form_cta_action_types'
            },

            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: '7697afb9-ee7c-4703-94fa-4443c334b960',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Error Message (text)
             */
            error_message: {
                codename: 'error_message',
                id: 'd282a9fb-95e3-492f-96f8-0a0b68d8b2c7',
                externalId: undefined,
                name: 'Error Message',
                required: false,
                type: 'text'
            },

            /**
             * Success Message (text)
             */
            success_message: {
                codename: 'success_message',
                id: '89ef455d-26df-433f-bd59-493d6102c0c4',
                externalId: undefined,
                name: 'Success Message',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Form Field
     */
    form_field: {
        codename: 'form_field',
        id: 'fea5f784-5777-4a1b-8aed-8c4ccce41aaf',
        externalId: undefined,
        name: 'Form Field',
        elements: {
            /**
             * Dependency Criteria (multiple_choice)
             */
            dependency_criteria: {
                codename: 'dependency_criteria',
                id: '6734cb58-0cd9-469a-b997-7a3af9f11e66',
                externalId: undefined,
                name: 'Dependency Criteria',
                required: false,
                type: 'multiple_choice',
                options: {
                    equals: {
                        name: 'Equals',
                        id: 'e809f9f2-5886-40a9-8607-33d81d4df99c',
                        codename: 'equals',
                        externalId: undefined
                    },
                    equals___ignore_case: {
                        name: 'Equals   Ignore Case',
                        id: '05cc335b-90d0-4398-a65b-115125cccdb1',
                        codename: 'equals___ignore_case',
                        externalId: undefined
                    },
                    not_equals: {
                        name: 'Not Equals',
                        id: '37b18b05-ad6b-43d8-b6d5-6097018d19ac',
                        codename: 'not_equals',
                        externalId: undefined
                    },
                    not_equals___ignore_case: {
                        name: 'Not Equals   Ignore Case',
                        id: '0f0083db-d742-4e9c-8ff0-35b8dc7881b0',
                        codename: 'not_equals___ignore_case',
                        externalId: undefined
                    },
                    includes: {
                        name: 'Includes',
                        id: 'b80c4323-b672-485c-a6e5-4fc3a7186f6f',
                        codename: 'includes',
                        externalId: undefined
                    },
                    includes___ignore_case: {
                        name: 'Includes   Ignore Case',
                        id: '478ca56f-e8d0-4113-bb46-ba002074609f',
                        codename: 'includes___ignore_case',
                        externalId: undefined
                    },
                    not_includes: {
                        name: 'Not Includes',
                        id: '7041c080-7cb0-465b-994d-ebedf804b906',
                        codename: 'not_includes',
                        externalId: undefined
                    },
                    not_includes___ignore_case: {
                        name: 'Not Includes   Ignore Case',
                        id: '97a526b6-f133-4aa4-9b3a-b5591c7d7d5e',
                        codename: 'not_includes___ignore_case',
                        externalId: undefined
                    }
                }
            },

            /**
             * Dependency Validation Message (text)
             */
            dependency_validation_message: {
                codename: 'dependency_validation_message',
                id: '2cc32128-c10f-41b4-bfce-178f7cef1622',
                externalId: undefined,
                name: 'Dependency Validation Message',
                required: false,
                type: 'text'
            },

            /**
             * Depends On (modular_content)
             */
            depends_on: {
                codename: 'depends_on',
                id: '9400cbb8-d743-4180-8ddc-8a7f97f6fce6',
                externalId: undefined,
                name: 'Depends On',
                required: false,
                type: 'modular_content'
            },

            /**
             * Id (text)
             */
            id: {
                codename: 'id',
                id: '0eb2acad-c710-4383-885b-9ec74a2f2ad6',
                externalId: undefined,
                name: 'Id',
                required: false,
                type: 'text'
            },

            /**
             * Is ReadOnly (multiple_choice)
             */
            is_readonly: {
                codename: 'is_readonly',
                id: 'd2360e1e-1d9c-4603-943a-cfb6d8cc0ff9',
                externalId: undefined,
                name: 'Is ReadOnly',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '189c989f-f3a5-4007-afc1-b4891fcee228',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '5e4828a2-b8a9-490c-8e90-a9421dd5a288',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Is Required (multiple_choice)
             */
            is_required: {
                codename: 'is_required',
                id: '14a26152-a268-4db9-8983-1ec1a566cd04',
                externalId: undefined,
                name: 'Is Required',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '72350d10-95b1-4179-9c67-ec0cdf33763d',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '0d9fb365-6694-45c2-8d69-f9ef5e17ff17',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: '76347559-15e1-4c11-b2e0-ac4144d0d3fd',
                externalId: undefined,
                name: 'Label',
                required: false,
                type: 'text'
            },

            /**
             * Placeholder (text)
             */
            placeholder: {
                codename: 'placeholder',
                id: '111d0e91-ab01-4ee5-adbc-3cf80e19cb2a',
                externalId: undefined,
                name: 'Placeholder',
                required: false,
                type: 'text'
            },

            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: '5a696a6e-b72d-4f47-8990-14bf305d677b',
                externalId: undefined,
                name: 'Type',
                required: false,
                type: 'multiple_choice',
                options: {
                    one_club_free_text: {
                        name: 'One Club Free Text',
                        id: 'fc9e59d3-e4b7-4f25-8f46-011b0c05f756',
                        codename: 'one_club_free_text',
                        externalId: undefined
                    }
                }
            },

            /**
             * Validation Message (text)
             */
            validation_message: {
                codename: 'validation_message',
                id: '4364f443-36d3-4aa8-9b59-f51875fed48b',
                externalId: undefined,
                name: 'Validation Message',
                required: false,
                type: 'text'
            },

            /**
             * Validation Regex (text)
             */
            validation_regex: {
                codename: 'validation_regex',
                id: '3216f8a6-b784-4278-9365-de7aba1cc001',
                externalId: undefined,
                name: 'Validation Regex',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Form Footer
     */
    form_footer: {
        codename: 'form_footer',
        id: 'a3fe3c39-9bf7-45fe-8af1-f9b0a1df331a',
        externalId: undefined,
        name: 'Form Footer',
        elements: {
            /**
             * Footer Text Color (modular_content)
             */
            footer_text_color: {
                codename: 'footer_text_color',
                id: '831c76e5-b299-4116-91ee-47689a06dac7',
                externalId: undefined,
                name: 'Footer Text Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Privacy Policy (rich_text)
             */
            privacy_policy: {
                codename: 'privacy_policy',
                id: 'c28216e2-db32-4e83-85f8-e01e9703cff7',
                externalId: undefined,
                name: 'Privacy Policy',
                required: false,
                type: 'rich_text'
            },

            /**
             * Terms and Conditions (rich_text)
             */
            terms_and_conditions: {
                codename: 'terms_and_conditions',
                id: '0b5d59b9-ce58-447b-a2c8-f4c1480d9434',
                externalId: undefined,
                name: 'Terms and Conditions',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     * Form Header
     */
    form_header: {
        codename: 'form_header',
        id: '2c469735-3be7-44d3-9b08-35265f0652fe',
        externalId: undefined,
        name: 'Form Header',
        elements: {
            /**
             * CTAs (modular_content)
             */
            ctas: {
                codename: 'ctas',
                id: 'bad19a2c-006a-4922-a3d3-b6bf1a4f53e8',
                externalId: undefined,
                name: 'CTAs',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subtitle Color (modular_content)
             */
            subtitle_color: {
                codename: 'subtitle_color',
                id: 'a47f32d8-e6e2-481d-b502-28e8316a4cd3',
                externalId: undefined,
                name: 'Subtitle Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '91f88086-86f4-4618-94db-77349f1d223d',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Title Color (modular_content)
             */
            title_color: {
                codename: 'title_color',
                id: 'b15c7a31-5a8c-4b5d-b26f-dd5018d41c3a',
                externalId: undefined,
                name: 'Title Color',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Form Step
     */
    form_step: {
        codename: 'form_step',
        id: 'c1e47fe9-28ee-4559-a25f-635d13276583',
        externalId: undefined,
        name: 'Form Step',
        elements: {
            /**
             * Audio (asset)
             */
            audio: {
                codename: 'audio',
                id: '44f44c96-6b61-41c0-a7f3-c757fc5af090',
                externalId: undefined,
                name: 'Audio',
                required: false,
                type: 'asset'
            },

            /**
             * CTAs (modular_content)
             */
            ctas: {
                codename: 'ctas',
                id: '41ac5830-2648-4496-becf-5333d9ead6af',
                externalId: undefined,
                name: 'CTAs',
                required: false,
                type: 'modular_content'
            },

            /**
             * Custom Header (modular_content)
             */
            custom_header: {
                codename: 'custom_header',
                id: '7a2eeae9-5156-4b7f-ba5e-0ee490493a75',
                externalId: undefined,
                name: 'Custom Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * Form Fields (modular_content)
             */
            form_fields: {
                codename: 'form_fields',
                id: '2da50e55-4cfe-4360-9ac7-890cb3202be5',
                externalId: undefined,
                name: 'Form Fields',
                required: false,
                type: 'modular_content'
            },

            /**
             * Rich Text Items (modular_content)
             */
            rich_text_items: {
                codename: 'rich_text_items',
                id: 'f3f13ba4-67f7-438f-9292-a881ef311b12',
                externalId: undefined,
                name: 'Rich Text Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Show Step Counter (multiple_choice)
             */
            show_step_counter: {
                codename: 'show_step_counter',
                id: 'd0fbcdc2-9cb0-444c-9ea4-b1734e9780f2',
                externalId: undefined,
                name: 'Show Step Counter',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '530fc31b-5759-49c0-aefb-2420604e2cc1',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '4413423c-cb5d-499f-938f-608767cef0c3',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Header
     */
    header: {
        codename: 'header',
        id: 'e2810db6-f776-4e21-9d65-b7943e50639c',
        externalId: undefined,
        name: 'Header',
        elements: {
            /**
             * Content (modular_content)
             */
            content: {
                codename: 'content',
                id: 'ff275cb7-a3b7-4e8b-bb08-4fa237ad9267',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'modular_content'
            },

            /**
             * Desktop Logo (modular_content)
             */
            desktop_logo: {
                codename: 'desktop_logo',
                id: '24916afa-2657-4aec-8ef4-6b19d1cc3246',
                externalId: undefined,
                name: 'Desktop Logo',
                required: false,
                type: 'modular_content'
            },

            /**
             * Mobile Logo (modular_content)
             */
            mobile_logo: {
                codename: 'mobile_logo',
                id: 'dd360749-ba8d-48a1-9350-134302470079',
                externalId: undefined,
                name: 'Mobile Logo',
                required: false,
                type: 'modular_content'
            },

            /**
             * My account link (modular_content)
             */
            my_account_link: {
                codename: 'my_account_link',
                id: '107dfaa8-6099-4316-bc7e-9fe2f7f0762f',
                externalId: undefined,
                name: 'My account link',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Hero Tile
     */
    hero_tile: {
        codename: 'hero_tile',
        id: '14ba2a6e-3a15-4de2-8027-242816ff4754',
        externalId: undefined,
        name: 'Hero Tile',
        elements: {
            /**
             * Cta label (text)
             *
             * If the CTA label is left incomplete, the link title (from Redirect to) will be utilized as the label instead.
             */
            cta_label: {
                codename: 'cta_label',
                id: '2b1ec04d-b7be-4d5d-a996-c40281c70b22',
                externalId: undefined,
                name: 'Cta label',
                required: false,
                type: 'text'
            },

            /**
             * Desktop Background Image (asset)
             */
            desktop_background_image: {
                codename: 'desktop_background_image',
                id: '8438c3f1-f4da-4962-bc6c-8f9075c7fd57',
                externalId: undefined,
                name: 'Desktop Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Mobile Background Image (asset)
             */
            mobile_background_image: {
                codename: 'mobile_background_image',
                id: '9d93b2a9-7c9b-470f-b53c-a4f3521dedff',
                externalId: undefined,
                name: 'Mobile Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Redirect to (modular_content)
             */
            redirect_to: {
                codename: 'redirect_to',
                id: '6819ad58-41bb-4edd-9b77-53251acd04cf',
                externalId: undefined,
                name: 'Redirect to',
                required: false,
                type: 'modular_content'
            },

            /**
             * Select access (multiple_choice)
             *
             * If Standard or Advantage is selected the access to the content will be limited to the logged in user membership level.
             */
            membership_based_access__select_access: {
                codename: 'membership_based_access__select_access',
                id: 'b1494d90-9c32-4e37-971d-675996e05481',
                externalId: undefined,
                name: 'Select access',
                required: false,
                type: 'multiple_choice',
                options: {
                    none: {
                        name: 'No restrictions',
                        id: 'ffa1df83-f8af-480a-956d-5544ed4644a4',
                        codename: 'none',
                        externalId: undefined
                    },
                    oneclub: {
                        name: 'One Club',
                        id: '0d8f00cc-9438-4d95-843a-f007e4c3c71a',
                        codename: 'oneclub',
                        externalId: undefined
                    },
                    oneclubadvantage: {
                        name: 'One Club Advantage',
                        id: '9c47187e-b0dc-45bd-97c4-d86cd7aa65c3',
                        codename: 'oneclubadvantage',
                        externalId: undefined
                    }
                },
                snippetCodename: 'membership_based_access'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: '518add38-edb0-4198-80a9-b9551789d5e2',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Tags (modular_content)
             */
            tags: {
                codename: 'tags',
                id: '38386435-8b3d-4f25-98fd-b5fe98e1b905',
                externalId: undefined,
                name: 'Tags',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '9b5c798c-d699-4cd0-bd32-6fa8dab158d6',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: '686e3602-dedb-41f4-a3c7-23e84e5fdd70',
                externalId: undefined,
                name: 'type',
                required: false,
                type: 'multiple_choice',
                options: {
                    highlight_article: {
                        name: 'highlight article',
                        id: '33c7af7a-dcab-4cb6-9cff-020c1da9ad05',
                        codename: 'highlight_article',
                        externalId: undefined
                    },
                    highlight_video: {
                        name: 'highlight video',
                        id: 'a460d4b7-e9e1-4232-8e9f-4f7c215c7ef8',
                        codename: 'highlight_video',
                        externalId: undefined
                    },
                    reward: {
                        name: 'reward',
                        id: '5614aa3b-860a-46d2-b46d-a4320a3508a2',
                        codename: 'reward',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Home page
     */
    home_page: {
        codename: 'home_page',
        id: '6ac80d8e-258b-43d8-af2c-066339133052',
        externalId: undefined,
        name: 'Home page',
        elements: {
            /**
             * Body (modular_content)
             */
            body: {
                codename: 'body',
                id: '77570d9b-8683-463e-98d6-de41eea16d6b',
                externalId: undefined,
                name: 'Body',
                required: false,
                type: 'modular_content'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Mobile elements (modular_content)
             *
             * All the elements will be shown in the order you are putting them in
             */
            mobile_elements: {
                codename: 'mobile_elements',
                id: '8c9757bf-25e4-4b92-b3e8-9c78b0d5a0d5',
                externalId: undefined,
                name: 'Mobile elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Pre footer elements (modular_content)
             *
             * These elements will be shown after the body content. The web banners will always be on top of these
             */
            pre_footer_elements: {
                codename: 'pre_footer_elements',
                id: 'dd642814-0ac9-4087-ab2b-3963ec1e6cfe',
                externalId: undefined,
                name: 'Pre footer elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sidebar (modular_content)
             *
             * This side bar is shown beside the main body content on desktop. On mobile the web banners will show below the main body and nothing else will be shown
             */
            sidebar: {
                codename: 'sidebar',
                id: 'd06ecbf0-d80c-4023-bc59-eb00cbe5b201',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subheader elements (modular_content)
             */
            subheader_elements: {
                codename: 'subheader_elements',
                id: 'ab1b3caa-0835-430f-a57f-a6ee845b18b5',
                externalId: undefined,
                name: 'Subheader elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Template (modular_content)
             */
            template: {
                codename: 'template',
                id: '4f989f49-56d6-4e56-af74-77c78fb11656',
                externalId: undefined,
                name: 'Template',
                required: true,
                type: 'modular_content'
            },

            /**
             * URL Slug (text)
             */
            url_slug: {
                codename: 'url_slug',
                id: '3de6d13e-555d-4f40-a8b2-d59d35fa7b7b',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Image
     */
    image: {
        codename: 'image',
        id: '06ff6440-f839-4f24-9227-39ad168cfce7',
        externalId: undefined,
        name: 'Image',
        elements: {
            /**
             * Alt (text)
             */
            alt: {
                codename: 'alt',
                id: '57d860a2-6e3f-4214-aa8a-d9a2f20af063',
                externalId: undefined,
                name: 'Alt',
                required: false,
                type: 'text'
            },

            /**
             * Asset (asset)
             */
            asset: {
                codename: 'asset',
                id: 'f462b464-d651-4e1c-82ec-d742242059e2',
                externalId: undefined,
                name: 'Asset',
                required: true,
                type: 'asset'
            },

            /**
             * Height (number)
             *
             * Please leave this blank unless specifically needed inside a component.
             */
            height: {
                codename: 'height',
                id: '029a9516-20bc-4cc5-ab08-bd5ca9f370d5',
                externalId: undefined,
                name: 'Height',
                required: false,
                type: 'number'
            },

            /**
             * License (text)
             */
            license: {
                codename: 'license',
                id: '5ebd2871-932c-4abc-8dd6-e1f705484f4d',
                externalId: undefined,
                name: 'License',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '260788bc-cb12-49ae-969c-643e0fcd706e',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Width (number)
             *
             * Please leave this blank unless specifically needed inside a component.
             */
            width: {
                codename: 'width',
                id: '5e320a8b-e056-41e0-b5d2-679bdd245922',
                externalId: undefined,
                name: 'Width',
                required: false,
                type: 'number'
            }
        }
    },

    /**
     * Link
     */
    link: {
        codename: 'link',
        id: 'ff2f2b4f-4897-46dc-b5c9-6e649557a9b1',
        externalId: undefined,
        name: 'Link',
        elements: {
            /**
             * href (text)
             *
             * Use this property when reffering to an external page
             */
            href: {
                codename: 'href',
                id: '9a2d7a19-bdb5-402f-85bf-cd1c916d83b8',
                externalId: undefined,
                name: 'href',
                required: false,
                type: 'text'
            },

            /**
             * Icon (asset)
             */
            icon: {
                codename: 'icon',
                id: 'b229d723-383b-4b6a-9264-873df144a5aa',
                externalId: undefined,
                name: 'Icon',
                required: false,
                type: 'asset'
            },

            /**
             * Internal page (modular_content)
             */
            internal_page: {
                codename: 'internal_page',
                id: 'bf6b32f1-282b-401f-9fb9-fe28183b80f2',
                externalId: undefined,
                name: 'Internal page',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a709636b-4a5a-40e5-9ae8-f43ad6e5a7cd',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Logo
     */
    logo: {
        codename: 'logo',
        id: '912e9504-5452-4fe8-b290-cecf08ec7c57',
        externalId: undefined,
        name: 'Logo',
        elements: {
            /**
             * Logo Image (asset)
             */
            logo_image: {
                codename: 'logo_image',
                id: '3e13414d-3095-46a6-a4d3-674f422ac017',
                externalId: undefined,
                name: 'Logo Image',
                required: true,
                type: 'asset'
            },

            /**
             * Resources (modular_content)
             */
            resources: {
                codename: 'resources',
                id: '258987c9-9bb1-4f9d-a9cd-9492a8673b14',
                externalId: undefined,
                name: 'Resources',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * Membership Configuration
     */
    membership_configuration: {
        codename: 'membership_configuration',
        id: 'd1a398f4-379e-497e-9c46-14098b1f5e33',
        externalId: undefined,
        name: 'Membership Configuration',
        elements: {
            /**
             * API Key (modular_content)
             */
            api_key: {
                codename: 'api_key',
                id: '40d37174-946c-4734-a4fe-8e3eacf0da47',
                externalId: undefined,
                name: 'API Key',
                required: false,
                type: 'modular_content'
            },

            /**
             * Base Url (modular_content)
             */
            base_url: {
                codename: 'base_url',
                id: '339c92f8-d410-45e8-a6cd-f574b08fbd25',
                externalId: undefined,
                name: 'Base Url',
                required: false,
                type: 'modular_content'
            },

            /**
             * Cookie Popup (modular_content)
             */
            cookie_popup: {
                codename: 'cookie_popup',
                id: 'fc0650f9-1b26-444f-ac3e-745ead0dd4a9',
                externalId: undefined,
                name: 'Cookie Popup',
                required: false,
                type: 'modular_content'
            },

            /**
             * Cookie Settings (modular_content)
             */
            cookie_settings: {
                codename: 'cookie_settings',
                id: '59281a40-63e6-47a5-a257-f661d1b5f3a8',
                externalId: undefined,
                name: 'Cookie Settings',
                required: false,
                type: 'modular_content'
            },

            /**
             * Realm (modular_content)
             */
            realm: {
                codename: 'realm',
                id: '659d6ce4-8968-4dc7-be32-07f812c89a8e',
                externalId: undefined,
                name: 'Realm',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Membership Teaser Panel
     */
    membership_teaser_panel: {
        codename: 'membership_teaser_panel',
        id: '9db5f03f-9806-4e90-b141-8580922fd3a7',
        externalId: undefined,
        name: 'Membership Teaser Panel',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '4a2c9e9e-548c-45de-bcbe-dcd300c112db',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Logo (asset)
             */
            logo: {
                codename: 'logo',
                id: 'cf11fb5c-30bb-4b1d-b246-83569e4a9ce5',
                externalId: undefined,
                name: 'Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Membership types (modular_content)
             *
             * Here you can put the membership types, and they will be rendered in that order.
             */
            membership_types: {
                codename: 'membership_types',
                id: '014603d1-ff71-49c6-afd4-64c9e0ff14aa',
                externalId: undefined,
                name: 'Membership types',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * Membership type
     */
    membership_type: {
        codename: 'membership_type',
        id: '24af0e52-31a3-4a3f-b128-9e1b89698956',
        externalId: undefined,
        name: 'Membership type',
        elements: {
            /**
             * Active background color (modular_content)
             *
             * This the active background color that will be used when this membership is detected from the logged in user. Defaults to platform
             */
            active_background_color: {
                codename: 'active_background_color',
                id: 'c3315eb1-09c4-4b7f-9576-30d0258f08f7',
                externalId: undefined,
                name: 'Active background color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Icon Image (asset)
             *
             * There is a default value set for this in code, use this if you are certain you want to override the default value
             */
            icon_image: {
                codename: 'icon_image',
                id: 'a740cd7b-a44c-4ede-aa1b-082afa07cea9',
                externalId: undefined,
                name: 'Icon Image',
                required: false,
                type: 'asset'
            },

            /**
             * Inactive background color (modular_content)
             *
             * This the inactive background color that will be used when this membership is detected from the logged in user. Defaults to platform
             */
            inactive_background_color: {
                codename: 'inactive_background_color',
                id: '94d5dbd3-0b17-45eb-9d6c-a18f809258cc',
                externalId: undefined,
                name: 'Inactive background color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: '7f9eae1e-8667-4876-a1b6-f0816244bda1',
                externalId: undefined,
                name: 'Label',
                required: false,
                type: 'text'
            },

            /**
             * Popup content (modular_content)
             *
             * These components will be used to populate the pop-up content when user clicks on this Membership type
             */
            popup_content: {
                codename: 'popup_content',
                id: 'db21f6f4-6d28-462a-a7e5-c7adee8f43b0',
                externalId: undefined,
                name: 'Popup content',
                required: false,
                type: 'modular_content'
            },

            /**
             * Price (text)
             *
             * This is the price in the format "£10"
             */
            price: {
                codename: 'price',
                id: 'fd0b44d0-19ad-4dd5-9887-749b6047875a',
                externalId: undefined,
                name: 'Price',
                required: true,
                type: 'text'
            },

            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: '6c8bee20-8ba8-4d2e-9e51-d19155fcfb04',
                externalId: undefined,
                name: 'Type',
                required: false,
                type: 'multiple_choice',
                options: {
                    none: {
                        name: 'None',
                        id: '5c945f8a-7de2-4e16-b777-135e6b24989e',
                        codename: 'none',
                        externalId: undefined
                    },
                    oneclub: {
                        name: 'One Club',
                        id: 'fac9cb15-c6e5-4e99-abc0-ac0a95956e71',
                        codename: 'oneclub',
                        externalId: undefined
                    },
                    oneclubadvantage: {
                        name: 'One Club Advantage',
                        id: '0052981a-c836-48d4-aba1-429277c875bf',
                        codename: 'oneclubadvantage',
                        externalId: undefined
                    }
                }
            },

            /**
             * Welcome message (modular_content)
             *
             * Title can be customized by using {{memberName}} i.e. Hi, {{memberName}}!  <=> Hi, John Doe!Body can be customized by adding {{formattedDateSinceMember}} i.e. "You are an Advantage Member since {{formattedDateSinceMember}}!" <=>  "You are and Advantage Member since November 2023"NOTE: These placeholders are only available for the feature cards use in the Membership teaser panel
             */
            welcome_message: {
                codename: 'welcome_message',
                id: '22a4de9c-da5d-4da6-b2ba-b0ad6923e48c',
                externalId: undefined,
                name: 'Welcome message',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Menu
     */
    menu: {
        codename: 'menu',
        id: 'fc959654-dd1b-403e-a0e4-762f2b911973',
        externalId: undefined,
        name: 'Menu',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '0fabbea6-d8b0-415a-89df-ee94b4c8a379',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Footer (modular_content)
             */
            footer: {
                codename: 'footer',
                id: 'c5d587c3-558b-46c7-9279-2ca0735682f3',
                externalId: undefined,
                name: 'Footer',
                required: false,
                type: 'modular_content'
            },

            /**
             * Items (modular_content)
             *
             * These can be links to internal/external pages and also submenus
             */
            items: {
                codename: 'items',
                id: '0d15324c-f907-4b85-b093-f11f9cc47a03',
                externalId: undefined,
                name: 'Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Style (multiple_choice)
             */
            style: {
                codename: 'style',
                id: '7f9f1c27-de86-474c-84b8-8fd58a15d9b9',
                externalId: undefined,
                name: 'Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    main_menu: {
                        name: 'Main menu',
                        id: 'a16b53b7-df38-4c87-b1f0-6b4b0e3d0cdd',
                        codename: 'main_menu',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             *
             * This is the text that appears if it is not the root of the menu
             */
            title: {
                codename: 'title',
                id: 'a01aa1d0-d432-489f-8d8f-0a73290e3e22',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Menu Footer
     */
    menu_footer: {
        codename: 'menu_footer',
        id: '6ae43299-aad8-409b-8bbb-068059197f77',
        externalId: undefined,
        name: 'Menu Footer',
        elements: {
            /**
             * Logos (modular_content)
             */
            logos: {
                codename: 'logos',
                id: 'c96573a3-91d1-4fd9-b162-1ae5574910c1',
                externalId: undefined,
                name: 'Logos',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * My Account Page
     */
    my_account_page: {
        codename: 'my_account_page',
        id: 'f8213f66-ade3-486b-b248-d4261f3e7cb8',
        externalId: undefined,
        name: 'My Account Page',
        elements: {
            /**
             * Body (modular_content)
             */
            body: {
                codename: 'body',
                id: '45f6c743-2b1a-4925-9065-56c97f8780b5',
                externalId: undefined,
                name: 'Body',
                required: false,
                type: 'modular_content'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Mobile elements (modular_content)
             */
            mobile_elements: {
                codename: 'mobile_elements',
                id: 'de75f32b-370a-47e3-8ad0-ab348190c4dd',
                externalId: undefined,
                name: 'Mobile elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Sidebar (modular_content)
             */
            sidebar: {
                codename: 'sidebar',
                id: 'a097b660-f4e6-4d46-88d8-c59d784df458',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subheader elements (modular_content)
             */
            subheader_elements: {
                codename: 'subheader_elements',
                id: 'f03acbaf-a7a4-4d62-800d-699d0bb98457',
                externalId: undefined,
                name: 'Subheader elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Template (modular_content)
             */
            template: {
                codename: 'template',
                id: '2814fa3a-e83d-4212-bb59-2dfe910b8af8',
                externalId: undefined,
                name: 'Template',
                required: true,
                type: 'modular_content'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '1cb7b2e9-7789-43e1-90bf-44e24ab71bba',
                externalId: undefined,
                name: 'URL slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     * My Account Placeholder
     */
    my_account_placeholder: {
        codename: 'my_account_placeholder',
        id: '1ea5ae0e-fdef-49d7-80ec-365baad4f2e1',
        externalId: undefined,
        name: 'My Account Placeholder',
        elements: {}
    },

    /**
     * Page
     */
    page: {
        codename: 'page',
        id: 'ecf72ca1-07d7-415e-85d5-e769882180b2',
        externalId: undefined,
        name: 'Page',
        elements: {
            /**
             * Body (modular_content)
             */
            body: {
                codename: 'body',
                id: '134c444b-1ef9-4373-9a2f-a48f3226c1c7',
                externalId: undefined,
                name: 'Body',
                required: false,
                type: 'modular_content'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Pre footer elements (modular_content)
             *
             * These elements will be shown after the body content. The web banners will always be on top of these
             */
            pre_footer_elements: {
                codename: 'pre_footer_elements',
                id: 'f61ab6fe-8af1-4588-8da2-d7401148468a',
                externalId: undefined,
                name: 'Pre footer elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sidebar (modular_content)
             *
             * This side bar is shown beside the main body content on desktop. On mobile the web banners will show below the main body and nothing else will be shown
             */
            sidebar: {
                codename: 'sidebar',
                id: '6556aba8-3a5b-4e2c-bdd2-1efa65ddb98f',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'modular_content'
            },

            /**
             * Special URL (text)
             *
             * WARNING: IF THERE ARE TWO PAGES WITH THE SAME SPECIAL URL, THE OUTCOME OF THE SEARCH WILL BE UNPREDICTABLE.
             */
            special_url: {
                codename: 'special_url',
                id: 'd84dfc45-5146-478e-a04c-fdbf2304c346',
                externalId: undefined,
                name: 'Special URL',
                required: false,
                type: 'text'
            },

            /**
             * Subheader elements (modular_content)
             */
            subheader_elements: {
                codename: 'subheader_elements',
                id: '2301c829-c4c0-428c-bc38-72b8d40c7867',
                externalId: undefined,
                name: 'Subheader elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Template (modular_content)
             */
            template: {
                codename: 'template',
                id: '8bc3884e-67b1-4350-a66b-0989e35295ea',
                externalId: undefined,
                name: 'Template',
                required: true,
                type: 'modular_content'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'bef6433a-bace-4431-865a-c228b86ff129',
                externalId: undefined,
                name: 'URL slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     * Page template
     */
    page_template: {
        codename: 'page_template',
        id: '7fd14900-f22c-46fb-a943-34f3982fe592',
        externalId: undefined,
        name: 'Page template',
        elements: {
            /**
             * Footer (modular_content)
             */
            footer: {
                codename: 'footer',
                id: '4b8a657d-a747-4854-9396-d362ac9403e1',
                externalId: undefined,
                name: 'Footer',
                required: true,
                type: 'modular_content'
            },

            /**
             * Header (modular_content)
             */
            header: {
                codename: 'header',
                id: 'd3fc4123-9135-449e-9d9d-ceb8616645e7',
                externalId: undefined,
                name: 'Header',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * Playlist
     */
    playlist: {
        codename: 'playlist',
        id: 'dbee0767-d697-4932-a6aa-8349b30b8e87',
        externalId: undefined,
        name: 'Playlist',
        elements: {
            /**
             * Arrows  color (modular_content)
             */
            carousel_config__slider_arrows_color: {
                codename: 'carousel_config__slider_arrows_color',
                id: 'c4c8dbcc-1e4a-4ffb-943f-160ce6d43f02',
                externalId: undefined,
                name: 'Arrows: color',
                required: false,
                type: 'modular_content',

                snippetCodename: 'carousel_config'
            },

            /**
             * Arrows  size (multiple_choice)
             *
             * Default is set to "lg"
             */
            carousel_config__slider_arrows_size: {
                codename: 'carousel_config__slider_arrows_size',
                id: '6c137d73-9d0a-41d6-90f6-485c2a3f2e5e',
                externalId: undefined,
                name: 'Arrows: size',
                required: false,
                type: 'multiple_choice',
                options: {
                    sm: {
                        name: 'sm',
                        id: 'f617cdfb-c3b9-4041-863f-1441f701f1ae',
                        codename: 'sm',
                        externalId: undefined
                    },
                    lg: {
                        name: 'lg',
                        id: '19619166-86cd-4ada-8791-0a33285c81b6',
                        codename: 'lg',
                        externalId: undefined
                    },
                    xl: {
                        name: 'xl',
                        id: 'a07d155f-7c30-4c83-9098-5545b03474bb',
                        codename: 'xl',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Arrows  transparent background (multiple_choice)
             *
             * Default state is "No". If set to "Yes", the arrows will not have the navy blue circle container around them.
             */
            carousel_config__arrows__transparent_background: {
                codename: 'carousel_config__arrows__transparent_background',
                id: 'b7baa384-8fa1-4244-b87c-972b47717b2a',
                externalId: undefined,
                name: 'Arrows: transparent background',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: 'c2088fb8-f187-4a7a-8a66-576ca2cdcb2b',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '338248f0-f3e5-43b5-8720-59ff6ae6587a',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Autoplay  timeout (number)
             *
             * If enabled: Set how much time the slide will auto play (in seconds) until it stops completely.
             */
            carousel_config__autoplay_timeout: {
                codename: 'carousel_config__autoplay_timeout',
                id: '1c758157-031e-43d5-90f4-cecf648a12e7',
                externalId: undefined,
                name: 'Autoplay: timeout',
                required: false,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Autoplay  timer (number)
             *
             * If enabled: Set the time interval (in seconds) between slides.
             */
            carousel_config__autoplay_timer: {
                codename: 'carousel_config__autoplay_timer',
                id: '77a04154-3dd5-4b30-9109-9cbb7cd63250',
                externalId: undefined,
                name: 'Autoplay: timer',
                required: false,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Desktop  Tiles to show (number)
             *
             * Recommended setting as per design: 1.2
             */
            carousel_config__slides_to_show_desktop: {
                codename: 'carousel_config__slides_to_show_desktop',
                id: 'e6ec9ff7-8aab-4270-9d3f-1545dfd5d49a',
                externalId: undefined,
                name: 'Desktop: Tiles to show',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Desktop  Tiles to slide (number)
             *
             * Always use number without decimal. Example: If we choose to show 1.2 tiles, we will slide 1 tile
             */
            carousel_config__slides_to_scroll_desktop: {
                codename: 'carousel_config__slides_to_scroll_desktop',
                id: '81a3fa77-9a08-4bbb-a26e-886488c8cafb',
                externalId: undefined,
                name: 'Desktop: Tiles to slide',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Enable arrows (multiple_choice)
             */
            carousel_config__enable_arrows: {
                codename: 'carousel_config__enable_arrows',
                id: '6c19ef2f-2f9d-4722-8cb6-72ed4790b3fc',
                externalId: undefined,
                name: 'Enable arrows',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: 'bc937795-16d1-491a-8c35-5e8a131c1e8e',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '33467f46-d29b-4a52-ba1b-48abe2dc8537',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Enable Autoplay (multiple_choice)
             *
             * Default value is "No"
             */
            carousel_config__autoplay: {
                codename: 'carousel_config__autoplay',
                id: '85596b43-ed10-4f35-bfae-203678416df3',
                externalId: undefined,
                name: 'Enable Autoplay',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: 'aa8abbec-2324-4e7e-9f1f-d29089b83d06',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: 'f6d73420-559d-41f3-8883-e7f63f2c7dab',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Enable Loop (multiple_choice)
             *
             * Default value is "No". If set to "Yes", the carousel will not stop when it reaches the last tile. It will be an infinite loop.
             */
            carousel_config__infinite_loop: {
                codename: 'carousel_config__infinite_loop',
                id: 'd2e72694-b0bd-49c4-b825-829fc86c956a',
                externalId: undefined,
                name: 'Enable Loop',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: '899d34ed-5fda-4f73-b81b-1b378fc6702f',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '57cffda5-72a7-44f3-9239-1ed25784fdeb',
                        codename: 'false',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            },

            /**
             * Mobile  Tiles to show (number)
             *
             * Recommended setting as per design: 1.2
             */
            carousel_config__slides_to_show_mobile: {
                codename: 'carousel_config__slides_to_show_mobile',
                id: 'e7133558-b8a5-46c2-845c-d184549d97dd',
                externalId: undefined,
                name: 'Mobile: Tiles to show',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Mobile  Tiles to slide (number)
             *
             * Always use number without decimal. Example: If we choose to show 1.2 tiles, we will slide 1 tile
             */
            carousel_config__slide_to_scroll_mobile: {
                codename: 'carousel_config__slide_to_scroll_mobile',
                id: 'd22feeb5-e5ec-4e08-aa6f-ee4d7d62175a',
                externalId: undefined,
                name: 'Mobile: Tiles to slide',
                required: true,
                type: 'number',

                snippetCodename: 'carousel_config'
            },

            /**
             * Playlist Id (text)
             */
            playlist_id: {
                codename: 'playlist_id',
                id: '2c5aafe8-e8cb-461c-a90e-780c18e4e507',
                externalId: undefined,
                name: 'Playlist Id',
                required: true,
                type: 'text'
            },

            /**
             * Playlist Title (text)
             */
            playlist_title: {
                codename: 'playlist_title',
                id: 'be78876d-a4ad-4713-8bb7-d9433d074ac2',
                externalId: undefined,
                name: 'Playlist Title',
                required: false,
                type: 'text'
            },

            /**
             * Slider width (multiple_choice)
             *
             * If "Full width" selected: the carousel will take the parent's container width.If "None" selected: the carousel will be inside it's own container but the tiles will "break" out on the right side.
             */
            carousel_config__slider_width: {
                codename: 'carousel_config__slider_width',
                id: 'ecf1b958-ea96-4d9d-b5d2-363c4ba11a2b',
                externalId: undefined,
                name: 'Slider width',
                required: false,
                type: 'multiple_choice',
                options: {
                    full_width: {
                        name: 'Full width',
                        id: '707bb566-0dc9-4de0-9fd3-33bdb5db707f',
                        codename: 'full_width',
                        externalId: undefined
                    }
                },
                snippetCodename: 'carousel_config'
            }
        }
    },

    /**
     * Query Param
     */
    query_param: {
        codename: 'query_param',
        id: '96a61c57-9e78-48a2-ad93-c8da42a63f25',
        externalId: undefined,
        name: 'Query Param',
        elements: {
            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: '68fb16a3-4837-4e44-aa99-b5345a91a801',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Recommended Articles
     */
    recommended_articles: {
        codename: 'recommended_articles',
        id: 'b95c194f-6553-4199-8a1d-aecaf767cf9f',
        externalId: undefined,
        name: 'Recommended Articles',
        elements: {
            /**
             * Articles List (modular_content)
             *
             * When including the highlighted article, it is recommended to use the list with a maximum of 5 articles. Otherwise, the maximum number should be 9.
             */
            articles_list: {
                codename: 'articles_list',
                id: '45c38647-1023-49c5-ae36-7bccde034704',
                externalId: undefined,
                name: 'Articles List',
                required: false,
                type: 'modular_content'
            },

            /**
             * Cta all articles text (text)
             */
            cta_all_articles_text: {
                codename: 'cta_all_articles_text',
                id: 'f478f737-7e4f-4ff6-b909-81938c0be3e9',
                externalId: undefined,
                name: 'Cta all articles text',
                required: false,
                type: 'text'
            },

            /**
             * Filter dynamic list (modular_content)
             */
            filter_dynamic_list: {
                codename: 'filter_dynamic_list',
                id: 'e2ab3c63-dd8f-43c5-8d29-a818b2c5bef0',
                externalId: undefined,
                name: 'Filter dynamic list',
                required: false,
                type: 'modular_content'
            },

            /**
             * Highlighted Article (modular_content)
             */
            highlighted_article: {
                codename: 'highlighted_article',
                id: 'dd22b432-53f3-4019-8413-8b282321e205',
                externalId: undefined,
                name: 'Highlighted Article',
                required: false,
                type: 'modular_content'
            },

            /**
             * Highlighted Article position (multiple_choice)
             */
            highlighted_article_position: {
                codename: 'highlighted_article_position',
                id: '7a58f196-a312-403e-a9f6-8fb3810969ed',
                externalId: undefined,
                name: 'Highlighted Article position',
                required: false,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: '64e4f6e8-d0b2-4dc6-82f2-205c0a693922',
                        codename: 'left',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: '88cfca52-ece1-4900-930d-fcd6abb65990',
                        codename: 'right',
                        externalId: undefined
                    }
                }
            },

            /**
             * Max list items (number)
             *
             * Please ensure to fill out this field:1. if filters are applied and you intend to restrict the number of articles displayed in the dynamically fetched list.2.if you add manually more items in list and want to restric the number of articles to show.
             */
            max_list_items: {
                codename: 'max_list_items',
                id: 'dc45f845-ba07-40a5-a896-f62a88337242',
                externalId: undefined,
                name: 'Max list items',
                required: false,
                type: 'number'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a2458b45-402c-4106-b069-a271f85effa8',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Reward
     */
    reward: {
        codename: 'reward',
        id: 'b0e0ca97-572f-46b5-a8b3-9354e18f0833',
        externalId: undefined,
        name: 'Reward',
        elements: {
            /**
             * ENTER Button link (modular_content)
             *
             * This link should go to the event itself (external)
             */
            enter_button_link: {
                codename: 'enter_button_link',
                id: '24b31d69-831c-42e3-bdbd-a27f3b322f23',
                externalId: undefined,
                name: 'ENTER Button link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Form (modular_content)
             */
            form: {
                codename: 'form',
                id: '540d823e-aa3b-4f5a-9ed8-b98709514722',
                externalId: undefined,
                name: 'Form',
                required: false,
                type: 'modular_content'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * More content (modular_content)
             *
             * These elements will appear after the form
             */
            more_content: {
                codename: 'more_content',
                id: '4f4b8c79-2e23-47fb-877b-85c762137787',
                externalId: undefined,
                name: 'More content',
                required: false,
                type: 'modular_content'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Primary image (modular_content)
             */
            primary_image: {
                codename: 'primary_image',
                id: 'b92232da-234e-475b-a7aa-5872fd57e86c',
                externalId: undefined,
                name: 'Primary image',
                required: false,
                type: 'modular_content'
            },

            /**
             * Primary Mobile Image (modular_content)
             */
            primary_mobile_image: {
                codename: 'primary_mobile_image',
                id: '6c383bb8-3e88-4b3c-ba49-5401345cd711',
                externalId: undefined,
                name: 'Primary Mobile Image',
                required: false,
                type: 'modular_content'
            },

            /**
             * Select access (multiple_choice)
             *
             * If Standard or Advantage is selected the access to the content will be limited to the logged in user membership level.
             */
            membership_based_access__select_access: {
                codename: 'membership_based_access__select_access',
                id: 'b1494d90-9c32-4e37-971d-675996e05481',
                externalId: undefined,
                name: 'Select access',
                required: false,
                type: 'multiple_choice',
                options: {
                    none: {
                        name: 'No restrictions',
                        id: 'ffa1df83-f8af-480a-956d-5544ed4644a4',
                        codename: 'none',
                        externalId: undefined
                    },
                    oneclub: {
                        name: 'One Club',
                        id: '0d8f00cc-9438-4d95-843a-f007e4c3c71a',
                        codename: 'oneclub',
                        externalId: undefined
                    },
                    oneclubadvantage: {
                        name: 'One Club Advantage',
                        id: '9c47187e-b0dc-45bd-97c4-d86cd7aa65c3',
                        codename: 'oneclubadvantage',
                        externalId: undefined
                    }
                },
                snippetCodename: 'membership_based_access'
            },

            /**
             * Sidebar (modular_content)
             */
            sidebar: {
                codename: 'sidebar',
                id: '68598da8-67f5-47df-82d1-7d11b26ce00c',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subheader elements (modular_content)
             */
            subheader_elements: {
                codename: 'subheader_elements',
                id: '2e6f2142-9e6d-4c99-9a36-611875af69a4',
                externalId: undefined,
                name: 'Subheader elements',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: 'b517e60b-2a0e-4c7b-afde-b54ceaddce90',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Template (modular_content)
             */
            template: {
                codename: 'template',
                id: '0f641356-6abe-4f5b-943a-815e8ed4b93a',
                externalId: undefined,
                name: 'Template',
                required: true,
                type: 'modular_content'
            },

            /**
             * Term and conditions link (modular_content)
             */
            term_and_conditions_link: {
                codename: 'term_and_conditions_link',
                id: 'd34c2c7f-d29d-4aec-b2d3-f6618d6c048f',
                externalId: undefined,
                name: 'Term and conditions link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '24c5682b-792c-4a7b-b8b1-fca4c3a2b1ce',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'a7a568e8-aa68-4a73-9887-0560a2c56622',
                externalId: undefined,
                name: 'URL slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     * Rich Text
     */
    rich_text: {
        codename: 'rich_text',
        id: '3ca708a0-3880-42a8-9b15-230fca450c5e',
        externalId: undefined,
        name: 'Rich Text',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '6f41458c-0e6f-4604-b260-0a2610b2b6c3',
                externalId: undefined,
                name: 'Content',
                required: true,
                type: 'rich_text'
            }
        }
    },

    /**
     * Section Title
     */
    section_title: {
        codename: 'section_title',
        id: '5014ca05-91de-4484-a674-89597b43dd62',
        externalId: undefined,
        name: 'Section Title',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: 'e22b4f42-c52e-4c1e-8ece-5939be567536',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '41c1a149-499c-4549-86bb-19640e6bbc77',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * SEO Data
     */
    seo_data: {
        codename: 'seo_data',
        id: '6ef82f82-86d3-420c-b20a-f3be7fb77621',
        externalId: undefined,
        name: 'SEO Data',
        elements: {
            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8f293fc7-cd47-475c-b7e1-333f5bfa7cc2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '382cf3e9-e8d4-407a-9c84-59bdf22673f5',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '557966f4-d90f-4022-aec4-be7ef49b826f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'bd90cd0a-fb94-4309-b51e-cb7a3b69df49',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Required Query Params (modular_content)
             */
            required_query_params: {
                codename: 'required_query_params',
                id: 'd3a2275b-b966-427a-a422-96510021c6a7',
                externalId: undefined,
                name: 'Required Query Params',
                required: false,
                type: 'modular_content'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '54469fd6-5828-473c-bc71-fff47cf95274',
                externalId: undefined,
                name: 'URL Slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     * SSO CTA
     */
    sso_cta: {
        codename: 'sso_cta',
        id: 'e7794a33-2b75-415f-949f-40f0ca42d28b',
        externalId: undefined,
        name: 'SSO CTA',
        elements: {
            /**
             * Action (multiple_choice)
             */
            action_type: {
                codename: 'action_type',
                id: '83f76638-3ace-4ade-88ff-5af6a0e775d3',
                externalId: undefined,
                name: 'Action',
                required: true,
                type: 'multiple_choice',
                options: {
                    login: {
                        name: 'Login',
                        id: 'd11216b8-6831-4931-8094-cfdb2d985ca7',
                        codename: 'login',
                        externalId: undefined
                    },
                    register: {
                        name: 'Register',
                        id: '17b21ecb-6dd0-434b-a6a9-a724c48d823b',
                        codename: 'register',
                        externalId: undefined
                    },
                    update: {
                        name: 'Update',
                        id: '753ee45b-f235-4d00-a2e5-6c374649ae52',
                        codename: 'update',
                        externalId: undefined
                    },
                    upgrade: {
                        name: 'Upgrade',
                        id: 'f12faf5f-41dd-46f7-baee-1a8313b762d1',
                        codename: 'upgrade',
                        externalId: undefined
                    },
                    logout: {
                        name: 'Logout',
                        id: 'ea658b6f-54af-4f53-b70e-345f496fb391',
                        codename: 'logout',
                        externalId: undefined
                    }
                }
            },

            /**
             * Label (text)
             *
             * This label is what will be shown on the button
             */
            label: {
                codename: 'label',
                id: '2adea8b1-e802-466b-b265-207233f73f39',
                externalId: undefined,
                name: 'Label',
                required: false,
                type: 'text'
            },

            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: '0e085340-ce22-495b-80f3-fccf73edf3bf',
                externalId: undefined,
                name: 'Type',
                required: false,
                type: 'multiple_choice',
                options: {
                    button: {
                        name: 'Button',
                        id: 'a097dd59-d173-4f7f-b865-ee48bbcd54d3',
                        codename: 'button',
                        externalId: undefined
                    },
                    link: {
                        name: 'Link',
                        id: 'c6bb84bb-f422-44e6-b9a9-afd7b7e65d3c',
                        codename: 'link',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Stories iFrame
     */
    stories_iframe: {
        codename: 'stories_iframe',
        id: 'b5cebd88-8eb0-4d27-9c64-f6b128f0a749',
        externalId: undefined,
        name: 'Stories iFrame',
        elements: {
            /**
             * PlayerID (text)
             */
            playerid: {
                codename: 'playerid',
                id: '380d1522-8c27-4fad-a1dd-4a53a3ddaf6f',
                externalId: undefined,
                name: 'PlayerID',
                required: true,
                type: 'text'
            },

            /**
             * WidgetID (text)
             */
            widgetid: {
                codename: 'widgetid',
                id: '590272d8-9e9a-42af-9259-c76630f97fc7',
                externalId: undefined,
                name: 'WidgetID',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Tag
     */
    tag: {
        codename: 'tag',
        id: '6f1d5d6f-26d0-4433-89d1-80414200ae7d',
        externalId: undefined,
        name: 'Tag',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: '53849931-c21b-4bba-9a9b-f06f468ad953',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: 'e637ee72-0d18-4268-9acc-954e3f24b784',
                externalId: undefined,
                name: 'Label',
                required: true,
                type: 'text'
            },

            /**
             * Text Color (modular_content)
             */
            text_color: {
                codename: 'text_color',
                id: 'cf991425-849c-4ee2-b240-7e12763f1efa',
                externalId: undefined,
                name: 'Text Color',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Test ContentType
     */
    test_contenttype: {
        codename: 'test_contenttype',
        id: '247a188d-ff9f-4392-a87a-ba1a2bbeb84a',
        externalId: undefined,
        name: 'Test ContentType',
        elements: {
            /**
             * Common components (taxonomy)
             */
            common_components: {
                codename: 'common_components',
                id: '62ca1f57-4d3c-4d59-bad7-617c1ffa6762',
                externalId: undefined,
                name: 'Common components',
                required: false,
                type: 'taxonomy'
            },

            /**
             * HELLO TEXT (text)
             */
            hello_texts: {
                codename: 'hello_texts',
                id: '57415ffc-c7d9-495a-9d69-b672711a5aab',
                externalId: undefined,
                name: 'HELLO TEXT',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Text Item
     */
    text_item: {
        codename: 'text_item',
        id: '6b9efdcf-e73e-4842-8f7b-89158de2fb08',
        externalId: undefined,
        name: 'Text Item',
        elements: {
            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: '93a7980a-ae00-40e6-aa45-a1a1e1357c83',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Upgrade Experience Card
     */
    upgrade_experience_card: {
        codename: 'upgrade_experience_card',
        id: '447c694d-c5e8-4155-8f89-9ad38740bd63',
        externalId: undefined,
        name: 'Upgrade Experience Card',
        elements: {
            /**
             * Background Image (asset)
             */
            card_base__background_image: {
                codename: 'card_base__background_image',
                id: '28265d54-3936-4f74-90d7-243e5f7815b9',
                externalId: undefined,
                name: 'Background Image',
                required: true,
                type: 'asset',

                snippetCodename: 'card_base'
            },

            /**
             * CTA text (text)
             */
            cta_text: {
                codename: 'cta_text',
                id: 'a85fc651-43c8-4d4d-8785-64abd7fd9532',
                externalId: undefined,
                name: 'CTA text',
                required: false,
                type: 'text'
            },

            /**
             * Description (text)
             */
            card_base__description: {
                codename: 'card_base__description',
                id: '34c6c90e-1e42-4f35-93e9-6735e5653672',
                externalId: undefined,
                name: 'Description',
                required: true,
                type: 'text',

                snippetCodename: 'card_base'
            },

            /**
             * Link (modular_content)
             */
            card_base__link: {
                codename: 'card_base__link',
                id: 'c264fa00-8a40-4714-bb87-d5d00d87ff45',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'modular_content',

                snippetCodename: 'card_base'
            },

            /**
             * Mobile background image (asset)
             */
            mobile_background_image: {
                codename: 'mobile_background_image',
                id: '96f89e90-3e03-43cc-8025-a771cfc60730',
                externalId: undefined,
                name: 'Mobile background image',
                required: true,
                type: 'asset'
            },

            /**
             * Select access (multiple_choice)
             *
             * If Standard or Advantage is selected the access to the content will be limited to the logged in user membership level.
             */
            membership_based_access__select_access: {
                codename: 'membership_based_access__select_access',
                id: 'b1494d90-9c32-4e37-971d-675996e05481',
                externalId: undefined,
                name: 'Select access',
                required: false,
                type: 'multiple_choice',
                options: {
                    none: {
                        name: 'No restrictions',
                        id: 'ffa1df83-f8af-480a-956d-5544ed4644a4',
                        codename: 'none',
                        externalId: undefined
                    },
                    oneclub: {
                        name: 'One Club',
                        id: '0d8f00cc-9438-4d95-843a-f007e4c3c71a',
                        codename: 'oneclub',
                        externalId: undefined
                    },
                    oneclubadvantage: {
                        name: 'One Club Advantage',
                        id: '9c47187e-b0dc-45bd-97c4-d86cd7aa65c3',
                        codename: 'oneclubadvantage',
                        externalId: undefined
                    }
                },
                snippetCodename: 'membership_based_access'
            },

            /**
             * Tags (modular_content)
             */
            tags: {
                codename: 'tags',
                id: 'ce796eaa-93ed-4be6-9364-ae87c9fdacad',
                externalId: undefined,
                name: 'Tags',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            card_base__title: {
                codename: 'card_base__title',
                id: '30363806-5fbc-40aa-9c22-8bb3d7ba85e0',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text',

                snippetCodename: 'card_base'
            }
        }
    },

    /**
     * Web Banner
     */
    web_banner: {
        codename: 'web_banner',
        id: 'd181e868-ad25-4ae9-b1ad-c2cf3cd8bc54',
        externalId: undefined,
        name: 'Web Banner',
        elements: {
            /**
             * Desktop Portrait Image (modular_content)
             */
            portrait_image: {
                codename: 'portrait_image',
                id: '495b9940-3475-4dda-9f05-5670d60221f2',
                externalId: undefined,
                name: 'Desktop Portrait Image',
                required: false,
                type: 'modular_content'
            },

            /**
             * Desktop Square Image (modular_content)
             */
            window_image: {
                codename: 'window_image',
                id: 'ccde30fb-9ac0-41fa-9332-7f85b5dfdd4b',
                externalId: undefined,
                name: 'Desktop Square Image',
                required: false,
                type: 'modular_content'
            },

            /**
             * Link (modular_content)
             */
            link: {
                codename: 'link',
                id: 'fdda3d44-8555-4211-8e68-357c12fd356c',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Mobile Image (modular_content)
             */
            mobile_image: {
                codename: 'mobile_image',
                id: 'e0c7fa75-357f-4df8-9f69-1d633c9341e2',
                externalId: undefined,
                name: 'Mobile Image',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Web Banner Placeholder
     */
    web_banner_placeholder: {
        codename: 'web_banner_placeholder',
        id: '4d6ad00d-0975-442a-a516-e558adb243c4',
        externalId: undefined,
        name: 'Web Banner Placeholder',
        elements: {
            /**
             * Portrait image (multiple_choice)
             *
             * Default value is "No". That means the web banner will have a square aspect ratio. If set to "YES", the web banner will have the following resolution: 340x490 pixels.
             */
            forced_portrait: {
                codename: 'forced_portrait',
                id: '57b37e31-9914-4411-b613-f8e6fe82f4c4',
                externalId: undefined,
                name: 'Portrait image',
                required: false,
                type: 'multiple_choice',
                options: {
                    true: {
                        name: 'Yes',
                        id: '891867c1-4a8c-4e9e-b2e4-d0b0baa052c1',
                        codename: 'true',
                        externalId: undefined
                    },
                    false: {
                        name: 'No',
                        id: '15f6e54f-c51a-4b4c-a502-7ce40db885af',
                        codename: 'false',
                        externalId: undefined
                    }
                }
            },

            /**
             * Selected web banners (modular_content)
             */
            selected_web_banners: {
                codename: 'selected_web_banners',
                id: '9533a817-05b3-4f77-99df-2689f71d9ece',
                externalId: undefined,
                name: 'Selected web banners',
                required: true,
                type: 'modular_content'
            }
        }
    }
} as const;
