import { createContext, FC, ReactNode } from 'react';
import Link from 'next/link';
import Head from 'next/head';
import Image, { StaticImageData } from 'next/image';

export const EnvironmentContext = createContext<{
  Link?:
    | FC<{
        href: string;
        children: ReactNode;
        isExternal?: boolean;
        prefix?: string;
        className?: string;
      }>
    | typeof Link;
  Image:
    | FC<{
        src: string | StaticImageData;
        width?: number | `${number}` | undefined;
        height?: number | `${number}` | undefined;
        alt: string;
      }>
    | typeof Image;
  SEO:
    | FC<{
        title?: string;
        description?: string;
        keywords?: string[];
        noindex?: boolean;
        nofollow?: boolean;
      }>
    | typeof Head;
}>({
  Link: ()=> null,
  Image: ()=> null,
  SEO: ()=>null,
});
