import { OauthQuery } from "../utils";

export interface HubPayload {
    event: string,
    data?: any,
    message?: string
}

export enum UserJourneyTypes {
    Default = 'Default',
    Ticketing = 'Ticketing',
    WOTicketing = 'WOTicketing',
    TheOpenBallot = 'TheOpenBallot',
}

export type RedirectInfo = {
    [K in typeof OauthQuery[keyof typeof OauthQuery]]: string;
};