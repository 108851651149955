import { MembershipTeaserPanelProps } from './types';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  MembershipTypes as mTypes,
  useAuthContext,
} from '@r-and-a-shared-ui/auth-client';
import { MembershipTypeIconProps } from '../MembershipType/types';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { IContentItem } from '@kontent-ai/delivery-sdk';

export const useMembershipTeaserPanel = ({
  membershipTypes,
}: Omit<MembershipTeaserPanelProps, 'logo'>) => {
  const {
    userState: { membership, isAuthenticated },
  } = useAuthContext();
  const membershipType = isAuthenticated ? membership?.type : mTypes.None;
  const activeMessage = useRef<
    | MEMBERSHIP_MODELS.MembershipType['elements']['welcome_message']['linkedItems'][0]
    | any
  >();
  const activeMembershipType = useRef(membershipTypes?.[0]);

  const parsedMembershipPopups = useRef<
    {
      membershipId: string;
      content: IContentItem[];
    }[]
  >([]);
  const parsedMembershipTypes = useMemo(() => {
    parsedMembershipPopups.current = [];
    activeMessage.current = undefined;
    return membershipTypes?.map(mType => {
        const active =
          mType.membershipType.toLocaleUpperCase() ===
          membershipType.toLocaleUpperCase();
        if (active) {
          activeMessage.current = mType.welcomeMessage;
          activeMembershipType.current = mType;
        }
        // we don't add the NONE membership type to the list
        if (
          mType.membershipType.toLowerCase().toLocaleUpperCase() ===
          mTypes.None.toLowerCase().toLocaleUpperCase()
        ) {
          return null;
        }

        if (mType.popupContent) {
          parsedMembershipPopups.current.push({
            membershipId: mType.id,
            content: [...mType.popupContent],
          });
        }

        return {
          ...mType,
          active,
        };
      })
      .filter(Boolean) as MembershipTypeIconProps[] ?? [];
  }, [membershipType, membershipTypes, activeMembershipType, activeMessage]);

  const [activePopupIndex, setActivePopupIndex] = useState(0);

  const [popUpIsOpened, setPopupIsOpened] = useState(false);

  const openPopup = useCallback(
    (activeMembershipId?: string) => {
      setPopupIsOpened(true);
      if (activeMembershipId) {
        const index = parsedMembershipPopups.current.findIndex(
          popup => popup?.membershipId === activeMembershipId,
        );
        setActivePopupIndex(index);
      }
    },
    [parsedMembershipPopups],
  );

  const sliderBeforeChange = (_: any, nextIndex: number) => {
    setActivePopupIndex(nextIndex);
  };

  return {
    sliderBeforeChange,
    openPopup,
    closePopup: () => setPopupIsOpened(false),
    popUpIsOpened,
    activePopupIndex,
    activeMembershipType,
    activeMessage: activeMessage.current ? activeMessage.current : {},
    membershipPopups: parsedMembershipPopups.current,
    parsedMembershipTypes,
  };
};
