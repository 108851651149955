/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const assetFolders = {
    /**
     * Flags
     */
    flags: {
        id: 'b62ed2b5-b4ba-46a2-aace-fda477eb3b22',
        name: 'Flags',
        externalId: undefined,
        folders: {}
    },

    /**
     * Form Images
     */
    formImages: {
        id: 'a53be8e2-1dea-4386-b8aa-cc2f958bce9c',
        name: 'Form Images',
        externalId: undefined,
        folders: {}
    },

    /**
     * Mastercard
     */
    mastercard: {
        id: '62e8c0b9-1bdd-4d47-b82c-d82f9df65291',
        name: 'Mastercard',
        externalId: undefined,
        folders: {}
    },

    /**
     * No Access Folder
     */
    noAccessFolder: {
        id: 'bff2cab2-92f7-41a8-b33c-fcdd3f37c914',
        name: 'No Access Folder',
        externalId: undefined,
        folders: {}
    }
} as const;
