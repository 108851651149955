import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const rich_text_with_asset_container = (data: SSO_MODELS.RichTextWithAssetContainer) => {
  if (!data || !data?.elements) {
    return undefined;
  }
  const { elements } = data;

  return {
    richTextWithAssetElements: elements?.rich_text_with_asset?.linkedItems
  };
};

export default rich_text_with_asset_container;
