import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getAllItemsFromFeed } from './get-all-items-from-feed';
import { extractParents } from './extract-parents';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getFullDependencyTree = async () => {
  const cacheKey = `full_dependency_tree`;

  try {

    // we'll create a map for all the items and their dependencies
    const response = await getAllItemsFromFeed();
    if (!response || response.length < 1) return {};
    const allItemsMap: Record<string, IContentItem> = {};
    response.forEach(item => {
      allItemsMap[item.system.codename] = item;
    });

    const dependencyTree = extractParents(allItemsMap);

    return dependencyTree;
  } catch (e) {
    console.error('Error getting full dependency tree', e);
    throw e;
  }
};

