import { FC } from 'react';
import { useScreenDetector } from '../../hooks';
import { RenderOn } from '../RenderOn/RenderOn';
import SEOWrapper from '../SEOWrapper/SEOWrapper';
import { MyAccountProps } from './types';
import { PageTemplate } from '../templates';
import { MyAccountMobile } from './MyAccountMobile';
import { MyAccountDesktop } from './MyAccountDesktop';

const PageContent: FC<MyAccountProps> = ({
  seo,
  body,
  sidebar,
  subheader,
  mobile,
}) => {
  const { isMobile } = useScreenDetector();
  return (
    <>
      <SEOWrapper
        title={seo?.title}
        description={seo?.description}
        keywords={seo?.keywords}
        noindex={seo?.noindex}
        nofollow={seo?.nofollow}
        urlSlug={seo?.urlSlug}
      />
      <RenderOn condition={isMobile && !!mobile.length}>
        <MyAccountMobile mobile={mobile} />
      </RenderOn>
      <RenderOn condition={!isMobile}>
        <MyAccountDesktop subheader={subheader} sidebar={sidebar} body={body} />
      </RenderOn>
    </>
  );
};

export const MyAccount: FC<MyAccountProps> = ({
  seo,
  pageTemplate,
  body,
  sidebar,
  subheader,
  mobile,
}) => {
  if (pageTemplate) {
    return (
      <PageTemplate header={pageTemplate.header} footer={pageTemplate.footer}>
        <PageContent
          seo={seo}
          pageTemplate={pageTemplate}
          body={body}
          sidebar={sidebar}
          subheader={subheader}
          mobile={mobile}
        />
      </PageTemplate>
    );
  }
  return (
    <PageContent
      seo={seo}
      pageTemplate={pageTemplate}
      body={body}
      sidebar={sidebar}
      subheader={subheader}
      mobile={mobile}
    />
  );
};
