import { TextItem } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type {
  MEMBERSHIP_MODELS,
  SSO_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
export default {
  component: ()=>TextItem,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.TextItem | SSO_MODELS.TextItem) =>
    kontentAiDataMapping.text_item(entry),
};
