import { createElement, useId } from 'react';
import { api } from '@r-and-a-shared-ui/randa-trpc-client';
import AsyncComponent from './AsyncComponent';

type UseDynamicComponentProps = {
  codename: string;
  contentType: string;
  locale?: string;
};
export const useDynamicComponent = ({
  contentType,
  codename,
}: UseDynamicComponentProps) => {
  const { data: item, isLoading } = api.kontent.itemWithLinkedItems.useQuery({
    codename,
  });
  const uniqKey = useId();

  const itemId = item?.system.id;

  const key = `${uniqKey}-${contentType}-${itemId}`;

  return isLoading
    ? createElement('div', { key: uniqKey }, 'Loading...')
    : item
      ? createElement(
        AsyncComponent,
        {
          key,
          kontentItem: item,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          contentType,
        },
        '',
      )
      : '';
};
