import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { formatEventDate } from '@r-and-a-shared-ui/utils';
import { LinkProps } from '../Link/Link';

import styles from './EventCard.module.scss';
import { WithOrWithoutLink } from '../WithOrWithoutLink/WithOrWithoutLink';
import { EnvironmentContext } from '../../contexts';
export interface EventCardProps {
  title?: string;
  logoImage?: string;
  backgroundImage?: string;
  startDate: string | null;
  endDate?: string | null;
  liveUrl?: LinkProps;
  url?: LinkProps;
  liveText?: string;
  isEventLive?: boolean;
}

export function EventCard({
  backgroundImage,
  startDate,
  endDate,
  title,
  logoImage,
  liveUrl,
  liveText,
  isEventLive,
  url,
}: EventCardProps) {
  const { Image } = useContext(EnvironmentContext);
  const router = useRouter();
  const isOnAttendPage = router.asPath.includes('attend');

  const isEventActive =
    isOnAttendPage && url?.href ? `/${url.href}` === router.asPath : false;

  const eventUrl = isEventLive && !isOnAttendPage ? liveUrl : url;

  const inlineStyles = {
    backgroundImage: backgroundImage && `url(${backgroundImage})`,
  };

  return (
    <div>
      <WithOrWithoutLink url={eventUrl}>
        <div className={styles.wrapper} style={inlineStyles}>
          <div
            className={classNames(`${styles.content}`, {
              [styles['is-live']]: isEventLive,
              [styles['selected']]: isEventActive,
            })}
          >
            {logoImage && (
              <div className={styles.logo}>
                <Image 
                  src={logoImage}
                  alt="Event logo"
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{ 
                    width: '100%',
                    height: 'auto'
                  }} />
              </div>
            )}

            {isEventLive && (
              <div className={styles.live}>
                <span className={styles['live__dot']}>&#x25CF;</span>
                {liveText && (
                  <span className={styles['live__tag']}>{liveText}</span>
                )}
              </div>
            )}

            {!isEventLive && (
              <div className={styles.info}>
                {title && <div className={styles.info__title}>{title}</div>}
                {startDate && endDate ? (
                  <div className={styles.info__date}>
                    {formatEventDate(startDate, endDate)}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </WithOrWithoutLink>
    </div>
  );
}

export default EventCard;
