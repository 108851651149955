import { ResponseModel, getParametersByNames } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { ForgotPasswordInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { OauthQuery } from "@r-and-a-shared-ui/auth-client";

const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {
  const queryParams = getParametersByNames([ 
    OauthQuery.AUTHORIZATION_CODE,
    OauthQuery.REDIRECT_URI,
    OauthQuery.CLIENT_ID,
    OauthQuery.STATE
  ]);

  params.submitData = {
    ...params.submitData,
    authorizationCode: queryParams[OauthQuery.AUTHORIZATION_CODE],
    redirectUri: queryParams[OauthQuery.REDIRECT_URI],
    clientId: queryParams[OauthQuery.CLIENT_ID],
    state: queryParams[OauthQuery.STATE],
  };

  return await genericMutationHandlerWithFormValidation(
    params,
    ForgotPasswordInputSchema,
  );
};

export default handle;