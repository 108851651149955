export const THE_OPEN_PLATFORM = 'TheOpen';
export const AIGWO_PLATFORM = 'AIGWO';
export const RANDA_PLATFORM = 'Randa';

// TO_DO review values here
export const THE_OPEN_CLIENT_ID = '2f847gukbfgj2c44vk5ilm2lqv';
export const THE_OPEN_DEV_CLIENT_ID = '2d0a2trd059eo9sj2pfpli75sc';
export const THE_OPEN_APP_CLIENT_ID = '2o2viivmokg4m8hmei17nduib3';

export const AIGWO_CLIENT_ID = '1i4fmv9m7tu481fes6i779gses';
export const AIGWO_STG_CLIENT_ID = '7km6l4no8dm0d42crcd24455b8';

export const RANDA_CLIENT_ID = '250cj9hgcbkb1g7ki866l0k5k7';

export const AIGWO_APP_CLIENT_ID = '41s3jh8isusl39jeoifihuh9o9';
export const RANDA_TV_CLIENT_ID = '10m5jep544s8ifak8i6qnia6ur';
export const ONE_CLUB_CLIENT_ID='3u64mbqae9pie5hga7l02csvak';

export const ClientIds: any = {
  [THE_OPEN_CLIENT_ID]: THE_OPEN_PLATFORM,
  [THE_OPEN_DEV_CLIENT_ID]: THE_OPEN_PLATFORM,
  [AIGWO_CLIENT_ID]: AIGWO_PLATFORM,
  [AIGWO_STG_CLIENT_ID]: AIGWO_PLATFORM,
  [RANDA_CLIENT_ID]: RANDA_PLATFORM,
  [AIGWO_APP_CLIENT_ID]: "AIGWOApp",
  [RANDA_TV_CLIENT_ID]: "RandaTV",
  [ONE_CLUB_CLIENT_ID]: "OneClub",
  [THE_OPEN_APP_CLIENT_ID]: "TheOpenApp"
};
