import {
  getFirstLinkedItemOrDefault,
  getSingleChoiceValue,
  getTrueFalseSingleChoiceValue,
  prefixDictionary,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import mapImage from './map-image';
import { ArticleSchemaOrgModel , SchemaOrgContext, SchemaOrgTypes } from '@r-and-a-shared-ui/utils';

export const article = (entry: MEMBERSHIP_MODELS.Article) => {

  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements, system } = entry;

  const author = getFirstMappedLinkedItemOrDefault(elements?.author);

  const seo = {
    title: elements?.seo__meta_title?.value,
    description: elements?.seo__meta_description?.value,
    keywords: elements?.seo__key_words?.value,
    noindex: getTrueFalseSingleChoiceValue(elements?.seo__no_index),
    nofollow: getTrueFalseSingleChoiceValue(elements?.seo__no_follow),
    urlSlug: elements?.url_slug?.value,
  };

  const articleProps = {
    author: `${author?.name} ${author?.surname}`,
    publishDate: elements?.publish_date?.value,
    title: elements?.title?.value,
    mobileTitle: elements?.mobile_title?.value,
    subtitle: elements?.subtitle?.value,
    primaryImage: mapImage(
      getFirstLinkedItemOrDefault(
        elements.primary_image,
      ) as MEMBERSHIP_MODELS.Image,
    ),
    primaryImageMobile: mapImage(
      getFirstLinkedItemOrDefault(
        elements.primary_image_mobile,
      ) as MEMBERSHIP_MODELS.Image,
    ),
    articleTextBody: elements?.article_text_body?.linkedItems ?? [],
    subheaderElements: elements?.subheader_elements?.linkedItems ?? [],
    sidebar: elements?.sidebar?.linkedItems ?? [],
    articlePreview: elements?.article_preview,
    elementsBelowArticleBody:
      elements?.elements_below_article_body?.linkedItems ?? [],
    access: getSingleChoiceValue(
      elements?.membership_based_access__select_access,
    ),
  };

  const schemaOrgContent: ArticleSchemaOrgModel = {
    "@type": SchemaOrgTypes.NEWS_ARTICLE,
    headline: articleProps.title,
    text: articleProps.subtitle || articleProps.title,
    "@context": SchemaOrgContext,
    image: articleProps?.primaryImage?.imageUrl,
    url: prefixDictionary[system.type] ?
      `${window.location.origin}/${prefixDictionary[system.type]}/${seo.urlSlug}` : seo.urlSlug,
    author: [{
      "@type": SchemaOrgTypes.PERSON,
      "@context": SchemaOrgContext,
      name: articleProps.author
    }]
  };

  return {
    ...articleProps,
    seo,
    schemaOrgContent,
  };
};

export default article;
