import { useState, useEffect } from 'react';
import { WebBannerProps } from '../WebBanner/WebBanner';

const useBannerData = (selected_web_banner: any): WebBannerProps | null => {
  const [randomBanner, setRandomBanner] = useState<WebBannerProps | null>(null);

  useEffect(() => {
    if (selected_web_banner && selected_web_banner.length > 0) {
      const randomIndex = Math.floor(Math.random() * selected_web_banner.length);
      setRandomBanner(selected_web_banner[randomIndex]);
    } else {
      setRandomBanner(null);
    }
  }, [selected_web_banner]);

  return randomBanner;
};

export default useBannerData;