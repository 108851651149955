export const pageLikeContentTypesArray: string[] = [
  'article',
  'page',
  'home_page',
  'coach',
  'event_page',
  'reward',
];

export const pageLikeContentTypesSet = new Set(pageLikeContentTypesArray);

export const pageLikeContentTypesPathPrefixMap = {
  article: '/articles/',
  page: '/',
  home_page: '/',
  coach: '/coaches/',
  event_page: '/attend/',
  reward: '/rewards-and-benefits/',
};
