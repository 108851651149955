import { ZodError, ZodSchema, z } from 'zod';

import { isNullOrUndefined } from './general-utils';

export const safeParseZod = <Type extends ZodSchema>(schema: Type, obj: object | undefined) => {
  if (isNullOrUndefined(obj)) {
    return undefined;
  }

  try {
    const mappedObject = schema.parse(obj) as z.infer<typeof schema>;
    return mappedObject;
  } catch (error) {
    if (error instanceof ZodError) {
      console.error(error.errors);
    }
    else {
      console.error(error);
    }
    return undefined;
  }
};