import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const logo = (entry: MEMBERSHIP_MODELS.Logo) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  const logoImage = getFirstLinkedAssetOrDefault(elements?.logo_image);

  return {
    logoImage: logoImage?.url,
    logoDescription: logoImage?.description,
    resource: getFirstMappedLinkedItemOrDefault(elements?.resources),
  };
};

export default logo;
