import React from 'react';
import { useSimplePopUp } from './useSimplePopUp';
import { hasValue } from '@r-and-a-shared-ui/utils';

import styles from './SimplePopUp.module.scss';

export interface SimplePopUpProps {
  isVisible: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  backgroundColor?: string;
  overlayBackgroundColor?: string;
}

export function SimplePopUp({
  isVisible,
  onClose,
  children,
  backgroundColor,
  overlayBackgroundColor,
}: SimplePopUpProps) {
  const { popupRef } = useSimplePopUp({ onClose });

  if (!isVisible) return null;

  const inlineStyle: React.CSSProperties = hasValue(backgroundColor)
    ? { backgroundColor }
    : {};
  const overlayStyle: React.CSSProperties = hasValue(overlayBackgroundColor)
    ? { backgroundColor: overlayBackgroundColor }
    : {};

  return (
    <div
      className={`${styles['overlay']} ${!isVisible ? styles['hidden'] : ''}`}
      style={overlayStyle}
    >
      <div ref={popupRef} className={styles['popup']} style={inlineStyle}>
        <button
          className={styles['close-button']}
          onClick={onClose}
          aria-label="Close"
        >
          ✖
        </button>
        {children}
      </div>
    </div>
  );
}

export default SimplePopUp;
