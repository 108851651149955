import { CognitoUserAttribute } from '@r-and-a-shared-ui/auth-client';
import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const cognito_user_attribute = (data: SSO_MODELS.CognitoUserAttribute) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    attributeName: elements?.attribute_name?.value,
  } as CognitoUserAttribute;
};

export default cognito_user_attribute;
