import { SectionTitle } from '@r-and-a-shared-ui/common-ui';

import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
export default {
    component: () => SectionTitle,
    mapEntryToProps: (entry: MEMBERSHIP_MODELS.SectionTitle) =>
        kontentAiDataMapping.section_title(entry),
};
