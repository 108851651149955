/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, ReactNode, useState, useEffect } from 'react';
import { EnvironmentContext } from '../../contexts';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';
import { api } from '@r-and-a-shared-ui/randa-trpc-client';
import { hasValue } from '@r-and-a-shared-ui/utils';
import styles from './Link.module.scss';

export type LinkProps = {
  title: string;
  href: string;
  internal: boolean;
  icon?: string;
  children?: ReactNode;
  className?: string;
  codename?: string;
};

const LinkContent = ({
  icon,
  title,
  children,
}: {
  icon?: string;
  title: string;
  children?: ReactNode;
}) => {
  const { Image } = useContext(EnvironmentContext);
  return (
    <>
      <span className={styles.icon}>
        {icon && <Image src={icon} alt={`${title} icon`} fill />}
      </span>
      {children || title}
    </>
  );
};


const Anchor = ({
  href,
  title,
  internal,
  children,
  className,
}: {
  href: string;
  title: string;
  internal: boolean;
  children: ReactNode;
  className?: string;
}) => (
  <a
    className={className}
    href={href}
    title={title}
    target={internal ? undefined : '_blank'}
    rel={internal ? undefined : 'noopener noreferrer'}
  >
    {children}
  </a>
);

export const Link = (props: LinkProps) => {
  const {
    title: propTitle,
    href: propHref,
    internal: propInternal,
    children,
    icon: propIcon,
    className,
    codename,
  } = props;

  const hasPropsInfo = propHref && propTitle;
  const { Link: ProvidedLinkComponent } = useContext(EnvironmentContext);
  const [loadedLink, setLoadedLink] = useState<any>();
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);

  const [link, setLink] = useState<LinkProps>(props);

  const {
    title,
    href,
    internal,
    icon,
  } = link as LinkProps;

  const { data } = api.kontent.itemWithLinkedItems.useQuery({
    codename: isDataFetched ? '' : codename ?? ''
  });

  if (hasValue(data)) {
    const mappedLink = kontentAiDataMapping.link(data as MEMBERSHIP_MODELS.Link);
    setLoadedLink(mappedLink);
    setIsDataFetched(true);
  }

  useEffect(() => {
    if(href || hasPropsInfo || !codename) {
      return;
    }
    setIsDataFetched(false);
  }, [codename, href, hasPropsInfo]);

  useEffect(() => {
    if(hasPropsInfo) {
      setLink({...props});
      return;
    }
    if(loadedLink) {
      setLink({...loadedLink});
      return;
    }
  }, [loadedLink, codename, hasPropsInfo, props]);

  if (ProvidedLinkComponent) {
    return (
      <ProvidedLinkComponent
        href={href}
        isExternal={!internal}
        className={className}
      >
        <LinkContent icon={icon} title={title}>
          {children}
        </LinkContent>
      </ProvidedLinkComponent>
    );
  }

  return (
    <Anchor href={href} title={title} internal={internal}>
      <LinkContent icon={icon} title={title}>
        {children}
      </LinkContent>
    </Anchor>
  );
};
