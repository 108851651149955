import { useEffect, useCallback, useRef } from 'react';

type UseSimplePopUpProps = {
    onClose?: () => void;
};

export const useSimplePopUp = ({ onClose }: UseSimplePopUpProps) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      onClose?.();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return { popupRef };
};
