import { PageTemplate } from '../templates';
import SEOWrapper from '../SEOWrapper/SEOWrapper';
import { BodyContent } from './BodyContent';
import { SubHeader } from './SubHeader';
import { PreFooter } from './PreFooter';
import type { PageProps } from './types';

import styles from './Page.module.scss';

const PageContent = ({
  seo,
  children,
}: Pick<PageProps, 'seo' | 'children'>) => {
  return (
    <main data-testid={'page'} className={styles.wrapper}>
      <SEOWrapper
        title={seo?.title}
        description={seo?.description}
        keywords={seo?.keywords}
        noindex={seo?.noindex}
        nofollow={seo?.nofollow}
        urlSlug={seo?.urlSlug}
      />
      {children}
    </main>
  );
};

export const Page = ({
  seo,
  pageTemplate,
  body,
  sidebar,
  subheader,
  preFooter,
}: PageProps) => {
  if (pageTemplate) {
    return (
      <PageTemplate header={pageTemplate.header} footer={pageTemplate.footer}>
        <SubHeader subheader={subheader} />
        <PageContent seo={seo}>
          <BodyContent body={body} sidebar={sidebar} />
        </PageContent>
        <PreFooter preFooter={preFooter} />
      </PageTemplate>
    );
  }
  return (
    <>
      <SubHeader subheader={subheader} />
      <PageContent seo={seo}>
        <BodyContent body={body} sidebar={sidebar} />
      </PageContent>
      <PreFooter preFooter={preFooter} />
    </>
  );
};
