import { stringToBoolean } from '@r-and-a-shared-ui/utils';
import { FormStepProps } from '../FormStep/FormStep';
import { FormFieldProps } from '../fields';

export const setSpecialFieldsValues = (defaultValues: any, separator: string, steps: FormStepProps[] | undefined) => {
  if (!defaultValues || !steps) {
    return;
  }

  steps.forEach((step) => step.fieldsData?.forEach((fieldData) => {
    if (fieldData.fieldType === 'date') {
      setDateValues(defaultValues, separator, fieldData);
    }
    if (fieldData.fieldType === 'checkboxes') {
      setCheckboxesValue(defaultValues, separator, fieldData);
    }
  }));
};

const setDateValues = (defaultValues: any, separator: string, fieldData: FormFieldProps) => {
  if(defaultValues[fieldData.name]) {
    const dateValue = new Date(defaultValues[fieldData.name]);
    fieldData.subfields?.forEach((subfield) => {
      const subFieldName = subfield?.name;
      if (subFieldName) {
        const indicator = subFieldName.split(separator).slice(-1);
        if (indicator.includes('date')) {
          defaultValues[subFieldName] = dateValue.getDate().toString();
        }
        else if (indicator.includes('month')) {
          defaultValues[subFieldName] = (dateValue.getMonth() + 1).toString();
        }
        else if (indicator.includes('year')) {
          defaultValues[subFieldName] = dateValue.getFullYear().toString();
        }
      }
    });
  }
  else {
    fieldData.subfields?.forEach((subfield) => {
      const subFieldName = subfield?.name;
      if (subFieldName)
        defaultValues[subFieldName] = '';
    });
  }
};

const setCheckboxesValue = (defaultValues: any, separator: string, fieldData: FormFieldProps) => {
  //Used to keep the previous value when field is true or false
  const previousFieldValue = defaultValues[fieldData.name] ;

  const isTrue = stringToBoolean(previousFieldValue);
  
  if (isTrue)
    defaultValues[fieldData.name] = [];

  let index = 0;
  const defValuesKeys = Object.keys(defaultValues);

  fieldData.options?.forEach((option) => {
    const currentKey = getCurrentKey(separator, fieldData, index);
    if (defValuesKeys.includes(currentKey) && (isTrue || defaultValues[currentKey] === option.value)) {
      defaultValues[fieldData.name].push(option.value);
      index++;
    }
  });
};

const getCurrentKey = (separator: string, fieldData: FormFieldProps, index: number): string => {
  if (fieldData?.options?.length === 1) {
    return `${fieldData.name}`;
  }
  return `${fieldData.name}${separator}${index}`;
};
