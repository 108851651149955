export const enum NextStepsAfterSignIn {
    CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
    MISSING_REQUIRED_MEMBER_FIELDS = 'MISSING_REQUIRED_MEMBER_FIELDS',
}

export const STEP = 'step';
export const TEMP_PASSWORD = 'xxBProfCUB';
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const LOCAL_STORAGE_KEYS = { REDIRECT_INFO: 'redirectInfo', };
export const DEFAULT_REDIRECT_URL = '/';
