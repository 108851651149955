export type ApiContactData = {
    Key: string,
    Value: string
}

export type AddUserToAddressBookRequest = {
    UserDetails: ApiContactData[],
    Email: string,
    AddressBookID: number
};

export const enum ContactDataKeys { 
    FIRSTNAME = 'FirstName',
    LASTNAME = 'LastName',
    COUNTRY = 'Country',
    ONECLUBPLUS = 'OneClubPlus',
    USER_JOURNEY = 'User_Journey',
    FAVORITEPLAYER = 'FavouritePlayer',
    SSOUSERID = 'SSOUserId',
}