import { CSSProperties, useEffect, useMemo, useState } from 'react';
import styles from './ContentList.module.scss';
import classNames from 'classnames';
import { ContentListItem } from './ContentListItem';
import {
  ContentListDisplay,
  ContentListType,
  ListDisplay,
} from './ContentList.types';
import Slider, { SliderConfig } from './Slider/Slider';
import { RenderOn } from '../RenderOn/RenderOn';
import {
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';
import { api } from '@r-and-a-shared-ui/randa-trpc-client';
import { hasValue } from '@r-and-a-shared-ui/utils';

export interface ContentListProps {
  elements?: any[];
  display?: string | ContentListDisplay;
  type: string | ContentListType;
  backgroundColor?: string;
  totalTilesToShow?: number | null;
  listDisplay?: string | ListDisplay;
  carouselConfig?: SliderConfig;
  title?: string;
  codename?: string;
}

export function ContentList(props: ContentListProps) {
  const {
    title,
    elements: propsElements,
    display,
    type,
    backgroundColor,
    totalTilesToShow,
    listDisplay,
    carouselConfig,
    codename,
  } = props;

  const [loadedElements, setLoadedElements] = useState<any>();
  const [elements, setElements] = useState<any[]>([]);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);  

  const { data } = api.kontent.itemWithLinkedItems.useQuery({
    codename: isDataFetched ? '' : codename ?? '',
  });

  if (hasValue(data)) {
    const mappedList = kontentAiDataMapping.content_list___fixed_length(
      data as MEMBERSHIP_MODELS.ContentListFixedLength,
    );
    setLoadedElements(mappedList?.elements);
    setIsDataFetched(true);
  }

  useEffect(() => {
    if (elements?.length || propsElements?.length || !codename) {
      return;
    }
    setIsDataFetched(false);
  }, [elements, propsElements, codename]);

  useEffect(() => {
    if (propsElements?.length) {
      setElements(propsElements);
      return;
    }

    if (loadedElements?.length) {
      setElements(loadedElements);
      return;
    }
  }, [loadedElements, propsElements]);

  const listElements =
    totalTilesToShow && elements && totalTilesToShow < elements?.length
      ? elements.slice(0, totalTilesToShow)
      : elements;

  const getContentTypeStyle = useMemo((): CSSProperties => {
    if (type === ContentListType.GRID) {
      return {
        display: 'grid',
        gridTemplateColumns: Array.from(
          { length: listElements?.length || 0 },
          () => '1fr',
        ).join(' '),
      };
    } else if (type === ContentListType.PLAIN_LIST) {
      if (display === ContentListDisplay.HORIZONTAL) {
        return {
          display: 'flex',
          flexDirection: 'row',
        };
      } else if (display === ContentListDisplay.VERTICAL) {
        return {
          display: 'flex',
          flexDirection: 'column',
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  }, [type, display, listElements]);

  const inlineStyle: CSSProperties = {
    backgroundColor,
    ...getContentTypeStyle,
  };

  const listComponent = (
    <ListComponent
      listElements={listElements}
      inlineStyle={inlineStyle}
      listDisplay={listDisplay}
    />
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <RenderOn condition={!!title}>
        <div className={classNames(styles.title, listDisplay)}>
          <h2>{title}</h2>
        </div>
      </RenderOn>
      {elements && elements?.length > 1 ? (
        <>
          {type === ContentListType.SLIDER && (
            <Slider
              listDisplay={listDisplay}
              elements={listElements}
              backgroundColor={backgroundColor}
              {...carouselConfig}
            />
          )}
          {(type === ContentListType.PLAIN_LIST ||
            type === ContentListType.GRID) &&
            listComponent}
        </>
      ) : null}
    </>
  );
}

export default ContentList;

const ListComponent = ({
  listElements,
  inlineStyle,
  listDisplay,
}: {
  listElements?: any;
  inlineStyle?: CSSProperties;
  listDisplay?: string;
}) => (
  <div className={styles.wrapper} style={inlineStyle}>
    {listElements?.map((element: any, i: number) => (
      <ContentListItem element={element} key={i} listDisplay={listDisplay} />
    ))}
  </div>
);
