import { useContext } from 'react';
import { Link, LinkProps } from '../../Link/Link';
import Tag, { TagProps } from '../../Tag/Tag';
import { EnvironmentContext } from '../../../contexts';
import MembershipTypesLogos from '../../MembershipTypeLogos/MembershipTypesLogos';
import { RestrictionLevel } from '../../MembershipTypeLogos/constants';
import { RenderOn } from '../../RenderOn/RenderOn';
import classNames from 'classnames';
import styles from './HeroTile.module.scss';
import { useScreenDetector } from 'libs/common-ui/src/hooks';
import { AssetProps } from 'libs/common-ui/src/helpers/types';

export interface HeroTileProps {
  title?: string;
  subtitle?: string;
  type?: string;
  redirectTo?: LinkProps;
  desktopBackgroundImage?: AssetProps;
  mobileBackgroundImage?: AssetProps;
  tags: TagProps[];
  access?: keyof typeof RestrictionLevel;
  ctaLabel?: string;
}

export function HeroTile({
  title,
  subtitle,
  redirectTo,
  desktopBackgroundImage,
  mobileBackgroundImage,
  access = 'none',
  tags,
  ctaLabel,
}: HeroTileProps) {
  const { Image } = useContext(EnvironmentContext);
  const { isMobile } = useScreenDetector();

  return (
    <div
      data-testid="hero-tile"
      className={classNames(
        styles.wrapper,
        access !== RestrictionLevel.none ? styles['is-membership'] : '',
      )}
    >
      <div className={styles.content}>
        {desktopBackgroundImage && !isMobile && (
          <div className={styles.background}>
            <Image
              src={desktopBackgroundImage.url}
              alt={desktopBackgroundImage?.description || 'Hero background'}
              width={1920}
              height={1300}
              sizes="100vw"
            />
          </div>
        )}
        {mobileBackgroundImage && isMobile && (
          <div className={styles.background}>
            <Image
              src={mobileBackgroundImage.url}
              alt={
                mobileBackgroundImage?.description || 'Mobile Hero background'
              }
              width={mobileBackgroundImage.width || 391}
              height={mobileBackgroundImage.height || 510}
              sizes="100vw"
            />
          </div>
        )}
        <div className={styles.content__header}>
          <MembershipTypesLogos restrictionLevel={access} />
          <RenderOn condition={!!tags?.length}>
            <div className={styles.tags}>
              {tags?.map(tag => (
                <Tag key={tag.label?.value} {...tag} />
              ))}
            </div>
          </RenderOn>
        </div>
        <div className={styles.content__footer}>
          {redirectTo ? (
            <>
              <Link
                title={title ? title : ''}
                internal={redirectTo?.internal}
                href={redirectTo?.href || ''}
              >
                {title && <h3>{title}</h3>}
                {subtitle && (
                  <p className={styles.content__footer__description}>
                    {subtitle}
                  </p>
                )}
              </Link>
              <Link
                title={ctaLabel ? ctaLabel : redirectTo?.title || ''}
                href={redirectTo?.href || ''}
                internal={redirectTo?.internal}
                className={classNames(
                  styles.btn,
                  styles['btn--secondary'],
                  access !== RestrictionLevel.none
                    ? styles['btn--advantage']
                    : '',
                )}
              ></Link>
            </>
          ) : (
            <>
              {title && <h3>{title}</h3>}
              {subtitle && (
                <p className={styles.content__footer__description}>
                  {subtitle}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeroTile;
