import { useState, useEffect } from 'react';

const TABLET_MIN_WIDTH = 768;
const DESKTOP_MIN_WIDTH = 1024;

export function useScreenDetector() {
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    if (typeof window !== 'undefined') {
      setIsClient(true);
      setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const isMobile = width !== undefined && width < TABLET_MIN_WIDTH;
  const isTablet = width !== undefined && width >= TABLET_MIN_WIDTH && width < DESKTOP_MIN_WIDTH;
  const isDesktop = width !== undefined && width >= DESKTOP_MIN_WIDTH;

  return { isMobile, isTablet, isDesktop, width, isClient };
}