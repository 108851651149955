import { isFunction } from '@r-and-a-shared-ui/utils';
import { CTA, CTAProps } from '@r-and-a-shared-ui/common-ui';
import { useMutation } from '@tanstack/react-query';
import { useActionContext } from '../context/useActionsContext';
import { FormCtaActionTypes } from '../config';
import { ActionHandlerParams } from '../types';
import { useFormContext } from 'react-hook-form';
import { useContext, useState } from 'react';
import { FormContentContext, FormContentContextType } from '../context/FormContentContext';
import { useAuthContext, useSiteConfigContext } from '@r-and-a-shared-ui/auth-client';

export interface FormCTAProps {
  key?: string;
  ctaProps?: CTAProps;
  actionType?: FormCtaActionTypes;
  isMembership?: boolean;
  onSubmissionSuccess?: () => void;
  toEmail?: string,
  fromEmail?: string,
  subject?: string,
  emailKey?: string;
}

export const FormCTA = (props: FormCTAProps) => {
  const {
    actionType,
    ctaProps,
    isMembership,
    onSubmissionSuccess,
    toEmail,
    fromEmail,
    subject,
    emailKey,
  } = props;

  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState<boolean | null>(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);

  const { getValues, trigger, setError, clearErrors, setValue } = useFormContext();
  const {
    formContentProps,
    isLoading,
    errorsDictionary,
    successMessagesDictionary,
    nextStepsAfterSubmit,
    setSubmitSuccessMessage,
    initialFormState,
  } = useContext(FormContentContext) as FormContentContextType;

  const { memberRequiredAttributes } = useSiteConfigContext() || {};
  const context = useActionContext();
  const { API, FormCTAConfig } = context || {};

  const config = FormCTAConfig?.[actionType as FormCtaActionTypes];

  const ctaMutation: any = config?.trpcRoute
    ? API?.[config?.trpcRoute as keyof typeof API]
    : config?.clientSideMutation;

  const mutation = isFunction(ctaMutation?.useMutation)
    ? ctaMutation?.useMutation()
    : isFunction(ctaMutation)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      ? useMutation(ctaMutation)
      : undefined;

  const storeTokensMutation = API?.['storeTokens']?.useMutation();
  const addUserToAddressBookMutation = API?.['add_user_to_address_book']?.useMutation();

  const actionHandler = config?.actionHandler;

  let userEmail: string | undefined;

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userState } = useAuthContext();
    userEmail = userState.user?.email;
  } catch (error) {
    // We are in SSO
  }

  const onClick = async () => {
    setHasAttemptedSubmit(true);

    const submitData = getValues ? getValues() : {};

    const hasNonEmptyField = Object.values(submitData).some(
      (value) => typeof value === 'string' && value.trim() !== ''
    );

    if (!hasNonEmptyField) {
      setError('submitData', {
        type: 'manual',
        message: 'At least one answer is required',
      });
      setIsSubmittedSuccessfully(false);
      return;
    }

    const params: ActionHandlerParams = {
      resource: ctaProps?.resource,
      submitData,
      mutation,
      storeTokensMutation,
      addUserToAddressBookMutation,
      memberRequiredAttributes,
      trigger,
      formContentProps,
      setSubmitSuccessMessage,
      setError,
      setValue,
      clearErrors,
      errorsDictionary,
      successMessagesDictionary,
      nextStepsAfterSubmit,
      initialFormState,
      userEmail,
      toEmail,
      fromEmail,
      subject,
      emailKey,
    };

    if (isFunction(actionHandler)) {
      if (setSubmitSuccessMessage) setSubmitSuccessMessage('');
      if (clearErrors) clearErrors('root.serverError');
      const result = await actionHandler(params);

      if (result?.hasErrors) {
        console.error(result.error);
        setIsSubmittedSuccessfully(false);
      } else {
        setIsSubmittedSuccessfully(true);

        if (onSubmissionSuccess) {
          onSubmissionSuccess();
        }
      }
    }
  };

  return (
    <CTA
      {...ctaProps}
      onClick={onClick}
      isDisabled={isLoading}
      isMembership={isMembership}
      isSubmittedSuccessfully={isSubmittedSuccessfully}
      hasAttemptedSubmit={hasAttemptedSubmit}
    />
  );
};

export default FormCTA;
