import type { NextRequest } from 'next/server';
import { hasValue } from './general-utils';
import { ParsedUrlQuery } from 'querystring';

type SlugParams = ParsedUrlQuery & {
  slug: string[];
};

const defaultRoot = 'query.';

export const getUrlSlugAndQueryFromContext = (context: any) => {
  const { slug } = (context?.params ?? { params: {} }) as SlugParams;
  const { parsedSlug, query } = nextjsParseSlugAndQuery(slug ?? []);
  const url = hasValue(parsedSlug) ? parsedSlug?.join('/') ?? '/' : '/';
  return {
    url,
    slug: parsedSlug,
    query,
  };
};

export const isRootUrl = (url: string): boolean => {
  return url === '/' || url == '';
};

export const addQueryToNextJsUrl = (request: NextRequest, excludeKeys?: string[]) => {
  const val = request.nextUrl.search;
  const queryUrlSearchParams = new URLSearchParams(val);
  // parse the query params from the url and return them as an object
  // if some query params are repeated, the value will be an array of strings
  const query: { [key: string]: any } = {};
  queryUrlSearchParams.forEach((value, key) => {
    if (!excludeKeys || excludeKeys?.indexOf(key) === -1) {
      if (query[key]) {
        const oldQuery = query[key];
        const isOldQueryArray = Array.isArray(oldQuery);
  
        query[key] = isOldQueryArray ? [...oldQuery, value] : [oldQuery, value];
      } else {
        query[key] = value;
      }
    }  
  });
  // we clean all the empty values from the query
  Object.keys(query).forEach(key => {
    if (Array.isArray(query[key])) {
      query[key] = query[key].filter(Boolean);
    }
  });

  const initialPath = request.nextUrl.pathname;
  const url = request.nextUrl.clone();
  // build a new path that contains at the end the query params after a query string
  // the queryParam will be added at the end of the path with the following format: query/queryParam1/value1/queryParam2/value2 etc...
  if (hasValue(query)) {
    const stringifyValue = JSON.stringify(query);

    const encodedValue = encodeURIComponent(stringifyValue);
    const path =
      initialPath.replace(/\/$/, '') + `/${defaultRoot}${encodedValue}`;
    url.pathname = path;
  }

  return url;
};

export const getQueryFromSlug = (
  params: string[],
  root = defaultRoot,
): { [key: string]: string } => {
  if (!params) {
    return {};
  }

  // we look for query in the params array
  const queryIndex = params.findIndex(param => param.includes(root));
  // if we found it, we get the value of the query
  if (queryIndex !== -1) {
    const query = params[queryIndex];
    // we stringify the query to get the values
    let queryObject: Record<string, string> = {};
    try {
      const decodedValue = decodeURIComponent(query.replace(`${root}`, ''));

      queryObject = JSON.parse(decodedValue);
      if (!queryObject) {
        return {};
      }
    } catch (err) {
      console.log('error parsing query', err);
      return {};
    }
    return queryObject;
  }
  return {};
};
export const removeQueryFromSlug = (
  slug: string[],
  root = defaultRoot,
): string[] => {
  if (!slug) {
    return [];
  }

  // we look for query in the slug array
  const queryIndex = slug.findIndex(param => param.includes(root));
  // if we found it, we remove it from the slug
  if (queryIndex !== -1) {
    slug.splice(queryIndex, 1);
  }
  return slug;
};
export const nextjsParseSlugAndQuery = (
  slug: string[],
  root = defaultRoot,
): {
  parsedSlug: string[] | undefined;
  query: { [key: string]: any };
} => {
  const query = getQueryFromSlug(slug, root);
  const parsedSlug = removeQueryFromSlug(slug, root);

  return {
    parsedSlug,
    query,
  };
};
