import { deliveryClient } from '../config/kontent-delivery-client';
import { getItemWithLinkedItemsByCodename } from './get-item-with-linked-items-by-codename';
import { MEMBERSHIP_MODELS } from '../models';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { adjustSystemProperties } from '../utils';

type GetFullArticleBySlugResponse = {
  article: MEMBERSHIP_MODELS.Article | undefined;
  template: MEMBERSHIP_MODELS.PageTemplate | undefined;
};

export const getFullArticleBySlug = async (
  slug: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
) => {
  const cacheKey = `getFullArticleBySlug-articles/${slug}`;

  try {
    const cachedItem = getCachedItem<GetFullArticleBySlugResponse>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const item = await deliveryClient
      .items<MEMBERSHIP_MODELS.Article>()
      .type('article')
      .equalsFilter('elements.url_slug', slug)
      .depthParameter(3)
      .toPromise()
      .then(r => r.data.items[0]);

    if (!item || !item?.system.codename) {
      return undefined;
    }

    adjustSystemProperties(item);

    const template = await getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.PageTemplate>({
      codename: item.elements['template'].value[0],
      depthParameter: 2,
      cache,
      cacheTTL: CacheConfig.TTL.PageTemplate,
    });

    const response = {
      article: item,
      template,
    };

    setCacheItem<GetFullArticleBySlugResponse>(cacheKey, response, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return response;
  } catch (error) {
    console.error('Error in getFullArticleBySlug', error);
    throw error;
  }
};
