import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedItemOrDefault,
  getColorValue,
  getSingleChoiceValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';
import { getBooleanValue } from '@r-and-a-shared-ui/utils';

export const carousel_hero = (entry: MEMBERSHIP_MODELS.CarouselHero) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  return {
    heroItems: getMappedLinkedItems(elements?.hero_list) || [],
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    sliderChangeTime: elements?.slider_change_time?.value,
    sliderTimeout: elements?.slider_time_out?.value,
    infiniteLoop: getBooleanValue(
      getSingleChoiceValue(elements?.enable_loop),
    ),
  };
};

export default carousel_hero;
