/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from './CustomPagination.module.scss';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export type CustomPaginationProps = {
    handlePageClick: ({selected}: {selected: any}) => void;
    setPage: (pageNumber: number) => void;
    totalResults: number;
    perPage: number;
    currentPage: number;
    pageCount: number;
};

export function CustomPagination({
  handlePageClick,
  setPage,
  totalResults,
  perPage,
  currentPage,
  pageCount,
}: CustomPaginationProps) {
  return (
    totalResults === 0 ? undefined : (
      <div className={styles.pagination__container}>
        <div className={styles.pagination__section}>
          {!(currentPage === 0)
          && (
            <a
              role="button"
              className={styles.pagination__link__end}
              onClick={() => setPage(0)}
              title="first page"
              tabIndex={0}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={styles.icon} />
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={styles.icon} />
            </a>
          )}
          <ReactPaginate
            previousLabel={!(currentPage === 0) ?
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={styles.icon} />: ' '}
            nextLabel={!(currentPage + 1 === pageCount) ?
              <FontAwesomeIcon
                icon={faChevronRight}
                className={styles.icon} />: ' '}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            forcePage={currentPage}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.pagination__link}
            nextLinkClassName={styles.pagination__link}
            activeClassName={styles.pagination__link__active}
            marginPagesDisplayed={0}
            pageRangeDisplayed={4}
            breakLabel=""
          />
          { !(currentPage + 1 === pageCount)
        && (
          <a
            role="button"
            className={styles.pagination__link__end}
            onClick={() => setPage(pageCount - 1)}
            title="last page"
            tabIndex={0}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={styles.icon} />
            <FontAwesomeIcon
              icon={faChevronRight}
              className={styles.icon} />
          </a>
        )}
        </div>
      </div>
    )
  );
}

export default CustomPagination;