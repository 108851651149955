import { ResourcesConfig } from 'aws-amplify';

const oauthDomain = process.env.NEXT_PUBLIC_COGNITO_OAUTH_DOMAIN || 'my.oneclub.golf';
const redirectSignIn = process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGN_IN?.split(',') || ['https://oneclub.golf/account/sso/callback', 'https://stg.oneclub.golf/account/sso/callback', 'https://dev.oneclub.golf/account/sso/callback'];
const redirectSignOut = process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGN_OUT?.split(',') || ['https://oneclub.golf/account/sso/callback', 'https://stg.oneclub.golf/account/sso/callback', 'https://dev.oneclub.golf/account/sso/callback'];
const identityPoolId = process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID || 'eu-west-2:2a990645-b488-4de7-87c3-72629a9fa737';
const userPoolId = process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID || 'eu-west-2_JkMfKIKUb';
const userPoolClientId = process.env.NEXT_PUBLIC_COGNITO_USER_POOL_CLIENT_ID || '3u64mbqae9pie5hga7l02csvak';
const scopes =  process.env.NEXT_PUBLIC_COGNITO_USER_POOL_SCOPES?.split(',') || [
  "email",
  "openid",
  "profile",
  'aws.cognito.signin.user.admin',
  'email'
];

const responseTypeVar: "code" | "token" = 'code'; // Correctly typed

export const awsExports: ResourcesConfig = Object.freeze({
  Auth: {
    Cognito: {
      identityPoolId,
      userPoolId,
      userPoolClientId,
      loginWith: {
        oauth: {
          domain: oauthDomain,
          scopes,
          redirectSignIn,
          redirectSignOut,
          responseType: responseTypeVar
        },
      }
    },
  },
});
