import React from 'react';
import FilterBlock from '../FilterBlock/FilterBlock';
import { RenderOn } from '../RenderOn/RenderOn';
import { useArticleFilter } from './useArticleFilter';
import { ImageProps } from '../Image/Image';

interface ArticleFilterProps {
  backgroundImage: ImageProps | null;
}

export function ArticleFilter({ backgroundImage }: ArticleFilterProps)  {
  const { activeFilters, filterOption, handleApplyFilter } = useArticleFilter();

  return (
    <RenderOn condition={!!filterOption.length}>
      <FilterBlock
        filterCategories={[
          {
            name: 'Authors',
            options: filterOption,
          },
        ]}
        activeFilters={{ Authors: activeFilters }}
        onRemove={handleApplyFilter}
        onApply={handleApplyFilter}
        backgroundImage={backgroundImage}
      />
    </RenderOn>
  );
}

export default ArticleFilter;
