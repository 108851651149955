import React, { useEffect } from 'react';
import styles from './FormDateField.module.scss';
import { BaseFormFieldProps, FormFieldProps } from '../FormField/FormField';
import { useFormContext, useWatch } from 'react-hook-form';
import FormDropdown from '../FormDropdown/FormDropdown';
import {
  PredefinedOptionsList,
  formFieldOptionsList,
} from '../config/form-field-options-lists';
import { convertDateToUTC, isValidDate } from '@r-and-a-shared-ui/utils';

export interface FormDateFieldProps extends BaseFormFieldProps {
  minimumYear?: number;
  yearOffset?: number;
}

export const FormDateField = (props: FormDateFieldProps) => {
  const {
    name,
    label,
    subfields = [],
    minimumYear,
    yearOffset,
    validationMessage,
    isRequired
  } = props;

  const {
    setValue,
    formState: { errors },
    control,
    trigger,
  } = useFormContext();

  const watchedValues = useWatch({
    name: subfields?.map(field => field.name),
    control,
  });

  useEffect(() => {
    const [dayDate, monthDate, yearDate] = watchedValues;
    const isEmpty = !dayDate && !monthDate && !yearDate;
    const isFullDate = dayDate && monthDate && yearDate;

    let date: Date | string | undefined = !isEmpty
      ? `${yearDate}-${monthDate}-${dayDate}`
      : undefined;

    if(isFullDate) {
      const correctMonth = parseInt(monthDate) - 1;
      const isValid = isValidDate(parseInt(yearDate), correctMonth, parseInt(dayDate));
      if(isValid) {
        const localDate = new Date(yearDate, correctMonth, dayDate);
        date = convertDateToUTC(localDate);
      }
    }
    setValue(name, date);
    if(isEmpty && isRequired) {
      return;
    }
    trigger(name);
  }, [watchedValues, setValue, name]);

  const renderDateDropdowns = (
    subfields: FormFieldProps[] | undefined,
  ): React.JSX.Element | undefined => {

    return (
      <div className={styles['form-element__dropdown-container']}>
        {subfields?.map((subFieldProps, index) => {
          const predefinedOptionsListKey = subFieldProps.preferredOptionsList;
          const fieldOptions = formFieldOptionsList[
            predefinedOptionsListKey as PredefinedOptionsList
          ]({
            minimumYear,
            yearOffset,
          });
          return (
            <FormDropdown
              {...subFieldProps}
              key={(subFieldProps.key || index).toString()}
              options={fieldOptions}
              hideIndividualError={true}
              triggerError={!!errors?.[name]}
              menuPlacement="top"
            ></FormDropdown>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <div className={`${styles['form-element__container']}`}>
        <div className={`${styles['form-element__title']}`}>{label}</div>
        {renderDateDropdowns(subfields)}
      </div>
      {errors?.[name] && (
        <small className={styles['form-element__hint']}>
          {validationMessage}
        </small>
      )}
    </div>
  );
};

export default FormDateField;
