import { isNullOrUndefined } from '@r-and-a-shared-ui/utils';
import { NextSeo } from 'next-seo';

export interface SEOWrapperProps {
  title?: string;
  description?: string;
  keywords?: string;
  noindex?: boolean;
  nofollow?: boolean;
  urlSlug?: string;
}

export const SEOWrapper = (seoData: SEOWrapperProps) => {
  const {
    title,
    description,
    keywords,
    noindex,
    nofollow
  } = seoData;

  return (
    <NextSeo
      title={title}
      description={description}
      additionalMetaTags={
        !isNullOrUndefined(keywords) ?
          [
            {
              name: 'keywords',
              content: keywords
            },
          ]
          : undefined
      }
      noindex={noindex}
      nofollow={nofollow}
    />
  );
};

export default SEOWrapper;
