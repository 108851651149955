import { useRef } from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { hasValue } from '@r-and-a-shared-ui/utils';
import styles from './FormDropdown.module.scss';
import { FormFieldProps, OptionProps } from '../FormField/FormField';
import { useController, useFormContext } from 'react-hook-form';
import { defaultValidationMessage } from '../config/validators/validators';

export const FormDropdown = (
  props: FormFieldProps,
) => {
  const selectRef = useRef<any | null>(null);
  const {
    name,
    fieldRef,
    placeholder,
    label,
    isDisabled,
    validationMessage,
    options,
    triggerError,
    entireObjectSelection,
    menuPlacement,
  } = props;
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const { field } = useController({ name, control });
  const {
    value: selectedOption,
    onChange: selectOnChange,
    ...restField
  } = field;

  const { ref, ...rest } = register(name);

  const getValue = () => {
    let selectionValue = selectedOption;
    if (entireObjectSelection) {
      selectionValue = selectedOption?.value || selectedOption;
    }
    return options?.find(option => option.value === selectionValue);
  };
  const onSelectChange = (
    option: SingleValue<OptionProps>,
    { action }: ActionMeta<OptionProps>,
  ) => {
    if (action === 'clear') {
      selectOnChange('');
      return;
    }
    selectOnChange(entireObjectSelection ? option : option?.value);
  };

  const addErrorClass = triggerError || !!errors?.[name];
  return (
    <div
      ref={fieldRef}
      className={`${styles['form-element']} ${styles['form-select']} ${
        addErrorClass ? styles['form-element__has-error'] : ''
      }`}
    >
      {hasValue(label) && (
        <label
          className={`${styles['form-element__label']} ${
            getValue() ? styles['form-element__has-value'] : ''
          }`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <Select
        id={name}
        classNamePrefix={'custom__select'}
        placeholder={hasValue(label) ? label : placeholder}
        isClearable={true}
        options={options || []}
        value={getValue()}
        {...restField}
        {...rest}
        ref={e => {
          ref(e);
          selectRef.current = e;
        }}
        onChange={onSelectChange}
        isDisabled={isDisabled}
        menuPlacement={menuPlacement}
      />
      <div className={styles['form-element__bar']}></div>
      {errors?.[name] && (
        <small className={styles['form-element__hint']}>
          {validationMessage || defaultValidationMessage}
        </small>
      )}
    </div>
  );
};

export default FormDropdown;
