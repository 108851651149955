import { ReactNode } from 'react';
import Link from 'next/link';

export const NextJSLinkWrapper = ({
  href,
  children,
  isExternal,
  prefix,
  prefetch,
  className,
}: {
  href: string;
  children: ReactNode;
  isExternal?: boolean;
  prefix?: string;
  prefetch?: boolean;
  className?: string;
}) => {
  // always cleaning up the href
  const parsedHref = !isExternal ? `/${href?.replace(/^\/+(.*)$/, '$1')}` : href;

  return isExternal ? (
    <a
      data-testid={'LinkWrapper'}
      href={parsedHref}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  ) : (
    <Link
      data-testid={'LinkWrapper'}
      prefetch={prefetch}
      href={
        prefix !== '' && prefix !== undefined
          ? '/' + prefix + parsedHref
          : parsedHref
      }
      className={className}
    >
      {children}
    </Link>
  );
};
