import { FC } from 'react';
import { featureCardThemes } from './constants';
import { FeatureCardTOC } from './FeatureCardTOC/FeatureCardTOC';
import { FeatureCardProps } from './FeatureCard.types';

export const FeatureCard: FC<FeatureCardProps> = props => {
  const { theme } = props;

  switch (theme) {
  case featureCardThemes.THE_ONE_CLUB:
    return <FeatureCardTOC {...props} />;
  default:
    return <div>Please choose an existing theme</div>;
  }
};
