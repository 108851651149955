import { CallOutPanelList } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: ()=>CallOutPanelList,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.CalloutPanelList) =>
    kontentAiDataMapping.callout_panel_list(entry),
  depthParameter: 4
};
