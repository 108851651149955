import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';
import { generateUniqueKey } from '@r-and-a-shared-ui/utils';
import { Article } from 'libs/common-kontent-client/src/models/MEMBERSHIP';
import styles from './ArticleList.module.scss';
import { IPagination } from '@kontent-ai/delivery-sdk';

export interface ArticleListProps {
  filteredArticles?: {
    queryUrl: string;
    items: Article[];
    pagination: IPagination;
  } | null;
}

export function ArticleList({ filteredArticles }: ArticleListProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          {filteredArticles?.items?.length ? (
            filteredArticles?.items?.map((article: Article) => {
              return (
                <AsyncComponent
                  key={`article-${article.system.id}-${generateUniqueKey()}`}
                  kontentItem={article}
                  contentType={'article_listicle'}
                />
              );
            })
          ) : (
            <p className={styles['empty-list-text']}>
              No articles matching your filter criteria were found.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ArticleList;
