import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';
import {
  getFirstLinkedAssetOrDefault,
  getFirstLinkedItemOrDefault,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const form_page = (data: SSO_MODELS.FormPage) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    seoData: getFirstMappedLinkedItemOrDefault(elements?.seo_data),
    form: getFirstMappedLinkedItemOrDefault(elements?.form),
    backGroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    desktopAsset: getFirstLinkedAssetOrDefault(elements?.desktop_image),
    mobileAsset: getFirstLinkedAssetOrDefault(elements?.mobile_image)
  };
};

export default form_page;
