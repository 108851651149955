import 'slick-carousel/slick/slick.css';
import React, { CSSProperties, ReactNode, useId, useState, JSX } from 'react';
import { default as SlickSlider } from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import ContentListItem from '../ContentListItem';
import { ListDisplay } from '../ContentList.types';
import { ContentListProps } from '../ContentList';
import SliderCustomDots from './SliderCustomDots';
import { useAutoplay } from './useAutoPlay';

import styles from './Slider.module.scss';

enum SliderWidth {
  FULL_WIDTH = 'full_width',
}

export interface SliderConfig {
  autoPlay?: boolean;
  autoplayTimeout?: number | null;
  autoplayTimer?: number | null;
  infiniteLoop?: boolean;
  sliderArrowsColor?: string;
  sliderArrowsSize?: string;
  slidesToShowDesktop?: number | null;
  slidesToShowMobile?: number | null;
  slidesToScrollDesktop?: number | null;
  slidesToScrollMobile?: number | null;
  sliderWidth?: string | SliderWidth;
  enableArrows?: boolean;
  appendDots?: (dots: ReactNode) => JSX.Element;
  customNavBar?: (index: number) => JSX.Element | null;
  extraNavChild?: (index: number) => JSX.Element | null;
  isArrowBackgroundTransparent?: boolean;
}

type SliderProps = Omit<ContentListProps, 'type' | 'carouselConfig'> &
  SliderConfig;

interface ArrowProps {
  currentSlide?: number;
  slideCount?: number;
  icon: IconDefinition;
  onClick?: (item: any) => void;
  [key: string]: any;
}

export function Slider(props: SliderProps) {
  const {
    elements,
    autoPlay = false,
    autoplayTimeout,
    autoplayTimer = 5,
    infiniteLoop = false,
    sliderArrowsColor,
    sliderArrowsSize,
    slidesToShowDesktop,
    slidesToShowMobile,
    listDisplay,
    backgroundColor,
    customNavBar,
    appendDots,
    extraNavChild,
    slidesToScrollDesktop,
    slidesToScrollMobile,
    enableArrows,
    sliderWidth,
    isArrowBackgroundTransparent,
  } = props;
  const key = useId();
  const { isAutoPlay } = useAutoplay(autoPlay, autoplayTimer, autoplayTimeout);
  const [activeIndex, setActiveSlide] = useState(0);
  const itemsView =
    slidesToShowDesktop && slidesToShowDesktop > 0 ? slidesToShowDesktop : 1;
  const isNavVisibleByItems = elements ? elements?.length > itemsView : false;
  const showCustomDots = listDisplay === ListDisplay.HERO_LIST;
  const autoplaySpeedValue = autoplayTimer ? autoplayTimer * 1000 : undefined;
  const isAutoplayValue = isNavVisibleByItems ? isAutoPlay : false;

  const arrowBtnProp = {
    color: sliderArrowsColor || 'white',
    size: (sliderArrowsSize as SizeProp) || 'lg',
  };

  const SlickArrow = ({
    icon,
    currentSlide,
    slideCount,
    onClick,
    ...props
  }: ArrowProps) =>
    !showCustomDots && enableArrows ? (
      <div
        className={classNames(styles['slider-arrow'], {
          [styles['slider-arrow--transparent']]: isArrowBackgroundTransparent,
        })}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} {...arrowBtnProp} {...props} />
      </div>
    ) : null;

  const customDots = (dots: ReactNode) =>
    appendDots ? (
      appendDots(dots)
    ) : (
      <SliderCustomDots
        extraNavChild={extraNavChild}
        dots={dots}
        customNavBar={customNavBar}
        activeIndex={activeIndex}
      />
    );

  const inlineStyle: CSSProperties = {
    backgroundColor,
  };

  const responsiveConfig = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: slidesToShowMobile || 1,
        slidesToScroll: slidesToScrollMobile || 1,
      },
    },
  ];

  return (
    <div
      className={classNames(styles.wrapper, listDisplay && styles[listDisplay])}
    >
      <div
        className={classNames(styles['container'], {
          [styles['is-full-width']]: sliderWidth === SliderWidth.FULL_WIDTH,
        })}
        style={inlineStyle}
      >
        <SlickSlider
          infinite={infiniteLoop}
          arrows={enableArrows}
          prevArrow={<SlickArrow icon={faChevronLeft} />}
          nextArrow={<SlickArrow icon={faChevronRight} />}
          draggable={isNavVisibleByItems && !showCustomDots}
          autoplaySpeed={autoplaySpeedValue}
          autoplay={isAutoplayValue}
          className={classNames({ [styles['slick-slider']]: !showCustomDots })}
          appendDots={customDots}
          dots={showCustomDots}
          beforeChange={(_, nextIndex) => setActiveSlide(nextIndex)}
          slidesToShow={itemsView}
          slidesToScroll={slidesToScrollDesktop || 1}
          responsive={responsiveConfig}
        >
          {
            elements?.map(element => (
              <ContentListItem
                element={element}
                key={key}
                listDisplay={listDisplay}
              />
            )) as any
          }
        </SlickSlider>
      </div>
    </div>
  );
}

export default Slider;
