import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getColorValue,
  getFirstLinkedAssetOrDefault,
  getFirstLinkedItemOrDefault,
  getTextItemValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const alert_bar = (entry: MEMBERSHIP_MODELS.AlertBar) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  return {
    countdown: elements?.countdown?.value,
    text: elements?.text?.value,
    daysLabel: elements?.days_label?.value,
    dayLabel: elements?.day_label?.value,
    hoursLabel: elements?.hours_label?.value,
    hourLabel: elements?.hour_label?.value,
    minutesLabel: elements?.minutes_label?.value,
    minuteLabel: elements?.minute_label?.value,
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    textColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.text_color),
    ),
    textFont: getTextItemValue(
      getFirstLinkedItemOrDefault(elements?.text_font),
    ),
    backgroundImage: getFirstLinkedAssetOrDefault(elements?.background_image)
      ?.url,
  };
};

export default alert_bar;
