import {
  AsyncComponent,
  ConfiguredComponents,
  DynamicComponent,
} from '@r-and-a-shared-ui/core-component-config';
import type { PageProps } from './types';

import styles from './Page.module.scss';
import { useScreenDetector } from '../../hooks';

export const BodyContent = ({
  body,
  sidebar,
}: Pick<PageProps, 'body' | 'sidebar'>) => {
  const { isTablet } = useScreenDetector();
  return (
    <div className={styles.container}>
      <div data-testid={'body-content'} className={styles.content}>
        <div className={styles.content__left}>
          {body.map(x => {
            // if (x.dynamic) {
            //   return (
            //     <DynamicComponent
            //       key={x.system.id}
            //       codename={x.system.codename}
            //       contentType={x.system.type}
            //       locale={x.system.language}
            //     />
            //   );
            // }
            return (
              <AsyncComponent
                kontentItem={x}
                contentType={x.system.type as ConfiguredComponents}
                key={x.system.id}
              />
            );
          })}
        </div>
        <div className={styles.content__right}>
          {sidebar
            .map(x => {
              if (isTablet && x.system.type !== 'web_banner_placeholder') {
                return null;
              }
              return (
                <AsyncComponent
                  kontentItem={x}
                  contentType={x.system.type as ConfiguredComponents}
                  key={x.system.id}
                />
              );
            })
            .filter(Boolean)}
        </div>
      </div>
    </div>
  );
};
