import { Attributes, Membership } from '@r-and-a-shared-ui/auth-client';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import dayjs from 'dayjs';

export const getContentMap = (user:any, membership: Membership) => {
  const memberName = user?.[Attributes.GivenName] || 'Member';
  const formattedDateSinceMember = membership.startedAt ? dayjs(membership.startedAt).format(
    'MMMM YYYY',
  ) : '2024';

  return {
    [RestrictionLevel.none]: {
      title: "Log in to Unlock",
      subtitle: 'Login/ Sign Up to unlock Exclusive Content, Premium Rewards and Benefits',
    },
    [RestrictionLevel.oneClub]: {
      title: `Hi ${memberName}`,
      subtitle: `You have been part of the club since ${formattedDateSinceMember}`,
    },
    [RestrictionLevel.oneClubAdvantage]: {
      title: `Hi ${memberName}`,
      subtitle: `You have been part of the club since ${formattedDateSinceMember}`,
    },
  };
};
