import { WebBannerPlaceholder } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: () => WebBannerPlaceholder,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.WebBannerPlaceholder) =>
    kontentAiDataMapping.web_banner_placeholder(entry),
};
