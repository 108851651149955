export enum ContentListType {
  SLIDER = 'slider',
  PLAIN_LIST = 'plain_list',
  GRID = 'grid',
}

export enum ContentListDisplay {
  HORIZONTAL = 'force_horizontal',
  VERTICAL = 'force_vertical',
}

export enum ListDisplay {
  ALERT_BAR_LIST = 'alert_bar_list',
  CALLOUT_PANEL_LIST = 'callout_panel_list',
  EVENTS_LIST = 'events_list',
  HERO_LIST = 'hero_list',
  PLAY_LIST = 'play_list',
  UPGRADE_EXPERIENCE = 'upgrade_experience_list'
}