import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getTrueFalseSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { web_banner } from './map-web_banner';
import { WebBanner } from 'libs/common-kontent-client/src/models/MEMBERSHIP';

export const web_banner_placeholder = (entry: MEMBERSHIP_MODELS.WebBannerPlaceholder) => {
  if (!entry || !entry.elements) {
    return undefined;
  }

  const { elements } = entry;

  const selectedWebBanners = elements?.selected_web_banners?.linkedItems as WebBanner[];

  return {
    forced_portrait: getTrueFalseSingleChoiceValue(elements?.forced_portrait),
    selected_web_banners: selectedWebBanners.map(web_banner),
  };
};

export default web_banner_placeholder;
