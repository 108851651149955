import classnames from 'classnames';
import { FC, useContext } from 'react';
import { EnvironmentContext } from '../../contexts';
import { MembershipTypeIconProps } from './types';
import styles from './MembershipType.module.scss';
import { membershipTypeIcons } from './constants';
import { RenderOn } from '../RenderOn/RenderOn';

export const MembershipType: FC<MembershipTypeIconProps> = ({
  membershipType,
  active = false,
  label,
  price,
  image,
  activeBackgroundColor,
  inactiveBackgroundColor,
}) => {
  const { Image } = useContext(EnvironmentContext);
  const icon = image || membershipTypeIcons[membershipType];
  const parsedStyle = active
    ? activeBackgroundColor
      ? { backgroundColor: activeBackgroundColor }
      : undefined
    : inactiveBackgroundColor
      ? { backgroundColor: inactiveBackgroundColor }
      : undefined;

  return (
    <div
      className={classnames(
        styles['membership-type-icon'],
        styles[active ? 'membership-type-icon--active' : ''],
      )}
      style={parsedStyle}
    >
      {icon && (
        <Image
          src={icon}
          alt="Membership Type Icon"
          placeholder="blur"
          width={100}
          height={100}
          className={styles['membership-type-icon__image']}
        />
      )}
      <RenderOn condition={!!label}>
        <div className={styles['membership-type-icon__title']}>{label}</div>
      </RenderOn>
      <div className={styles['membership-type-icon__price']}>{price}</div>
    </div>
  );
};
