import { LinkProps } from '@r-and-a-shared-ui/common-ui';
import * as contentTypes from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getPrefixedMembershipInternalLink } from '@r-and-a-shared-ui/utils';

export const link = (
  entry: contentTypes.MEMBERSHIP_MODELS.Link,
): LinkProps | undefined => {
  if (!entry || !entry?.elements) {
    return undefined;
  }

  const { elements } = entry;

  const internalHref = getPrefixedMembershipInternalLink(
    elements?.internal_page?.linkedItems?.[0]?.system.type,
    elements?.internal_page?.linkedItems?.[0]?.elements?.url_slug?.value,
  );

  const href = (elements?.href?.value || internalHref) ?? '';

  const internal = !elements?.href?.value;

  return {
    title: elements.title?.value,
    href,
    internal,
    icon: getFirstLinkedAssetOrDefault(elements?.icon)?.url,
    codename: entry?.system?.codename,
  };
};

export default link;
