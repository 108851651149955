import {
  createContext,
} from 'react';
import { CognitoUserAttribute } from '../types';
import { useSSOConfigContext } from './SSOConfigContext';
import { useMembershipConfigContext } from './MembershipConfigContext';
  
export interface SiteConfigContextType {
    memberRequiredAttributes?: CognitoUserAttribute[],
  }
  
export const siteConfigContext = createContext<SiteConfigContextType | undefined>(undefined);
  
export const useSiteConfigContext = () => {
  const ssoConfig = useSSOConfigContext();
  const memebershipConfig = useMembershipConfigContext();
  const context = ssoConfig || memebershipConfig;
  if (!context) {
    console.error('SiteConfigContext must be used within an SiteConfig Provider');
    return undefined;
  }
  return context;
};
  