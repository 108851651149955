import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedItemOrDefault,
  getSingleChoiceValue,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';
import { getBooleanValue } from '@r-and-a-shared-ui/utils';

export const mapSliderConfig = ({
  elements,
}: MEMBERSHIP_MODELS.CarouselConfig) => {
  if (!elements || !elements) {
    return undefined;
  }

  return {
    autoPlay: getBooleanValue(
      getSingleChoiceValue(elements?.carousel_config__autoplay),
    ),
    autoplayTimeout: elements?.carousel_config__autoplay_timeout?.value,
    autoplayTimer: elements?.carousel_config__autoplay_timer?.value,
    infiniteLoop: getBooleanValue(
      getSingleChoiceValue(elements?.carousel_config__infinite_loop),
    ),
    sliderArrowsColor: getColorValue(
      getFirstLinkedItemOrDefault(
        elements?.carousel_config__slider_arrows_color,
      ),
    ),
    sliderArrowsSize: getSingleChoiceValue(
      elements?.carousel_config__slider_arrows_size,
    ),
    slidesToShowDesktop:
      elements?.carousel_config__slides_to_show_desktop?.value,
    slidesToShowMobile: elements?.carousel_config__slides_to_show_mobile?.value,
    slidesToScrollDesktop:
      elements?.carousel_config__slides_to_scroll_desktop?.value,
    slidesToScrollMobile:
      elements?.carousel_config__slide_to_scroll_mobile?.value,
    enableArrows: getBooleanValue(
      getSingleChoiceValue(elements?.carousel_config__enable_arrows),
    ),
    sliderWidth: getSingleChoiceValue(elements?.carousel_config__slider_width),
    isArrowBackgroundTransparent: getBooleanValue(
      getSingleChoiceValue(
        elements?.carousel_config__arrows__transparent_background,
      ),
    ),
  };
};

export const content_list___fixed_length = (
  entry: MEMBERSHIP_MODELS.ContentListFixedLength,
) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }

  const { elements } = entry;
  const carouselConfig = mapSliderConfig(entry);

  return {
    title: elements?.title?.value,
    elements: getMappedLinkedItems(elements?.elements),
    display: getSingleChoiceValue(elements?.display),
    type: getSingleChoiceValue(elements?.type) || 'slider',
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    listDisplay: getSingleChoiceValue(elements?.list_display),
    totalTilesToShow: elements?.total_tiles_to_show?.value,
    carouselConfig,
    codename: entry?.system?.codename || '',
  };
};

export default content_list___fixed_length;
