import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const cookie_popup = (data: MEMBERSHIP_MODELS.CookiesPopup) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const ctaProps = {
    logoImage: getFirstLinkedAssetOrDefault(data.elements?.logo_image)?.url,
    title: data.elements.title?.value,
    description: data.elements?.description?.value,
    buttonLabel: data.elements?.button_label?.value,
    detailsSubtext: data.elements?.details_subtext?.value,
    detailsSubscription: data.elements?.details_subscription?.value,
    cookiesPageLink: getFirstMappedLinkedItemOrDefault(data.elements?.cookies_page_link)
  };

  return ctaProps;
};

export default cookie_popup;
