import { CacheConfig } from "../constants";
import { CacheService } from "../lib/cache-service";
import { hasValue } from '@r-and-a-shared-ui/utils';

export interface CacheStatus {
    xInMemoryCache?: string,
    cacheable?: boolean,
    cacheTTL?: number
}

export const getCachedItem = <Type>(key: string, cacheEnabled = true) => {
    if (!cacheEnabled) {
        return undefined;
    }
    const cacheService = CacheService.getInstance();

    return cacheService.get<Type>(key);
};

export const setCacheItem = <Type>(key: string, value: Type, cacheTTL = CacheService.defaultCacheTTL, cacheEnabled = true) => {
    if (!cacheEnabled || !hasValue(key) || !hasValue(value)) {
        return;
    }

    const cacheService = CacheService.getInstance();

    cacheService.set<Type>(key, value, cacheTTL);
};

export const setCacheStatus = (cacheStatus?: CacheStatus, value?: CacheStatus) => {
    if (!cacheStatus) {
        return;
    }

    cacheStatus.cacheable = value?.cacheable;
    cacheStatus.xInMemoryCache = value?.xInMemoryCache;
    cacheStatus.cacheTTL = value?.cacheTTL;
}

export const initCacheStatus = (cacheable = false, xInMemoryCache = CacheConfig.Miss.toString(), cacheTTL = CacheConfig.TTL.Default): CacheStatus => {
    return {
        cacheable,
        xInMemoryCache,
        cacheTTL
    }
}

export const setNextMiddlewareCacheHeader = (requestHeaders: Headers) => {
    const headers = new Headers(requestHeaders);
  
    headers.set(
        'Cache-Control',
        `public, max-age=${CacheConfig.TTL.Page + CacheConfig.TTL.ttlDelta}, stale-while-revalidate=59`,
    );
  
    return headers;
};
