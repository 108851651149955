import { HeroTile } from '@r-and-a-shared-ui/common-ui';

import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
export default {
  component: ()=>HeroTile,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.HeroTile) =>
    kontentAiDataMapping.hero_tile(entry),
};
