import { createContext } from 'react';
import { DictionaryItemProps, DictionaryWithLinkedItemProps, FormProps } from '../types';

export type FormContentContextType = {
  formContentProps: FormProps;
  currentFormStep: number;
  isLoading: boolean;
  setSubmitSuccessMessage: (...args: any[]) => any;
  errorsDictionary?: DictionaryItemProps[];
  successMessagesDictionary?: DictionaryItemProps[];
  nextStepsAfterSubmit?: DictionaryWithLinkedItemProps[];
  initialFormState?:any;
};
export const FormContentContext = createContext<FormContentContextType | null>(null);
