import { hasValue, isNullOrUndefined, safeParseJSON } from "./general-utils";

export const getNumberFromSessionStorage = (key: string): number => {
  try {
    return parseInt(sessionStorage.getItem(key) || '');
  } catch (error) {
    return 0;
  }
};
  
export const setNumberInSessionStorage = (key: string, value: number | undefined) => {
  if(isNullOrUndefined(value)) {
    return;
  }

  try {
    sessionStorage.setItem(key, value.toString());
  } catch (error) {
    return;
  }
};

export const deleteFromSessionStorage = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    return;
  }
};

export const deleteItemsFromSessionStorage = (keys: string[]) => {
  if(!hasValue(keys)) {
    return;
  }

  keys.forEach(key => {
    deleteFromSessionStorage(key);
  });
};

export const getStringFromSessionStorage = (key: string): string | undefined => {
  try {
    return sessionStorage.getItem(key) as string | undefined;
  } catch (error) {
    return '';
  }
};

export const setSessionStorageItem = (key: string, value: any) => {
  if(isNullOrUndefined(value)) {
    return;
  }
  
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    return;
  }
};

export const getSessionStorageItem = (key: string, deleteItem = false) => {
  try {
    const item = safeParseJSON(sessionStorage.getItem(key) as string | undefined);

    if (deleteItem){
      sessionStorage.removeItem(key);
    }

    return item;
  } catch (error) {
    return undefined;
  }
};

export const getSessionStorageItems = (keys: string[]): { [key: string]: string | undefined } => {
  const result: { [key: string]: string | undefined } = {};

  if(!hasValue(keys)) {
    return {};
  }

  keys.forEach(key => {
    result[key] = getSessionStorageItem(key);
  });

  return result;
};