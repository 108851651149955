import styles from './StepsCounter.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
export interface StepsCounterProps {
  count: number,
  current: number
}

export function StepsCounter(props: StepsCounterProps) {
  const { count, current } = props;
  return (
    <div className={styles['container']}>
      { Array(count).fill(0).map((_, index) => {
        return <FontAwesomeIcon
          icon={faCircle}
          key={`${index}_step`}
          className={index === current ? styles.active : ''} />;
      })
      }
    </div>
  );
}

export default StepsCounter;
