import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const dictionary_with_linked_item = (
  data: SSO_MODELS.DictionaryWithLinkedItem,
) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    key: elements?.key?.value,
    item: getFirstMappedLinkedItemOrDefault(elements?.item),
  };
};

export default dictionary_with_linked_item;
