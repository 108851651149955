import React, { useContext, CSSProperties } from 'react';
import styles from './CallOutPanel.module.scss';
import { Tag, TagProps } from '../Tag/Tag';
import { EnvironmentContext } from '../../contexts';
import { WithOrWithoutLink } from '../WithOrWithoutLink/WithOrWithoutLink';
import { LinkProps } from '../Link/Link';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import MembershipTypesLogos from '../MembershipTypeLogos/MembershipTypesLogos';
import { useScreenDetector } from '../../hooks';

export interface CallOutPanelProps {
  backgroundImage?: string;
  backgroundColor?: string;
  mobileBackgroundImage?: string;
  description?: string;
  title?: string;
  tags?: TagProps[];
  link?: LinkProps;
  access?: keyof typeof RestrictionLevel;
}

export function CallOutPanel(props: CallOutPanelProps) {
  const { Image } = useContext(EnvironmentContext);
  const { isMobile } = useScreenDetector();
  const {
    backgroundImage,
    backgroundColor,
    mobileBackgroundImage,
    description,
    title,
    tags,
    link,
    access = 'none',
  } = props;

  const inlineStyle: CSSProperties = {
    backgroundColor,
  };

  const imageSource = isMobile && mobileBackgroundImage ? mobileBackgroundImage : backgroundImage;

  const isHoverable = !!link;

  return (
    <div
      className={`${styles['wrapper']} ${isHoverable ? styles['hoverable'] : ''}`}
      style={inlineStyle}
    >
      <WithOrWithoutLink url={link}>
        {imageSource && (
          <div className={styles['background']}>
            <Image
              src={imageSource}
              alt={'Call out panel image'}
              width={372}
              height={250}
            />
          </div>
        )}
        <div className={styles['labels-container']}>
          <MembershipTypesLogos restrictionLevel={access} className={styles['logo']} />
          {tags && (
            <div className={styles['tags']}>
              {tags?.map((tag, i) => (
                <Tag
                  key={`${i}`}
                  label={tag.label}
                  backgroundColor={tag?.backgroundColor}
                  textColor={tag?.textColor}
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles['content']}>
          {title && <h4>{title}</h4>}
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className={styles['description']}
            />
          )}
        </div>
      </WithOrWithoutLink>
    </div>
  );
}

export default CallOutPanel;
