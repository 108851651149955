/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const contentTypes = {
    /**
     * Callback URL
     */
    callback_url: {
        codename: 'callback_url',
        id: '9810505f-fd12-4dfb-ab29-7e7b223197ca',
        externalId: undefined,
        name: 'Callback URL',
        elements: {
            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: '4f06a496-da02-44bb-a1d7-d29ed4780b2d',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Client ID
     */
    client_id: {
        codename: 'client_id',
        id: 'f69f1593-a7f8-4a51-aa38-937707bc4ef0',
        externalId: undefined,
        name: 'Client ID',
        elements: {
            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: 'e5c69009-84f2-4c6c-bece-3069546912ae',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Cognito User Attribute
     */
    cognito_user_attribute: {
        codename: 'cognito_user_attribute',
        id: '5ade6f3f-f701-4c1f-90f0-3b59e136dde7',
        externalId: undefined,
        name: 'Cognito User Attribute',
        elements: {
            /**
             * Attribute Name (text)
             */
            attribute_name: {
                codename: 'attribute_name',
                id: '041d4366-2247-460d-a5ce-fa30b82eec6e',
                externalId: undefined,
                name: 'Attribute Name',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Color
     */
    color: {
        codename: 'color',
        id: '9594560a-78a5-4c4c-9161-6973e8b871f8',
        externalId: undefined,
        name: 'Color',
        elements: {
            /**
             * Color Code (text)
             */
            color_code: {
                codename: 'color_code',
                id: '4bebb02f-6152-4526-9a9e-746e5e14e7ce',
                externalId: undefined,
                name: 'Color Code',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Country
     */
    country: {
        codename: 'country',
        id: '151c01be-3499-4036-b7c7-d65af5c4d100',
        externalId: undefined,
        name: 'Country',
        elements: {
            /**
             * Aliases (text)
             */
            aliases: {
                codename: 'aliases',
                id: '290696b9-c476-4acb-889c-82b5ca8c2ce9',
                externalId: undefined,
                name: 'Aliases',
                required: false,
                type: 'text'
            },

            /**
             * Code (text)
             */
            code: {
                codename: 'code',
                id: 'b409f90c-278d-4eb7-bcee-c09ac87607d6',
                externalId: undefined,
                name: 'Code',
                required: false,
                type: 'text'
            },

            /**
             * Config (multiple_choice)
             */
            config: {
                codename: 'config',
                id: '703ab9a4-9e30-4a4b-854d-f9a606acbfce',
                externalId: undefined,
                name: 'Config',
                required: false,
                type: 'multiple_choice',
                options: {
                    exclude_from_phone_list: {
                        name: 'Exclude From Phone List',
                        id: 'cfdfcec9-1e8f-49ed-92b2-27a81fe09c81',
                        codename: 'exclude_from_phone_list',
                        externalId: undefined
                    },
                    exclude_from_secutix: {
                        name: 'Exclude From Secutix',
                        id: '0c95808a-bef4-46b1-8caf-3ea05401a170',
                        codename: 'exclude_from_secutix',
                        externalId: undefined
                    },
                    exclude_from_countries_list: {
                        name: 'Exclude From Countries List',
                        id: '92a9d5e0-4f3f-4620-a712-d971942f92da',
                        codename: 'exclude_from_countries_list',
                        externalId: undefined
                    },
                    secutix_compatible: {
                        name: 'Secutix Compatible',
                        id: '375fc562-c711-4ebe-b706-1ac265b82921',
                        codename: 'secutix_compatible',
                        externalId: undefined
                    }
                }
            },

            /**
             * Counties (modular_content)
             */
            counties: {
                codename: 'counties',
                id: 'd9e6a94a-c01b-4a90-981a-6adf47ac31c7',
                externalId: undefined,
                name: 'Counties',
                required: false,
                type: 'modular_content'
            },

            /**
             * Flag (asset)
             */
            flag: {
                codename: 'flag',
                id: '8deb73a8-b7c7-4fd8-80cd-18df5dc52bcf',
                externalId: undefined,
                name: 'Flag',
                required: false,
                type: 'asset'
            },

            /**
             * Id (text)
             */
            id: {
                codename: 'id',
                id: '48bcb653-e020-4d9e-a924-96df3b22d4f9',
                externalId: undefined,
                name: 'Id',
                required: true,
                type: 'text'
            },

            /**
             * International Code Prefix (text)
             */
            international_code_prefix: {
                codename: 'international_code_prefix',
                id: 'a7521360-bb65-4b8b-8248-d79041f364e6',
                externalId: undefined,
                name: 'International Code Prefix',
                required: false,
                type: 'text'
            },

            /**
             * Iso Country Code (text)
             */
            iso_country_code: {
                codename: 'iso_country_code',
                id: '6675268b-6790-43b8-886a-ec0069f3c91b',
                externalId: undefined,
                name: 'Iso Country Code',
                required: false,
                type: 'text'
            },

            /**
             * Nationality Name (text)
             */
            nationality_name: {
                codename: 'nationality_name',
                id: '7a798ea1-fa7f-495a-9fb1-c18ea2c7d3e7',
                externalId: undefined,
                name: 'Nationality Name',
                required: true,
                type: 'text'
            },

            /**
             * States (modular_content)
             */
            states: {
                codename: 'states',
                id: 'fbec6af5-dc83-4f80-8505-aafc2bd7dd97',
                externalId: undefined,
                name: 'States',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * County
     */
    county: {
        codename: 'county',
        id: '87c93e3d-8f7c-4db6-bc30-dc4445258324',
        externalId: undefined,
        name: 'County',
        elements: {
            /**
             * Name (text)
             */
            name: {
                codename: 'name',
                id: '9c707611-a6c5-47b6-8ce0-d178993d23fa',
                externalId: undefined,
                name: 'Name',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * CTA
     */
    cta: {
        codename: 'cta',
        id: '3dd00f1f-7848-490b-a1f5-fe7c0f5a36ce',
        externalId: undefined,
        name: 'CTA',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: 'f03f8578-1de8-4afe-8bf0-8b3c56ee6bac',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Border Color (modular_content)
             */
            border_color: {
                codename: 'border_color',
                id: 'f1d935f6-1209-4e6c-ba7d-79454209184b',
                externalId: undefined,
                name: 'Border Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: '4a4c1413-1d2b-48e3-8abb-d79cab946341',
                externalId: undefined,
                name: 'Label',
                required: true,
                type: 'text'
            },

            /**
             * Opacity (number)
             */
            opacity: {
                codename: 'opacity',
                id: 'bd18ac2b-9185-4398-ab38-f9c2bbd73afa',
                externalId: undefined,
                name: 'Opacity',
                required: false,
                type: 'number'
            },

            /**
             * Resource (modular_content)
             */
            resource: {
                codename: 'resource',
                id: 'd02ba08c-bb3b-4469-8037-1398520439e8',
                externalId: undefined,
                name: 'Resource',
                required: false,
                type: 'modular_content'
            },

            /**
             * Text Color (modular_content)
             */
            text_color: {
                codename: 'text_color',
                id: '96d59652-2aff-4c1a-8297-38e16f0b4899',
                externalId: undefined,
                name: 'Text Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Theme (multiple_choice)
             */
            theme: {
                codename: 'theme',
                id: '5395d3d1-2d25-443f-8429-0f6502adb423',
                externalId: undefined,
                name: 'Theme',
                required: false,
                type: 'multiple_choice',
                options: {
                    bottom_border_only: {
                        name: 'Bottom Border Only',
                        id: 'aa7783eb-e37e-4768-8d8b-0e16c4271f1e',
                        codename: 'bottom_border_only',
                        externalId: undefined
                    }
                }
            },

            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: 'a8e18e9e-1e87-4752-8b27-bd13cff7e0d2',
                externalId: undefined,
                name: 'Type',
                required: true,
                type: 'multiple_choice',
                options: {
                    button: {
                        name: 'Button',
                        id: '02c79423-df54-4944-b3f4-d097f5f9591d',
                        codename: 'button',
                        externalId: undefined
                    },
                    submit: {
                        name: 'Submit',
                        id: 'd1b192fe-c64a-4ed2-a428-69288d82b0fa',
                        codename: 'submit',
                        externalId: undefined
                    },
                    reset: {
                        name: 'Reset',
                        id: 'b5b3b5fe-14ef-4321-91f4-9d6815113dd7',
                        codename: 'reset',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Dictionary Item
     */
    dictionary_item: {
        codename: 'dictionary_item',
        id: 'e763b2e9-6930-4bff-a937-8d649c206fe5',
        externalId: undefined,
        name: 'Dictionary Item',
        elements: {
            /**
             * Key (text)
             */
            key: {
                codename: 'key',
                id: 'b2af44be-0891-4d9f-80cd-f808f575707e',
                externalId: undefined,
                name: 'Key',
                required: false,
                type: 'text'
            },

            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: '22978b43-2916-44b7-a4e1-c9aaad6a13df',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Dictionary with Linked Item
     */
    dictionary_with_linked_item: {
        codename: 'dictionary_with_linked_item',
        id: 'def97f55-839b-44ff-9225-8e840664210b',
        externalId: undefined,
        name: 'Dictionary with Linked Item',
        elements: {
            /**
             * Item (modular_content)
             */
            item: {
                codename: 'item',
                id: '1ee9b675-e4a4-41fb-86f6-2bb753bea9b5',
                externalId: undefined,
                name: 'Item',
                required: false,
                type: 'modular_content'
            },

            /**
             * Key (text)
             */
            key: {
                codename: 'key',
                id: '84d1eeb1-6bfc-4cf6-a9aa-4757e33679ea',
                externalId: undefined,
                name: 'Key',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Form
     */
    form: {
        codename: 'form',
        id: '4f8dfe45-26da-4c4f-86ff-d97a7f505610',
        externalId: undefined,
        name: 'Form',
        elements: {
            /**
             * Default Values Callback URL (modular_content)
             */
            default_values_callback_url: {
                codename: 'default_values_callback_url',
                id: 'f241b8d1-00ae-4833-a601-17e83099d8f3',
                externalId: undefined,
                name: 'Default Values Callback URL',
                required: false,
                type: 'modular_content'
            },

            /**
             * Errors Messages (modular_content)
             */
            errors_messages: {
                codename: 'errors_messages',
                id: '4d4a541e-fae4-4c91-b4af-1881f299b484',
                externalId: undefined,
                name: 'Errors Messages',
                required: false,
                type: 'modular_content'
            },

            /**
             * Form Content (modular_content)
             */
            form_content: {
                codename: 'form_content',
                id: '14cf4097-6b90-4409-8630-bc20e114f4f5',
                externalId: undefined,
                name: 'Form Content',
                required: true,
                type: 'modular_content'
            },

            /**
             * Form Footer (modular_content)
             */
            form_footer: {
                codename: 'form_footer',
                id: '1b330787-1940-4538-92be-84b6ea377c62',
                externalId: undefined,
                name: 'Form Footer',
                required: true,
                type: 'modular_content'
            },

            /**
             * Form Header (modular_content)
             */
            form_header: {
                codename: 'form_header',
                id: '059bb45b-b268-4c37-82df-c9cc27169ccb',
                externalId: undefined,
                name: 'Form Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * Is ReCaptcha Enabled (multiple_choice)
             */
            is_recaptcha_enabled: {
                codename: 'is_recaptcha_enabled',
                id: 'b419f5a3-5d0c-42f4-9591-ff532265a932',
                externalId: undefined,
                name: 'Is ReCaptcha Enabled',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '0d8c9fc9-3e08-4d97-93be-d653d4fa04b5',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '1fa5d136-6e59-4c51-b7bc-d9611d8dc43f',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Next Steps (modular_content)
             */
            next_steps: {
                codename: 'next_steps',
                id: '4d7b9b41-cb8c-48cf-886a-466e71f4e9df',
                externalId: undefined,
                name: 'Next Steps',
                required: false,
                type: 'modular_content'
            },

            /**
             * reCAPTCHA (modular_content)
             */
            recaptcha: {
                codename: 'recaptcha',
                id: 'e8cf9c47-4b32-454c-bb8b-61d4189fe275',
                externalId: undefined,
                name: 'reCAPTCHA',
                required: false,
                type: 'modular_content'
            },

            /**
             * reCAPTCHA Attribute Key (text)
             */
            recaptcha_attribute_key: {
                codename: 'recaptcha_attribute_key',
                id: '5fa3cc9b-ca8a-4a28-8dc6-7bf27c2509e6',
                externalId: undefined,
                name: 'reCAPTCHA Attribute Key',
                required: false,
                type: 'text'
            },

            /**
             * Success Messages (modular_content)
             */
            success_messages: {
                codename: 'success_messages',
                id: 'ecaabd21-ddf6-4fc6-bc89-d3916eb24780',
                externalId: undefined,
                name: 'Success Messages',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Form CTA
     */
    form_cta: {
        codename: 'form_cta',
        id: 'c0b25673-fbae-4cfd-ae4c-554693613130',
        externalId: undefined,
        name: 'Form CTA',
        elements: {
            /**
             * Action Type (multiple_choice)
             */
            form_cta_action_types__action_type: {
                codename: 'form_cta_action_types__action_type',
                id: '0ac6ad5c-e61f-4b61-892d-9ec24fab47e7',
                externalId: undefined,
                name: 'Action Type',
                required: true,
                type: 'multiple_choice',
                options: {
                    oauth_redirect: {
                        name: 'Oauth Redirect',
                        id: '6df84ffa-02a6-4285-9115-51d182f710d6',
                        codename: 'oauth_redirect',
                        externalId: undefined
                    },
                    redirect_to: {
                        name: 'Redirect To',
                        id: '135e2198-fbde-4470-b75a-2190fdfb1a44',
                        codename: 'redirect_to',
                        externalId: undefined
                    },
                    redirect_to_preserve_query: {
                        name: 'Redirect To   Preserve Query',
                        id: 'fdba7c05-b911-4342-8aef-b5ccb477ce8e',
                        codename: 'redirect_to_preserve_query',
                        externalId: undefined
                    },
                    sign_in: {
                        name: 'Sign In',
                        id: '85784166-d1b0-4f82-940d-8901b1d8cd52',
                        codename: 'sign_in',
                        externalId: undefined
                    },
                    next_step: {
                        name: 'Next Step',
                        id: 'bd7552d6-6fbc-4193-a5d5-33a9424bb7d9',
                        codename: 'next_step',
                        externalId: undefined
                    },
                    skip_step: {
                        name: 'Skip Step',
                        id: 'a67436ab-ef17-4acf-ae73-210859563f5d',
                        codename: 'skip_step',
                        externalId: undefined
                    },
                    previous_step: {
                        name: 'Previous Step',
                        id: 'f49583e6-eb2f-4184-a7ec-1432093e93ed',
                        codename: 'previous_step',
                        externalId: undefined
                    },
                    register: {
                        name: 'Register',
                        id: 'cf3ed964-fd21-4979-b707-ecc44cdb3ee9',
                        codename: 'register',
                        externalId: undefined
                    },
                    register_next_step: {
                        name: 'Register   Next Step',
                        id: 'ff58407d-6512-42a3-8d84-30b102f03475',
                        codename: 'register_next_step',
                        externalId: undefined
                    },
                    update: {
                        name: 'Update',
                        id: 'd79f68ea-fdf7-4a4f-8f91-f946d044d520',
                        codename: 'update',
                        externalId: undefined
                    },
                    update_next_step: {
                        name: 'Update   Next Step',
                        id: '7c238b6c-e242-4d6d-98e0-3c54dbe385a7',
                        codename: 'update_next_step',
                        externalId: undefined
                    },
                    update_redirect: {
                        name: 'Update   Redirect',
                        id: 'a9d20aef-7716-48dc-96d0-8cf2e9f83a7c',
                        codename: 'update_redirect',
                        externalId: undefined
                    },
                    forgot_password: {
                        name: 'Forgot Password',
                        id: '7822caba-be39-41ce-98de-b05c7dd7cb23',
                        codename: 'forgot_password',
                        externalId: undefined
                    },
                    reset_password: {
                        name: 'Reset Password',
                        id: 'b744a5dd-a4dd-4dbd-adeb-815049312fa1',
                        codename: 'reset_password',
                        externalId: undefined
                    },
                    new_password_required: {
                        name: 'New Password Required',
                        id: 'cef6a84c-7f68-4836-9874-3250278852ff',
                        codename: 'new_password_required',
                        externalId: undefined
                    },
                    change_password: {
                        name: 'Change Password',
                        id: '4d9b26c4-f11b-4004-85cd-ec700e78fd60',
                        codename: 'change_password',
                        externalId: undefined
                    },
                    verify_email_exists: {
                        name: 'Verify Email Exists',
                        id: 'f588fe22-f966-4cd5-b0e0-6e2ae3bf537f',
                        codename: 'verify_email_exists',
                        externalId: undefined
                    },
                    verify_email_exists_next_step: {
                        name: 'Verify Email Exists   Next Step',
                        id: 'dc485747-42fa-4439-b31c-da7f5edc5a7b',
                        codename: 'verify_email_exists_next_step',
                        externalId: undefined
                    },
                    update_next_step_add_to_address_book: {
                        name: 'Update   Next Step   Add to address book',
                        id: '9d422b6b-6563-4f0d-aee3-4498c1de846e',
                        codename: 'update_next_step_add_to_address_book',
                        externalId: undefined
                    },
                    admin_reset_password: {
                        name: 'Admin Reset Password',
                        id: 'ece7c0ba-96dd-4070-9cfd-2eecc0eacf07',
                        codename: 'admin_reset_password',
                        externalId: undefined
                    },
                    admin_reset_password_next_step: {
                        name: 'Admin Reset Password   Next Step',
                        id: 'eb515978-1977-4cf6-b184-077c1bb5c076',
                        codename: 'admin_reset_password_next_step',
                        externalId: undefined
                    }
                },
                snippetCodename: 'form_cta_action_types'
            },

            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: '6f2e9597-7cf3-4ba3-a512-cf86c35bfea5',
                externalId: undefined,
                name: 'CTA',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * Form Date Field
     */
    form_date_field: {
        codename: 'form_date_field',
        id: '54393d48-cf71-4388-b3b2-9ddba05449e9',
        externalId: undefined,
        name: 'Form Date Field',
        elements: {
            /**
             * Form Field (modular_content)
             */
            form_field: {
                codename: 'form_field',
                id: '9f01493f-6306-4a43-b75e-3f157c0843e7',
                externalId: undefined,
                name: 'Form Field',
                required: true,
                type: 'modular_content'
            },

            /**
             * Minimum Year (number)
             */
            minimum_year: {
                codename: 'minimum_year',
                id: '443e85d8-ae2d-4494-8688-5f9731e2484e',
                externalId: undefined,
                name: 'Minimum Year',
                required: false,
                type: 'number'
            },

            /**
             * Year Offset (number)
             */
            year_offset: {
                codename: 'year_offset',
                id: 'b20443a6-27d2-4a39-bbc3-17e327021422',
                externalId: undefined,
                name: 'Year Offset',
                required: false,
                type: 'number'
            }
        }
    },

    /**
     * Form Field
     */
    form_field: {
        codename: 'form_field',
        id: 'f12d1389-c05f-418f-a2a2-bce67c3d2ec7',
        externalId: undefined,
        name: 'Form Field',
        elements: {
            /**
             * Dependency Criteria (multiple_choice)
             */
            dependency_criteria: {
                codename: 'dependency_criteria',
                id: '8e9a40d9-621f-4de1-8807-9a1cd1466c07',
                externalId: undefined,
                name: 'Dependency Criteria',
                required: false,
                type: 'multiple_choice',
                options: {
                    equals: {
                        name: 'Equals',
                        id: 'a5fdf332-340a-4293-bd24-9cc1f769c5d4',
                        codename: 'equals',
                        externalId: undefined
                    },
                    equals_ignore_case: {
                        name: 'Equals   Ignore Case',
                        id: '78befc03-fa6c-4855-9296-6dbade3a4a63',
                        codename: 'equals_ignore_case',
                        externalId: undefined
                    },
                    not_equals: {
                        name: 'Not Equals',
                        id: '89814d58-dc12-4dd7-9edc-1b2d75d53dd0',
                        codename: 'not_equals',
                        externalId: undefined
                    },
                    not_equals_ignore_case: {
                        name: 'Not Equals   Ignore Case',
                        id: '1fdbc952-1105-4aa9-857c-5f011ae95cef',
                        codename: 'not_equals_ignore_case',
                        externalId: undefined
                    },
                    includes: {
                        name: 'Includes',
                        id: '9c6962be-013b-4e12-a43a-1f7fd4c58021',
                        codename: 'includes',
                        externalId: undefined
                    },
                    includes_ignore_case: {
                        name: 'Includes   Ignore Case',
                        id: 'edb64d82-2612-4485-b657-65cca570d589',
                        codename: 'includes_ignore_case',
                        externalId: undefined
                    },
                    not_includes: {
                        name: 'Not Includes',
                        id: 'f96f2ca1-0a9e-46a6-8543-b926303a2c61',
                        codename: 'not_includes',
                        externalId: undefined
                    },
                    not_includes_ignore_case: {
                        name: 'Not Includes   Ignore Case',
                        id: 'd7c73fab-ec61-4cbb-a5b0-74ba79cea9f6',
                        codename: 'not_includes_ignore_case',
                        externalId: undefined
                    }
                }
            },

            /**
             * Dependency Validation Message (text)
             */
            dependency_validation_message: {
                codename: 'dependency_validation_message',
                id: '318117a2-b84d-4d6d-94bd-a34f7e915a00',
                externalId: undefined,
                name: 'Dependency Validation Message',
                required: false,
                type: 'text'
            },

            /**
             * Depends On (modular_content)
             */
            depends_on: {
                codename: 'depends_on',
                id: '5c28a609-aa2f-4542-9cf5-34f41f657126',
                externalId: undefined,
                name: 'Depends On',
                required: false,
                type: 'modular_content'
            },

            /**
             * Field Replacement (modular_content)
             */
            field_replacement: {
                codename: 'field_replacement',
                id: '5ba341f5-1262-4932-b477-b369426eb7ca',
                externalId: undefined,
                name: 'Field Replacement',
                required: false,
                type: 'modular_content'
            },

            /**
             * Id (text)
             *
             * THIS VALUE IS USED AS THE UNIQUE FIELD ID IN CODE! CHANGING IT WITHOUT APPROPRIATE CODE ADJUSTMENTS WILL CAUSE AN APPLICATION CRASH
             */
            id: {
                codename: 'id',
                id: 'c835c18b-c327-4bde-8091-9fc06da51207',
                externalId: undefined,
                name: 'Id',
                required: true,
                type: 'text'
            },

            /**
             * Is ReadOnly (multiple_choice)
             */
            is_readonly: {
                codename: 'is_readonly',
                id: '7181d7a5-64f4-4e7d-bb17-412347bc89b8',
                externalId: undefined,
                name: 'Is ReadOnly',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '556f906c-965d-45bf-9fb4-6db758ac7fd0',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '071ccae3-1c03-44ed-9cb8-a36da195495d',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Is Required (multiple_choice)
             */
            is_required: {
                codename: 'is_required',
                id: 'ae8e1d36-7dea-4055-a6c4-a690d89dbc20',
                externalId: undefined,
                name: 'Is Required',
                required: true,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'b3b52f2a-295d-4c2e-9564-84d686d15eaa',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '9cc94c7b-71d8-440f-bb2e-630e1d6f51f6',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: '6a63f005-3a03-4e64-bebb-14a065e87fc8',
                externalId: undefined,
                name: 'Label',
                required: false,
                type: 'text'
            },

            /**
             * Options (modular_content)
             *
             * If the field is of type 'Checkboxes', 'Dropdown' or 'Radio Buttons' you should add here the desired options to be displayed
             */
            options: {
                codename: 'options',
                id: '4f64d5d2-913f-4c59-96c5-095d3c06c4eb',
                externalId: undefined,
                name: 'Options',
                required: false,
                type: 'modular_content'
            },

            /**
             * Placeholder (text)
             */
            placeholder: {
                codename: 'placeholder',
                id: '18448dcf-5a88-4721-8aaa-593b10afe2f8',
                externalId: undefined,
                name: 'Placeholder',
                required: false,
                type: 'text'
            },

            /**
             * Predefined Options List (multiple_choice)
             */
            predefined_options_list: {
                codename: 'predefined_options_list',
                id: 'e5cbd144-aef1-4bf1-b5bb-c34889eb89b9',
                externalId: undefined,
                name: 'Predefined Options List',
                required: false,
                type: 'multiple_choice',
                options: {
                    day_options: {
                        name: 'Day Options',
                        id: '10fd3fe5-7f0a-462e-838d-b6ff403a3087',
                        codename: 'day_options',
                        externalId: undefined
                    },
                    month_options: {
                        name: 'Month Options',
                        id: 'a24c8687-53b8-4c11-aa80-61a24aa119d9',
                        codename: 'month_options',
                        externalId: undefined
                    },
                    year_options: {
                        name: 'Year Options',
                        id: 'b2386efb-0d1e-4819-af51-bc5b7bdab7c5',
                        codename: 'year_options',
                        externalId: undefined
                    }
                }
            },

            /**
             * Subfields (modular_content)
             */
            subfields: {
                codename: 'subfields',
                id: '31753ce2-ac1b-4022-9e2d-bff26a490757',
                externalId: undefined,
                name: 'Subfields',
                required: false,
                type: 'modular_content'
            },

            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: '111add63-53c1-4ab8-ac8a-ba8920629660',
                externalId: undefined,
                name: 'Type',
                required: true,
                type: 'multiple_choice',
                options: {
                    text: {
                        name: 'Text',
                        id: 'a22ae5b2-4ea6-4df2-aa3a-f388c941c819',
                        codename: 'text',
                        externalId: undefined
                    },
                    email: {
                        name: 'Email',
                        id: '46954722-62a9-432b-b25b-b87b4051b534',
                        codename: 'email',
                        externalId: undefined
                    },
                    password: {
                        name: 'Password',
                        id: '6ff7e630-5d0f-4626-81a0-24a9a42efbe7',
                        codename: 'password',
                        externalId: undefined
                    },
                    password_confirmation: {
                        name: 'Password Confirmation',
                        id: '95d49a93-0b75-47bb-96df-3e929bf27703',
                        codename: 'password_confirmation',
                        externalId: undefined
                    },
                    phone_number: {
                        name: 'Phone Number',
                        id: 'ca384015-bdac-4095-8411-41d7b8ca5288',
                        codename: 'phone_number',
                        externalId: undefined
                    },
                    date: {
                        name: 'Date',
                        id: '4ec432af-1b34-466b-9399-5e47946086ee',
                        codename: 'date',
                        externalId: undefined
                    },
                    number: {
                        name: 'Number',
                        id: '0bd70ab9-1482-441d-b006-24e39241a0e3',
                        codename: 'number',
                        externalId: undefined
                    },
                    dropdown: {
                        name: 'Dropdown',
                        id: '4117fb1b-979c-418e-8f6f-d1c1e647e9a3',
                        codename: 'dropdown',
                        externalId: undefined
                    },
                    checkboxes: {
                        name: 'Checkboxes',
                        id: '87270dd8-57f7-40d1-9b82-902134a9ead9',
                        codename: 'checkboxes',
                        externalId: undefined
                    },
                    radio_buttons: {
                        name: 'Radio Buttons',
                        id: '6b646d40-1c44-4fd5-967d-a5a25791efbb',
                        codename: 'radio_buttons',
                        externalId: undefined
                    },
                    country: {
                        name: 'Country',
                        id: '7bb2c21e-e3e9-4b32-9127-9f47fce8dce5',
                        codename: 'country',
                        externalId: undefined
                    },
                    mastercard: {
                        name: 'Mastercard',
                        id: 'e43c4f5a-d7d6-4720-8480-d085cbb36d90',
                        codename: 'mastercard',
                        externalId: undefined
                    }
                }
            },

            /**
             * Validation Message (text)
             */
            validation_message: {
                codename: 'validation_message',
                id: 'b8726d44-c52d-4246-8a83-5a581e2e0189',
                externalId: undefined,
                name: 'Validation Message',
                required: false,
                type: 'text'
            },

            /**
             * Validation Regex (text)
             */
            validation_regex: {
                codename: 'validation_regex',
                id: '64b9ddb7-a200-4ef5-9751-6f485715784d',
                externalId: undefined,
                name: 'Validation Regex',
                required: false,
                type: 'text'
            },

            /**
             * Value From Query Param (text)
             */
            value_from_query_param: {
                codename: 'value_from_query_param',
                id: '603b8f16-b55f-4eb8-a2ae-375ec886ca03',
                externalId: undefined,
                name: 'Value From Query Param',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Form Footer
     */
    form_footer: {
        codename: 'form_footer',
        id: '6891c8b1-5ab4-414c-bd06-523415dfb03e',
        externalId: undefined,
        name: 'Form Footer',
        elements: {
            /**
             * Footer Text Color (modular_content)
             */
            footer_text_color: {
                codename: 'footer_text_color',
                id: '89fbbba1-5c1b-47ef-9097-836d1f6fd1ba',
                externalId: undefined,
                name: 'Footer Text Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Forgot Password (rich_text)
             */
            forgot_password: {
                codename: 'forgot_password',
                id: 'e86ed1a9-4a49-4148-99db-b4fd088ea9ee',
                externalId: undefined,
                name: 'Forgot Password',
                required: false,
                type: 'rich_text'
            },

            /**
             * Privacy Policy (rich_text)
             */
            privacy_policy: {
                codename: 'privacy_policy',
                id: 'bac41ccb-8766-41df-86cd-2279633f3114',
                externalId: undefined,
                name: 'Privacy Policy',
                required: false,
                type: 'rich_text'
            },

            /**
             * Terms and Conditions (rich_text)
             */
            terms_and_conditions: {
                codename: 'terms_and_conditions',
                id: '3655f9cb-07e1-4196-bad2-a05b52b8eb9b',
                externalId: undefined,
                name: 'Terms and Conditions',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     * Form Header
     */
    form_header: {
        codename: 'form_header',
        id: '3ff90220-c1a7-4021-82ae-6bb3002cf074',
        externalId: undefined,
        name: 'Form Header',
        elements: {
            /**
             * CTAs (modular_content)
             */
            ctas: {
                codename: 'ctas',
                id: '053a27ab-8c40-4821-9ba1-2ca69571d9ba',
                externalId: undefined,
                name: 'CTAs',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subtitle (rich_text)
             */
            subtitle: {
                codename: 'subtitle',
                id: 'd68191f4-5e0c-4c68-adc1-74f2f68bfcce',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'rich_text'
            },

            /**
             * Subtitle Color (modular_content)
             */
            subtitle_color: {
                codename: 'subtitle_color',
                id: '6812f863-ffe3-4fa2-b4b4-5e04987469a3',
                externalId: undefined,
                name: 'Subtitle Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '2b5de6b7-56a0-4c7f-bbdb-51df0c0648d3',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Title Color (modular_content)
             */
            title_color: {
                codename: 'title_color',
                id: '0e9fbadb-1f45-49c9-b3b2-4ddc47f52571',
                externalId: undefined,
                name: 'Title Color',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Form Page
     */
    form_page: {
        codename: 'form_page',
        id: '911eeb26-72a9-42e1-9cab-c4db68d02d54',
        externalId: undefined,
        name: 'Form Page',
        elements: {
            /**
             * Background Color (modular_content)
             */
            background_color: {
                codename: 'background_color',
                id: 'e6633697-4de7-4065-bc08-09b24e0819ba',
                externalId: undefined,
                name: 'Background Color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Desktop Image (asset)
             */
            desktop_image: {
                codename: 'desktop_image',
                id: '1c3ab3b5-a834-48c3-88d0-012d30f07aad',
                externalId: undefined,
                name: 'Desktop Image',
                required: true,
                type: 'asset'
            },

            /**
             * Form (modular_content)
             */
            form: {
                codename: 'form',
                id: '4ba013ff-4639-4459-8e8b-fab4a9a5f664',
                externalId: undefined,
                name: 'Form',
                required: true,
                type: 'modular_content'
            },

            /**
             * Mobile Image (asset)
             */
            mobile_image: {
                codename: 'mobile_image',
                id: '462933ae-9113-4c3b-9c99-de8140898ae0',
                externalId: undefined,
                name: 'Mobile Image',
                required: true,
                type: 'asset'
            },

            /**
             * SEO Data (modular_content)
             */
            seo_data: {
                codename: 'seo_data',
                id: 'db0d5bf4-b15c-4d7e-8221-02a22d924dc3',
                externalId: undefined,
                name: 'SEO Data',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * Form Step
     */
    form_step: {
        codename: 'form_step',
        id: '5f436df5-d814-4f6b-9afa-b6f5eefe702d',
        externalId: undefined,
        name: 'Form Step',
        elements: {
            /**
             * Audio (asset)
             *
             * This audio will play after the user completes the current step.
             */
            audio: {
                codename: 'audio',
                id: '2c30930e-4f3a-494d-9ef4-edb32adebc99',
                externalId: undefined,
                name: 'Audio',
                required: false,
                type: 'asset'
            },

            /**
             * CTAs (modular_content)
             */
            ctas: {
                codename: 'ctas',
                id: '8b12a3c2-6529-4c77-8f85-4d25268df1fc',
                externalId: undefined,
                name: 'CTAs',
                required: false,
                type: 'modular_content'
            },

            /**
             * Custom Header (modular_content)
             *
             * If nothing is selected, the general header of the form will be displayed
             */
            custom_header: {
                codename: 'custom_header',
                id: '5b643c88-7904-400d-9e99-d255a4456a0d',
                externalId: undefined,
                name: 'Custom Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * Form Fields (modular_content)
             */
            form_fields: {
                codename: 'form_fields',
                id: '41a3e157-abf9-4788-956a-27c244ce541e',
                externalId: undefined,
                name: 'Form Fields',
                required: false,
                type: 'modular_content'
            },

            /**
             * Rich Text Items (modular_content)
             */
            rich_text_items: {
                codename: 'rich_text_items',
                id: '95f12e4f-cd7c-4989-87fc-afba4a7c9065',
                externalId: undefined,
                name: 'Rich Text Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Show Step Counter (multiple_choice)
             */
            show_step_counter: {
                codename: 'show_step_counter',
                id: 'e72ded4a-3706-468f-8522-7c22dcbb04e8',
                externalId: undefined,
                name: 'Show Step Counter',
                required: true,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'de4f0275-86a5-48cc-b340-ac31abc12d5e',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '12b6dccd-7b48-46c2-b725-9e0b39e28fcb',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     * Logo
     */
    logo: {
        codename: 'logo',
        id: '7a07d308-e058-4125-b274-1cb1ba9ca122',
        externalId: undefined,
        name: 'Logo',
        elements: {
            /**
             * External Link (text)
             */
            external_link: {
                codename: 'external_link',
                id: '872fd09b-740e-4068-bc71-d6df53c721a7',
                externalId: undefined,
                name: 'External Link',
                required: false,
                type: 'text'
            },

            /**
             * Logo Image (asset)
             */
            logo_image: {
                codename: 'logo_image',
                id: '491e3cfe-9c22-4103-bfdb-bf77e3f059ed',
                externalId: undefined,
                name: 'Logo Image',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     * No Access Page
     */
    no_access_page: {
        codename: 'no_access_page',
        id: 'bbda22f1-925f-4ed0-b0be-6aa1297397b6',
        externalId: undefined,
        name: 'No Access Page',
        elements: {
            /**
             * Header (text)
             */
            header: {
                codename: 'header',
                id: '2f090437-fa47-41f2-84f9-a26a91026b82',
                externalId: undefined,
                name: 'Header',
                required: false,
                type: 'text'
            },

            /**
             * Logos (modular_content)
             */
            logos: {
                codename: 'logos',
                id: 'c21dbd4e-4559-455c-a142-1f42221251ac',
                externalId: undefined,
                name: 'Logos',
                required: false,
                type: 'modular_content'
            },

            /**
             * Side Image (asset)
             */
            side_image: {
                codename: 'side_image',
                id: 'd6433664-e1f5-41a8-9fd4-d1fcb06ba410',
                externalId: undefined,
                name: 'Side Image',
                required: false,
                type: 'asset'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: 'da9b6bb0-93c5-4a52-a25e-0a1a5d9f0870',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'cce27133-aaef-444d-a4d0-22cec370487a',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Option
     */
    option: {
        codename: 'option',
        id: '407fbfab-95ef-49f7-9cf3-2d98ad9e2538',
        externalId: undefined,
        name: 'Option',
        elements: {
            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: '34fdd9c4-5409-41d1-8d47-c72732536eb6',
                externalId: undefined,
                name: 'Label',
                required: false,
                type: 'text'
            },

            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: 'feba2a7c-a1bf-4d64-bb4b-6f9e7801e4f4',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Option with Rich Text
     */
    option_with_rich_text: {
        codename: 'option_with_rich_text',
        id: '0f9b5862-aec1-45f4-99da-341cd9bd3dc4',
        externalId: undefined,
        name: 'Option with Rich Text',
        elements: {
            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: '27db1cf3-6837-463a-adf5-23664e493314',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: '0cf7d6cb-feee-447e-875b-b3d105aeb528',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Player
     */
    county__copy_: {
        codename: 'county__copy_',
        id: 'e7786276-c95a-43c4-9c27-126725ed35de',
        externalId: undefined,
        name: 'Player',
        elements: {
            /**
             * Name (text)
             */
            name: {
                codename: 'name',
                id: 'f5419e7d-052f-403e-a43c-6b687c18b41f',
                externalId: undefined,
                name: 'Name',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Query Param
     */
    query_param: {
        codename: 'query_param',
        id: '98ef1cfb-9086-49a7-997f-87bbb207dca8',
        externalId: undefined,
        name: 'Query Param',
        elements: {
            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: 'f7c0fbfb-c31f-44da-9f81-a5aebcaadccc',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Rich Text with Asset
     */
    rich_text_with_asset: {
        codename: 'rich_text_with_asset',
        id: '8babf320-c16a-4407-95b1-025131c74965',
        externalId: undefined,
        name: 'Rich Text with Asset',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '06c41a21-7e98-4b2e-8b22-5c0b97dc7653',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Desktop Image (asset)
             */
            desktop_image: {
                codename: 'desktop_image',
                id: '09a18e64-5964-4349-b8b6-8dcb1b7cd1de',
                externalId: undefined,
                name: 'Desktop Image',
                required: false,
                type: 'asset'
            },

            /**
             * Mobile Image (asset)
             */
            mobile_image: {
                codename: 'mobile_image',
                id: '555018d1-5ae0-41dd-8d4a-110dba3888f7',
                externalId: undefined,
                name: 'Mobile Image',
                required: false,
                type: 'asset'
            },

            /**
             * Url (text)
             */
            url: {
                codename: 'url',
                id: '6e3c1aee-ca5c-45d4-9d25-f55297d94a21',
                externalId: undefined,
                name: 'Url',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Rich Text with Asset Container
     */
    rich_text_with_asset_container: {
        codename: 'rich_text_with_asset_container',
        id: '7b88f3f2-68d1-4e6b-abf4-f163de91f819',
        externalId: undefined,
        name: 'Rich Text with Asset Container',
        elements: {
            /**
             * Rich Text with Asset (modular_content)
             */
            rich_text_with_asset: {
                codename: 'rich_text_with_asset',
                id: '1ca030c4-dc54-457a-bab9-cc219d2ac43e',
                externalId: undefined,
                name: 'Rich Text with Asset',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * SEO Data
     */
    seo_data: {
        codename: 'seo_data',
        id: '22c00822-fc81-4deb-8eda-7a9016f55069',
        externalId: undefined,
        name: 'SEO Data',
        elements: {
            /**
             * Key Words (text)
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '2c7c7857-24a4-43ff-9b7c-f9004b3775f2',
                externalId: undefined,
                name: 'Key Words',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'b3c70fb6-1294-4878-a0c9-c118179e780e',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'd9a0c69e-781b-4757-b0c7-ee9b1c89c39a',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text',

                snippetCodename: 'seo'
            },

            /**
             * No Follow (multiple_choice)
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: 'e6e86212-2718-40c6-b044-8939976f0a9e',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '64bdb13b-becc-4510-a53f-b0472072ec2e',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'ba407485-4b99-4f1b-bbdb-dc039c3d52ff',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * No Index (multiple_choice)
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'bb27d4d7-60c6-4f13-b3d7-0a6b5e1df026',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'bc6cb0cd-d01c-4a2e-b739-93d6b88a283f',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '98d5cdc7-c732-44a8-8cef-cc584d1b11d7',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'seo'
            },

            /**
             * Required Query Params (modular_content)
             */
            required_query_params: {
                codename: 'required_query_params',
                id: '48ad9246-0395-4608-b6a7-023a0fdda0ad',
                externalId: undefined,
                name: 'Required Query Params',
                required: false,
                type: 'modular_content'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '5ab4e61d-c763-4e32-87eb-f5d771e7e808',
                externalId: undefined,
                name: 'URL Slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     * SSO Configuration
     */
    sso_configuration: {
        codename: 'sso_configuration',
        id: '450d5b9a-1873-4d87-8bd5-02abacb9e444',
        externalId: undefined,
        name: 'SSO Configuration',
        elements: {
            /**
             * Default Form Pages for Slugs (modular_content)
             *
             * choose one form page for each available URL Slug
             */
            default_form_pages_for_slugs: {
                codename: 'default_form_pages_for_slugs',
                id: '410b330e-ef4e-4b97-8ba4-3003d97fa1d0',
                externalId: undefined,
                name: 'Default Form Pages for Slugs',
                required: false,
                type: 'modular_content'
            },

            /**
             * Member required attributes (modular_content)
             */
            member_required_attributes: {
                codename: 'member_required_attributes',
                id: '457a07d4-1beb-40a9-a1eb-36c070ca815b',
                externalId: undefined,
                name: 'Member required attributes',
                required: false,
                type: 'modular_content'
            },

            /**
             * User Journey Mappings (modular_content)
             */
            user_journey_mappings: {
                codename: 'user_journey_mappings',
                id: '31d21225-f53e-4d3d-8442-a1fa760e5bf7',
                externalId: undefined,
                name: 'User Journey Mappings',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * State
     */
    state: {
        codename: 'state',
        id: '6646e7bd-6712-4bae-a06e-86e72e2dcfb6',
        externalId: undefined,
        name: 'State',
        elements: {
            /**
             * Code (text)
             */
            code: {
                codename: 'code',
                id: 'c6295ba1-f882-41d1-bcc3-8db10a72687e',
                externalId: undefined,
                name: 'Code',
                required: false,
                type: 'text'
            },

            /**
             * Name (text)
             */
            name: {
                codename: 'name',
                id: 'd27c2cce-c9f2-493c-9f11-b59130d57fa0',
                externalId: undefined,
                name: 'Name',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * TestSSOFormField
     */
    testssoformfield: {
        codename: 'testssoformfield',
        id: '26783b34-b27c-412e-ab5f-ce7b9228897b',
        externalId: undefined,
        name: 'TestSSOFormField',
        elements: {
            /**
             * Is Mandatory (multiple_choice)
             */
            is_mandatory: {
                codename: 'is_mandatory',
                id: '793b365c-144d-4390-a54d-28d735c928ac',
                externalId: undefined,
                name: 'Is Mandatory',
                required: true,
                type: 'multiple_choice',
                options: {
                    mandatory: {
                        name: 'Mandatory',
                        id: 'fb4aa54f-7691-42ed-af9a-4585460f6a63',
                        codename: 'mandatory',
                        externalId: undefined
                    }
                }
            },

            /**
             * Label (text)
             */
            label: {
                codename: 'label',
                id: '447b5b27-0bcd-437e-81ff-d2f2a7e12b72',
                externalId: undefined,
                name: 'Label',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     * Text Item
     */
    text_item: {
        codename: 'text_item',
        id: '7049744c-eab7-490d-b0f2-21f935738034',
        externalId: undefined,
        name: 'Text Item',
        elements: {
            /**
             * Value (text)
             */
            value: {
                codename: 'value',
                id: '98d375ba-b131-4827-be84-4215f6ba6f55',
                externalId: undefined,
                name: 'Value',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * User Journey
     */
    user_journey: {
        codename: 'user_journey',
        id: '079b9710-beea-42d2-8b0b-2add69815bfa',
        externalId: undefined,
        name: 'User Journey',
        elements: {
            /**
             * Client ID (modular_content)
             */
            client_id: {
                codename: 'client_id',
                id: '630b9b21-d3da-47b4-bdf6-f77c93438fdc',
                externalId: undefined,
                name: 'Client ID',
                required: true,
                type: 'modular_content'
            },

            /**
             * User Journey Type (modular_content)
             */
            user_journey_type: {
                codename: 'user_journey_type',
                id: 'a3d1299e-a278-4998-bef6-002d7db892b4',
                externalId: undefined,
                name: 'User Journey Type',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * User Journey Mapping
     */
    user_journey_mapping: {
        codename: 'user_journey_mapping',
        id: '239eb499-db53-4e42-bccc-483b9b419431',
        externalId: undefined,
        name: 'User Journey Mapping',
        elements: {
            /**
             * Form Page (modular_content)
             */
            form_page: {
                codename: 'form_page',
                id: 'cee09005-afc8-4a6f-9170-da2e65951157',
                externalId: undefined,
                name: 'Form Page',
                required: true,
                type: 'modular_content'
            },

            /**
             * User Journey (modular_content)
             */
            user_journey: {
                codename: 'user_journey',
                id: '9764920a-1ae4-402c-a948-f230976b9112',
                externalId: undefined,
                name: 'User Journey',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     * User Journey Type
     */
    user_journey_type: {
        codename: 'user_journey_type',
        id: 'fcf9fdfe-b5f7-457d-88bc-ebafadbe6386',
        externalId: undefined,
        name: 'User Journey Type',
        elements: {
            /**
             * Type (multiple_choice)
             */
            type: {
                codename: 'type',
                id: 'b866315e-4194-4f31-bcb6-e92811e2dac4',
                externalId: undefined,
                name: 'Type',
                required: false,
                type: 'multiple_choice',
                options: {
                    default: {
                        name: 'Default',
                        id: '2773d432-6cb4-4926-aad8-804d12721aab',
                        codename: 'default',
                        externalId: undefined
                    },
                    ticketing: {
                        name: 'Ticketing',
                        id: 'bf8d526b-1072-49fe-8d72-0da6d7a92b09',
                        codename: 'ticketing',
                        externalId: undefined
                    },
                    woticketing: {
                        name: 'WOTicketing',
                        id: '50efd203-6bfc-4a9c-8cc9-80e14141bb40',
                        codename: 'woticketing',
                        externalId: undefined
                    },
                    theopenballot: {
                        name: 'TheOpenBallot',
                        id: '902f3ee1-361d-472b-8087-822040fbd624',
                        codename: 'theopenballot',
                        externalId: undefined
                    }
                }
            }
        }
    }
} as const;
