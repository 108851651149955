import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstMappedLinkedItemOrDefault,
  getMappedItem,
} from './utils/component-mapping-helpers';
import {
  getFirstLinkedItemOrDefault,
  getSingleChoiceValue,
  getTrueFalseSingleChoiceValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const article_highlight = (entry: MEMBERSHIP_MODELS.Article) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;
  const author = getFirstMappedLinkedItemOrDefault(elements.author);

  const seo = {
    title: elements?.seo__meta_title?.value,
    description: elements?.seo__meta_description?.value,
    keywords: elements?.seo__key_words?.value,
    noindex: getTrueFalseSingleChoiceValue(elements?.seo__no_index),
    nofollow: getTrueFalseSingleChoiceValue(elements?.seo__no_follow),
    urlSlug: elements?.url_slug?.value,
  };
  return {
    author: `${author.name} ${author.surname}`,
    publishDate: elements?.publish_date?.value,
    title: elements?.title?.value,
    subtitle: elements?.subtitle?.value,
    primaryImage: getMappedItem(
      getFirstLinkedItemOrDefault(elements.primary_image),
    ),
    seo,
    access: getSingleChoiceValue(
      elements?.membership_based_access__select_access,
    ),
  };
};

export default article_highlight;
