import { ResponseModel, getQueryParamByKey } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { ResetPasswordInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { execStoreTokensMutation } from "../form-action-helpers";
import { OauthQuery, handleCompleteForgotPasswordRedirect } from '@r-and-a-shared-ui/auth-client';

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {
  
  //Set additional data for the mutation
  params.submitData.clientId = getQueryParamByKey(OauthQuery.CLIENT_ID);
  params.submitData.redirectUri = getQueryParamByKey(OauthQuery.REDIRECT_URI);
  params.submitData.authorizationCode = getQueryParamByKey(OauthQuery.AUTHORIZATION_CODE);

  const resultData = await genericMutationHandlerWithFormValidation(
    params,
    ResetPasswordInputSchema,
  );
  
  if(resultData?.hasErrors) {
    return resultData;
  }
  
  params.data = {
    username: params?.submitData?.email,
    password: params?.submitData?.password
  };
  
  const storeTokensMutationResult = await execStoreTokensMutation(params);
  
  if (storeTokensMutationResult?.hasErrors) {
    return resultData;
  }
  
  handleCompleteForgotPasswordRedirect();
  
  return resultData;
};

export default handle;