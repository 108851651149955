/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from './RichTextWithAssetContainer.module.scss';
import { hasValue } from '@r-and-a-shared-ui/utils';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';

/* eslint-disable-next-line */
export interface RichTextWithAssetContainerProps {
  richTextWithAssetElements: any[]
}

export function RichTextWithAssetContainer(
  props: RichTextWithAssetContainerProps,
) {
  const { richTextWithAssetElements } = props;
  if (!hasValue(richTextWithAssetElements)) {
    return undefined;
  }

  return (
    <div className={styles['wrapper']}>
      {richTextWithAssetElements.map((richTextElement, index) => (
        <AsyncComponent
          key={richTextElement.system.id}
          kontentItem={richTextElement}
          contentType={richTextElement.system.type}
        />
      ))}
    </div>
  );
}

export default RichTextWithAssetContainer;
