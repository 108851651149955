import Image, { StaticImageData } from 'next/image';
import { ReactNode } from 'react';

declare const VALID_LOADING_VALUES: readonly ["lazy", "eager", undefined];
declare type LoadingValue = typeof VALID_LOADING_VALUES[number];

export const NextJSCustomImage = ({
  src,
  width,
  height,
  alt,
  children = null,
  className = '',
  priority = false,
  sizes = undefined,
  fill = undefined,
  loading = 'lazy',
}: {
  src: string | StaticImageData;
  width?: number | `${number}` | undefined;
  height?: number | `${number}` | undefined;
  alt: string;
  children?: ReactNode;
  className?: string;
  priority?: boolean;
  sizes?: string | undefined;
  fill?: boolean | undefined;
  loading?: LoadingValue;
}) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      priority={priority}
      sizes={sizes}
      fill={fill}
      loading={loading}
    />
  );
};
