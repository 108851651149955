import { deliveryClient } from '../config/kontent-delivery-client';
import { MEMBERSHIP_MODELS } from '../models';
import { getItemWithLinkedItemsByCodename } from './get-item-with-linked-items-by-codename';
import { adjustSystemProperties, getDynamicThreshold } from '../utils';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';

type GetFullRewardBySlugResponse = {
  reward: MEMBERSHIP_MODELS.Reward;
  template: MEMBERSHIP_MODELS.PageTemplate | undefined;
};

export const getFullRewardBySlug = async (
  slug: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
) => {
  const cacheKey = `getFullRewardBySlug-rewards-and-benefits/${slug}`;

  try {
    const cachedItem = getCachedItem<GetFullRewardBySlugResponse>(cacheKey, cache);
  
    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const item = await deliveryClient
      .items<MEMBERSHIP_MODELS.Reward>()
      .type('reward')
      .equalsFilter('elements.url_slug', slug)
      .depthParameter(0)
      .toPromise()
      .then(r => r.data.items[0]);

    adjustSystemProperties(item);

    if (!item || !item?.system.codename) {
      return undefined;
    }

    const [reward, template] = await Promise.all([
      getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.Reward>({
        codename: item.system.codename,
        depthParameter: 3,
        cache,
        cacheTTL: CacheConfig.TTL.PageContent,
      }),
      getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.PageTemplate>({
        codename: item.elements['template'].value[0],
        depthParameter: 3,
        cache,
        cacheTTL: CacheConfig.TTL.PageTemplate,
      }),
    ]);
    const dynamicThreshold = getDynamicThreshold();

    const bodyPromises: Promise<any>[] = [];
    const bodyItems = reward?.elements?.more_content?.linkedItems;
    bodyItems?.forEach((bodyItem, index) => {
      if (index < dynamicThreshold) {
        bodyPromises.push(
          getItemWithLinkedItemsByCodename({
            codename: bodyItem.system.codename,
            depthParameter: 3,
            cache,
            cacheTTL: CacheConfig.TTL.PageContent,
          }),
        );
        return;
      }
      bodyPromises.push(Promise.resolve(bodyItem));
    });
    const body = await Promise.all(bodyPromises);

    const parsedReward = {
      ...reward,
      elements: {
        ...reward?.elements,
        more_content: {
          ...reward?.elements?.more_content,
          linkedItems: body,
        },
      },
    } as MEMBERSHIP_MODELS.Reward;

    const response = {
      reward: parsedReward,
      template,
    };

    setCacheItem<GetFullRewardBySlugResponse>(cacheKey, response, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return response;
  } catch (error) {
    console.error('Error in getFullRewardBySlug', error);
    throw error;
  }
};

