import { hasValue, isNullOrUndefined } from "..";

export const getParameterByName = (name?: string, url?: string) => {
  if (!name) {
    return undefined;
  }

  if (!url) {
    url = window.location.href;
  }
  
  name = name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return undefined;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const removeParameterByName = (name?: string) => {
  if (isNullOrUndefined(name)) {
    return;
  }

  const url = new URL(window.location.toString());
  const params = new URLSearchParams(url.search);
  params.delete(name);
  url.search = params.toString();
  window.history.replaceState({}, '', url);
};

export const getParametersByNames = (keys: string[]): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  if(!hasValue(keys)) {
    return {};
  }

  keys.forEach(key => {
    const paramValue = getParameterByName(key);

    if (paramValue) {
      result[key] = paramValue;
    }
  });

  return result;
};

export const getQueryParamByKey = (key: string): string => {
  const queryStringParams = new URLSearchParams(window.location.search);
  const param = queryStringParams.get(key);

  return param ?? '';
};

export const hasRequiredParams = (formPageItem: any): boolean => {
  if(!formPageItem) {
    return true;
  }

  const seoData = formPageItem.data?.elements?.seo_data?.linkedItems;

  const requiredQueryParams = seoData?.[0]?.elements?.required_query_params?.value;
  
  if(!requiredQueryParams) {
    return true;
  }

  let valid = true;

  requiredQueryParams?.forEach((queryKey: string) => {
    if(!hasValue(getQueryParamByKey(queryKey))) {
      valid = false;
    }
  });
  return valid;
};