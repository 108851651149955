import * as Auth from 'aws-amplify/auth';
import { ResponseModel, isNullOrUndefined } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { NewPasswordRequiredInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { signIn } from "./sign-in-handler";
import { execStoreTokensMutation } from "../form-action-helpers";
import { z } from "zod";
import { NextStepsAfterSignIn, TEMP_PASSWORD } from "../../constants";
import { handleOauth2Redirect, hasRequiredFieldsForMember } from '@r-and-a-shared-ui/auth-client';
import { handleSignInNextSteps } from '../../helpers/sign-in-helpers';

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {
  if (isNullOrUndefined(params?.submitData?.oldPassword)) {
    params.submitData.oldPassword = params?.submitData?.[TEMP_PASSWORD] || '';
  }

  const resultData = await genericMutationHandlerWithFormValidation(
    params,
    NewPasswordRequiredInputSchema,
  );
  
  if(resultData?.hasErrors) {
    return resultData;
  }
  
  params.data = {
    username: params?.submitData?.email,
    password: params?.submitData?.oldPassword
  };

  if (
    resultData.data?.isSignedIn &&
    !await hasRequiredFieldsForMember(params?.memberRequiredAttributes)
  ) {
    if (handleSignInNextSteps(params, NextStepsAfterSignIn.MISSING_REQUIRED_MEMBER_FIELDS)) {
      return resultData;
    }
  }
  
  const storeTokensMutationResult = await execStoreTokensMutation(params);
  
  if (storeTokensMutationResult?.hasErrors) {
    return resultData;
  }
  
  handleOauth2Redirect();

  return resultData;
};
  
export const confirmSignInWithNewPasswordRequired = async (
  input: z.infer<typeof NewPasswordRequiredInputSchema>
) => {
  const confirmSignInInput: Auth.ConfirmSignInInput = {
    challengeResponse: input?.password
  };
  
  const params: ActionHandlerParams = {
    data: {
      username: input?.email,
      password: input?.oldPassword
    }, useWholeURL : false
  };
  
  const signInResponse = await signIn(params);

  if (signInResponse?.nextStepAfterSignIn !== NextStepsAfterSignIn.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED) {
    return signInResponse;
  }
  
  return await Auth.confirmSignIn(confirmSignInInput);
};

export default handle;