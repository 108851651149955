import { CSSProperties } from 'react';
import ContentList from '../ContentList/ContentList';
import { ListDisplay } from '../ContentList/ContentList.types';
import styles from './Carousel.module.scss';
import { HeroTileProps } from './HeroTile/HeroTile';

export interface CarouselHeroProps {
  heroItems: HeroTileProps[];
  backgroundColor?: string;
  sliderChangeTime?: number | null;
  sliderTimeout?: number | null;
  infiniteLoop?: boolean;
  codename?: string;
}

export function CarouselHero({
  heroItems,
  backgroundColor,
  sliderChangeTime,
  sliderTimeout,
  infiniteLoop,
}: CarouselHeroProps) {
  const inlineStyle: CSSProperties = {
    backgroundColor,
  };

  const carouselNavBarText = (activeIndex: number) =>
    heroItems ? (
      <div>
        <p className={styles['carousel-hero__bar-label']}>
          {heroItems[activeIndex].type
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              heroItems[activeIndex].type!.toUpperCase().replace(/_/g, ' ')
            : ''}
        </p>
        <p className={styles['carousel-hero__bar-text']}>
          {heroItems[activeIndex].title || ''}
        </p>
      </div>
    ) : null;

  const mobileNavBar = (activeIndex: number) =>
    heroItems?.length ? (
      <p className={styles['carousel-hero__bar-text-mobile']}>
        Next:{' '}
        {
          heroItems[heroItems.length - 1 === activeIndex ? 0 : ++activeIndex]
            .title
        }
      </p>
    ) : null;

  return (
    <div
      data-testid="carousel-hero"
      className={styles['carousel-hero']}
      style={inlineStyle}
    >
      {!!heroItems?.length && (
        <ContentList
          type="slider"
          elements={heroItems}
          listDisplay={ListDisplay.HERO_LIST}
          carouselConfig={{
            autoPlay: !!sliderChangeTime,
            autoplayTimer: sliderChangeTime,
            autoplayTimeout: sliderTimeout,
            customNavBar: carouselNavBarText,
            extraNavChild: mobileNavBar,
            infiniteLoop: infiniteLoop,
          }}
        />
      )}
    </div>
  );
}

export default CarouselHero;
