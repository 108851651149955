import {
  MembershipTypes,
  useAuthContext,
} from '@r-and-a-shared-ui/auth-client';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import { useAccessRestrictedContent } from '../../hooks/useAccessRestrictedContent';

type RestrictedContentPromptProps = {
  access?: keyof typeof RestrictionLevel;
};

export const useRestrictedContentPrompt = ({
  access,
}: RestrictedContentPromptProps) => {
  const { userHasAccess } = useAccessRestrictedContent({ access });

  const {
    userState: { membership, isAuthenticated = true },
  } = useAuthContext();

  const titleText = isAuthenticated
    ? membership.type === MembershipTypes.OneClub
      ? `Upgrade to Unlock`
      : ``
    : `You're not on par!`;
  
  const subtitleText = isAuthenticated
    ? membership.type === MembershipTypes.OneClub
      ? `Upgrade to unlock Exclusive Content, Premium Rewards and Benefits`
      : ``
    : `Login/Sign Up to unlock Exclusive Content, Premium Rewards and Benefits`;
  return {
    userHasAccess,
    titleText,
    subtitleText,
  };
};
