import { formatCookiePolicyDate } from '@r-and-a-shared-ui/utils';

const COOKIE_CONTROL_INITIAL_STATE = 'closed';
const COOKIE_CONTROL_DISPLAY_WAY = 'slideout';
const COOKIE_CONTROL_POSITION = 'left';
const COOKIE_CONTROL_THEME = 'dark';
const COOKIE_CONTROL_ACCEPT_BEHAVIOUR = 'all';
const CIVIK_UK_API_KEY = '6bc7157ae3ff16db05930c2f20ad79be6733e7aa';
const CIVIK_UK_PRODUCT_TYPE = 'PRO_MULTISITE';
const CIVIC_UK_MODE = 'gdpr';

export interface CustomWindow extends Window {
  dataLayer: any;
}
declare let window: CustomWindow;

function gtag(..._args: unknown[]) {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

const googleTagManagerCookie = (cookiesSettings: any) => ({
  name: cookiesSettings.performanceCookiesLabel,
  label: cookiesSettings.performanceCookiesTitle,
  description: cookiesSettings.performanceCookiesDescription,
  cookies: cookiesSettings.performanceCookies,
  
  onAccept: () => {
    if (window.clarity) {
      window.clarity('consent');
    }

    gtag('consent', 'update', {
      'analytics_storage': 'granted',
      'ad_personalization': 'granted',
      'ad_user_data': 'granted',
      'ad_storage': 'granted'
    });
  },
  onRevoke: () => {
    if (window.clarity) {
      window.clarity('consent', false);
    }

    gtag('consent', 'update', {
      'analytics_storage': 'denied',
      'ad_personalization': 'denied',
      'ad_user_data': 'denied',
      'ad_storage': 'denied'
    });
  },
  lawfulBasis: cookiesSettings.performanceCookiesLawfulBasis,
});



const cookieBranding = () => ({
  fontFamily: 'sans-serif',
  fontColor: '#fff',
  fontSizeTitle: '1.2em',
  fontSizeIntro: '1.2em',
  fontSizeHeaders: '1.1em',
  fontSize: '1.00em',
  backgroundColor: '#041e42',
  toggleText: '#041e42',
  toggleColor: '#041e42',
  toggleBackground: '#14e8ff',
  buttonIcon: null,
  buttonIconWidth: '60',
  buttonIconHeight: '60',
  removeAbout: true,
  acceptText: '#041e42',
  acceptBackground: '#14e8ff',
  removeIcon: true,
});

const cookieText = (cookiesSettings: any) => ({
  title: cookiesSettings.title,
  intro: cookiesSettings.description,
  necessaryTitle: cookiesSettings.necessaryCookiesTitle,
  necessaryDescription: cookiesSettings.necessaryCookiesDescription,
  acceptSettings: cookiesSettings.acceptAllButtonLabel,
  on: cookiesSettings.toggleOnLabel,
  off: cookiesSettings.toggleOffLabel,
});

const cookieStatement = (cookiesSettings: any) => {
  if (!cookiesSettings.detailsLink) {
    return {};
  }
  return {
    description: cookiesSettings?.detailsDescription,
    name: cookiesSettings?.detailsLink?.title,
    url: cookiesSettings?.detailsLink?.href,
    updated: formatCookiePolicyDate(cookiesSettings.detailsUpdatedDate),
  };
};

const getFunctionalCookies = (cookiesSettings: any) => {
  let functionalCookiesList = [];
  if (cookiesSettings.functionalCookies?.length > 0) {
    functionalCookiesList = cookiesSettings.functionalCookies;
  }
  return {
    name: cookiesSettings.functionalCookiesLabel,
    label: cookiesSettings.functionalCookiesTitle,
    description: cookiesSettings.functionalCookiesDescription,
    cookies: functionalCookiesList,
    lawfulBasis: cookiesSettings.functionalCookiesLawfulBasis,
  };
};

export const getCookieConfig = (cookiesSettings: any) => {
  if (!cookiesSettings) {
    return null;
  }

  return {
    apiKey: CIVIK_UK_API_KEY,
    product: CIVIK_UK_PRODUCT_TYPE,
    layout: COOKIE_CONTROL_DISPLAY_WAY,
    initialState: COOKIE_CONTROL_INITIAL_STATE,
    mode: CIVIC_UK_MODE,
    consentCookieExpiry: cookiesSettings.consentCookiesExpiryDate,
    text: cookieText(cookiesSettings),
    necessaryCookies: cookiesSettings.necessaryCookies,
    optionalCookies: [
      googleTagManagerCookie(cookiesSettings),
      getFunctionalCookies(cookiesSettings),
    ],
    branding: cookieBranding(),
    theme: COOKIE_CONTROL_THEME,
    rejectButton: false,
    acceptBehaviour: COOKIE_CONTROL_ACCEPT_BEHAVIOUR,
    position: COOKIE_CONTROL_POSITION,
    statement: cookieStatement(cookiesSettings),
  };
};
