/* eslint-disable prefer-const */
import { AuthConfig } from '@aws-amplify/core';
import * as Auth from 'aws-amplify/auth';
import {
  addQueryParams,
  deleteFromLocalStorage,
  ensureRelativePath,
  getCookies,
  getLocalStorageItem,
  getPageUrlWithoutQueryParams,
  getParameterByName,
  getParametersByNames,
  getQueryParamByKey,
  getSessionStorageItem,
  hasValue,
  isNullOrUndefined,
  redirectTo,
  removeParameterByName,
  setLocalStorageItem,
  setSessionStorageItem,
  stringToBoolean,
} from '@r-and-a-shared-ui/utils';
import { AuthConstants, OauthQuery } from './constants/auth-constants';
import { MembershipTypes, UserJourneyTypes } from '../types';
import { ClientIds } from './constants/client-ids-constants';
import { federatedSignIn, federatedSignOut, handleOauthCompleteFlow, signUp } from '../contexts/authProviderMethods';
import { signOut } from 'aws-amplify/auth';

export const getOauthReturnUrl = (useWholeURL = false) => {
  if (useWholeURL) {
    return encodeURIComponent(getPageUrlWithoutQueryParams());
  } else {
    return encodeURIComponent(
      getPageUrlWithoutQueryParams().split('#')[0].replace(/\/+$/, '')
    );
  }
};

export const setOauthSignInReturnUrl = () => {
  const oauthSignInReturnUrl = encodeURIComponent(
    getPageUrlWithoutQueryParams(),
  );
  setSessionStorageItem(
    AuthConstants.OauthSignInReturnUrl,
    oauthSignInReturnUrl,
  );
};

export const setOauthSignOutReturnUrl = () => {
  const oauthSignOutReturnUrl = encodeURIComponent(
    getPageUrlWithoutQueryParams(),
  );
  setSessionStorageItem(
    AuthConstants.OauthSignOutReturnUrl,
    oauthSignOutReturnUrl,
  );
};

export const getOauthSignInReturnUrl = (purgeValue = false) => {
  const returnUri = getSessionStorageItem(
    AuthConstants.OauthSignInReturnUrl,
    purgeValue,
  );
  return hasValue(returnUri) ? decodeURIComponent(returnUri) : undefined;
};

export const getOauthSignOutReturnUrl = (purgeValue = false) => {
  const returnUri = getSessionStorageItem(
    AuthConstants.OauthSignOutReturnUrl,
    purgeValue,
  );
  return hasValue(returnUri) ? decodeURIComponent(returnUri) : undefined;
};

export const getOauthRedirectUri = (oauthRoute: string,
  oauthClientDomain: string | undefined, client_id: string | undefined, userJourneyType: UserJourneyTypes | undefined, useWholeURL=false) => {
  if (!hasValue(oauthRoute)) {
    return undefined;
  }

  return addQueryParams(`https://${oauthClientDomain}/${oauthRoute}`,
    [
      {
        key: OauthQuery.CLIENT_ID,
        value: client_id,
      },
      {
        key: OauthQuery.REDIRECT_URI,
        value: getOauthReturnUrl(useWholeURL),
      },
      {
        key: OauthQuery.USER_JOURNEY_TYPE,
        value: userJourneyType,
      },
    ]);
};

export const getOauthDeleteRedirectUri = (oauthRoute: string,
  oauthClientDomain: string | undefined,
  client_id: string | undefined,
  username: string | undefined) => {
  if (!hasValue(oauthRoute) || !hasValue(username)) {
    return undefined;
  }

  return addQueryParams(`https://${oauthClientDomain}/${oauthRoute}`,
    [
      {
        key: OauthQuery.CLIENT_ID,
        value: client_id,
      },
      {
        key: OauthQuery.REDIRECT_URI,
        value: `${window.location.origin}/account/sso/callback`,
      },
      {
        key: OauthQuery.USERNAME,
        value: username,
      },
    ]);
};
export const getOauthUpdateReturnUrl = (
  config: AuthConfig,
  userJourneyType?: UserJourneyTypes,
) => {
  const updateRoute =
    process.env.NEXT_PUBLIC_MEMBER_UPDATE_ROUTE || 'update-account';
  const oauthDomain =
    process.env.NEXT_PUBLIC_COGNITO_CLIENT_OAUTH_DOMAIN ||
    config?.Cognito?.loginWith?.oauth?.domain;
  const client_id = config?.Cognito?.userPoolClientId;

  return getOauthRedirectUri(updateRoute, oauthDomain, client_id, userJourneyType,true);
};

export const getOauthUpgradeReturnUrl = (
  config: AuthConfig,
  userJourneyType?: UserJourneyTypes,
) => {
  const upgradeRoute =
    process.env.NEXT_PUBLIC_MEMBER_UPDATE_ROUTE || 'upgrade-to-member-plus';
  const oauthDomain =
    process.env.NEXT_PUBLIC_COGNITO_CLIENT_OAUTH_DOMAIN ||
    config?.Cognito?.loginWith?.oauth?.domain;
  const client_id = config?.Cognito?.userPoolClientId;

  return getOauthRedirectUri(upgradeRoute, oauthDomain, client_id, userJourneyType);
};

export const getOauthChangePasswordReturnUrl = (
  config: AuthConfig,
  userJourneyType?: UserJourneyTypes,
) => {
  const changePasswordRoute =
    process.env.NEXT_PUBLIC_MEMBER_CHANGE_PASSWORD_ROUTE || 'change-password';
  const oauthDomain =
    process.env.NEXT_PUBLIC_COGNITO_CLIENT_OAUTH_DOMAIN ||
    config?.Cognito?.loginWith?.oauth?.domain;
  const client_id = config?.Cognito?.userPoolClientId;

  return getOauthRedirectUri(changePasswordRoute, oauthDomain, client_id, userJourneyType);
};

export const getOauthDeleteReturnUrl = (
  config: AuthConfig,
  username?: string
) => {
  const deleteRoute =
    process.env.NEXT_PUBLIC_MEMBER_DELETE_ACCOUNT_ROUTE || 'delete-account';
  const oauthDomain =
    process.env.NEXT_PUBLIC_COGNITO_CLIENT_OAUTH_DOMAIN ||
    config?.Cognito?.loginWith?.oauth?.domain;
  const client_id = config?.Cognito?.userPoolClientId;

  return getOauthDeleteRedirectUri(deleteRoute, oauthDomain,
    client_id, username);
};

export const getUserCreatedDateUrl = (
  config: AuthConfig,
) => {
  const userCreatedDatePath =
    process.env.NEXT_PUBLIC_USER_CREATED_DATE_PATH || 'api/UserCreatedDate';
  const oauthDomain =
    process.env.NEXT_PUBLIC_COGNITO_OAUTH_DOMAIN ||
    config?.Cognito?.loginWith?.oauth?.domain;

  
  return `https://${oauthDomain}${ensureRelativePath(userCreatedDatePath)}`;
};


export const buildCustomOauthState = (oauthRoute?: string, userJourneyType?: UserJourneyTypes) => {
  if (!hasValue(oauthRoute)) {
    return undefined;
  }

  return JSON.stringify({
    slug: oauthRoute,
    userJourneyType
  });
};

export const isOauthSignOutFlow = () => {
  return hasValue(getOauthSignOutReturnUrl());
};

export const isOauthSignInFlow = () => {
  return hasValue(getOauthSignInReturnUrl());
};

export const parseUserJourneyType = (
  userJourney: string | undefined,
): UserJourneyTypes => {
  if (!hasValue(userJourney)) {
    return UserJourneyTypes.Default;
  }

  const validUserJourneyIndex = Object.values(UserJourneyTypes).findIndex(
    uJType => uJType.toLowerCase() === userJourney?.toLowerCase(),
  );

  if (validUserJourneyIndex === -1) {
    return UserJourneyTypes.Default;
  }

  return Object.values(UserJourneyTypes)[validUserJourneyIndex];
};

export const getUserJourneyTypeIndex = (
  userJourney: string | undefined,
): number => {
  if (!hasValue(userJourney) || userJourney === UserJourneyTypes.Default) {
    return -1;
  }

  return Object.values(UserJourneyTypes).findIndex(
    uJType => uJType.toLowerCase() === userJourney?.toLowerCase(),
  );
};

export const tryGetRefreshToken = async (retries = 2, interval = 100) :  Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const attemptRead = (attempt: number) => {
      const value = getRefreshToken();

      if (value !== null) {
        resolve(value);
      } else if (attempt < retries) {
        setTimeout(() => attemptRead(attempt + 1), interval);
      } else {
        reject(
          // eslint-disable-next-line no-restricted-globals
          new Error(`Cookie '${name}' was not found after ${retries} attempts`),
        );
      }
    };

    attemptRead(0);
  });
};

export const getRefreshToken = () => {
  const cookies = getCookies();
  const refreshToken = cookies?.find(cookie =>
    isRefreshTokenCookie(cookie.name),
  );

  return refreshToken?.value;
};

export const getIdToken = async () => {
  const session = await Auth.fetchAuthSession();
  return session.tokens?.idToken?.toString();
};

export const getAccessToken = async () => {
  const session = await Auth.fetchAuthSession();
  return session.tokens?.accessToken.toString();
};

export function isRefreshTokenCookie(key: string): boolean {
  const pattern = `CognitoIdentityServiceProvider\\.[^.]+\\.[^.]+\\.refreshToken`;
  const regex = new RegExp(pattern);
  const match = regex.test(key);

  return match;
}

export const isSameUser = async (tokens: Auth.AuthTokens, command: Auth.SignInInput) => {
  const email = tokens?.idToken?.payload?.['email'];

  if (!email) return false;

  return (
    tokens?.idToken?.payload?.['email']
      ?.toString()
      .trim()
      .toLowerCase() === command?.username?.trim()?.toLowerCase()
  );
};
export const getOAuth2QueryParams = () => {
  const params = getParametersByNames([OauthQuery.REDIRECT_URI, OauthQuery.AUTHORIZATION_CODE, OauthQuery.STATE]);
  const redirectUri = params?.[OauthQuery.REDIRECT_URI];

  const qsRedirectUri = !isNullOrUndefined(redirectUri) ? decodeURIComponent(redirectUri) : redirectUri;

  return {
    qsRedirectUri,
    qsAuthorizationCode: params?.[OauthQuery.AUTHORIZATION_CODE],
    qsClientState: params?.[OauthQuery.STATE],
  };
};

export const handleOauth2Redirect = ({
  purgeQuery = false,
  noTrailingSlash = true,
  noUrlAnchor = true,
  useWholeURL = false,
}: {
  purgeQuery?: boolean,
  noTrailingSlash?: boolean,
  noUrlAnchor?: boolean,
  useWholeURL? :boolean
} = {
  purgeQuery: false,
  noTrailingSlash: true,
  noUrlAnchor: true,
}) => {
  let {
    qsRedirectUri,
    qsAuthorizationCode,
    qsClientState
  } = getOAuth2QueryParams();

  if (!hasValue(qsRedirectUri)) {
    return;
  }

  if(!useWholeURL){
    if (noUrlAnchor) {
      qsRedirectUri = qsRedirectUri?.split('#')[0];
    }

    if (noTrailingSlash) {
      qsRedirectUri = qsRedirectUri?.replace(/\/+$/, '');
    }
  }

  const queryToAppend = purgeQuery ? [] : [
    {
      key: OauthQuery.CODE,
      value: qsAuthorizationCode,
    },
    {
      key: OauthQuery.STATE,
      value: qsClientState,
    },
  ];

  const targetUrl = addQueryParams(qsRedirectUri, queryToAppend);

  redirectTo(targetUrl);
};

export const handleCompleteForgotPasswordRedirect = () => {
  let {
    qsRedirectUri,
  } = getOAuth2QueryParams();

  if (!hasValue(qsRedirectUri)) {
    return;
  }

  qsRedirectUri = qsRedirectUri?.split('#')[0]?.replace(/\/+$/, '');

  const targetUrl = addQueryParams(qsRedirectUri, [
    {
      key: OauthQuery.COMPLETE_FORGOT_PASSWORD,
      value: String(true)
    }
  ]);

  redirectTo(targetUrl);
};

export const getBearerToken = async (): Promise<string> => {
  const session = await Auth.fetchAuthSession({ forceRefresh: true });
  if (!session?.tokens || !session?.tokens.accessToken) {
    return '';
  }

  const token = session?.tokens.accessToken.toString();
  return `Bearer ${token}`;
};

export const checkForceSignOut = () => {
  const isForceAuth = getQueryParamByKey(OauthQuery.FORCE_AUTH);

  if ((String(isForceAuth).toLowerCase() === 'true')) {
    Auth.fetchAuthSession().then((session) => {
      if (hasValue(session?.tokens)) {
        Auth.signOut({ global: true })
          .then(() => {
            deleteFromLocalStorage(OauthQuery.CLIENT_ID);
            removeParameterByName(OauthQuery.FORCE_AUTH);
          })
          .catch(() => {
            deleteFromLocalStorage(OauthQuery.CLIENT_ID);
            removeParameterByName(OauthQuery.FORCE_AUTH);
          });
      }
    });
  }
};

export const checkForceAuth = () => {
  checkForceSignIn();
  checkForceSignUp();
};

export const checkForceSignIn = () => {
  const isForceSignIn = stringToBoolean(getQueryParamByKey(OauthQuery.FORCE_SIGN_IN));

  if (!isForceSignIn) {
    return;
  }

  removeParameterByName(OauthQuery.FORCE_SIGN_IN);
  federatedSignIn(undefined, false);
};

export const checkForceSignUp = () => {
  const isForceSignUp = stringToBoolean(getQueryParamByKey(OauthQuery.FORCE_SIGN_UP));

  if (!isForceSignUp) {
    return;
  }
  Auth.fetchAuthSession().then((session) => {
    if (hasValue(session?.tokens)) {
      const oauthSignOutReturnUrl = encodeURIComponent(
        window.location.toString(),
      );
      setSessionStorageItem(
        AuthConstants.OauthSignOutReturnUrl,
        oauthSignOutReturnUrl
      );
      signOut();
    }
    removeParameterByName(OauthQuery.FORCE_SIGN_UP);
    signUp(undefined, false);
  });
};

export const clearForceAuthParams = () => {
  removeParameterByName(OauthQuery.FORCE_AUTH);
  removeParameterByName(OauthQuery.FORCE_SIGN_IN);
  removeParameterByName(OauthQuery.FORCE_SIGN_UP);
}

export const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (/tablet|ipad/i.test(userAgent)) {
    return 'Tablet';
  } else if (/mobile|iphone|android|windows phone|tablet|ipad/i.test(userAgent)) {
    return 'Mobile';
  } else {
    return 'Website';
  }
};

export const getPlatformByClientId = (): string | undefined => {
  const clientId = getQueryParamByKey(OauthQuery.CLIENT_ID);
  return ClientIds[clientId] || "Unknown";
};

export const getMembership = (params: any) => {
  return params?.submitData?.user_becameMasterCard ? MembershipTypes.OneClubAdvantage : params?.submitData?.user_membership ?? MembershipTypes.OneClub;
};

export const getOauthFlowReturnUrl = () => {
  const returnUri = getLocalStorageItem(AuthConstants.OauthFlowReturnUrl, true);

  return hasValue(returnUri) ? decodeURIComponent(returnUri) : undefined;
};

export const setOauthFlowReturnUrl = () => {
  let returnUrl = getParameterByName(OauthQuery.REDIRECT_TO);

  if (!hasValue(returnUrl)) {
    returnUrl = getOauthReturnUrl();
  }

  setLocalStorageItem(AuthConstants.OauthFlowReturnUrl, returnUrl);
};

export const checkOauthFlow = (oauthQueryKey: string) => {
  if (!hasValue(oauthQueryKey)) {
    return undefined;
  }

  const oauthFlow = stringToBoolean(getParameterByName(oauthQueryKey));
  removeParameterByName(oauthQueryKey);

  return oauthFlow;
};

export const checkCompleteFlow = () => {
  if (
    checkOauthFlow(OauthQuery.COMPLETE_FORGOT_PASSWORD) ||
    checkOauthFlow(OauthQuery.COMPLETE_REGISTER)
  ) {
    handleOauthCompleteFlow();
    return true;
  }

  if(checkOauthFlow(OauthQuery.COMPLETE_DELETE)) {
    federatedSignOut();
    return true;
  }

  if(checkOauthFlow(OauthQuery.UNSUCCESSFUL_DELETE)) {
    redirectTo(`/?${OauthQuery.UNSUCCESSFUL_DELETE}=true`);
    return true;
  }

  return false;
};

export const handleUncaughtFlow = () => {
  const redirectURI =  getOauthFlowReturnUrl() || window.location.origin;
  
  if (getPageUrlWithoutQueryParams().toString().toLowerCase() !== redirectURI?.toLowerCase()) {
    const redirectURI = getOauthFlowReturnUrl() || window.location.origin;
    redirectTo(redirectURI);
  }
};