import type {
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
import { SSOCta } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';
export default {
  component: ()=>SSOCta,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.SSOCTA) =>
    kontentAiDataMapping.sso_cta(entry),
};
