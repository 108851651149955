import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { useScreenDetector } from '../../hooks';
import { config } from '@r-and-a-shared-ui/core-component-config';
import { RenderOn } from '../RenderOn/RenderOn';
import { ImageProps } from '../Image/Image';
import BurgerTOCComponent from '../BurgerTOCComponent/BurgerTOCComponent';
import { getFirstMappedLinkedItemOrDefault } from '@r-and-a-shared-ui/core-data-mapping';

export const HeaderMainMenu = ({
  menuEntry,
}: {
  menuEntry: IContentItem<any>;
}) => {
  const { isMobile } = useScreenDetector();
  const Menu = config.menu.component();
  const menuProps = config.menu.mapEntryToProps(menuEntry);
  if (!menuEntry || !Menu || !menuProps) {
    return null;
  }
  const rawImage = menuEntry.elements?.background_image?.value?.[0];
  let adaptedImage: ImageProps | undefined;
  if (rawImage) {
    adaptedImage = {
      imageUrl: rawImage.url,
      license: 'default-license',
      width: rawImage?.width || 100,
      height: rawImage?.height || 100,
      alt: rawImage.name,
    };
  }
  const footerElements = getFirstMappedLinkedItemOrDefault(menuEntry.elements?.footer)?.logos || [];

  return (
    <Menu
      key={menuEntry.system.id}
      {...menuProps}
      menuStyle={'main_menu'}
      backgroundImage={adaptedImage}
      footer={footerElements}
      extraItems={[
        <RenderOn condition={isMobile} key={'burger-toc'}>
          <BurgerTOCComponent/>
        </RenderOn>
      ].filter(Boolean)}
    />
  );
};
