import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const stories_iframe = (entry: MEMBERSHIP_MODELS.StoriesIFrame) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  return {
    PlayerID: elements?.playerid?.value,
    WidgetID: elements?.widgetid?.value,
  };
};

export default stories_iframe;
