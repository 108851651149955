import { useCallback, useState } from 'react';
import { FilterBlockOptions } from './FilterBlock';

interface UseFilterProps {
  initialFilters: FilterBlockOptions;
  onChange?: (filters: FilterBlockOptions) => void;
  onRemove?: (filters: FilterBlockOptions) => void;
  onApply?: (filters: FilterBlockOptions) => void;
}

const useFilter = ({
  initialFilters,
  onChange,
  onRemove,
  onApply,
}: UseFilterProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] =
    useState<FilterBlockOptions>(initialFilters);
  const [selectedTags, setSelectedTags] =
    useState<FilterBlockOptions>(initialFilters);

  const handleRemove = useCallback(
    (category: string, tag: string) => {
      setSelectedTags(prevState => {
        const updatedFilters = {
          ...prevState,
          [category]: prevState[category].filter(option => option !== tag),
        };
        onRemove?.(updatedFilters);
        return updatedFilters;
      });
    },
    [onRemove],
  );

  const handleRemoveAll = useCallback(() => {
    const emptySelectedCategories = Object.fromEntries(
      Object.keys(selectedTags).map(key => [key, []]),
    );

    onRemove?.(emptySelectedCategories);
    setSelectedTags(emptySelectedCategories);
  }, [onRemove, selectedTags]);

  const handleClearFilters = useCallback(() => {
    const emptySelectedCategories = Object.fromEntries(
      Object.keys(selectedOptions).map(key => [key, []]),
    );
    setSelectedOptions(emptySelectedCategories);
  }, [selectedOptions]);

  const handleSelect = useCallback(
    (category: string, selectedValues: string[]) => {
      setSelectedOptions(prevState => ({
        ...prevState,
        [category]: selectedValues,
      }));
      onChange?.({ ...selectedOptions, [category]: selectedValues });
    },
    [onChange, selectedOptions],
  );

  const handleApply = useCallback(() => {
    setSelectedTags(selectedOptions);
    onApply?.(selectedOptions);
    setDropdownVisible(false);
  }, [onApply, selectedOptions]);

  const toggleDropdown = useCallback(() => {
    if (dropdownVisible) {
      setDropdownVisible(false);
      setSelectedOptions(initialFilters);
      return;
    }
    setDropdownVisible(true);
  }, [dropdownVisible, initialFilters]);

  return {
    selectedTags,
    handleSelect,
    handleRemove,
    handleRemoveAll,
    handleApply,
    handleClearFilters,
    selectedOptions,
    toggleDropdown,
    dropdownVisible,
  };
};

export default useFilter;
