import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { deliveryClient } from '../../config/kontent-delivery-client';
import { MEMBERSHIP_MODELS } from '../../models';
import { getItemWithLinkedItemsByCodename } from '../get-item-with-linked-items-by-codename';
import { getFullPageBody } from './get-full-page-body';
import { getLinkedItemList } from '../get-linked-item-list';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { adjustSystemProperties } from '../../utils/common-kontent-ai-utils';

type GetFullPageBySlugResponse = {
  page: MEMBERSHIP_MODELS.Page;
  template: MEMBERSHIP_MODELS.PageTemplate | undefined;
  body: any[];
};

export const getFullPageBySlug = async <Type extends IContentItem>(
  slug: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
) => {
  const cacheKey = `getFullPageBySlug-${slug}`;

  try {
    const cachedItem = getCachedItem<GetFullPageBySlugResponse>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const item = await deliveryClient
      .items<Type>()
      .type('page')
      .equalsFilter('elements.url_slug', slug)
      .depthParameter(0)
      .toPromise()
      .then(r => r.data.items[0]);

    adjustSystemProperties(item);

    if (!item || !item?.system.codename) {
      return undefined;
    }

    const [fullPage, template] = await Promise.all([
      getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.Page>({
        codename: item.system.codename,
        depthParameter: 1,
        cache,
        cacheTTL: cacheTTL - 1,
      }),
      getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.PageTemplate>({
        codename: item.elements['template'].value[0],
        depthParameter: 3,
        cache,
        cacheTTL: CacheConfig.TTL.PageTemplate,
      }),
    ]);

    const bodyItems = fullPage?.elements?.body?.linkedItems ?? [];
    const subHeaderElelements =
      fullPage?.elements?.subheader_elements?.linkedItems ?? [];
    const sidebarItems = fullPage?.elements?.sidebar?.linkedItems ?? [];
    const preFooterItems =
      fullPage?.elements?.pre_footer_elements?.linkedItems ?? [];

    const [body, subheader, sidebar, preFooter] = await Promise.all([
      getFullPageBody(
        bodyItems,
      ),
      getLinkedItemList({
        linkedItems: subHeaderElelements,
        cache,
        cacheTTL: CacheConfig.TTL.PageContent,
      }),
      getLinkedItemList({
        linkedItems: sidebarItems,
        cache,
        cacheTTL: CacheConfig.TTL.PageContent,
      }),
      getLinkedItemList({
        linkedItems: preFooterItems,
        cache,
        cacheTTL: CacheConfig.TTL.PageContent,
      }),
    ]);

    const parsedFullPage = {
      ...fullPage,
      elements: {
        ...fullPage?.elements,
        body: {
          ...fullPage?.elements?.body,
          linkedItems: body,
        },
        subheader_elements: {
          ...fullPage?.elements?.subheader_elements,
          linkedItems: subheader,
        },
        sidebar: {
          ...fullPage?.elements?.sidebar,
          linkedItems: sidebar,
        },
        pre_footer_elements: {
          ...fullPage?.elements?.pre_footer_elements,
          linkedItems: preFooter,
        },
      },
    } as MEMBERSHIP_MODELS.Page;

    const response = {
      page: parsedFullPage,
      template,
      body,
    };

    setCacheItem<GetFullPageBySlugResponse>(cacheKey, response, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

