import { deliveryClient } from "../config/kontent-delivery-client";
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from "@r-and-a-shared-ui/core-caching";
import { IContentItem } from "@kontent-ai/delivery-sdk";
import { adjustSystemProperties } from "../utils";

export const getPageBodyByCodeName = async <Type extends IContentItem>(
    codeNames: string[],
    depthParameter = 0,
    cache = true,
    cacheTTL = CacheConfig.TTL.PageContent.valueOf(),
    cacheStatus?: CacheStatus
    ): Promise<Type[] | undefined> => {
    const cacheKey = `getPageBodyByCodeName-${codeNames.join('_')}`;

    try {
      const cachedItem = getCachedItem<Type[]>(cacheKey, cache);

      if (cachedItem) {
        setCacheStatus(cacheStatus, {
          cacheable: cache,
          xInMemoryCache: CacheConfig.Hit,
          cacheTTL
        });
        return cachedItem;
      }
  
      const response = await deliveryClient.items()
        .inFilter('system.codename', [codeNames.join(',')])
        .depthParameter(depthParameter)
        .toPromise()
        .then(response => response?.data?.items);
  
      const responseItem = response as Type[];

      adjustSystemProperties(responseItem);
  
      setCacheItem<Type[]>(cacheKey, responseItem, cacheTTL, cache);

      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Miss,
        cacheTTL
      });
  
      return responseItem;
  
    } catch (error) {
      console.error('Error in getPageTemplateByCodeName', error);
      throw error;
    }
  };