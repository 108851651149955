/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const roles = {
    /**
     * Project manager
     */
    projectManager: {
        codename: 'project-manager',
        id: '69f9c940-e54b-4a5d-a60a-853b2176590e',
        name: 'Project manager'
    }
} as const;
