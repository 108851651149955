import { CacheConfig } from '@r-and-a-shared-ui/core-caching';
import { MEMBERSHIP_MODELS } from '../../models';
import { getDynamicThreshold } from '../../utils';
import { getItemWithLinkedItemsByCodename } from '../get-item-with-linked-items-by-codename';


export const getFullPageBody = async (
  pageBody: MEMBERSHIP_MODELS.Page['elements']['body']['linkedItems'],
  cache = true,
  cacheTTL = CacheConfig.TTL.PageContent.valueOf(),
) => {
  const dynamicThreshold = getDynamicThreshold();

  const bodyPromises: Promise<any>[] = [];
  pageBody?.forEach((bodyItem, index) => {
    if (index < dynamicThreshold) {
      bodyPromises.push(
        getItemWithLinkedItemsByCodename({
          codename: bodyItem.system.codename,
          depthParameter: 3,
          cache,
          cacheTTL,
        }),
      );
      return;
    }
    bodyPromises.push(Promise.resolve(bodyItem));
  });
  return await Promise.all(bodyPromises);
};
