import { useContext } from 'react';
import { EnvironmentContext } from '../../contexts';
import { Link, LinkProps } from '../Link/Link';
export interface LogoProps {
  logoImage?: string;
  resource?: LinkProps;
  logoDescription?: string | null;
}

export function Logo(props: LogoProps) {
  const { Image } = useContext(EnvironmentContext);

  if (!props) return null;
  const { logoImage, resource, logoDescription } = props;
  
  return (
    <Link
      title={resource?.title || ''}
      href={resource?.href || ''}
      internal={!!resource?.internal}
    >
      {logoImage && (
        <Image
          alt={logoDescription || 'logo'}
          src={logoImage}
          height={0}
          width={0}
          sizes="100vw"
        />
      )}
    </Link>
  );
}

export default Logo;
