import { CarouselHero } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: () => CarouselHero,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.CarouselHero) =>
    kontentAiDataMapping.carousel_hero(entry),
  depthParameter: 3,
};
