import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { Reward } from '@r-and-a-shared-ui/common-ui';

export default {
  component: () => Reward,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.Reward) =>
    kontentAiDataMapping.reward(entry),
};
