import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { MEMBERSHIP_MODELS } from '../../models';
import { getItemWithLinkedItemsByCodename } from '../get-item-with-linked-items-by-codename';

import { getLinkedItemList } from '../get-linked-item-list';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { getPageBodyByCodeName } from '../get-page-body-by-codename';

type GetFullPageBySlugResponse = {
  page: MEMBERSHIP_MODELS.Page;
  body: any[] | undefined;
};

export const getFullPageByCodenameWithoutTemplate = async <Type extends IContentItem>(
  codeName: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
) => {
  const cacheKey = `getFullPageBySlugWithoutTemplate-${codeName}`;

  try {
    const cachedItem = getCachedItem<GetFullPageBySlugResponse>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const fullPage =
      await getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.Page>({
        codename: codeName,
        depthParameter: 1,
        cache,
        cacheTTL,
      });

    const bodyItemsCodeNames = fullPage?.elements?.body?.value ?? [];

    const subHeaderElements =
      fullPage?.elements?.subheader_elements?.linkedItems ?? [];
    const sidebarItems = fullPage?.elements?.sidebar?.linkedItems ?? [];
    const preFooterItems =
      fullPage?.elements?.pre_footer_elements?.linkedItems ?? [];

    const [body, subheader, sidebar, preFooter] = await Promise.all([
      getPageBodyByCodeName(
        bodyItemsCodeNames,
        3,
        cache,
        CacheConfig.TTL.PageContent
        ),
      getLinkedItemList({
        linkedItems: subHeaderElements,
        cache,
        cacheTTL: CacheConfig.TTL.PageContent
      }),
      getLinkedItemList({
        linkedItems: sidebarItems,
        cache,
        cacheTTL: CacheConfig.TTL.PageContent
      }),
      getLinkedItemList({
        linkedItems: preFooterItems,
        cache,
        cacheTTL: CacheConfig.TTL.PageContent
      }),
    ]);

    const parsedFullPage = {
      ...fullPage,
      elements: {
        ...fullPage?.elements,
        body: {
          ...fullPage?.elements?.body,
          linkedItems: body,
        },
        subheader_elements: {
          ...fullPage?.elements?.subheader_elements,
          linkedItems: subheader,
        },
        sidebar: {
          ...fullPage?.elements?.sidebar,
          linkedItems: sidebar,
        },
        pre_footer_elements: {
          ...fullPage?.elements?.pre_footer_elements,
          linkedItems: preFooter,
        },
      },
    } as MEMBERSHIP_MODELS.Page;

    const response = {
      page: parsedFullPage,
      body,
    };

    setCacheItem<GetFullPageBySlugResponse>(cacheKey, response, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
