import { parseUserJourneyType } from '@r-and-a-shared-ui/auth-client';
import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedItemOrDefault,
  getTextItemsValue,
  getTextItemValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const user_journey = (data: SSO_MODELS.UserJourney) => {
  if (!data || !data?.elements) {
    return undefined;
  }
  const { elements } = data;

  const journeyTypeValue =
    getTextItemValue(
      getFirstLinkedItemOrDefault(elements?.user_journey_type),
    ) ?? '';

  return {
    clientId: getTextItemValue(
      getFirstLinkedItemOrDefault(elements?.client_id),
    ),
    userJourneyType: parseUserJourneyType(journeyTypeValue),
  };
};

export default user_journey;
