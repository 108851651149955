import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { api } from '@r-and-a-shared-ui/randa-trpc-client';
import { FilterBlockOptions } from '../FilterBlock/FilterBlock';

export const useArticleFilter = () => {
  const { data } = api.kontent.articleAuthors.useQuery();
  const router = useRouter();
  const searchParams = useSearchParams();

  const authors = useMemo(
    () =>
      data?.authors?.map(author => ({
        name: `${author.elements.name.value} ${author.elements.surname.value}`,
        codename: author.system.codename,
      })) || [],
    [data],
  );

  const authorFilterOptions = useMemo(
    () => authors?.map(author => author.name),
    [authors],
  );

  const activeFilters = useMemo(
    () =>
      (searchParams
        .getAll('author')
        ?.map(codename => {
          const author = authors.find(author => author.codename === codename);
          return author ? author.name : null;
        })
        .filter(Boolean) as string[]) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authors],
  );

  const handleApplyFilter = useCallback(
    (value: FilterBlockOptions) => {
      const filteredCodeNames = value.Authors.map(authorName => {
        const author = authors.find(author => author.name === authorName);
        return author ? author.codename : null;
      }).filter(Boolean) as string[];

      const url = {
        pathname: router.pathname,
        query: filteredCodeNames.length
          ? {
            author: filteredCodeNames,
          }
          : undefined,
      };
      router.push(url);
    },
    [router, authors],
  );

  return {
    filterOption: authorFilterOptions,
    activeFilters,
    handleApplyFilter,
  };
};
