import styles from './TextItem.module.scss';

/* eslint-disable-next-line */
export interface TextItemProps {
  value?: string;
}

export function TextItem(props: TextItemProps) {
  return (
    <div className={styles['container']}>
      {props.value}
    </div>
  );
}

export default TextItem;
