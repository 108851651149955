import { getItemByCodeName } from './get-item-by-code-name';
import { DictionaryItem, MyAccountPage } from '../models/MEMBERSHIP';
import { getLinkedItemList } from './get-linked-item-list';
import { getDynamicThreshold } from '../utils';
import { getItemWithLinkedItemsByCodename } from './get-item-with-linked-items-by-codename';
import { MEMBERSHIP_MODELS } from '../models';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';

type GetFullMyAccountPageResponse = {
  page: MEMBERSHIP_MODELS.MyAccountPage;
  template: MEMBERSHIP_MODELS.PageTemplate | undefined;
};


export const getFullMyAccountPageBySlug = async (
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
) => {
  const cacheKey = 'getFullMyAccountPageBySlug-account';
  const cachedItem = getCachedItem<GetFullMyAccountPageResponse>(cacheKey, cache);

  if (cachedItem) {
    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Hit,
      cacheTTL
    });
    return cachedItem;
  }

  try {
    const myAccountPageDictionary = await getItemByCodeName<DictionaryItem>(
      'my_account_codename',
      false,
      cache,
      cacheTTL - 2
    );

    if (!myAccountPageDictionary) {
      return undefined;
    }

    const myAccountPage = await getItemByCodeName<MyAccountPage>(
      myAccountPageDictionary.elements.value.value,
      false,
      cache,
      cacheTTL - 1
    );

    if (!myAccountPage) {
      return undefined;
    }

    const bodyItems = myAccountPage?.elements?.body?.linkedItems ?? [];
    const subHeaderElelements =
      myAccountPage?.elements?.subheader_elements?.linkedItems ?? [];
    const sidebarItems = myAccountPage?.elements?.sidebar?.linkedItems ?? [];
    const mobileItems =
      myAccountPage?.elements?.mobile_elements?.linkedItems ?? [];

    const [body, subheader, sidebar, mobile, template] =
      await Promise.all([
        getLinkedItemList({
          linkedItems: bodyItems,
          dynamicThreshold: getDynamicThreshold(),
          cache,
          cacheTTL: CacheConfig.TTL.PageContent,
        }),
        getLinkedItemList({
          linkedItems: subHeaderElelements,
          cache,
          cacheTTL: CacheConfig.TTL.PageContent,
        }),
        getLinkedItemList({
          linkedItems: sidebarItems,
          cache,
          cacheTTL: CacheConfig.TTL.PageContent,
        }),
        getLinkedItemList({
          linkedItems: mobileItems,
          dynamicThreshold: getDynamicThreshold(),
          cache,
          cacheTTL: CacheConfig.TTL.PageContent,
        }),
        getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.PageTemplate>({
          codename: myAccountPage?.elements['template'].value[0],
          depthParameter: 4,
          cache,
          cacheTTL: CacheConfig.TTL.PageTemplate,
        }),
      ]);

    const parsedMyAccountPage = {
      ...myAccountPage,
      elements: {
        ...myAccountPage?.elements,
        body: {
          ...myAccountPage?.elements?.body,
          linkedItems: body,
        },
        subheader_elements: {
          ...myAccountPage?.elements?.subheader_elements,
          linkedItems: subheader,
        },
        sidebar: {
          ...myAccountPage?.elements?.sidebar,
          linkedItems: sidebar,
        },
        mobile_elements: {
          ...myAccountPage?.elements?.mobile_elements,
          linkedItems: mobile,
        },
      },
    };

    const result = {
      page: parsedMyAccountPage,
      template: template,
    } as GetFullMyAccountPageResponse;

    setCacheItem<GetFullMyAccountPageResponse>(cacheKey, result, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return result;
  } catch (error) {
    console.error('Error in getFullMyAccountPageBySlug', error);
    throw error;
  }
};
