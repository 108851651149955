import {
  getFirstLinkedItemOrDefault,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const tag = (entry: MEMBERSHIP_MODELS.Tag) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }

  const { elements } = entry || {};

  return {
    label: elements?.label,
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    textColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.text_color),
    ),
  };
};

export default tag;
