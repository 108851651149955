import { getMappedLinkedItems } from './utils/component-mapping-helpers';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const membership_teaser_panel = (
  input: MEMBERSHIP_MODELS.MembershipTeaserPanel,
) => {
  const membershipTypes = getMappedLinkedItems(
    input?.elements?.membership_types,
  );
  const logo = getFirstLinkedAssetOrDefault(input?.elements?.logo);
  const backgroundImage = getFirstLinkedAssetOrDefault(input?.elements?.background_image)
    ?.url;

  return {
    membershipTypes,
    logo,
    backgroundImage
  };
};

export default membership_teaser_panel;
