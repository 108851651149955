import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { mapSliderConfig } from './map-content_list___fixed_length';
export const playlist = (data: MEMBERSHIP_MODELS.Playlist) => {
  if (!data || !data?.elements) {
    return undefined;
  }
  const { elements } = data;

  const ctaProps = {
    playlistId: elements.playlist_id.value,
    playlistTitle: elements?.playlist_title?.value,
    carouselConfig: mapSliderConfig(data),
  };

  return ctaProps;
};

export default playlist;
