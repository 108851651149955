import styles from './LoadingSpinner.module.scss';

/* eslint-disable-next-line */
export interface LoadingSpinnerProps {}

export function LoadingSpinner(props: LoadingSpinnerProps) {
  return (
    <div className={styles['spinner-container']}>
      <div className={styles['loading-spinner']}></div>
    </div>
  );
}

export default LoadingSpinner;
