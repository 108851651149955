import { getTrueFalseSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { defaultDynamicThreshold } from '@r-and-a-shared-ui/utils';

export const page = (page: MEMBERSHIP_MODELS.Page) => {
  let dynamicThreshold = defaultDynamicThreshold;
  try {
    dynamicThreshold = parseInt(
      process?.env?.NEXT_PUBLIC_DYNAMIC_LOAD_THRESHOLD ?? `${defaultDynamicThreshold}`,
    );
  } catch {
    dynamicThreshold = defaultDynamicThreshold;
  }
  const bodyContent = page.elements?.body.linkedItems ?? [];
  const seo = {
    title: page.elements?.seo__meta_title?.value,
    description: page.elements?.seo__meta_description?.value,
    keywords: page.elements?.seo__key_words?.value,
    noindex: getTrueFalseSingleChoiceValue(page.elements?.seo__no_index),
    nofollow: getTrueFalseSingleChoiceValue(page.elements?.seo__no_follow),
    urlSlug: page.elements?.url_slug?.value,
  };
  const subheader = page.elements?.subheader_elements?.linkedItems ?? [];
  const sidebar = page.elements?.sidebar?.linkedItems ?? [];
  const preFooter = page.elements?.pre_footer_elements?.linkedItems ?? [];
  return {
    seo,
    body: bodyContent.map((x, index) => {
      if (index >= dynamicThreshold) {
        return {
          ...x,
          dynamic: true,
        };
      }
      return x;
    }),
    sidebar,
    subheader,
    preFooter,
  };
};

export default page;
