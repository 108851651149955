import React, { CSSProperties, useState } from 'react';
import { hasValue, isFunction } from '@r-and-a-shared-ui/utils';
import styles from './CTA.module.scss';

type AllowedButtonTypes = 'button' | 'submit' | 'reset';

const defaultBtnLabel = 'Click Here';
const defaultBtnType = 'button';

export interface CTAProps {
  key?: string;
  onClick?: (...args: unknown[]) => unknown;
  label?: string;
  type?: string;
  resource?: string;
  theme?: string;
  backgroundColor?: string;
  borderColor?: string;
  opacity?: number;
  textColor?: string;
  isDisabled?: boolean;
  isMembership?: boolean;
  isSubmittedSuccessfully?: boolean | null;
  hasAttemptedSubmit?: boolean;
  successMessage?: string;
  errorMessage?: string;
}

export function CTA(props: CTAProps) {
  const {
    label,
    onClick,
    type,
    theme,
    isDisabled,
    isSubmittedSuccessfully,
    hasAttemptedSubmit,
    successMessage,
    textColor,
    errorMessage,
    isMembership,
    backgroundColor,
    borderColor,
    opacity,
  } = props;

  const [isTemporarilyDisabled, setIsTemporarilyDisabled] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isTemporarilyDisabled && isFunction(onClick)) {
      setIsTemporarilyDisabled(true);

      try {
        await onClick();
      } finally {
        setTimeout(() => {
          setIsTemporarilyDisabled(false);
        }, 2000);
      }
    }
  };

  const buttonLabel =
    isMembership && hasAttemptedSubmit
      ? isSubmittedSuccessfully === true
        ? successMessage
        : isSubmittedSuccessfully === false
          ? errorMessage
          : label ?? defaultBtnLabel
      : label ?? defaultBtnLabel;

  const themeClass = hasValue(theme) ? `button__${theme}` : '';
  const membershipClass = isMembership ? styles['button--membership'] : '';
  const successClass = isMembership && isSubmittedSuccessfully === true ? styles['button--success'] : '';
  const errorClass = isMembership && isSubmittedSuccessfully === false ? styles['button--error'] : '';

  const inlineStyle: CSSProperties = {
    backgroundColor: backgroundColor,
    borderColor: borderColor ?? backgroundColor,
    color: textColor,
    opacity: opacity,
  };

  const shouldDisableButton = (isMembership && (isDisabled || isTemporarilyDisabled)) || !!successClass;

  return (
    <div key={label} className={styles['button--common__container']}>
      <button
        type={(type ?? defaultBtnType) as AllowedButtonTypes}
        className={`${styles['button--common']} ${membershipClass} ${successClass} ${errorClass} ${
          hasValue(themeClass) ? styles[themeClass] : ''
        } ${shouldDisableButton ? styles['button--disabled'] : ''}`}
        onClick={handleSubmit}
        style={inlineStyle}
        disabled={shouldDisableButton}
      >
        <span
          className={`${styles['button--common__content']} ${styles['button--common__content--centered']}`}
        >
          <span>{buttonLabel ?? defaultBtnLabel}</span>
        </span>
      </button>
    </div>
  );
}

export default CTA;
