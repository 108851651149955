import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedAssetOrDefault,
  getSingleChoiceValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import {
  getFirstMappedLinkedItemOrDefault,
  getMappedLinkedItems,
} from './utils/component-mapping-helpers';

export const upgrade_experience_card = (
  entry: MEMBERSHIP_MODELS.UpgradeExperienceCard,
) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  const backgroundImage = getFirstLinkedAssetOrDefault(
    elements?.card_base__background_image,
  );
  const mobileBackgroundImage = getFirstLinkedAssetOrDefault(
    elements?.mobile_background_image,
  );

  return {
    title: elements?.card_base__title?.value,
    description: elements?.card_base__description?.value,
    backgroundImage: {
      url: backgroundImage?.url,
      alt: backgroundImage?.description,
    },
    mobileBackgroundImage: {
      url: mobileBackgroundImage?.url,
      alt: mobileBackgroundImage?.description,
    },
    link: getFirstMappedLinkedItemOrDefault(elements?.card_base__link),
    ctaText: elements?.cta_text?.value,
    access: getSingleChoiceValue(
      elements?.membership_based_access__select_access,
    ),
    tags: getMappedLinkedItems(elements?.tags) || [],
    codename: entry?.system?.codename || '',
  };
};

export default upgrade_experience_card;
