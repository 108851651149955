import { sendGAEvent } from '@next/third-parties/google';
import { SSOCtaAction, useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { getPageTrackingDetails } from '@r-and-a-shared-ui/utils';
import { useMemo } from 'react';

export const useSSOCta = ({ action }: { action?: string } = {}) => {
  const {
    methods: { signUp, federatedSignIn, federatedSignOut,
      upgradeAccount, updateAccountData, changePassword, deleteAccount },
    config,
    userState: { user }
  } = useAuthContext();
  const onClick = useMemo(() => {
    switch (action) {
    case SSOCtaAction.REGISTER:
      return () => signUp();
    case SSOCtaAction.LOGIN:
      return () => {
        sendGAEvent({ event: 'log_in_click', value: getPageTrackingDetails() });
        federatedSignIn();
      };
    case SSOCtaAction.LOGOUT:
      return () => federatedSignOut();
    case SSOCtaAction.UPGRADE:
      return () => upgradeAccount(config);
    case SSOCtaAction.UPDATE:
      return () => updateAccountData(config);
    case SSOCtaAction.CHANGE_PASSWORD:
      return () => changePassword(config);
    case SSOCtaAction.DELETE_ACCOUNT:
      return () => deleteAccount(config, user?.sub);
    default:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  }, [
    action,
    config,
    user?.sub,
    federatedSignIn,
    federatedSignOut,
    signUp,
    upgradeAccount,
    updateAccountData,
    changePassword,
    deleteAccount
  ]);
  return {
    onClick,
  };
};
