import {
  SSO_MODELS,
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
import { getTrueFalseSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';

export const seo_data =  (data: SSO_MODELS.SEOData | MEMBERSHIP_MODELS.SEOData) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;
  return {
    title: elements?.seo__meta_title?.value,
    description: elements?.seo__meta_description?.value,
    keywords: elements?.seo__key_words?.value,
    noindex: getTrueFalseSingleChoiceValue(elements?.seo__no_index),
    nofollow: getTrueFalseSingleChoiceValue(elements?.seo__no_follow),
    urlSlug: elements?.url_slug?.value,
    requiredQueryParams: getMappedLinkedItems(elements?.required_query_params)
  };
};

export default seo_data;
