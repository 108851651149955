export const UNDEFINED= 'undefined';
export const TEXT = 'text';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const PASSWORD_CONFIRMATION = 'password_confirmation';
export const DROPDOWN = 'dropdown';
export const RADIO_BUTTONS = 'radio_buttons';
export const CHECKBOXES = 'checkboxes';
export const NUMBER = 'number';
export const PHONE_NUMBER = 'phone_number';
export const DATE = 'date';
export const COUNTRY = 'country';
export const MASTERCARD = 'mastercard';
export const FAVORITE_PLAYER = 'favorite_player';
export const ONE_CLUB_FREE_TEXT = 'one_club_free_text';