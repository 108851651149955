import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const query_param = (data: SSO_MODELS.QueryParam) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    value: elements?.value?.value,
  };
};

export default query_param;
