import React from 'react';
import { Fragment } from 'react';
import { FormFieldTypes, formFieldsConfig } from '../config/form-field-config';
import { StringComparisonCriteria, isFunction } from '@r-and-a-shared-ui/utils';
import { MenuPlacement } from 'react-select';
import { RichTextWithAsset } from 'libs/common-kontent-client/src/models/SSO';

export interface OptionProps {
  label?: string;
  value?: string;
  counties?: OptionProps[];
  states?: OptionProps[];
}

export interface BaseFormFieldProps {
  defaultValue?: string;
  key: string;
  name: string;
  queryParamKey?: string;
  fieldRef: React.RefObject<HTMLDivElement>;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  validationMessage?: string;
  validationRegex?: string;
  fieldType: FormFieldTypes;
  options?: OptionProps[];
  dependsOn?: string;
  dependencyCriteria?: StringComparisonCriteria;
  dependencyValidationMessage?: string;
  subfields?: FormFieldProps[];
  allowNumbersOnly?: boolean;
  hideIndividualError?: boolean;
  triggerError?: boolean;
  preferredOptionsList?: string;
  entireObjectSelection?: boolean;
  menuPlacement?: MenuPlacement;
  fieldReplacement?: RichTextWithAsset;
  isHidden?: boolean;
  isMembership?: boolean;
  isSubmittedSuccessfully?: boolean;
}

export type FormFieldProps = BaseFormFieldProps;

export const FormField = (props: FormFieldProps) => {
  const { fieldType, isMembership, isSubmittedSuccessfully } = props;

  if (!isFunction(formFieldsConfig[fieldType])) {
    return undefined;
  }

  return (
    <Fragment>
      {formFieldsConfig[fieldType]({
        ...props,
        isMembership,
        isSubmittedSuccessfully,
      })}
    </Fragment>
  );
};

export default FormField;
