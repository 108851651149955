import React from 'react';
import styles from './CallOutBox.module.scss';
import { Link, LinkProps } from '../Link/Link';

export interface CallOutBoxProps {
  description?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  link?: LinkProps;
}

export function CallOutBox(props: CallOutBoxProps) {
  const { description, backgroundColor, backgroundImage, link } = props;

  const style = {
    backgroundColor: backgroundColor || undefined,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.background} style={style}>
        <div className={styles.content}>
          {description && (
            <div className={styles.content__description}>{description}</div>
          )}
          {link && (
            <Link
              {...link}
              className={`${styles.btn} ${styles['btn--primary']} ${styles['btn--dark']}`}
            >
              {link.title}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default CallOutBox;
