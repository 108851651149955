import React, { useContext } from 'react';
import classnames from 'classnames';
import { EnvironmentContext } from '../../contexts';

import styles from './Image.module.scss';
import { ImageSchemaOrgModel } from '@r-and-a-shared-ui/utils';
import useSchemaOrg from '../../hooks/useSchemaOrg';

export interface ImageProps {
  imageUrl: string;
  license?: string;
  width: number;
  height: number;
  alt: string;
  className?: string;
  schemaOrgContent?: ImageSchemaOrgModel,
}

export function Image(props: ImageProps) {
  const { className, imageUrl,
    height, width, alt, schemaOrgContent } = props;
  const { Image } = useContext(EnvironmentContext);

  useSchemaOrg(schemaOrgContent);

  return (
    <Image
      src={imageUrl}
      alt={alt}
      width={width}
      height={height}
      sizes="100vw"
      className={classnames(styles['image'], className)}
    />
  );
}

export default Image;
