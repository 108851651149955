import { useFormContext } from 'react-hook-form';
import { FormFieldProps, } from '../FormField/FormField';
import { useEffect, useId, useState } from 'react';
import FormTextInput from '../FormTextInput/FormTextInput';
import RichTextWithAsset from '../../RichTextWithAsset/RichTextWithAsset';
import { USER_MEMBERSHIP, findKey, hasValue } from '@r-and-a-shared-ui/utils';
import { MembershipTypes } from '@r-and-a-shared-ui/auth-client';
import { findDOMNode } from 'react-dom';

export function MastercardFormField(props: FormFieldProps) {
  const { getValues, setValue } = useFormContext(); 
  const  [becameMastercard, setBecameMstercard] = useState(null);
  
  const {name} = props;
  const id = useId();
   const fieldReplacement = props.fieldReplacement;
   const formValues = getValues();
   const userMembership = formValues?.[USER_MEMBERSHIP];

   useEffect(()=>{
   const becameMC = hasValue(userMembership) ? userMembership.includes(MembershipTypes.OneClubAdvantage) : false;
   if(findKey(formValues, (key)=>key===name)){
    setBecameMstercard(becameMC);
   }
  })
   
  if(becameMastercard === null)
   {
    return null
   };

   if(becameMastercard){
   return  <RichTextWithAsset {...(fieldReplacement)} key={`richtextwithasset-${id}`} />
   } 
  return <FormTextInput {...props} entireObjectSelection menuPlacement="bottom" isHidden={becameMastercard === true} />
   
}

export default MastercardFormField;
