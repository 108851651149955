import { MyAccountProps } from './types';
import { FC } from 'react';
import { SubHeader } from '../Page/SubHeader';
import styles from './MyAccount.module.scss';
import { MyAccountBodyContent } from './MyAccountBodyContent';

export const MyAccountDesktop: FC<
  Pick<MyAccountProps, 'subheader' | 'sidebar' | 'body'>
> = ({ subheader, sidebar, body }) => {
  return (
    <>
      <SubHeader subheader={subheader} />
      <main data-testid={'myAccountDesktop'} className={styles.wrapper}>
        <div className={styles.container}>
          <MyAccountBodyContent body={body} sidebar={sidebar} />
        </div>
      </main>
    </>
  );
};
