import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getMappedItem } from './component-mapping-helpers';
import {
  SSOConfiguration,
  UserJourneyMapping,
} from '../../models';
import { UserJourneyTypes } from '@r-and-a-shared-ui/auth-client';
import { IContentItem, IContentItemElements } from '@kontent-ai/delivery-sdk';
import { hasValue } from '@r-and-a-shared-ui/utils';
import { globalDataMapping } from '../../components/GlobalDataMapping';

const { KONTENT: dataMapping } = globalDataMapping.getDataMapping();

type DataMappingKeys = keyof typeof dataMapping;

export const getDefaultFormPageForSlug = (
  ssoConfig: SSOConfiguration | undefined,
  slug?: string,
) => {
  const foundFormPage = ssoConfig?.defaultFormPagesForSlugs?.find(
    formPage => formPage?.slug?.toLowerCase() === slug?.toLowerCase(),
  );

  return foundFormPage?.codename;
};

export const getUserJourneyMapping = ({
  ssoConfig,
  slug,
  clientId,
  userJourneyType,
  kontentUserJourneyMappings
}: {
  ssoConfig?: SSOConfiguration;
  slug?: string;
  clientId?: string;
  userJourneyType: UserJourneyTypes;
  kontentUserJourneyMappings: IContentItem[] | undefined | null;
}): UserJourneyMapping | undefined => {

  const normalizedSlug = slug?.toLowerCase();
  const normalizedUserJourneyType = userJourneyType?.toLowerCase();
  const normalizedClientId = clientId?.toLowerCase();

  const userJourneyMappingsFromSSOConfig = getMappedUserJourneyMappings(
    kontentUserJourneyMappings?.filter(
      (journeyMapping) => ssoConfig?.userJourneyMappings?.some(j => j.codename === journeyMapping?.system?.codename)
    )
  );

  const userJourneyMappingsForSlug: UserJourneyMapping[] | undefined = userJourneyMappingsFromSSOConfig?.filter(
    (journeyMapping) => journeyMapping?.formPageSlug?.toLowerCase() === normalizedSlug
  );


  if (!userJourneyMappingsForSlug || userJourneyMappingsForSlug.length === 0) {
    return undefined;
  }

  if (clientId) {

    const matchingElement = userJourneyMappingsForSlug.find(mapping => {
      const mappingClientId = mapping?.userJourney?.clientId?.toLowerCase();
      const mappingUserJourneyType = getUserJourneyType(mapping);

      return normalizedClientId === mappingClientId && (
        (mappingUserJourneyType === undefined && normalizedUserJourneyType === UserJourneyTypes.Default.toLowerCase()) ||
        mappingUserJourneyType === normalizedUserJourneyType
        );
    });

    // Return the first matching element or the first element of userJourneyMappings if no match is found
    return matchingElement;
  }

  return undefined;
};

export const getMappedSSOConfig = (ssoConfig?: SSO_MODELS.SSOConfiguration) => {
  return getMappedItem<
    SSO_MODELS.SSOConfiguration | undefined,
    SSOConfiguration
  >(ssoConfig);
};

export const getMappedUserJourneyMappings = (userJourneyMappings?: IContentItem<IContentItemElements>[]) => {
  if (!hasValue(userJourneyMappings)) {
    return undefined;
  }

  const mappedUserJourneyMappings = userJourneyMappings?.map(item => {
    const mappingConfig = dataMapping?.[item?.system?.type as DataMappingKeys];

    return (mappingConfig as any)?.(item as never);
  });

  return mappedUserJourneyMappings as UserJourneyMapping[];
};

const getUserJourneyType = (item: UserJourneyMapping | undefined) => {
  return item?.userJourney?.userJourneyType?.toLowerCase();
};

