import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { deliveryClient } from '../config/kontent-delivery-client';
import type { ArticleAuthor } from '../models/MEMBERSHIP';
import { adjustSystemProperties } from '../utils';

export const getArticleAuthors = async (
  cache = true,
  cacheTTL = CacheConfig.TTL.ArticleAuthors.valueOf(),
  cacheStatus?: CacheStatus
) => {
  try {
    const cacheKey = 'getArticleAuthors';
  
    const cachedItem = getCachedItem<ArticleAuthor[]>(cacheKey, cache);
  
    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return {
        authors: cachedItem,
      };
    }
  
    const query = await deliveryClient
      .items<ArticleAuthor>()
      .type('article_author')
      .depthParameter(0)
      .toPromise()
      .then(r => r.data);

    adjustSystemProperties(query.items);
  
    setCacheItem(cacheKey, query.items, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });
    
    return {
      authors: query.items,
    };
  } catch (error) {
    console.error('Error in getArticleAuthors', error);
    throw error;
  }
};
