/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const workflows = {
    /**
     * Default
     * Archived step Id: 7a535a69-ad34-47f8-806a-def1fdf4d391
     * Published step Id: c199950d-99f0-4983-b711-6c4c91624b22
     */
    default: {
        codename: 'default',
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Default',
        steps: {
            archived: {
                name: 'Archived',
                codename: 'archived',
                id: '7a535a69-ad34-47f8-806a-def1fdf4d391'
            },
            published: {
                name: 'Published',
                codename: 'published',
                id: 'c199950d-99f0-4983-b711-6c4c91624b22'
            },
            scheduled: {
                name: 'Scheduled',
                codename: 'scheduled',
                id: '9d2b0228-4d0d-4c23-8b49-01a698857709'
            },
            draft: {
                name: 'Draft',
                codename: 'draft',
                id: 'eee6db3b-545a-4785-8e86-e3772c8756f9'
            }
        }
    }
} as const;
