/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { hasValue } from '@r-and-a-shared-ui/utils';
import { FormField, FormFieldProps } from '../fields';
import styles from './FormStep.module.scss';
import StepsCounter from '../StepsCounter/StepsCounter';
import { FormCTA, FormCTAProps } from '../FormCTA/FormCTA';
import RichTextWithAsset, { RichTextWithAssetProps } from '../RichTextWithAsset/RichTextWithAsset';
import { FormHeaderProps } from '../FormHeader/FormHeader';
import { FormContentContext, FormContentContextType } from '../context/FormContentContext';
import { AssetProps, RichTextWithAssetContainer, RichTextWithAssetContainerProps } from '@r-and-a-shared-ui/common-ui';

export interface FormStepProps {
  key?: string;
  fieldsData?: FormFieldProps[];
  ctasData?: FormCTAProps[];
  richTextItems?: RichTextWithAssetProps[] | RichTextWithAssetContainerProps[];
  showStepCounter: boolean;
  customHeader?: FormHeaderProps;
  audio?: AssetProps;
  isMembership?: boolean;
  toEmail?: string,
  fromEmail?: string,
  subject?:string,
  emailKey?: string;
}

export function FormStep(props: FormStepProps) {
  const { fieldsData,
    ctasData,
    richTextItems,
    showStepCounter,
    isMembership,
    toEmail,
    fromEmail,
    subject,
    emailKey, } = props;
  const { formContentProps, currentFormStep } = useContext(
    FormContentContext,
  ) as FormContentContextType;

  const stepsLength = formContentProps.steps?.filter((step) => step.showStepCounter).length || 0;

  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const renderFormFields = (
    fieldsProps: FormFieldProps[] | undefined,
  ): React.JSX.Element[] | null => {
    if (!hasValue(fieldsProps) || typeof fieldsProps === 'undefined') {
      return null;
    }

    return fieldsProps.map((fieldProps) => {
      return (
        <FormField
          {...fieldProps}
          isMembership={isMembership}
          isSubmittedSuccessfully={submissionSuccess}
        />
      );
    });
  };

  const renderFormRichTextItems = (
    richTextItems: RichTextWithAssetProps[] | RichTextWithAssetContainerProps[] | undefined,
  ): React.JSX.Element[] | null => {
    if (!hasValue(richTextItems) || typeof richTextItems === 'undefined') {
      return null;
    }

    return richTextItems.map((richTextItem) => {
      if (hasValue((richTextItem as RichTextWithAssetContainerProps).richTextWithAssetElements)) {
        return <RichTextWithAssetContainer {...(richTextItem as RichTextWithAssetContainerProps)} />;
      }

      return <RichTextWithAsset {...(richTextItem as RichTextWithAssetProps)} />;
    });
  };

  const renderFormCTAs = (
    ctasProps: FormCTAProps[] | undefined,
  ): React.JSX.Element[] | null => {
    if (!hasValue(ctasProps) || typeof ctasProps === 'undefined') {
      return null;
    }

    return ctasProps.map((ctasProps) => {
      return (
        <FormCTA
          {...ctasProps}
          isMembership={isMembership}
          onSubmissionSuccess={() => setSubmissionSuccess(true)}
          toEmail={toEmail}
          fromEmail={fromEmail}
          subject={subject}
          emailKey={emailKey}
        />
      );
    });
  };
  
  return (
    <div className={`${styles['form-step']}`}>
      <div className={`${styles['form-step__wrapper']}`}>
        <div className={`${styles['form-step__content']}`}>
          {renderFormFields(fieldsData)}
        </div>
        <div className={`${styles['form-step__content']}`}>
          {renderFormRichTextItems(richTextItems)}
        </div>
        {stepsLength > 1 && showStepCounter && (
          <StepsCounter count={stepsLength} current={currentFormStep} />
        )}
        <div className={`${styles['form-step__buttons']}`}>
          {renderFormCTAs(ctasData)}
        </div>
      </div>
    </div>
  );
}

export default FormStep;
