import classnames from 'classnames';
import Image from '../Image/Image';
import OneClubMastercardFull from '../../assets/OneClubMastercardFull.svg';
import { FC } from 'react';

import styles from './RestrictedContentPrompt.module.scss';

type RestrictedContentPromptLogosProps = {
  className?: string;
};

export const RestrictedContentPromptLogos: FC<
  RestrictedContentPromptLogosProps
> = () => {
  return (
    <div className={classnames(styles['icon-group'])}>
      <div className={classnames(styles['icon-group__item'])}>
        <Image
          width={224}
          height={80}
          imageUrl={OneClubMastercardFull}
          alt="One Club Mastercard Logo"
        />
      </div>
    </div>
  );
};
