import { FormStepProps } from "../../FormStep/FormStep";
import * as z from 'zod';
import { hasValue, isFunction } from "@r-and-a-shared-ui/utils";
import { formFieldValidators } from "./form-field-validators";
import { BaseFormFieldProps, FormFieldProps } from "../FormField/FormField";
import { FormTextInputProps } from "../FormTextInput/FormTextInput";

interface SchemaMapper {
  field: BaseFormFieldProps,
  formStepProps: FormStepProps[],
  schema: any
}
const getFieldByName = (steps: FormStepProps[], name: string): FormFieldProps | undefined => {
  let field: FormFieldProps | undefined;
  steps.find((step) => {
    field = step.fieldsData?.find((fieldOnStep) => fieldOnStep.name === name);
    return !!field;
  });

  return field;
};

const mapValidatorToSchema = (params: SchemaMapper) => {
  const { field, formStepProps, schema } = params;
  let fieldToCompare: FormFieldProps | undefined;

  const validator = formFieldValidators[field?.fieldType];
  if (field?.dependsOn) {
    fieldToCompare = getFieldByName(formStepProps, field.dependsOn);
  }
  if (field?.subfields) {
    field.subfields.forEach((subfield) => mapValidatorToSchema({ ...params, field: subfield }));
  }
  schema[field?.name] = isFunction(validator) ? validator(field, fieldToCompare, field.dependencyCriteria) : z.unknown();
};

export const getValidationSchema = (formStepProps: FormStepProps[] | undefined): z.ZodType<any, any, any> => {
  if (!hasValue(formStepProps) || typeof formStepProps === 'undefined') {
    return z.undefined();
  }
  const schema: any = {};

  formStepProps.forEach((step) => {
    step.fieldsData?.forEach((field) => {
      mapValidatorToSchema({ field, formStepProps, schema });
    });
  });
  return z.object(schema);
};

export const getTextInputValidationSchema = (fieldData: FormTextInputProps | undefined): z.ZodType<any, any, any> => {
  if (!hasValue(fieldData) || typeof fieldData === 'undefined') {
    return z.undefined();
  }

  const validator = formFieldValidators['text'];
  return isFunction(validator) ? validator(fieldData) : z.unknown();
};