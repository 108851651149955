export const enum AuthConstants {
    OauthSignInReturnUrl = 'OauthSignInReturnUrl',
    OauthSignOutReturnUrl = 'OauthSignOutReturnUrl',
    OauthFlowReturnUrl = 'OauthFlowReturnUrl',
}

export const enum AmplifyAuthEvents {
    SignedIn = 'signedIn',
    SignedOut = 'signedOut',
    TokenRefresh = 'tokenRefresh',
    TokenRefreshFailure = 'tokenRefresh_failure',
    SignInWithRedirect = 'signInWithRedirect',
    SignInWithRedirectFailure = 'signInWithRedirect_failure',
    CustomOauthState = 'customOAuthState',
}

export const enum OauthQuery {
    CLIENT_ID = 'client_id',
    LOGOUT_URI = 'logout_uri',
    POST_LOGOUT_REDIRECT_URI = 'post_logout_redirect_uri',
    USER_JOURNEY_TYPE = 'user_journey_type',
    RESPONSE_TYPE = 'response_type',
    REDIRECT_URI = 'redirect_uri',
    CODE_CHALLENGE = 'code_challenge',
    CODE_CHALLENGE_METHOD = 'code_challenge_method',
    AUTHORIZATION_CODE = 'authorization_code',
    USERNAME = 'username',
    CODE = 'code',
    STATE = 'state',
    FORCE_AUTH = 'forceAuth',
    COMPLETE_FORGOT_PASSWORD = 'complete_forgot_password',
    COMPLETE_REGISTER = 'complete_register',
    COMPLETE_DELETE = 'complete_delete',
    STORE_TOKENS = 'store_tokens',
    REDIRECT_TO = 'redirect_to',
    UNSUCCESSFUL_DELETE = 'unsuccessful_delete',
    TOKEN = 'token',
    FORCE_SIGN_IN = 'force_sign_in',
    FORCE_SIGN_UP = 'force_sign_up',
  }

export const enum SSOCtaAction {
    REGISTER = 'register',
    LOGIN = 'login',
    LOGOUT= 'logout',
    UPGRADE ='upgrade',
    UPDATE =  'update',
    CHANGE_PASSWORD ='change_password',
    DELETE_ACCOUNT = 'delete_account'
}

export const NO_ACCESS_PAGE = '/no-access';
export const NO_ACCESS_CODENAME = 'no_access';
export const USER_ID = 'userId';
export const ENDEAVOR_ACCESS_TOKEN = 'endeavor_access_token';
