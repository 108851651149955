import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getMappedLinkedItems,
  getSlugUrlForFormPage,
} from './utils/component-mapping-helpers';

export const sso_configuration = (data: SSO_MODELS.SSOConfiguration) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  const formPagesLinkedItems =
    data?.elements?.default_form_pages_for_slugs?.linkedItems;

  return {
    userJourneyMappings: getMappedLinkedItems(elements?.user_journey_mappings),
    defaultFormPagesForSlugs: formPagesLinkedItems?.map(formPageItem => {
      return {
        slug: getSlugUrlForFormPage(formPageItem)?.value,
        codename: formPageItem?.system?.codename,
      };
    }),
    memberRequiredAttributes: getMappedLinkedItems(elements?.member_required_attributes)
  };
};

export default sso_configuration;
