import { MembershipTypes } from '@r-and-a-shared-ui/auth-client';

export const userDefaultValuesUpdate = {
  clientId: '',
  userJourneyType: -1,
  kontentItemCodename: '',
  user: {
    interests: {
      favoritePlayer: '',
      venues: [],
    },
    title: '',
    email: '',
    firstName: '',
    lastName: '',
    addresses: [
      {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        postcode: '',
        city: '',
        country: '',
        county: '',
        state: '',
        main: true,
        billingAddress: true,
        shippingAddress: true,
      },
    ],
    membership: MembershipTypes.OneClub,
    raMemberId: '0',
    ticketingAccountId: '',
    isActive: false,
    currentWebsite: 0,
    dateOfBirth: undefined,
    optIns: {
      optedTheOpenOffersByEmail: false,
      optedPartnerOffersByEmail: false,
      agreedToTermsAndConditions: false,
      agreedToTermsAndConditionsLastUpdated: '',
      agreedToPrivacyPolicy: false,
      theOpenOffersUpdated: '',
      partnerOffersUpdated: '',
    },
    preferences: {
      channels: {
        directMail: false,
        sms: false,
        email: false,
      },
      channelsValues: [],
      championshipValues: [],
      interestsValues: [],
      interests: {
        content: false,
        hospitality: false,
        retail: false,
        tickets: false,
      },
    },
    ssoUserId: '',
    preferredUserName: '',
    mobilePhone: '',
    countryCodeMobilePhonePrefix: '',
    zoneInfo: '',
    id: '',
    isEmailVerified: '',
    platform: '',
    createdDate: undefined,
    ballotCriteria: '',
    becameMasterCard: '',
    lastLoggedInDate: '',
    lastLoggedInDevice: '',
    lastLoggedInPlatform: '',
  },
};

export const userDefaultValuesRegister = {
  password: '',
  reCaptchaToken: '',
  ...userDefaultValuesUpdate,
};
