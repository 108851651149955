import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';

export const explore_benefits = (
  entry: MEMBERSHIP_MODELS.ExploreBenefits,
) => {
  if (!entry || !entry.elements) {
    return undefined;
  }

  const { elements } = entry;
  const rawImage = elements.background_image?.value?.[0];

  const adaptedImage = rawImage ? {
    imageUrl: rawImage.url,
    license: 'default-license',
    width: rawImage?.width || 100,
    height: rawImage?.height || 100,
    alt: rawImage.name,
  } : null;

  return {
    title: elements.title?.value,
    link: getMappedLinkedItems(elements.cta),
    background_image: adaptedImage,
  };
};

export default explore_benefits;
