import { z, ZodObject } from 'zod';
import defaults from 'defaults';

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const defaultServerEnv = z.object({
  KONTENT_ENVIRONMENT_ID: z.string().min(1),
  KONTENT_MANAGEMENT_API_KEY: z.string().min(1),
  NODE_ENV: z.enum(['development', 'test', 'production']),
});

const defaultClientEnv = z.object({
});

/**
 *
 * @param server
 * @param client
 * @param injectedEnv
 * @returns {MergedOutput|undefined|*}
 */
export const createEnv = ({
  /** @type ZodObject<any> */ server = defaultServerEnv,
  /** @type ZodObject<any> */ client = defaultClientEnv,
  /** @type ZodObject<any> */ injectedEnv = {},
}) => {
  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   *
   * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
   */
  const defaultProcessEnv = {
    NODE_ENV: process.env['NODE_ENV'],
    KONTENT_ENVIRONMENT_ID: process.env['NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID'],
    KONTENT_MANAGEMENT_API_KEY: process.env['NEXT_PUBLIC_KONTENT_MANAGEMENT_API_KEY'],
  };

  const processEnv = defaults(
    injectedEnv,
    defaultProcessEnv,
  )

  // if (processEnv.NODE_ENV === 'development') {
  //   process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
  // }

  // Don't touch the part below
  // --------------------------

  const merged = server.merge(client);

  /** @typedef {z.input<typeof merged>} MergedInput */
  /** @typedef {z.infer<typeof merged>} MergedOutput */
  /** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

  let env = /** @type {MergedOutput} */ (process.env);

  const skip =
    !!process.env['SKIP_ENV_VALIDATION'] &&
    process.env['SKIP_ENV_VALIDATION'] !== 'false' &&
    process.env['SKIP_ENV_VALIDATION'] !== '0';
  if (!skip) {
    const isServer = typeof window === 'undefined';

    const parsed = /** @type {MergedSafeParseReturn} */ (
      isServer
        ? merged.safeParse(processEnv) // on server we can validate all env vars
        : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
    );

    if (parsed.success === false) {
      console.error(
        '❌ Invalid environment variables:',
        parsed.error.flatten().fieldErrors,
      );
      throw new Error('Invalid environment variables');
    }

    env = new Proxy(parsed.data, {
      get(target, prop) {
        if (typeof prop !== 'string') return undefined;
        // Throw a descriptive error if a server-side env var is accessed on the client
        // Otherwise it would just be returning `undefined` and be annoying to debug
        if (!isServer && !prop.startsWith('NEXT_PUBLIC_'))
          throw new Error(
            process.env['NODE_ENV'] === 'production'
              ? '❌ Attempted to access a server-side environment variable on the client'
              : `❌ Attempted to access server-side environment variable '${prop}' on the client`,
          );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return target[/** @type {keyof typeof target} */ (prop)];
      },
    });
  }

  return env;
};
