import type { MEMBERSHIP_MODELS, SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedItemOrDefault,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';

export const form_footer = (data: SSO_MODELS.FormFooter & MEMBERSHIP_MODELS.FormFooter) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;
  
  return {
    termsAndConditionsLink: elements?.terms_and_conditions?.value,
    privacyPolicyLink: elements?.privacy_policy?.value,
    forgotPasswordLink: elements?.forgot_password?.value,
    footerTextColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.footer_text_color),
    ),
  };
};

export default form_footer;
