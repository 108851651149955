import { RestrictionLevel } from '../lib/MembershipTypeLogos/constants';
import { useEffect, useMemo, useState } from 'react';
import {
  Membership,
  MembershipTypes,
  useAuthContext,
} from '@r-and-a-shared-ui/auth-client';

type UseRestrictedContentPromptProps = {
  access?: keyof typeof RestrictionLevel;
};

export const parseUserCanAccess = ({
  access,
  currentMembership,
  isAuthenticated = true,
}: {
  access?: keyof typeof RestrictionLevel;
  currentMembership?: Membership;
  isAuthenticated?: boolean;
}) => {
  if (
    access === RestrictionLevel.none ||
    currentMembership?.type === MembershipTypes.OneClubAdvantage
  ) {
    return true;
  }

  if (!isAuthenticated && access !== RestrictionLevel.none) {
    return false;
  }

  return (
    access === RestrictionLevel.oneClub &&
    currentMembership?.type === MembershipTypes.OneClub
  );
};
export const useAccessRestrictedContent = ({
  access,
}: UseRestrictedContentPromptProps) => {
  const [accessEnabled, setAccessEnabled] = useState(true);
  const {
    userState: { membership, isAuthenticated = true },
  } = useAuthContext();

  const currentMembership = useMemo(
    () =>
      membership ?? {
        type: MembershipTypes.OneClubAdvantage,
      },
    [membership],
  );

  useEffect(() => {
    setAccessEnabled(
      parseUserCanAccess({
        access,
        currentMembership,
        isAuthenticated,
      }),
    );
  }, [access, currentMembership, isAuthenticated]);

  return { userHasAccess: accessEnabled };
};
