import { hasValue } from "./general-utils";

export const calculateRemainingTime = (startDate: string) => {
  const timeLeft = new Date(startDate).getTime() - new Date().getTime();

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

  return {
    days,
    hours,
    minutes,
  };
};

export const getTimeText = (
  time?: number,
  singularLabel?: string,
  pluralLabel?: string,
) => {
  const timeValue = time ? time : 0;
  switch (true) {
    case timeValue === 0 || timeValue < 0:
      return '';
    case timeValue === 1:
      return `${timeValue} ${singularLabel}`;
    default:
      return `${timeValue} ${pluralLabel}`;
  }
};

export const isLiveEvent = (startDate: string, endDate?: string | null) => {
  const currentDate = new Date();
  const startDateTime = new Date(startDate);

  if (endDate) {
    const endDateTime = new Date(endDate);

    return currentDate >= startDateTime && currentDate <= endDateTime;
  }

  return currentDate >= startDateTime;
};

export const formatMembershipDate = (date: string) => {
  const inputDate = formatDateToUTC(date);

  return `${inputDate.day} ${MONTH_NAMES[inputDate.monthIndex]} ${
    inputDate.year
  }`;
};

export const formatCookiePolicyDate = (date: string) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = ('0' + (inputDate.getMonth() + 1)).slice(-2);
  const day = inputDate.getDate().toString().padStart(2, '0');

  return `${day}/${month}/${year}`;
};

export const isValidDateString = (dateString: string) => {
  try {
    if (!hasValue(dateString)) {
      return false;
    }
    
    const parsedDate = new Date(dateString);

    return parsedDate instanceof Date && !isNaN(parsedDate?.getTime());
  }
  catch {
    return false;
  }
}

export const isValidDate = (year: number, month: number, day: number) => {
  const d = new Date(year, month, day);
  return (
    d.getFullYear() === year && d.getMonth() === month && d.getDate() === day
  );
};

export const secondsToTime = (sec: number) => {
  const date = new Date(sec * 1000);
  if (sec < 3600) {
    return date.toISOString().substring(14, 19);
  } else {
    return date.toISOString().substring(11, 19);
  }
};

export const getDateWithOffset = (yearOffset = 0): number => {
  const currentDate = new Date();
  const currentYear = currentDate.getUTCFullYear();

  const dateWithOffset = convertDateToUTC(currentDate).setFullYear(
    currentYear - yearOffset,
  );

  return dateWithOffset;
};

export const convertDateToUTC = (localDate: Date): Date => {
  return new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
    ),
  );
};

const formatDateToUTC = (date: string) => {
  const originalDate = new Date(date);
  originalDate.setUTCHours(originalDate.getUTCHours() + 1);

  return {
    day: originalDate.getUTCDate(),
    monthIndex: originalDate.getUTCMonth(),
    year: originalDate.getUTCFullYear(),
  };
};

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatEventDate = (startDate: string, endDate: string): string => {
  const newStartDate = formatDateToUTC(startDate);
  const newEndDate = formatDateToUTC(endDate);

  const eventDate =
    newStartDate.monthIndex === newEndDate.monthIndex &&
    newStartDate.year === newEndDate.year
      ? `${newStartDate.day} - ${newEndDate.day} ${
          MONTH_NAMES[newEndDate.monthIndex]
        } ${newEndDate.year}`
      : `${newStartDate.day} ${MONTH_NAMES[newStartDate.monthIndex]} ${
          newStartDate.year
        } - ${newEndDate.day} ${MONTH_NAMES[newEndDate.monthIndex]} ${
          newEndDate.year
        }`;

  return eventDate;
};
