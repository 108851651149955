import { VALIDATION_ERROR_KEY, VALIDATION_DEFAULT_ERROR_MESSAGE, CLIENT_APP_ERROR_KEY, CLIENT_APP_DEFAULT_ERROR_MESSAGE, safeParseJSON, isNullOrUndefined, isFunction, ResponseModel } from "@r-and-a-shared-ui/utils";
import httpErrors from "http-errors";
import { setServerError, tryGetDictionaryItemValue } from "../../utils";
import { ActionHandlerParams } from "../types";
import { z } from "zod";
import { preExecMutationCheck, preExecMutationCheckWithFormValidation } from "../helpers/config-helper";

interface MandrillResult {
  email: string;
  status: string;
  _id: string;
  reject_reason: string | null;
}


export const genericMutationHandlerWithFormValidation = async <
  InputSchema extends z.ZodSchema,
>(
  params: ActionHandlerParams,
  inputSchema: InputSchema,
  defaultValuesObj?: unknown,
): Promise<ResponseModel> => {
  
  const requestData = await preExecMutationCheckWithFormValidation(
    params,
    inputSchema,
    defaultValuesObj,
  );
  return await userMutationHandler(requestData, params);
};

export const genericMutationHandler = async <InputSchema extends z.ZodSchema>(
  params: ActionHandlerParams,
  inputSchema: InputSchema,
  defaultValuesObj?: unknown,
  ignoreError = false
): Promise<ResponseModel> => {
  const requestData = await preExecMutationCheck(
    params,
    inputSchema,
    defaultValuesObj,
  );
  return await userMutationHandler(requestData, params, ignoreError);
};

export const sendOneClubQuestionEmail = async (params: {
  submitData: Record<string, any>;
  fromEmail?: string;
  toEmail?: string;
  subject?: string;
  emailKey?: string;
  mutation?: any;
  userEmail?: string;
}): Promise<{ successCode: string; data: MandrillResult[] }> => {
  const { submitData, fromEmail, toEmail, subject, mutation, emailKey, userEmail } = params;
  
  try {
    const data = await mutation.mutateAsync({
      fromEmail,
      toEmail,
      subject,
      submitData,
      emailKey,
      userEmail,
    });

    return { successCode: data.successCode, data: data.data };
  } catch (error) {
    throw new Error('Error sending email via Mandrill.');
  }
};


export const userMutationHandler = async (
  requestData: unknown,
  params: ActionHandlerParams,
  ignoreError = false
) => {
  const {
    mutation,
    setError,
    errorsDictionary,
    successMessagesDictionary,
    setSubmitSuccessMessage,
  } = params;
  
  if (!ignoreError && !requestData) {
    setServerError({
      setError,
      errorKey: VALIDATION_ERROR_KEY,
      statusCode: '400',
      errorsDictionary,
      defaultMessage: VALIDATION_DEFAULT_ERROR_MESSAGE,
    });
    return { hasErrors: true };
  }
  
  if (!ignoreError && (isNullOrUndefined(mutation) || !isFunction(mutation.mutate))) {
    setServerError({
      setError,
      errorKey: CLIENT_APP_ERROR_KEY,
      statusCode: '500',
      errorsDictionary,
      defaultMessage: CLIENT_APP_DEFAULT_ERROR_MESSAGE,
    });
  
    return { hasErrors: true };
  }
  
  try {
    const data = (await mutation?.mutateAsync(requestData)) as any;
  
    const successMessage = tryGetDictionaryItemValue(
      successMessagesDictionary,
      data?.successCode,
    );
  
    if (isFunction(setSubmitSuccessMessage)) {
      setSubmitSuccessMessage(successMessage);
    }
  
    return { hasErrors: false, data };
  } catch (err) {
    const error = err as httpErrors.HttpError;
    const parsedError = safeParseJSON(error.message) || {
      message: error.message,
    };
    
    if (!ignoreError) {
      setServerError({
        setError,
        errorKey: parsedError.key || error.name,
        statusCode: error?.statusCode?.toString(),
        errorsDictionary,
        defaultMessage: parsedError.message,
      });
    }
  
    return { hasErrors: true };
  }
};