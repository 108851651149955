import EventHeroAsset, {
  EventHeroAssetProps,
} from './EventHeroAsset/EventHeroAsset';
import EventHeroBody, {
  EventHeroBodyProps,
} from './EventHeroBody/EventHeroBody';

import styles from './EventHero.module.scss';

export type EventHeroProps = EventHeroBodyProps & EventHeroAssetProps;

export function EventHero({
  title,
  description,
  backgroundAsset,
  mobileImgVideoPreview,
  playVideoText,
  eventLocation,
  eventStartDate,
  eventEndDate,
  viewEventLink,
}: EventHeroProps) {
  return (
    <div className={styles.wrapper} data-testid="event-hero">
      <EventHeroAsset
        backgroundAsset={backgroundAsset}
        mobileImgVideoPreview={mobileImgVideoPreview}
        playVideoText={playVideoText}
      />
      <EventHeroBody
        title={title}
        description={description}
        eventLocation={eventLocation}
        eventStartDate={eventStartDate}
        eventEndDate={eventEndDate}
        viewEventLink={viewEventLink}
      />
    </div>
  );
}

export default EventHero;
