import * as Auth from 'aws-amplify/auth';
import {
  hasValue,
  isNullOrUndefined,
  safeParseJSON
} from "@r-and-a-shared-ui/utils";
import {
  Attributes,
  CognitoUser,
  CognitoUserAttribute,
  Membership,
  MembershipStatus,
  MembershipTypes,
  MembershipsHierarchy,
  UserAddressType
} from '../types';
import { fetchCreatedDate } from '../contexts/authProviderMethods';
import { AuthConfig } from '@aws-amplify/core';

export const hasMembership = (userMemberships: string, membership: MembershipTypes) => {
  if (!hasValue(userMemberships) || !hasValue(membership)) {
    return false;
  }
    
  const activeMemberships = parseUserMemberships(userMemberships);
    
  if (!hasValue(activeMemberships)){
    return false;
  }
    
  return activeMemberships.findIndex(mType => mType.toLowerCase() === membership.toLowerCase()) !== -1;
};

export const parseUserMemberships = (userMemberships: string | undefined): MembershipTypes[] => {
  if (!hasValue(userMemberships)){
    return [];
  }

  const parsedUserMemberships: MembershipTypes[] | undefined = userMemberships?.split(';')
    ?.map(membership => membership.trim() as MembershipTypes)
    ?.filter(membership => Object.values(MembershipTypes).findIndex(mType => mType.toLowerCase() === membership.toLowerCase()) !== -1);

  return parsedUserMemberships || [];
};

export const getHighestMembership = (user: CognitoUser | undefined): Membership => {
  if (isNullOrUndefined(user)){
    return {
      type: MembershipTypes.None
    };
  }

  const userMemberships = user[Attributes.Membership];

  const parserUserMemberships = parseUserMemberships(userMemberships);

  if (!hasValue(parserUserMemberships)) {
    return {
      type: MembershipTypes.None
    };
  }

  let highestMembershipType: MembershipTypes = parserUserMemberships[0];

  parserUserMemberships.forEach(membership => {
    if(MembershipsHierarchy[membership] >= MembershipsHierarchy[highestMembershipType]){
      highestMembershipType = membership;
    }
  });

  return {
    type: Object.values(MembershipTypes).find(mType => mType.toLowerCase() === highestMembershipType.toLowerCase()) || MembershipTypes.None,
    status: MembershipStatus.NoChange,
  };
};

export const getMembershipStartingDate = async (user: CognitoUser,
  membership: MembershipTypes, config: AuthConfig) => {
  if (isNullOrUndefined(user) || isNullOrUndefined(membership)){
    return undefined;
  }

  if(membership === MembershipTypes.OneClub) {
    const response = await fetchCreatedDate(config, user?.sub);
    return response?.data;
  }
  if(membership === MembershipTypes.OneClubAdvantage) {
    return user[Attributes.BecameMasterCard];

  }
  return undefined;
};

export const hasRequiredFieldsForMember = async (memberRequiredAttributes?: CognitoUserAttribute[]) => {
  if (!hasValue(memberRequiredAttributes)) {
    return true;
  }

  let hasRequiredFields = true;
        
  const userAttributes = await Auth.fetchUserAttributes()
    .then(attributes => attributes)
    .catch(() => {
      return undefined;
    });
    
  if (isNullOrUndefined(userAttributes)) {
    return hasRequiredFields;
  }

  memberRequiredAttributes?.every((attribute) => {
    const keysArray = attribute?.attributeName?.split('.');
    const rootKey = keysArray?.[0];
    const nestedKeys = keysArray?.slice(1);

    if (!rootKey) {
      return hasRequiredFields;
    }

    const attributeValue = userAttributes[rootKey];

    switch (rootKey) {
    case Attributes.Address:
      if(!validateCognitoUserAddress(attributeValue, nestedKeys)) {
        hasRequiredFields = false;
      }
      break;
    case Attributes.OptIns:
      if(!validateCognitoUserOptIns(attributeValue, nestedKeys)){
        hasRequiredFields = false;
      }
      break;
    case Attributes.Membership:
      if(!validateCognitoUserMembership(attributeValue)){
        hasRequiredFields = false;
      }
      break;
    default:
      if (!hasValue(attributeValue)){
        hasRequiredFields = false;
      }
      break;
    }

    return hasRequiredFields;
  });
    
  return hasRequiredFields;
};

const validateCognitoUserAddress = (userAddresses?: string, requiredAttributes?: string[]) => {
  const addresses = safeParseJSON(userAddresses);

  if (!hasValue(addresses)) {
    return false;
  }

  if (
    !hasAddressOfType(addresses, UserAddressType.MAIN) ||
        !hasAddressOfType(addresses, UserAddressType.SHIPPING) ||
        !hasAddressOfType(addresses, UserAddressType.BILLING)
  ) {
    return false;
  }

  if (!hasValue(requiredAttributes)) {
    return true;
  }

  const mainAddress = getAddressByType(addresses, UserAddressType.MAIN);

  let isAddressValid = true;

  requiredAttributes?.every(attr => {
    if (!hasValue(mainAddress[attr])){
      isAddressValid = false;
      return false;
    }

    return true;
  });

  return isAddressValid;
};

export const hasAddressOfType = (addresses: any[], addressType: UserAddressType) => {
  let hasAddress = false;

  addresses.every((address) => {
    if (address[addressType]) {
      hasAddress = true;
      return false;
    }

    return true;
  });

  return hasAddress;
};

export const getAddressByType = (addresses: any[], addressType: UserAddressType) => {
  return addresses?.find(addr => addr[addressType]);
};

export const getUserCountry = (addressesAttribute: string | undefined, addressType: UserAddressType) => {
  if (!hasValue(addressesAttribute)) {
    return undefined;
  }

  const addressesArray = safeParseJSON(addressesAttribute);

  const address = getAddressByType(addressesArray, addressType);

  return address?.country;
};

export const getUserFavoritePlayer = (userData: CognitoUser) => {
  if ( !hasValue(userData?.[Attributes.Interests]) ) {
    return undefined;
  }

  return safeParseJSON(userData[Attributes.Interests])?.['favoritePlayer'];
};

const validateCognitoUserOptIns = (userOptIns?: string, requiredAttributes?: string[]) => {
  const optIns = safeParseJSON(userOptIns);

  if (!hasValue(optIns)) {
    return false;
  }

  if (!hasValue(requiredAttributes)) {
    return true;
  }

  let isOptInsValid = true;

  requiredAttributes?.every(attr => {
    if (!optIns[attr]){
      isOptInsValid = false;
      return false;
    }

    return true;
  });

  return isOptInsValid;
};

const validateCognitoUserMembership = (memberships?: string) => {
  const userMemberships = parseUserMemberships(memberships);

  if (!hasValue(userMemberships)) {
    return false;
  }

  return userMemberships.includes(MembershipTypes.OneClub);
};