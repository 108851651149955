import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedItemOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import mapLogo from './map-logo';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const header = (entry: MEMBERSHIP_MODELS.Header) => {
  const mobileRawLogo = getFirstLinkedItemOrDefault(
    entry?.elements?.mobile_logo,
  );
  const desktopRawLogo = getFirstLinkedItemOrDefault(
    entry?.elements?.desktop_logo,
  );

  return {
    mobileLogo: mapLogo(mobileRawLogo as MEMBERSHIP_MODELS.Logo),
    desktopLogo: mapLogo(desktopRawLogo as MEMBERSHIP_MODELS.Logo),
    content: entry?.elements?.content?.linkedItems,
    myAccountLink: getFirstMappedLinkedItemOrDefault(entry?.elements?.my_account_link)
  };
};

export default header;
