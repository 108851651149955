/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const collections = {
    /**
     * AIGWO
     */
    aigwo: {
        codename: 'aigwo',
        id: '15c75f3b-ea87-422a-bc8d-e1b568405952',
        name: 'AIGWO'
    },

    /**
     * Countries   Counties States
     */
    countries___counties: {
        codename: 'countries___counties',
        id: 'a97d4886-4d73-43d6-8bdf-6a85645e002a',
        name: 'Countries & Counties/States'
    },

    /**
     * Default
     */
    default: {
        codename: 'default',
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Default'
    },

    /**
     * Development
     */
    development: {
        codename: 'development',
        id: 'ed3f3553-29eb-4297-94d0-d1157fcea30e',
        name: 'Development'
    },

    /**
     * One Club
     */
    one_club: {
        codename: 'one_club',
        id: '39de3d5e-c915-4c4d-a427-4df3b6d12c4d',
        name: 'One Club'
    },

    /**
     * R ATV
     */
    r_atv: {
        codename: 'r_atv',
        id: 'd839b242-d63f-4cf6-be04-d7c3780e1660',
        name: 'R&ATV'
    },

    /**
     * The Open
     */
    the_open: {
        codename: 'the_open',
        id: '1d4c624e-f6c6-4691-962e-4c11dceb69dd',
        name: 'The Open'
    },

    /**
     * The Open CMS
     */
    the_open_cms: {
        codename: 'the_open_cms',
        id: '42b8ffa7-841e-441c-8dcd-44f5ebb33522',
        name: 'The Open CMS'
    }
} as const;
