import { ArticleSchemaOrgModel, ImageSchemaOrgModel,
  PersonSchemaOrgModel,
  WebsiteSchemaOrgModel, GolfCourseSchemaOrgModel,
  PostalAddressSchemaOrgModel, EventSchemaOrgModel
} from '@r-and-a-shared-ui/utils';
import { useEffect } from 'react';

export type SchemaModelContent = ArticleSchemaOrgModel
| ImageSchemaOrgModel
| PersonSchemaOrgModel
| WebsiteSchemaOrgModel
| GolfCourseSchemaOrgModel
| PostalAddressSchemaOrgModel
| EventSchemaOrgModel
| undefined;

const useSchemaOrg = (schemaOrgContent: SchemaModelContent) => {
  useEffect(() => {
    if(!schemaOrgContent) {
      return;
    }
    const script = addSchemaOrg(schemaOrgContent);
    return () => {
      removeScript(script);
    };
  }, [schemaOrgContent]);
};

const addSchemaOrg = (schemaOrgContent : SchemaModelContent) => {
  if(!schemaOrgContent) {
    return null;
  }
  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.innerHTML = JSON.stringify(schemaOrgContent);
  document.head.appendChild(script);
  
  return script;
};
  
const removeScript = (script: HTMLScriptElement | null) => {
  if(!script) {
    return;
  }
  try {
    document.head.removeChild(script);
    return;
  }
  catch(exception) {
    return;
  }
};

export default useSchemaOrg;