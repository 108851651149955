import { ResponseModel, getParameterByName } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { RegisterInputSchema, userDefaultValuesRegister } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { OauthQuery, getMembership } from "@r-and-a-shared-ui/auth-client";
import { sendGAEvent } from '@next/third-parties/google';


export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {
  params.submitData.redirectUri = getParameterByName(OauthQuery.REDIRECT_URI);

  const response = await genericMutationHandlerWithFormValidation(
    params,
    RegisterInputSchema,
    userDefaultValuesRegister,
  );

  if (!response?.hasErrors) {
    sendGAEvent({ event: 'one_club_registration', value: { membership: getMembership(params) } });
  }

  return response;
};

export default handle;