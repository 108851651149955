import { RichTextWithAsset } from '@r-and-a-shared-ui/common-forms-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: ()=>RichTextWithAsset,
  mapEntryToProps: (entry: SSO_MODELS.RichTextWithAsset) =>
    kontentAiDataMapping.rich_text_with_asset(entry),
};
