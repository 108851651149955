import { formatMembershipDate } from '@r-and-a-shared-ui/utils';
import { ArticleProps } from '../Article/Article';
import { Link } from '../Link/Link';
import { Image } from '../Image/Image';
import classnames from 'classnames';
import MembershipTypesLogos from '../MembershipTypeLogos/MembershipTypesLogos';
import { useScreenDetector } from '../../hooks';

import styles from './ArticleHighlight.module.scss';

export function ArticleHighlight(props: ArticleProps) {
  const { seo, author, publishDate, title, mobileTitle, subtitle, primaryImage, access } = props;
  const { isMobile } = useScreenDetector();
  const backgroundImage = primaryImage?.imageUrl;
  const altImage = primaryImage?.alt;

  const displayTitle = isMobile ? (mobileTitle || title) : (title || mobileTitle);

  return (
    <div className={styles.articleHighlightContainer}>
      {backgroundImage && (
        <div className={styles['background']}>
          <Image
            imageUrl={backgroundImage}
            alt={altImage || 'Hero background'}
            width={1280}
            height={720}
            schemaOrgContent={primaryImage.schemaOrgContent}
          />
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h3 className={styles.title}>{displayTitle}</h3>
          {subtitle && <div className={styles.description}>{subtitle}</div>}
          <div className={styles.info}>
            <div className={styles.author}>By {author}</div>
            {publishDate && (
              <div className={styles.date}>
                {formatMembershipDate(publishDate)}
              </div>
            )}
          </div>
          <Link
            title="Read Now"
            className={classnames(styles.btn, styles['btn--secondary'])}
            href={`/articles/${seo?.urlSlug}` || '#'}
            internal
          />
        </div>
      </div>
      <MembershipTypesLogos restrictionLevel={access!} />
    </div>
  );
}

export default ArticleHighlight;
