import { Elements } from '@kontent-ai/delivery-sdk';
import {
  getFirstLinkedItemOrDefault,
  getSingleChoiceValue,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const membership_type = (
  rawMembership_type: MEMBERSHIP_MODELS.MembershipType,
) => {
  const welcomeMessage = getFirstLinkedItemOrDefault(
    rawMembership_type.elements.welcome_message as Elements.LinkedItemsElement,
  );

  const popupContent = rawMembership_type.elements.popup_content.linkedItems;

  return {
    id: rawMembership_type.system.id,
    membershipType: getSingleChoiceValue(rawMembership_type.elements.type),
    price: rawMembership_type.elements.price.value,
    label: rawMembership_type.elements.label?.value,
    image: rawMembership_type.elements.icon_image?.value?.[0]?.url ?? undefined,
    popupContent,
    activeBackgroundColor: getColorValue(
      rawMembership_type.elements.active_background_color.linkedItems[0],
    ),
    inactiveBackgroundColor: getColorValue(
      rawMembership_type.elements.inactive_background_color.linkedItems[0],
    ),
    welcomeMessage,
  };
};
export default membership_type;
