import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../RenderOn/RenderOn';
import classnames from 'classnames';
import styles from './Header.module.scss';
import { sendGAEvent } from '@next/third-parties/google';
import { getPageTrackingDetails } from '@r-and-a-shared-ui/utils';

export const HeaderSignOutButton = () => {
  const {
    methods: { federatedSignOut },
    userState: { isAuthenticated },
  } = useAuthContext();

  const handleClick = () => {
    sendGAEvent({ event: 'log_out_click', value: getPageTrackingDetails() });
    federatedSignOut(true);
  };

  return (
    <RenderOn condition={isAuthenticated} key={'sign-out-button'}>
      <span
        onClick={handleClick}
        data-testid={'HeaderSignOutButton'}
        className={classnames(styles.btn, styles['btn--primary'])}
      >
        Sign Out
      </span>
    </RenderOn>
  );
};
