import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';
import { CTA } from '@r-and-a-shared-ui/common-ui';
import type {
  SSO_MODELS,
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: () => CTA,
  mapEntryToProps: (entry: SSO_MODELS.CTA & MEMBERSHIP_MODELS.CTA) =>
    kontentAiDataMapping.cta(entry),
};
