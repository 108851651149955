import React from 'react';
import { FormFieldProps } from '../FormField/FormField';
import {
  FormTextInputProps,
  FormTextInput,
} from '../FormTextInput/FormTextInput';
import { FormDropdown } from '../FormDropdown/FormDropdown';
import { FormCheckboxesGroup } from '../FormCheckboxesGroup/FormCheckboxesGroup';
import { FormRadioButtons } from '../FormRadioButtons/FormRadioButtons';
import {
  CHECKBOXES,
  PASSWORD_CONFIRMATION,
  DATE,
  DROPDOWN,
  EMAIL,
  NUMBER,
  PASSWORD,
  PHONE_NUMBER,
  RADIO_BUTTONS,
  TEXT,
  UNDEFINED,
  COUNTRY,
  MASTERCARD,
  FAVORITE_PLAYER,
  ONE_CLUB_FREE_TEXT
} from '../../constants/fieldTypes';
import FormPhoneField from '../FormPhoneField/FormPhoneField';
import CountryFormField from '../CountryFormField/CountryFormField';
import FavoritePlayerFormField from '../FavoritePlayerFormField/FavoritePlayerFormField';
import FormDateField, {
  FormDateFieldProps,
} from '../FormDateField/FormDateField';
import MastercardFormField from '../MastercardFormField/MastercardFormField';
import FreeText from '../FreeText/FreeText';

export type FormFieldTypes =
  | typeof TEXT
  | typeof EMAIL
  | typeof PASSWORD
  | typeof DROPDOWN
  | typeof RADIO_BUTTONS
  | typeof CHECKBOXES
  | typeof NUMBER
  | typeof PHONE_NUMBER
  | typeof PASSWORD_CONFIRMATION
  | typeof COUNTRY
  | typeof UNDEFINED
  | typeof DATE
  | typeof MASTERCARD
  | typeof FAVORITE_PLAYER
  | typeof ONE_CLUB_FREE_TEXT;

export const formFieldsConfig: Record<
  FormFieldTypes,
  (fieldProps: FormFieldProps) => React.JSX.Element | undefined
> = {
  text: (fieldProps: FormTextInputProps) => {
    return <FormTextInput {...fieldProps} />;
  },
  email: (fieldProps: FormTextInputProps) => formFieldsConfig.text(fieldProps),
  password: (fieldProps: FormTextInputProps) => formFieldsConfig.text(fieldProps),
  password_confirmation: (fieldProps: FormTextInputProps) => {
    return <FormTextInput {...fieldProps} fieldType={PASSWORD} />;
  },
  dropdown: (fieldProps: FormFieldProps) => {
    return <FormDropdown {...fieldProps} menuPlacement="bottom" />;
  },
  radio_buttons: (fieldProps: FormFieldProps) => {
    return <FormRadioButtons {...fieldProps} />;
  },
  checkboxes: (fieldProps: FormFieldProps) => {
    return <FormCheckboxesGroup {...fieldProps} />;
  },
  number: (fieldProps: FormFieldProps) => {
    return <FormTextInput {...fieldProps} />;
  },
  date: (fieldProps: FormDateFieldProps) => {
    return <FormDateField {...fieldProps} />;
  },
  phone_number: (fieldProps: FormFieldProps) => {
    return <FormPhoneField {...fieldProps} />;
  },
  country: (fieldProps: FormFieldProps) => {
    return <CountryFormField {...fieldProps} />;
  },
  mastercard: (fieldProps: FormFieldProps) => {
    return <MastercardFormField {...fieldProps} />;
  },
  favorite_player: (fieldProps: FormFieldProps) => {
    return <FavoritePlayerFormField {...fieldProps} />;
  },
  one_club_free_text: (fieldProps: FormFieldProps) => {
    return <FreeText {...fieldProps} />;
  },
  undefined: (...args: unknown[]) => undefined,
};

