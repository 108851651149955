import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedAssetOrDefault,
  getFirstLinkedItemOrDefault,
  getColorValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';

export const footer = (entry: MEMBERSHIP_MODELS.Footer) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  const masterLogo = getFirstLinkedAssetOrDefault(elements?.master_logo);
  const oneClubLogo = getFirstLinkedAssetOrDefault(elements?.one_club_logo);
  const backgroundImage = getFirstLinkedAssetOrDefault(
    elements?.background_image,
  );

  return {
    allRightReserved: elements?.all_rights_reserved?.value,
    backgroundColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.background_color),
    ),
    backgroundImage: {
      url: backgroundImage?.url,
      alt: backgroundImage?.description,
    },
    masterLogo: { url: masterLogo?.url, alt: masterLogo?.description },
    navigationLinks: getMappedLinkedItems(elements?.navigation_links),
    navigationTitle: elements?.navigation_title?.value,
    oneClubLogo: { url: oneClubLogo?.url, alt: oneClubLogo?.description },
    organizationLogos: getMappedLinkedItems(elements?.organizations_logos),
    otherLinks: getMappedLinkedItems(elements?.other_grouped_links),
    socialContentLinks: getMappedLinkedItems(elements?.social_content_links),
    socialContentTitle: elements?.social_content_title?.value,
    socialIconsLinks: getMappedLinkedItems(elements?.social_icons_links),
  };
};

export default footer;
