import type { FC } from 'react';
import styles from './SSOButtons.module.scss';
import { SSOSignInButton } from './SSOSignInButton';
import { SSOSignOutButton } from './SSOSignOutButton';
import { SSOSignUpButton } from './SSOSignUpButton';
import { SSOUpgradeButton } from './SSOUpgradeButton';

export const SSOCTAs: FC = () => {
  return (
    <div className={styles.ctas}>
      <SSOSignInButton />
      <SSOSignOutButton />
      <SSOSignUpButton />
      <SSOUpgradeButton />
    </div>
  );
};
