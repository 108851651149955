import * as Auth from 'aws-amplify/auth';
import { ResponseModel, hasValue, redirectTo } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { ChangePasswordInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { z } from "zod";
import { CHANGE_PASSWORD_SUCCESS } from '../../constants';
import { handleOauth2Redirect } from '@r-and-a-shared-ui/auth-client';

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {
  const { nextStepsAfterSubmit } = params;

  const resultData = await genericMutationHandlerWithFormValidation(
    params,
    ChangePasswordInputSchema,
  );
  
  if(resultData?.hasErrors) {
    return resultData;
  }

  if (hasValue(nextStepsAfterSubmit)) {
    redirectTo(
      nextStepsAfterSubmit?.find(
        item => item.key === CHANGE_PASSWORD_SUCCESS,
      )?.item?.urlSlug, true
    );

    return resultData;
  }
  
  handleOauth2Redirect({ purgeQuery: true, useWholeURL: true });

  return resultData;
};
  
export const confirmSignInWithChangePassword = async (
  input: z.infer<typeof ChangePasswordInputSchema>
) => {
  const updatePasswordInput: Auth.UpdatePasswordInput = {
    oldPassword: input?.oldPassword,
    newPassword: input?.newPassword
  };
  
  await Auth.updatePassword(updatePasswordInput);

  await Auth.fetchAuthSession({ forceRefresh: true });
};

export default handle;