import React, { FC } from 'react';
import { MainMenu } from './MainMenu';
import { ImageProps } from '../Image/Image';
import { MenuFooterProps } from '../MenuFooter/MenuFooter';

type MenuItem =
  | {
      title: string;
      href: string;
      internal: boolean;
      children?: never;
    }
  | {
      title: string;
      children: MenuItem[];
    };

type MenuProps = {
  menuStyle: string;
  menu: MenuItem[];
  backgroundImage?: ImageProps;
  extraItems?: React.ReactNode[];
  footer?: MenuFooterProps[];
  title: string;
  children?: React.ReactNode | React.ReactNode[];
};

export const Menu: FC<MenuProps> = (props: MenuProps) => {
  if (!props) return null;
  const { menu, menuStyle, children, extraItems, backgroundImage, footer } = props;
  
  switch (menuStyle) {
  case 'main_menu':
    return (
      <MainMenu menu={menu} extraItems={extraItems} backgroundImage={backgroundImage} footer={footer}>
        {children}
      </MainMenu>
    );
  default:
    return <MainMenu menu={menu} footer={footer}>{children}</MainMenu>;
  }
};
