import {
  AsyncComponent,
  ConfiguredComponents,
} from '@r-and-a-shared-ui/core-component-config';
import { RenderOn } from '../RenderOn/RenderOn';
import type { CoachProps } from './Coach';
  
import styles from './Coach.module.scss';
  
export const SubHeader = ({ subheader }: Pick<CoachProps, 'subheader'>) => {
  return (
    <RenderOn condition={subheader?.length > 0}>
      <section
        className={styles['subheader']}
        data-testid={'coach-subheader'}
      >
        {subheader.map(x => (
          <AsyncComponent
            kontentItem={x}
            contentType={x.system.type as ConfiguredComponents}
            key={x.system.id}
          />
        ))}
      </section>
    </RenderOn>
  );
};
  