import styles from './RichTextParser.module.scss';
import parse from 'html-react-parser';

export interface RichTextParserProps {
  richText: string | undefined,
}

export function RichTextParser(props: RichTextParserProps) {
  const { richText } = props;
  return (
    richText &&
    <div className={styles['container']}>
      {parse(richText, { trim: true })}
    </div>
  );
}

export default RichTextParser;
