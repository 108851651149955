/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
import styles from './FormPhoneField.module.scss';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useController, useFormContext } from 'react-hook-form';
import { FormFieldProps } from '../FormField/FormField';
import 'react-phone-input-2/lib/style.css';
import { DEFAULT_COUNTRY, hasValue } from '@r-and-a-shared-ui/utils';
import { defaultValidationMessage } from '../config/validators/validators';
import { ChangeEvent, useState } from 'react';


export function FormPhoneField(props: FormFieldProps) {
  const {
    name,
    fieldRef,
    placeholder,
    label,
    subfields = [],
    isDisabled,
    validationMessage,
    options,
  } = props;

  const {
    setValue,
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const { field } = useController({ name, control });
  const { value, onChange, ...restField } = field;
  const [fieldCountryCode, setFieldCountryCode] = useState(DEFAULT_COUNTRY);

  const getPreferredCountries = (): string[] => {
    const preferredCountries: string[] = [];
    options?.forEach(option => {
      if (option?.value) {
        preferredCountries.push(option?.value);
      }
    });
    return preferredCountries;
  };

  const handlePhoneInputChange = (value: string, country: {} | CountryData,
    e: ChangeEvent<HTMLInputElement>, formattedValue: string) =>{
    if(!value || value === '+') {
      onChange('');
      setFieldCountryCode(DEFAULT_COUNTRY);
      setCountryDialCode(value, country);
      return;
    }
    onChange(formattedValue);
    setCountryDialCode(value, country);
  };

  const setCountryDialCode = (value: string, country: {} | CountryData) => {
    // @ts-ignore
    const countryDialCode = country?.dialCode !== "" ? "+" + country?.dialCode : "";
    setFieldCountryCode(countryDialCode);
    if (subfields?.length > 0)
    {
      // @ts-ignore
      if (value && value !== '+' && value !== country?.dialCode)
      {
        setValue(subfields[0]?.name, countryDialCode);
      }
      else
      {
        setValue(subfields[0]?.name,"");
      }
    }
  };


  return (
    <div
      className={`${styles['form-element__container']} ${
        errors?.[name] ? styles['form-element__has-error'] : ''
      }`}
      ref={fieldRef}
    >
      {hasValue(label) && (
        <label
          className={`${styles['form-element__label']} ${
            value ? styles['form-element__has-value'] : ''
          }`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <PhoneInput
        placeholder={placeholder}
        disabled={isDisabled}
        preferredCountries={getPreferredCountries()}
        value={value}
        country={fieldCountryCode}
        {...restField}
        {...register(name)}
        onChange={handlePhoneInputChange}
        autoFormat={false}
      />
      <div className={styles['form-element__bar']}></div>
      {errors?.[name] ? (
        <small className={styles['form-element__hint']}>
          {validationMessage || defaultValidationMessage}
        </small>
      ) : null}
    </div>
  );
}

export default FormPhoneField;
