import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';
import mapArticleListicle from './map-article_listicle';
import { getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';

export const recommended_articles = (entry: MEMBERSHIP_MODELS.RecommendedArticles) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }

  const { elements } = entry || {};

  const category = elements.filter_dynamic_list.linkedItems
    .filter(item => item.system.type === 'category')
    .map(category => category.system.codename);

  const author = elements.filter_dynamic_list.linkedItems
    .filter(item => item.system.type === 'article_author')
    .map(author => author.system.codename);

  const articlesList = elements.articles_list?.linkedItems?.map(x => mapArticleListicle(x)) || [];

  const articlesValue = elements.articles_list?.value || [];

  return {
    highLightedArticle: getFirstMappedLinkedItemOrDefault(elements?.highlighted_article),
    articlesList,
    maxListItems: elements?.max_list_items?.value || 5,
    ctaAllArticlesText: elements?.cta_all_articles_text?.value,
    title: elements?.title?.value,
    highlightArticlePosition: getSingleChoiceValue(elements?.highlighted_article_position),
    filters: { author, category },
    isEmpty: articlesValue.length === 0
  };
};

export default recommended_articles;
