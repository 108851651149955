import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';

export const dynamic_filter = (entry: MEMBERSHIP_MODELS.DynamicFilter) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  const rawImage = elements.background_image?.value?.[0];

  const adaptedImage = rawImage ? {
    imageUrl: rawImage.url,
    license: 'default-license',
    width: rawImage?.width || 100,
    height: rawImage?.height || 100,
    alt: rawImage.name,
  } : null;

  return {
    component: getSingleChoiceValue(elements?.component),
    backgroundImage: adaptedImage,
  };
};

export default dynamic_filter;
