import type { FC } from 'react';
import { useContext } from 'react';
import { MembershipType } from '../MembershipType/MembershipType';
import { EnvironmentContext } from '../../contexts';
import { MembershipTeaserPanelProps } from './types';
import { useMembershipTeaserPanel } from './useMembershipTeaserPanel';
import { Popup } from './Popup/Popup';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';

import styles from './MembershipTeaserPanel.module.scss';
import 'slick-carousel/slick/slick.css';

export const MembershipTeaserPanel: FC<MembershipTeaserPanelProps> = ({
  membershipTypes,
  logo,
  backgroundImage,
}: MembershipTeaserPanelProps) => {
  const { Image } = useContext(EnvironmentContext);
  const {
    activeMessage,
    activePopupIndex,
    closePopup,
    membershipPopups,
    openPopup,
    popUpIsOpened,
    parsedMembershipTypes,
    sliderBeforeChange,
  } = useMembershipTeaserPanel({
    membershipTypes,
    backgroundImage,
  });

  return (
    <div className={styles.wrapper}>
      {backgroundImage ? (
        <div className={styles.background}>
          <Image
            src={backgroundImage}
            alt={activeMessage || 'Membership Panel Image'}
            width={1000}
            height={700}
            sizes="100vw"
          />
        </div>
      ) : (
        ''
      )}
      <div className={styles.content}>
        <AsyncComponent
          kontentItem={activeMessage}
          contentType={activeMessage?.system?.type}
        />
        <div className={styles.logo}>
          <Image
            src={logo.url}
            alt={logo.alt || "Logo"}
            width={logo.width}
            height={logo.height}
            sizes="100vw"
          />
        </div>
        <div className={styles.membership}>
          {parsedMembershipTypes?.map(membershipType => (
            <div
              key={membershipType.id}
              className={styles['membership--button']}
              onClick={() => openPopup?.(membershipType.id)}
            >
              <MembershipType
                key={membershipType.membershipType}
                {...membershipType}
              />
            </div>
          )) ?? ''}
        </div>
      </div>
      <Popup
        popupElements={membershipPopups}
        onClose={closePopup}
        beforeChange={sliderBeforeChange}
        activePopupIndex={activePopupIndex}
        isVisible={popUpIsOpened}
      />
    </div>
  );
};
