import React, { FC, useEffect } from 'react';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import { SEOWrapper, SEOWrapperProps } from '../SEOWrapper/SEOWrapper';
import { useAccessRestrictedContent, useScreenDetector } from '../../hooks';
import { RestrictedContentPrompt } from '../RestrictedContentPrompt/RestrictedContentPrompt';
import MembershipTypesLogos from '../MembershipTypeLogos/MembershipTypesLogos';
import { DotDigitalForm } from '../DotDigitalForm/DotDigitalForm';
import { Image, ImageProps } from '../Image/Image';
import { Link, LinkProps } from '../Link/Link';
import { RenderOn } from '../RenderOn/RenderOn';
import styles from './Reward.module.scss';
import { SubHeader } from '../Page/SubHeader';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

type RewardProps = {
  title?: string;
  subtitle?: string;
  primaryImage?: ImageProps;
  primaryMobileImage?: ImageProps;
  body?: any[];
  sidebar?: any[];
  textColor?: string;
  seo?: SEOWrapperProps;
  access?: keyof typeof RestrictionLevel;
  enterButtonLink: LinkProps;
  termsAndConditionsLink: LinkProps;
  dotDigitalFormIframe: any;
  subheader: any[];
};

export const Reward: FC<RewardProps> = ({
  title,
  subtitle,
  primaryImage,
  primaryMobileImage,
  body,
  sidebar,
  seo,
  access,
  termsAndConditionsLink,
  dotDigitalFormIframe,
  subheader,
}) => {
  const { userHasAccess = true } = useAccessRestrictedContent({ access });
  const { isMobile, isTablet, isClient, width } = useScreenDetector();

  useEffect(() => {
    // Optional: additional logic to handle width change
  }, [width]);

  if (!isClient) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  const imageSource = isMobile && primaryMobileImage ? primaryMobileImage : primaryImage;

  return (
    <>
      <SubHeader subheader={subheader} />
      <main className={styles.wrapper} data-testid={`Reward`}>
        <div className={styles.container}>
          <SEOWrapper
            title={seo?.title}
            description={seo?.description}
            keywords={seo?.keywords}
            noindex={seo?.noindex}
            nofollow={seo?.nofollow}
            urlSlug={seo?.urlSlug}
          />

          <RenderOn condition={Boolean(imageSource)}>
            <div className={styles.wrapper__image}>
              {imageSource && <Image {...imageSource} />}
              <MembershipTypesLogos
                restrictionLevel={access as keyof typeof RestrictionLevel}
                className={styles['membership-type']}
              />
            </div>
          </RenderOn>
          <div className={styles.content}>
            <div className={styles.content__left}>
              <section className={styles.reward__info}>
                {title && <h1 className={styles.reward__title}>{title}</h1>}
                {termsAndConditionsLink && (
                  <Link
                    className={styles.reward__terms_link}
                    {...termsAndConditionsLink}
                  />
                )}
              </section>
              {subtitle && (
                <p className={styles.reward__subtitle}>{subtitle}</p>
              )}

              <section className={styles.reward__form}>
                {userHasAccess && dotDigitalFormIframe && (
                  <DotDigitalForm {...dotDigitalFormIframe} />
                )}
                <RestrictedContentPrompt access={access} />
              </section>
              <section className={styles.reward__more_content}>
                <RenderOn condition={!!body?.length}>
                  {body
                    ?.map(x => {
                      return (
                        <AsyncComponent
                          key={x?.system?.id}
                          kontentItem={x}
                          contentType={x?.system?.type}
                        />
                      );
                    })
                    .filter(Boolean)}
                </RenderOn>
              </section>
            </div>
            <div className={styles.content__right}>
              <RenderOn condition={!!sidebar?.length}>
                {sidebar
                  ?.map(x => {
                    // showing only web_banner_placeholder on tablet
                    if (
                      isTablet &&
                      x?.system?.type !== 'web_banner_placeholder'
                    ) {
                      return null;
                    }
                    return (
                      <AsyncComponent
                        key={x?.system?.id}
                        kontentItem={x}
                        contentType={x?.system?.type}
                      />
                    );
                  })
                  .filter(Boolean)}
              </RenderOn>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
