import { useEffect, useState } from 'react';

export const useAutoplay = (
  autoPlay?: boolean,
  autoplayTimer?: number | null,
  autoplayTimeout?: number | null,
) => {
  const [isAutoPlay, setIsAutoplay] = useState(autoPlay);

  useEffect(() => {
    if (autoPlay && autoplayTimer && autoplayTimeout) {
      const stopIntervalId = setTimeout(() => {
        setIsAutoplay(prev => !prev);
      }, autoplayTimeout * 1000);

      return () => {
        clearTimeout(stopIntervalId);
        setIsAutoplay(false);
      };
    }
  }, [autoPlay, autoplayTimer, autoplayTimeout]);

  return { isAutoPlay };
};
