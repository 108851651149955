import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';

export const menu_footer = (entry: MEMBERSHIP_MODELS.MenuFooter) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  return {
    logos: getMappedLinkedItems(elements?.logos),
  };
};

export default menu_footer;
