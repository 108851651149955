import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../RenderOn/RenderOn';
import classnames from 'classnames';
import styles from './Header.module.scss';

export const HeaderSignUpButton = () => {
  const {
    methods: { signUp },
    userState: { isAuthenticated },
  } = useAuthContext();
  return (
    <RenderOn condition={!isAuthenticated} key={'sign-up-button'}>
      <span
        onClick={() => signUp()}
        data-testid={'HeaderSignUpButton'}
        className={classnames(styles.btn, styles['btn--primary'])}
      >
        Sign Up
      </span>
    </RenderOn>
  );
};
