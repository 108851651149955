import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';

export const rich_text_with_asset = (data: SSO_MODELS.RichTextWithAsset) => {
  if (!data || !data?.elements) {
    return undefined;
  }
  const { elements } = data;

  const desktopAsset = getFirstLinkedAssetOrDefault(elements?.desktop_image);
  const mobileAsset = getFirstLinkedAssetOrDefault(elements?.mobile_image);

  return {
    key: data?.system?.codename,
    content: elements?.content.value,
    desktopAsset: desktopAsset,
    mobileAsset: mobileAsset,
    url: elements?.url?.value
  };
};

export default rich_text_with_asset;
