import React from 'react';
import styles from './WebBannerPlaceholder.module.scss';
import WebBanner from '../WebBanner/WebBanner';
import useBannerData from './useBannerData';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import classNames from 'classnames';

export interface WebBannerPlaceholderProps {
  forced_portrait?: boolean;
  selected_web_banners?: IContentItem<any>[];
}

export function WebBannerPlaceholder({
  forced_portrait,
  selected_web_banners,
}: WebBannerPlaceholderProps) {
  const bannerData = useBannerData(selected_web_banners);

  if (!bannerData) return null;

  return (
    <div
      className={classNames(
        styles.wrapper,
        forced_portrait ? styles['wrapper--portrait'] : '',
      )}
    >
      <WebBanner
        mobile_image={
          bannerData.mobile_image ||
          bannerData.window_image ||
          bannerData.portrait_image
        }
        window_image={
          forced_portrait
            ? bannerData.portrait_image || bannerData.window_image
            : bannerData.window_image || bannerData.portrait_image
        }
        link={bannerData.link}
      />
    </div>
  );
}

export default WebBannerPlaceholder;
