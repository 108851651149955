import React, { JSX } from 'react';

type RenderOnProps = {
  condition: boolean;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
};

export const RenderOn = ({ condition, children }: RenderOnProps) => {
  if (!condition) {
    return null;
  }

  return children;
};
