export const enum SchemaOrgTypes {
    NEWS_ARTICLE = 'NewsArticle',
    WEBSITE='WebSite',
    VIDEO_OBJECT = 'VideoObject',
    IMAGE_OBJECT = 'ImageObject',
    SPORTS_EVENT = 'SportsEvent',
    PERSON = 'Person',
    ORGANIZATION = 'Organization',
    PLACE = 'Place',
    POSTAL_ADDRESS = 'PostalAddress',
    GOLF_COURSE = 'GolfCourse'
}

export const SchemaOrgContext = "http://schema.org";

export type PersonSchemaOrgModel = {
    "@type": string,
    "@context": string,
    name: string,
    url?: string,
}
export type ArticleSchemaOrgModel = {
    "@type": string,
    headline?: string,
    text?: string,
    "@context": string,
    url?: string,
    image?: string,
    datePublished?: string,
    author?: PersonSchemaOrgModel[],
}

export type ImageSchemaOrgModel = {
    "@type": string,
    "@context": string,
    contentUrl?: string,
    alternateName?: string,
}

export type WebsiteSchemaOrgModel = {
    "@type": string,
    "@context": string,
    url?: string,
    name?: string,
    alternateName?: string,
}
export type GolfCourseSchemaOrgModel = {
  "@type": string,
  "@context": string,
  openingHours?: string,
  location?: PostalAddressSchemaOrgModel,
  url?: string,
  name?: string,
}

export type PostalAddressSchemaOrgModel = {
  "@type":string,
  "@context": string,
  streetAddress?:string,
  postalCode?:string,
  addressCountry?:string
}
export type EventSchemaOrgModel = {
  "@type": string,
  "@context": string,
  name?: string,
  startDate?: string,
  endDate?: string,
  location?: GolfCourseSchemaOrgModel
}
export const getWebsiteStructuredData = () => {
  try {
    return {
      "@type": SchemaOrgTypes.WEBSITE,
      "@context": SchemaOrgContext,
      url: window.location.origin,
      name: "One Club",
      alternateName:  window.location.host,
    };
  }
  catch(exception) {
    return undefined;
  }
};

export const getEventStructuredData = (props: {
  title?: string;
  eventLocation?: string;
  eventStartDate?: string | null;
  eventEndDate?: string | null;
}) => {
  try {
    const {
      eventLocation,
      title,
      eventStartDate,
      eventEndDate,
    } = props;

    if(!eventEndDate ||  !eventStartDate) {
      return undefined;
    }

    return {
      "@type": SchemaOrgTypes.SPORTS_EVENT,
      "@context": SchemaOrgContext,
      name: title,
      startDate: eventStartDate,
      endDate: eventEndDate,
      location: {
        "@type": SchemaOrgTypes.GOLF_COURSE,
        "@context": SchemaOrgContext,
        location: {
          "@type": SchemaOrgTypes.POSTAL_ADDRESS,
          "@context": SchemaOrgContext,
          streetAddress: eventLocation,
        },
        name: title,
        url: window.location.href
      }
    };
  }
  catch(exception) {
    return undefined;
  }
};