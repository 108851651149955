import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const event_hero = (entry: MEMBERSHIP_MODELS.EventHero) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  const backgroundAsset = getFirstLinkedAssetOrDefault(
    elements?.background_asset,
  );

  const mobileImgVideoPreview = getFirstLinkedAssetOrDefault(
    elements?.mobile_img_video_preview,
  );

  return {
    backgroundAsset: {
      url: backgroundAsset?.url,
      type: backgroundAsset?.type,
      alt: backgroundAsset?.description,
    },
    mobileImgVideoPreview: {
      url: mobileImgVideoPreview?.url,
      alt: mobileImgVideoPreview?.description,
    },
    title: elements?.title?.value,
    description: elements?.description?.value,
    playVideoText: elements?.play_video_text?.value,
    eventLocation: elements?.event_location?.value,
    eventStartDate: elements?.event_start_date?.value,
    eventEndDate: elements?.event_end_date?.value,
    viewEventLink: getFirstMappedLinkedItemOrDefault(elements?.view_event_link) || {codename: elements?.view_event_link?.value?.[0]},
  };
};

export default event_hero;
