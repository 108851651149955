export function getCookies(): { name: string, value: string }[] {
  return document.cookie
    .split(';')
    .map(cookie => {
      const [name, value] = cookie.split('=').map(c => c.trim());
      return { name, value: decodeURIComponent(value) };
    });
}

export const setCookie = (name: string, value: string, hours: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + hours * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};