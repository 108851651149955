/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import styles from './StoriesIFrame.module.scss';

export interface StoriesIFrameProps {
  PlayerID: string;
  WidgetID: string;
}
export function StoriesIFrame(props: StoriesIFrameProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { PlayerID, WidgetID } = props;
  const canUseDOM = typeof window !== 'undefined';

  const useEffectHook = canUseDOM ? useLayoutEffect : useEffect;

  useEffectHook(() => {
    if (!ref.current) return;
    // Create a container for your custom HTML
    const divContainer = document.createElement('div');
    divContainer.id = `${PlayerID}`;
    divContainer.innerHTML = `<style>#mss3 * { box-sizing: border-box; } ... </style><div id="msfb"></div>`;
    // @ts-ignore
    ref.current.appendChild(divContainer);
    // Create and append style link
    const styleLink = document.createElement('link');
    styleLink.href = 'https://cdn.ampproject.org/amp-story-player-v0.css';
    styleLink.rel = 'stylesheet';
    styleLink.type = 'text/css';
    document.head.appendChild(styleLink);
    // Create and append scripts
    const scriptUrls = [
      'https://cdn.ampproject.org/amp-story-player-v0.js',
      'https://js.makestories.io/player/StoryPlayer.js',
    ];
    let scriptsToLoad = scriptUrls.length;
    // Function to call when scripts are loaded
    const onScriptsLoaded = () => {
      if (scriptsToLoad === 0) {
        // Append your HTML content here after scripts are loaded
        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = `var playerxom = msPlayer({ root: "#${PlayerID}", widgetId: "${WidgetID}" });`;
        //@ts-ignore
        ref.current?.appendChild(inlineScript);
      }
    };
    scriptUrls.forEach(url => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = () => {
        scriptsToLoad--;
        onScriptsLoaded();
      };
      document.body.appendChild(script);
    });
    // Clean up on unmount
    return () => {
      document.head.removeChild(styleLink);
      scriptUrls.forEach(url => {
        document
          .querySelectorAll(`script[src="${url}"]`)
          .forEach(el => document.body.removeChild(el));
      });
      // @ts-ignore
      ref.current?.removeChild(divContainer);
    };
  }, [PlayerID, WidgetID]);
  return <div ref={ref} className={styles['wrapper']}></div>;
}
export default StoriesIFrame;
