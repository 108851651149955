import { MembershipTypes, SSOCtaAction } from "@r-and-a-shared-ui/auth-client";

export const renderOnBySSOCtaAction = (action: string, params: {isAuthenticated: boolean,
    membershipType: string}) => {
  const {isAuthenticated, membershipType} = params;
  switch (action) {
  case SSOCtaAction.REGISTER:
    return !isAuthenticated;
  case SSOCtaAction.LOGIN:
    return !isAuthenticated;
  case SSOCtaAction.LOGOUT:
    return isAuthenticated;
  case SSOCtaAction.UPGRADE:
    return isAuthenticated &&
    (membershipType === MembershipTypes.OneClub ||
      membershipType === MembershipTypes.None);
  case SSOCtaAction.UPDATE:
    return isAuthenticated;
  case SSOCtaAction.CHANGE_PASSWORD:
    return isAuthenticated;
  case SSOCtaAction.DELETE_ACCOUNT:
    return isAuthenticated;
  default:
    return true;
  }
};