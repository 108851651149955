import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { deliveryClient } from '../config/kontent-delivery-client';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { adjustSystemProperties } from '../utils';

export const getItemByCodeName = async <Type extends IContentItem>(
  codeName: string,
  waitForLoadingNewContent = false,
  cache = true,
  cacheTTL = CacheConfig.TTL.PageElement.valueOf(),
  cacheStatus?: CacheStatus
): Promise<Type | undefined> => {
  const cacheKey = `kontent-itemByCodename-${codeName}`;
  try {
    const cachedItem = getCachedItem<Type>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const response = await deliveryClient
      .item<Type>(codeName)
      .queryConfig({
        waitForLoadingNewContent,
      })
      .toPromise();

    const result = response?.data?.item as Type;

    adjustSystemProperties(result);

    setCacheItem<Type>(cacheKey, result, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return result;
  } catch (error) {
    console.error('Error in getItemByCodeName', error);
    throw error;
  }
};
