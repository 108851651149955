import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const dotdigital_form_iframe = (
  iContentItem: MEMBERSHIP_MODELS.DotdigitalFormIFrame,
) => {
  if (!iContentItem || !iContentItem?.elements) {
    return undefined;
  }

  const { elements } = iContentItem;

  // we have the following sample data
  /**
   *
   * <script src="//r1.marketing-pages.com/resources/sharing/embed.js?sharing=lp-embed&domain=r1.marketing-pages.com&id=49MT-WES%2F%22&description="></script>
   *
   */

  // we need to extract the script src
  const scriptSrc = elements?.embed_script?.value
    ?.split('src="')[1]
    ?.split('"')[0];
  const formId = scriptSrc?.split('id=')[1];

  return {
    scriptSrc: scriptSrc,
    formId: formId,
  };
};

export default dotdigital_form_iframe;
