import { api } from '@r-and-a-shared-ui/randa-trpc-client';
import { ENDEAVOR_ACCESS_TOKEN, useAuthContext } from '@r-and-a-shared-ui/auth-client';
import {
  deleteFromSessionStorage,
  getSessionStorageItem,
  setSessionStorageItem,
} from '@r-and-a-shared-ui/utils';
import { useEffect } from 'react';
import { useGenericActionContext } from '../../contexts';

export const usePlaylist = (props: { playlistId: string }) => {
  const {
    userState: { isAuthenticated, refreshToken, accessToken, idToken },
  } = useAuthContext();

  const { VIDEO_API } = useGenericActionContext();
  const endeavorAccessToken = getSessionStorageItem(ENDEAVOR_ACCESS_TOKEN);

  const { mutate: fetchPlaylist, data: playlist } = api.endeavor.playback.getVODPlaylist.useMutation({
    onError: (error) => {
      console.error('API call failed', error);
    },
  });

  useEffect(() => {
    const { url, xApiKey, realm } = VIDEO_API.ENDEAVOR_CONFIG;
    if (props.playlistId && url && realm && xApiKey) {
      fetchPlaylist({
        isAuthenticated: isAuthenticated,
        playlistId: props.playlistId,
        refreshToken: refreshToken,
        accessToken: accessToken,
        idToken: idToken,
        endeavorAccessToken,
        config: VIDEO_API.ENDEAVOR_CONFIG
      });
    }
  }, [
    fetchPlaylist,
    isAuthenticated,
    props.playlistId,
    refreshToken,
    accessToken,
    idToken,
    endeavorAccessToken,
    VIDEO_API.ENDEAVOR_CONFIG
  ]);
  useEffect(() => {
    if (playlist?.authToken) {
      deleteFromSessionStorage(ENDEAVOR_ACCESS_TOKEN);
      setSessionStorageItem(ENDEAVOR_ACCESS_TOKEN, playlist?.authToken);
    }
  }, [playlist]);
  return { playlist };
};
