import 'slick-carousel/slick/slick.css';
import React, { ReactNode, JSX, useId } from 'react';

import styles from './Slider.module.scss';

interface SliderCustomDotsProps {
  dots?: ReactNode;
  activeIndex: number;
  customNavBar?: (index: number) => JSX.Element | null;
  extraNavChild?: (index: number) => JSX.Element | null;
}

const SliderCustomDots = ({
  dots,
  activeIndex,
  customNavBar,
  extraNavChild,
}: SliderCustomDotsProps) => {
  const key = useId();

  return (
    <div data-testid='slick-dots--custom'>
      {Array.isArray(dots) ? (
        <ul className={styles['slick-dots--custom']}>
          {dots.map((item, i) => {
            return (
              <li
                key={`${key}${i}`}
                className={`${styles['slick-navigation--custom']}`}
                onClick={item.props.children.props.onClick}
              >
                <div
                  className={`${styles['slick-bar']} ${
                    i === activeIndex ? styles['slick-bar--active'] : ''
                  }`}
                />
                <div
                  className={` ${
                    i !== activeIndex ? styles['slick-text--hidden'] : ''
                  }`}
                >
                  {customNavBar && customNavBar(i)}
                </div>
              </li>
            );
          })}
        </ul>
      ) : null}
      {extraNavChild && extraNavChild(activeIndex)}
    </div>
  );
};

export default SliderCustomDots;
