import { formatEventDate, getEventStructuredData } from '@r-and-a-shared-ui/utils';
import styles from './EventHeroBody.module.scss';
import { Link, LinkProps } from '../../Link/Link';
import useSchemaOrg from 'libs/common-ui/src/hooks/useSchemaOrg';

export interface EventHeroBodyProps {
  title?: string;
  description?: string;
  eventLocation?: string;
  eventStartDate?: string | null;
  eventEndDate?: string | null;
  viewEventLink?: LinkProps;
}

const EventHeroBody = ({
  eventLocation,
  title,
  eventStartDate,
  eventEndDate,
  viewEventLink,
  description,
}: EventHeroBodyProps) => {

  useSchemaOrg(getEventStructuredData({
    title, eventEndDate,
    eventLocation, eventStartDate
  }));

  return (
    <>
      <div className={styles.info}>
        <div className={styles.info__top}>
          <div className={styles['info__title']}>{title && title}</div>
          <div className={styles['info__location']}>
            {eventLocation && <p>{eventLocation}</p>}
          </div>
        </div>
        <div className={styles.info__bottom}>
          <div className={styles['info__date']}>
            {eventStartDate && eventEndDate && (
              <p>{formatEventDate(eventStartDate, eventEndDate)}</p>
            )}
          </div>
          <div className={styles['info__link']}>
            <Link
              title={viewEventLink?.title || ''}
              href={viewEventLink?.href || ''}
              internal={!!viewEventLink?.internal}
              codename={viewEventLink?.codename}
            />
          </div>
        </div>
      </div>
      <div className={styles.description}>{description && description}</div>
    </>
  );
};

export default EventHeroBody;
