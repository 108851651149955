import { getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { menu_footer } from './map-menu_footer';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';
import { getPrefixedMembershipInternalLink } from '@r-and-a-shared-ui/utils';

type MenuTreeDataMap =
  | {
    title: string;
    href: string;
    codename?: string;
    internal: boolean;
    children?: never;
  }
  | {
    children?: MenuTreeDataMap[];
  };

const parseLink = (link: any) => {
  const internal = !link.elements?.href.value;

  const internalHref = getPrefixedMembershipInternalLink(
    link?.elements?.internal_page?.linkedItems?.[0]?.system?.type,
    link?.elements?.internal_page?.linkedItems?.[0]?.elements?.url_slug?.value,
  );
  
  const href = link.elements?.href?.value || internalHref;
  return {
    title: link.elements.title.value,
    href,
    internal,
    codename: !href ? link?.system?.codename : undefined,
  };
};

const parseMenu = (menu: any) => {
  return menu.elements?.items?.linkedItems?.reduce(
    (acc: MenuTreeDataMap[], item: any) => {
      if (item.system.type === 'link') {
        acc.push(parseLink(item));
        return acc;
      }
      if (item.system.type === 'menu') {
        acc.push({
          title: item.elements?.title?.value,
          children: parseMenu(item),
        });
        return acc;
      }
    },
    [],
  );
};

export const menu = (item: MEMBERSHIP_MODELS.Menu) => {
  const result = parseMenu(item);

  const rawImage = item?.elements?.background_image?.value?.[0];

  const adaptedImage = rawImage ? {
    imageUrl: rawImage.url,
    license: 'default-license',
    width: rawImage?.width || 100,
    height: rawImage?.height || 100,
    alt: rawImage.name,
  } : null;

  const footerData = getFirstMappedLinkedItemOrDefault(item?.elements?.footer);
  const footer = footerData ? menu_footer(footerData) : [];
  return {
    menuStyle: getSingleChoiceValue(item?.elements?.style),
    title: item.elements?.title?.value,
    backgroundImage: adaptedImage,
    footer: footer,
    menu: result,
  };
};

export default menu;