/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const collections = {
    /**
     * Default
     */
    default: {
        codename: 'default',
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Default'
    }
} as const;
