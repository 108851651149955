import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { resolveRichText } from './utils/richtext-helpers';

export const feature_card = (item: MEMBERSHIP_MODELS.FeatureCard) => {
  const description = resolveRichText(item.elements?.description);

  return {
    title: item.elements?.title?.value,
    description,
    ctas: item.elements?.ctas?.linkedItems,
    theme: getSingleChoiceValue(item?.elements?.theme),
  };
};

export default feature_card;
