import { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedItemOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import {
  getFirstMappedLinkedItemOrDefault,
  getSlugUrlForFormPage,
} from './utils/component-mapping-helpers';

export const user_journey_mapping = (data: SSO_MODELS.UserJourneyMapping) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  const formPageLinkedItem = getFirstLinkedItemOrDefault(elements?.form_page);

  const mappedUserJourney = getFirstMappedLinkedItemOrDefault(elements?.user_journey);

  if (mappedUserJourney)
    mappedUserJourney.value = elements?.user_journey?.value[0];
  
  return {
    userJourney: mappedUserJourney,
    formPageCodename: formPageLinkedItem?.system?.codename,
    formPageSlug: getSlugUrlForFormPage(formPageLinkedItem)?.value,
    codename: data?.system?.codename
  };
};

export default user_journey_mapping;
