import type { IContentItem } from '@kontent-ai/delivery-sdk';
import {
  AsyncComponent,
  ConfiguredComponents,
} from '@r-and-a-shared-ui/core-component-config';
import { HeaderMainMenu } from './HeaderMainMenu';

export const HeaderContent = ({ content }: { content: any }) => {
  if (!content || !content.length) {
    console.warn('No Header Content please add components to the header');
    return null;
  }

  const mainMenuContentItem = content.find(
    (x: IContentItem<any>) => x.system.type === 'menu',
  );

  return (
    <>
      <HeaderMainMenu menuEntry={mainMenuContentItem} />
      <>
        {content.map((x: IContentItem<any>) => {
          if (x.system.type === 'menu') {
            return '';
          }
          // other components
          return (
            <AsyncComponent
              kontentItem={x}
              contentType={x.system.type as ConfiguredComponents}
              key={x.system.id}
            />
          );
        })}
      </>
    </>
  );
};
