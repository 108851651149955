import { useScreenDetector } from '../../hooks';
import { RenderOn } from '../RenderOn/RenderOn';
import Logo from '../Logo/Logo';
import { HeaderProps } from './Header';
import styles from './HeaderLogo.module.scss';

export const HeaderLogo = ({ mobileLogo, desktopLogo }: HeaderProps) => {
  const { isMobile, isClient } = useScreenDetector();

  if (!isClient) {
    return null;
  }

  return (
    <div className={styles.content}>
      <RenderOn condition={isMobile} key={'mobile-logo'}>
        <Logo {...mobileLogo} />
      </RenderOn>
      <RenderOn condition={!isMobile} key={'desktop-logo'}>
        <Logo {...desktopLogo} />
      </RenderOn>
    </div>
  );
};
