import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { ImageSchemaOrgModel, SchemaOrgContext, SchemaOrgTypes } from '@r-and-a-shared-ui/utils';

export const image = (entry: MEMBERSHIP_MODELS.Image) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }

  const { elements } = entry || {};

  const image = getFirstLinkedAssetOrDefault(elements?.asset);
  const schemaOrgContent: ImageSchemaOrgModel = {
    "@type": SchemaOrgTypes.IMAGE_OBJECT,
    "@context": SchemaOrgContext,
    contentUrl: image?.url
  };
  return {
    imageUrl: image?.url,
    license: elements?.license.value,
    width: elements?.width.value,
    height: elements?.height.value,
    alt: elements.alt.value || entry.system.codename,
    schemaOrgContent,
  };
};

export default image;
