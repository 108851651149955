import { useDynamicComponent } from './useDynamicComponent';

export const DynamicComponent = ({
  codename,
  contentType,
  locale,
}: {
  codename: string;
  contentType: string;
  locale: any;
}) => {
  const DynamicElement = useDynamicComponent({
    codename,
    contentType,
    locale,
  });

  return DynamicElement;
};

export default DynamicComponent;
