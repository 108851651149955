import { FormDateFieldProps } from '../FormDateField/FormDateField';
import { generateOptions } from './form-field-options-helpers';

export type PredefinedOptionsList = 'day_options' | 'month_options' | 'year_options' | 'undefined';

export const formFieldOptionsList: Record<PredefinedOptionsList, (...args: any[]) => any> = {
  day_options: () => {
    return generateOptions(1, 31);
  },
  month_options: () => {
    const monthOptions = generateOptions(1, 12, index =>
      new Date(0, index - 1).toLocaleString('en-US', { month: 'long' }),
    );
    return monthOptions;
  },
  year_options: (props?: FormDateFieldProps) => {
    const currentYear = new Date().getFullYear();
    const yearOffset = props?.yearOffset ?? 0;
    const maximumYear = currentYear - yearOffset;
    const yearOptions = generateOptions(props?.minimumYear ?? 1900, maximumYear).reverse();
    return yearOptions;
  },
  undefined: () => []
};