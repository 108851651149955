import { useEffect, useState } from 'react';
import { getLatestArticles } from '@r-and-a-shared-ui/common-kontent-client';
import { CacheConfig } from '@r-and-a-shared-ui/core-caching';
import { hasValue } from '@r-and-a-shared-ui/utils';
import type { ArticleListicleProps } from '../ArticleListicle/ArticleListicle';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

export function useFallbackArticles(
  articlesList: ArticleListicleProps[],
  highLightedArticle: any,
  isEmpty: boolean
) {
  const [fetchedArticles, setFetchedArticles] = useState<ArticleListicleProps[] | null>(null);
  const [fetchedHighlightedArticle, setFetchedHighlightedArticle] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isEmpty) {
      setLoading(true);

      const fetchArticles = async () => {
        try {
          const cacheStatus = {};
          const result = await getLatestArticles(true, CacheConfig.TTL.LatestArticles.valueOf(), cacheStatus);
          
          if (hasValue(result?.articles)) {
            // Map the fetched articles
            const mappedHighlightedArticle = kontentAiDataMapping.article_highlight(result.articles[0]);
            const mappedArticles = result.articles.slice(1).map((article: any) =>
              kontentAiDataMapping.article_listicle(article)
            );

            setFetchedHighlightedArticle(mappedHighlightedArticle);
            setFetchedArticles(mappedArticles as any);
          } else {
            setError('No articles found');
          }
        } catch (err) {
          setError('Error fetching articles');
        } finally {
          setLoading(false);
        }
      };

      fetchArticles();
    } else {
      setFetchedArticles(null);
      setFetchedHighlightedArticle(null);
    }
  }, [isEmpty]);
  return {
    articles: fetchedArticles || articlesList,
    highlighted: fetchedHighlightedArticle || highLightedArticle,
    loading,
    error,
  };
}
