/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const contentTypeSnippets = {
    /**
     * Card base
     */
    card_base: {
        codename: 'card_base',
        id: 'de4f8ff0-66e7-4da6-b764-06101e5c713c',
        externalId: undefined,
        name: 'Card base',
        elements: {
            /**
             * Title (text)
             */
            card_base__title: {
                codename: 'card_base__title',
                id: '30363806-5fbc-40aa-9c22-8bb3d7ba85e0',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Description (text)
             */
            card_base__description: {
                codename: 'card_base__description',
                id: '34c6c90e-1e42-4f35-93e9-6735e5653672',
                externalId: undefined,
                name: 'Description',
                required: true,
                type: 'text'
            },

            /**
             * Background Image (asset)
             */
            card_base__background_image: {
                codename: 'card_base__background_image',
                id: '28265d54-3936-4f74-90d7-243e5f7815b9',
                externalId: undefined,
                name: 'Background Image',
                required: true,
                type: 'asset'
            },

            /**
             * Link (modular_content)
             */
            card_base__link: {
                codename: 'card_base__link',
                id: 'c264fa00-8a40-4714-bb87-d5d00d87ff45',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     * Carousel Config
     */
    carousel_config: {
        codename: 'carousel_config',
        id: '115fa6ee-e09f-48dc-b848-1e457cff55f3',
        externalId: undefined,
        name: 'Carousel Config',
        elements: {
            /**
             * Mobile  Tiles to show (number)
             *
             * Recommended setting as per design: 1.2
             */
            carousel_config__slides_to_show_mobile: {
                codename: 'carousel_config__slides_to_show_mobile',
                id: 'e7133558-b8a5-46c2-845c-d184549d97dd',
                externalId: undefined,
                name: 'Mobile: Tiles to show',
                required: true,
                type: 'number'
            },

            /**
             * Mobile  Tiles to slide (number)
             *
             * Always use number without decimal. Example: If we choose to show 1.2 tiles, we will slide 1 tile
             */
            carousel_config__slide_to_scroll_mobile: {
                codename: 'carousel_config__slide_to_scroll_mobile',
                id: 'd22feeb5-e5ec-4e08-aa6f-ee4d7d62175a',
                externalId: undefined,
                name: 'Mobile: Tiles to slide',
                required: true,
                type: 'number'
            },

            /**
             * Desktop  Tiles to show (number)
             *
             * Recommended setting as per design: 1.2
             */
            carousel_config__slides_to_show_desktop: {
                codename: 'carousel_config__slides_to_show_desktop',
                id: 'e6ec9ff7-8aab-4270-9d3f-1545dfd5d49a',
                externalId: undefined,
                name: 'Desktop: Tiles to show',
                required: true,
                type: 'number'
            },

            /**
             * Desktop  Tiles to slide (number)
             *
             * Always use number without decimal. Example: If we choose to show 1.2 tiles, we will slide 1 tile
             */
            carousel_config__slides_to_scroll_desktop: {
                codename: 'carousel_config__slides_to_scroll_desktop',
                id: '81a3fa77-9a08-4bbb-a26e-886488c8cafb',
                externalId: undefined,
                name: 'Desktop: Tiles to slide',
                required: true,
                type: 'number'
            },

            /**
             * Enable Autoplay (multiple_choice)
             *
             * Default value is "No"
             */
            carousel_config__autoplay: {
                codename: 'carousel_config__autoplay',
                id: '85596b43-ed10-4f35-bfae-203678416df3',
                externalId: undefined,
                name: 'Enable Autoplay',
                required: false,
                type: 'multiple_choice'
            },

            /**
             * Autoplay  timeout (number)
             *
             * If enabled: Set how much time the slide will auto play (in seconds) until it stops completely.
             */
            carousel_config__autoplay_timeout: {
                codename: 'carousel_config__autoplay_timeout',
                id: '1c758157-031e-43d5-90f4-cecf648a12e7',
                externalId: undefined,
                name: 'Autoplay: timeout',
                required: false,
                type: 'number'
            },

            /**
             * Autoplay  timer (number)
             *
             * If enabled: Set the time interval (in seconds) between slides.
             */
            carousel_config__autoplay_timer: {
                codename: 'carousel_config__autoplay_timer',
                id: '77a04154-3dd5-4b30-9109-9cbb7cd63250',
                externalId: undefined,
                name: 'Autoplay: timer',
                required: false,
                type: 'number'
            },

            /**
             * Enable Loop (multiple_choice)
             *
             * Default value is "No". If set to "Yes", the carousel will not stop when it reaches the last tile. It will be an infinite loop.
             */
            carousel_config__infinite_loop: {
                codename: 'carousel_config__infinite_loop',
                id: 'd2e72694-b0bd-49c4-b825-829fc86c956a',
                externalId: undefined,
                name: 'Enable Loop',
                required: false,
                type: 'multiple_choice'
            },

            /**
             * Enable arrows (multiple_choice)
             */
            carousel_config__enable_arrows: {
                codename: 'carousel_config__enable_arrows',
                id: '6c19ef2f-2f9d-4722-8cb6-72ed4790b3fc',
                externalId: undefined,
                name: 'Enable arrows',
                required: false,
                type: 'multiple_choice'
            },

            /**
             * Arrows  transparent background (multiple_choice)
             *
             * Default state is "No". If set to "Yes", the arrows will not have the navy blue circle container around them.
             */
            carousel_config__arrows__transparent_background: {
                codename: 'carousel_config__arrows__transparent_background',
                id: 'b7baa384-8fa1-4244-b87c-972b47717b2a',
                externalId: undefined,
                name: 'Arrows: transparent background',
                required: false,
                type: 'multiple_choice'
            },

            /**
             * Arrows  color (modular_content)
             */
            carousel_config__slider_arrows_color: {
                codename: 'carousel_config__slider_arrows_color',
                id: 'c4c8dbcc-1e4a-4ffb-943f-160ce6d43f02',
                externalId: undefined,
                name: 'Arrows: color',
                required: false,
                type: 'modular_content'
            },

            /**
             * Arrows  size (multiple_choice)
             *
             * Default is set to "lg"
             */
            carousel_config__slider_arrows_size: {
                codename: 'carousel_config__slider_arrows_size',
                id: '6c137d73-9d0a-41d6-90f6-485c2a3f2e5e',
                externalId: undefined,
                name: 'Arrows: size',
                required: false,
                type: 'multiple_choice'
            },

            /**
             * Slider width (multiple_choice)
             *
             * If "Full width" selected: the carousel will take the parent's container width.If "None" selected: the carousel will be inside it's own container but the tiles will "break" out on the right side.
             */
            carousel_config__slider_width: {
                codename: 'carousel_config__slider_width',
                id: 'ecf1b958-ea96-4d9d-b5d2-363c4ba11a2b',
                externalId: undefined,
                name: 'Slider width',
                required: false,
                type: 'multiple_choice'
            }
        }
    },

    /**
     * Form CTA Action Types
     */
    form_cta_action_types: {
        codename: 'form_cta_action_types',
        id: 'fc693750-e342-4a35-b62a-f0deb9b11dd6',
        externalId: undefined,
        name: 'Form CTA Action Types',
        elements: {
            /**
             * Action Type (multiple_choice)
             */
            form_cta_action_types__action_type: {
                codename: 'form_cta_action_types__action_type',
                id: 'b8afa756-7d9c-4f27-b3c6-b92d06c8aedb',
                externalId: undefined,
                name: 'Action Type',
                required: true,
                type: 'multiple_choice'
            }
        }
    },

    /**
     * Membership based access
     */
    membership_based_access: {
        codename: 'membership_based_access',
        id: '0b7562a6-9772-4af7-a74d-fe8c712b59dc',
        externalId: undefined,
        name: 'Membership based access',
        elements: {
            /**
             * Select access (multiple_choice)
             *
             * If Standard or Advantage is selected the access to the content will be limited to the logged in user membership level.
             */
            membership_based_access__select_access: {
                codename: 'membership_based_access__select_access',
                id: 'b1494d90-9c32-4e37-971d-675996e05481',
                externalId: undefined,
                name: 'Select access',
                required: false,
                type: 'multiple_choice'
            }
        }
    },

    /**
     * SEO
     */
    seo: {
        codename: 'seo',
        id: 'cfce8a08-8d74-4aa8-b24f-5d118613b4b4',
        externalId: undefined,
        name: 'SEO',
        elements: {
            /**
             * Meta Title (text)
             */
            seo__meta_title: {
                codename: 'seo__meta_title',
                id: 'fdacc1c6-2b9f-4a62-8207-ea9884508bc8',
                externalId: undefined,
                name: 'Meta Title',
                required: true,
                type: 'text'
            },

            /**
             * Meta Description (text)
             */
            seo__meta_description: {
                codename: 'seo__meta_description',
                id: 'a5034781-1fee-4222-bd3f-89977d411fcf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text'
            },

            /**
             * Keywords (text)
             *
             * Keywords are ideas and topics that define what this content is about.
             */
            seo__key_words: {
                codename: 'seo__key_words',
                id: '43d4aca6-039e-4789-859b-f934f5104c01',
                externalId: undefined,
                name: 'Keywords',
                required: false,
                type: 'text'
            },

            /**
             * No Index (multiple_choice)
             *
             * Block page from Search indexing.
             */
            seo__no_index: {
                codename: 'seo__no_index',
                id: 'ca530a2e-571b-4ddc-8486-53ded9d8e6ba',
                externalId: undefined,
                name: 'No Index',
                required: false,
                type: 'multiple_choice'
            },

            /**
             * No Follow (multiple_choice)
             *
             * Block links within page from being followed by search engine crawlers.
             */
            seo__no_follow: {
                codename: 'seo__no_follow',
                id: '0f1821fb-b99c-44b2-bc1f-e30571cc5ad3',
                externalId: undefined,
                name: 'No Follow',
                required: false,
                type: 'multiple_choice'
            }
        }
    }
} as const;
