/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  deleteFromLocalStorage,
  ensureValidUri,
  flattenObjectWithChar,
  getNumberFromLocalStorage,
  getSSOUrl,
  hasValue,
  isNullOrUndefined,
  setNumberInLocalStorage,
} from '@r-and-a-shared-ui/utils';
import FormStep, { FormStepProps } from '../FormStep/FormStep';
import styles from './Form.module.scss';
import FormFooter, { FormFooterProps } from '../FormFooter/FormFooter';
import FormHeader, { FormHeaderProps } from '../FormHeader/FormHeader';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getValidationSchema } from '../fields/config/form-validation-schema';
import { EnvironmentContext, LoadingSpinner, notifications } from '@r-and-a-shared-ui/common-ui';
import { STEP } from '../constants';
import { FormContentContext } from '../context/FormContentContext';
import { FormProps } from '../types';
import { setFormStateFromSessionStorage } from '../helpers/form-helpers';
import { userDefaultValuesUpdate, userDefaultValuesRegister } from '@r-and-a-shared-ui/randa-trpc-server/input-schemas';
import { setSpecialFieldsValues } from '../helpers/form-steps-helper';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useActionContext } from '../context/useActionsContext';
import { RestrictedContentPrompt } from 'libs/common-ui/src/lib/RestrictedContentPrompt/RestrictedContentPrompt';
import { useAuthContext } from '@r-and-a-shared-ui/auth-client';

const STORAGE = 'storage';
const KONTENT_ITEM_CODENAME_ATTRIBUTE_KEY = 'kontentItemCodename';
const DEFAULT_VALUES_QUERY_KEY = 'default_values';

export function Form(props: FormProps) {
  const {
    formCodename,
    formHeaderData: defaultHeaderData,
    steps,
    formFooterData,
    hasReCaptcha,
    reCaptchaAttributeKey,
    errorsDictionary,
    successMessagesDictionary,
    nextStepsAfterSubmit,
    defaultValuesCallbackUrl,
    bearerToken,
    isMembership,
    backgroundImage,
    toEmail,
    fromEmail,
    subject,
    emailKey,
  } = props;

  const [currentFormStep, setCurrentFormStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState('');
  const context = useActionContext();
  const { API } = context || {};
  const [allowedToFetch, setAllowedToFetch] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { Image } = useContext(EnvironmentContext);

  const {
    formState,
    formState: { errors },
    getValues,
    setValue,
    reset,
    ...methods
  } = useForm({
    resolver: zodResolver(getValidationSchema(steps)),
    mode: 'all',
    reValidateMode: 'onChange' && 'onBlur',
    defaultValues: defaultValuesCallbackUrl ? userDefaultValuesUpdate : undefined
  });

  let isAuthenticated = false;
  let isAuthContextAvailable = false;

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userState } = useAuthContext();
    isAuthenticated = userState.isAuthenticated;
    isAuthContextAvailable = true;
  } catch (error) {
    // We are in SSO
  }

  const ssoUrl = isMembership ? '' : getSSOUrl();
  const baseUrl = ssoUrl || window.location.origin;

  const callBackUrl = ensureValidUri(defaultValuesCallbackUrl, baseUrl);

  const defValuesQueryRes = API?.[DEFAULT_VALUES_QUERY_KEY]?.useQuery(
    { callBackUrl, token: bearerToken },
    { enabled: allowedToFetch && !!bearerToken && !!callBackUrl }
  );

  useEffect(() => {
    if (defValuesQueryRes?.status === 'success' && defValuesQueryRes?.data) {
      const defaultValues = flattenObjectWithChar(defValuesQueryRes.data, '_');
      setSpecialFieldsValues(defaultValues, '_', steps);
      reset(defaultValues);
      setValue(KONTENT_ITEM_CODENAME_ATTRIBUTE_KEY, formCodename);
    }
  }, [defValuesQueryRes?.data]);

  useEffect(() => {
    if (defValuesQueryRes?.status === 'success' || !defaultValuesCallbackUrl) {
      setAllowedToFetch(false);
    } else {
      setAllowedToFetch(true);
    }
  }, [defValuesQueryRes?.status, defaultValuesCallbackUrl]);

  useEffect(() => {
    setValue(KONTENT_ITEM_CODENAME_ATTRIBUTE_KEY, formCodename);
  }, [formCodename, setValue]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha || !hasReCaptcha) {
      return;
    }

    const token = await executeRecaptcha('recaptcha_verify');
    // @ts-ignore
    setValue(reCaptchaAttributeKey, token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const stepSetter = () => {
    const step = getNumberFromLocalStorage(STEP);
    const totalSteps = steps?.length || 0;

    if (currentFormStep >= totalSteps) {
      return;
    }

    setCurrentFormStep(step);
  };

  window.addEventListener(STORAGE, stepSetter);

  useEffect(() => {
    setNumberInLocalStorage(STEP, 0);
    setFormStateFromSessionStorage(setValue);
    window.dispatchEvent(new Event(STORAGE));
    return () => {
      deleteFromLocalStorage(STEP);
      window.removeEventListener(STORAGE, stepSetter);
    };
  }, [setValue]);

  const notifyError = (message: string) => notifications.errorSmall(message);
  const notifySuccess = (message: string) => notifications.successSmall(message);

  useEffect(() => {
    const errorMessage = errors?.root?.serverError?.message;
    if (!isNullOrUndefined(errorMessage)) {
      notifyError(errorMessage);
    }
  }, [errors?.root?.serverError]);

  useEffect(() => {
    if (submitSuccessMessage) {
      notifySuccess(submitSuccessMessage);
    }
  }, [submitSuccessMessage]);

  const renderFormStep = (
    formStepProps: FormStepProps[] | undefined,
  ): React.JSX.Element | undefined => {
    if (
      !hasValue(formStepProps) ||
      typeof formStepProps === 'undefined' ||
      currentFormStep >= formStepProps.length
    ) {
      return undefined;
    }
    return (
      <FormStep
        {...formStepProps[currentFormStep]}
        isMembership={isMembership}
        toEmail={toEmail}
        fromEmail={fromEmail}
        subject={subject}
        emailKey={emailKey}
      />
    );
  };

  const getHeaderDataToDisplay = (): FormHeaderProps | undefined => {
    const customHeader = steps
      ? steps[currentFormStep]?.customHeader
      : undefined;
    return customHeader || defaultHeaderData;
  };

  const renderFormHeader = (
    formHeaderData: FormHeaderProps | undefined,
  ): React.JSX.Element | undefined => {
    if (isNullOrUndefined(formHeaderData)) {
      return undefined;
    }
    return <FormHeader {...formHeaderData} isMembership={isMembership} />;
  };

  const renderFormFooter = (
    formFooterData: FormFooterProps | undefined,
  ): React.JSX.Element | undefined => {
    if (isNullOrUndefined(formFooterData)) {
      return undefined;
    }
    return <FormFooter {...formFooterData} isMembership={isMembership} />;
  };

  if (isAuthContextAvailable && isMembership && !isAuthenticated) {
    return <RestrictedContentPrompt isMembership={isMembership} />;
  }

  return (
    <FormContentContext.Provider
      value={{
        formContentProps: props,
        currentFormStep,
        isLoading,
        errorsDictionary,
        setSubmitSuccessMessage,
        successMessagesDictionary,
        nextStepsAfterSubmit,
        initialFormState: hasValue(defValuesQueryRes?.data)
          ? flattenObjectWithChar(defValuesQueryRes.data, '_')
          : flattenObjectWithChar(userDefaultValuesRegister, '_')
      }}
    >
      <FormProvider
        {...methods}
        reset={reset}
        getValues={getValues}
        setValue={setValue}
        formState={formState}
      >
        {hasReCaptcha && <GoogleReCaptcha onVerify={handleReCaptchaVerify} />}
        <form>
          <div
            className={`${styles.form} ${
              isMembership ? styles['form-membership'] : ''
            }`}
          >
            <div
              className={`${styles['form__wrapper']} ${
                isMembership ? styles['form-membership__wrapper'] : ''
              }`}
            >
              <div
                className={`${
                  isMembership ? styles['form-membership__content'] : ''
                }`}
              >
                {backgroundImage && (
                  <Image
                    src={backgroundImage}
                    alt="Form background image"
                    className={styles['form__background']}
                    width={300}
                    height={300}
                  />
                )}
                {renderFormHeader(getHeaderDataToDisplay())}
                {isLoading && <LoadingSpinner />}
                <div
                  className={`${styles['form__content--body']} ${
                    isLoading ? styles.loading : ''
                  }`}
                >
                  {renderFormStep(steps)}
                </div>
              </div>
              {renderFormFooter(formFooterData)}
            </div>
          </div>
        </form>
      </FormProvider>
    </FormContentContext.Provider>
  );
}

export default Form;