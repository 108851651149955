import {
  AsyncComponent,
  ConfiguredComponents,
} from '@r-and-a-shared-ui/core-component-config';
import { RenderOn } from '../RenderOn/RenderOn';
import type { CoachProps } from './Coach';
  
import styles from './Coach.module.scss';
  
export const PreFooter = ({ preFooter }: Pick<CoachProps, 'preFooter'>) => {
  return (
    <RenderOn condition={preFooter.length > 0}>
      <section
        className={styles['coach-pre-footer']}
        data-testid={'coach-prefooter'}
      >
        {preFooter.map(x => (
          <AsyncComponent
            kontentItem={x}
            contentType={x.system.type as ConfiguredComponents}
            key={x.system.id}
          />
        ))}
      </section>
    </RenderOn>
  );
};