export enum MembershipTypes {
  None = 'none',
  OneClub = 'OneClub',
  OneClubAdvantage = 'OneClubAdvantage',
}

export enum MembershipStatus {
  NoChange = 'NoChange',
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
}

export const MembershipsHierarchy: Record<MembershipTypes, number> = {
  [MembershipTypes.None]: 0,
  [MembershipTypes.OneClub]: 1,
  [MembershipTypes.OneClubAdvantage]: 2,
};

export interface Membership {
  type: MembershipTypes;
  status?: MembershipStatus;
  startedAt?: string | Date;
}

export const initialMembership: Membership = {
  type: MembershipTypes.None,
};
