import { useController, useFormContext } from 'react-hook-form';
import { FormFieldProps, OptionProps } from '..';
import styles from './FormCheckboxesGroup.module.scss';
import { defaultValidationMessage } from '../config/validators/validators';
import { hasValue } from '@r-and-a-shared-ui/utils';
import { RichTextParser } from '@r-and-a-shared-ui/common-ui';

export function FormCheckboxesGroup(props: FormFieldProps) {
  const { name, label, isDisabled, validationMessage, options } = props;

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const { field } = useController({ name, control });
  const { value, onChange: selectOnChange, ...restField } = field;

  const isChecked = (option: OptionProps) => {
    if (!value) {
      return false;
    }
    return value.includes(option.value);
  };
  return (
    <div
      id={name}
      className={`${styles['form-element']}  ${
        errors?.[name] ? styles['form-element__has-error'] : ''
      }`}
    >
      {hasValue(label) && (
        <label className={styles['form-element__label']} htmlFor={name}>
          {label}
        </label>
      )}
      <div
        className={`${
          (options?.length ? options?.length > 1 : false)
            ? styles['form-element__checkbox-multiple']
            : styles['form-element__checkbox-single']
        }`}
      >
        {options?.map((option, index) => (
          <div
            key={`${name}_${index}`}
            className={`${styles['form-element__checkbox']}`}
          >
            <label>
              <RichTextParser richText={option.label} />
              <input
                id={`${name}_${index}`}
                type="checkbox"
                value={option.value}
                checked={isChecked(option)}
                {...restField}
                {...register(name)}
                disabled={isDisabled}
              />
              <span className={styles['form-element__checkmark']}></span>
            </label>
          </div>
        ))}
      </div>
      {errors?.[name] ? (
        <small className={styles['form-element__hint']}>
          {validationMessage || defaultValidationMessage}
        </small>
      ) : null}
    </div>
  );
}

export default FormCheckboxesGroup;
