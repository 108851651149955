import { createContext, useContext } from 'react';
import { CognitoUser, Membership, UserJourneyTypes } from '../types';
import { AuthConfig } from '@aws-amplify/core';

export interface AuthContextType {
  methods: {
    federatedSignIn: () => void;
    federatedSignOut: (global?: boolean) => void;
    signUp: (userJourneyType?: UserJourneyTypes, forceSignOut?: boolean) => void;
    updateAccountData: (
      config: AuthConfig,
      userJourneyType?: UserJourneyTypes,
    ) => void;
    upgradeAccount: (
      config: AuthConfig,
      userJourneyType?: UserJourneyTypes,
    ) => void;
    handleOauthSignedIn: () => void;
    handleOauthSignedOut: () => void;
    changePassword: (config: AuthConfig, userJourneyType?: UserJourneyTypes) => void;
    deleteAccount: (config: AuthConfig, username?: string) => void;

  },
  userState: {
    user?: CognitoUser;
    membership: Membership;
    refreshToken?: string;
    idToken?: string;
    accessToken?: string;
    isAuthenticated: boolean;
    checkAuthStatus: () => Promise<void>;
  };
  config: AuthConfig;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
