import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedAssetOrDefault, getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getBooleanValue } from '@r-and-a-shared-ui/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const event_card = (entry: MEMBERSHIP_MODELS.EventCard) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  return {
    backgroundImage: getFirstLinkedAssetOrDefault(elements?.background_image)
      ?.url,
    startDate: elements.start_date.value,
    endDate: elements?.end_date?.value,
    title: elements?.title?.value,
    logoImage: getFirstLinkedAssetOrDefault(elements?.logo_image)?.url,
    liveUrl: getFirstMappedLinkedItemOrDefault(elements?.live_url),
    url: getFirstMappedLinkedItemOrDefault(elements?.url),
    liveText: elements?.live_text?.value,
    isEventLive: getBooleanValue(
      getSingleChoiceValue(elements?.is_event_live),
    ),
  };
};

export default event_card;
