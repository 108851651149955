import { IContentItem } from '@kontent-ai/delivery-sdk';
import { deliveryClient } from '../config/kontent-delivery-client';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { adjustSystemProperties } from '../utils';

export const getContentListByCodename = async <Type extends IContentItem>(
  codename: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.PageElement.valueOf(),
  cacheStatus?: CacheStatus
) => {
  try {
    const cacheKey = `getContentListByCodename-${codename}`;
    const cachedItem = getCachedItem<Type>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const item = await deliveryClient
      .items<Type>()
      .type('content_list___fixed_length')
      .equalsFilter('system.codename', codename)
      .depthParameter(3)
      .toPromise()
      .then(r => r.data.items[0]);

    if (!item || !item?.system.codename) {
      return undefined;
    }

    adjustSystemProperties(item);

    setCacheItem(cacheKey, item, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return item;
  } catch (error) {
    console.error('Error in getContentListByCodename', error);
    throw error;
  }
};