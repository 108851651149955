import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { deliveryClient } from '../config/kontent-delivery-client';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { adjustSystemProperties } from '../utils';

export const getItemsByTypeCodename = async (
  typeCodeName: string,
  waitForLoadingNewContent = false,
  cache = true,
  cacheTTL = CacheConfig.TTL.PageElement.valueOf(),
  cacheStatus?: CacheStatus
): Promise<IContentItem[] | undefined> => {
  const cacheKey = `kontent-itemsByType-${typeCodeName}`;

  try {
    const cachedItem = getCachedItem<IContentItem[]>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }
    
    const response = await deliveryClient
      .items().type(typeCodeName)
      .queryConfig({
        waitForLoadingNewContent,
      })
      .depthParameter(2)
      .toPromise();

    const result = response?.data?.items;

    adjustSystemProperties(result);

    setCacheItem<IContentItem[]>(cacheKey, result, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });
    
    return result;

  } catch (error) {
    console.error('Error in getItemsByTypeCodename', error);

    throw error;
  }
};
