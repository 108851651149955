import type { MEMBERSHIP_MODELS, SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstMappedLinkedItemOrDefault,
  getMappedLinkedItems,
} from './utils/component-mapping-helpers';
import { getFirstLinkedAssetOrDefault, getTrueFalseSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';

export const form_step = (data: SSO_MODELS.FormStep & MEMBERSHIP_MODELS.FormStep) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;
  const formFields = elements?.form_fields;
  const formCTAs = elements?.ctas;

  return {
    key: data?.system?.codename,
    fieldsData: getMappedLinkedItems(formFields),
    ctasData: getMappedLinkedItems(formCTAs),
    richTextItems: getMappedLinkedItems(elements?.rich_text_items),
    showStepCounter: getTrueFalseSingleChoiceValue(elements?.show_step_counter),
    customHeader: getFirstMappedLinkedItemOrDefault(elements?.custom_header),
    audio: getFirstLinkedAssetOrDefault(elements?.audio)
  };
};

export default form_step;
