import { hasValue, isNullOrUndefined } from './general-utils';

export const snakeToPascal = (input: string): string | undefined => {
  if (!hasValue(input)) {
    return undefined;
  }
  return input
    .split('_')
    ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join('');
};

const camelCase = (word: string, index: number) => {
  return index === 0 ? word.toLowerCase() : word.toUpperCase();
};

export const toCamelCase = (str: string | undefined) => {
  if (isNullOrUndefined(str)) {
    return undefined;
  }

  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, camelCase).replace(/\s+/g, '');
};

export const replacePlaceholders = (
  template: string | undefined,
  params: { [key: string]: string } | undefined,
) => {
  if (template === undefined || !params || Object.keys(params).length === 0) {
    return template;
  }

  const openingSymbol = '{{';
  const closingSymbol = '}}';

  let result = '';
  let start = 0;
  while (start < template.length) {
    const openIndex = template.indexOf(openingSymbol, start);
    if (openIndex === -1) {
      // No more placeholders, append the rest of the template
      result += template.substring(start);
      break;
    }
    // Append the part before the placeholder
    result += template.substring(start, openIndex);
    const closeIndex = template.indexOf(
      closingSymbol,
      openIndex + openingSymbol.length,
    );
    if (closeIndex === -1) {
      // No matching closing symbol, append the rest of the template
      result += template.substring(openIndex);
      break;
    }
    // Extract the key and replace the placeholder with the corresponding value
    const key = template.substring(
      openIndex + openingSymbol.length,
      closeIndex,
    );
    // eslint-disable-next-line no-prototype-builtins
    result += params.hasOwnProperty(key)
      ? params[key]
      : `${openingSymbol}${key}${closingSymbol}`;
    start = closeIndex + closingSymbol.length;
  }
  return result;
};
