import Container, { Service } from 'typedi';
import { type ICache } from '../types/ICache';
import { NodeCacheService } from '../services/node-cache-service';
import { isNullOrUndefined } from '@r-and-a-shared-ui/utils';
import { CacheConfig } from '../constants';

@Service({
  global: true,
})

export class CacheService implements ICache {
  private static instance: CacheService | undefined = undefined;
  private cache: ICache;
  static defaultCacheTTL = CacheConfig.TTL.Default;

  constructor(cache: ICache) {
    this.cache = cache;
  }

  static getInstance(): CacheService {
    if (isNullOrUndefined(CacheService.instance)) {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>> Initialize Node Cache Service <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
      let cache = new NodeCacheService();
      CacheService.instance = new CacheService(cache);
      Container.set(CacheService, CacheService.instance)
    }

    return Container.get(CacheService);
  }

  get<T>(key: string): T | undefined {
    return this.cache.get(key);
  }

  del(keys: string | string[]): void {
    this.cache.del(keys);
  }

  delStartsWith(startsWith: string): void {
    this.cache.delStartsWith(startsWith);
  }

  set<T>(key: any, value: T, ttl?: number): void {
    this.cache.set(key, value, ttl);
  }

  flush(): void {
    this.cache.flush();
  }
}
