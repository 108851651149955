import '@fortawesome/fontawesome-svg-core/styles.css';
import { config as faconfig } from '@fortawesome/fontawesome-svg-core';
import { AppProps } from 'next/app';
import {
  EnvironmentContext,
  NextJSLinkWrapper,
  NextJSCustomImage,
  NextJSSEOWrapper,
  GenericActionContext,
  NotificationContainer,
} from '@r-and-a-shared-ui/common-ui';
import { SUPPORTED_CMS } from '@r-and-a-shared-ui/core-data-mapping';
import {
  ComponentConfigContext,
  config,
} from '@r-and-a-shared-ui/core-component-config';
import {

  formCtaConfig,
  MembershipActionsContext,
} from '@r-and-a-shared-ui/common-forms-ui';
import { AuthProvider, checkForceAuth, MembershipConfigContext } from '@r-and-a-shared-ui/auth-client';
import { api } from '@r-and-a-shared-ui/randa-trpc-client';

faconfig.autoAddCss = false;
import './styles.scss';
import { awsExports } from '../utils/aws-exports';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { getEndeavorConfig } from '@r-and-a-shared-ui/common-kontent-client/utils';

const MembershipConfiguration = dynamic(
  () =>
    import('../common/MembershipConfiguration').then(
      mod => mod.MembershipConfiguration,
    ),
  { ssr: false },
);

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const siteSettings = api.membershipConfig.membershipConfig.useQuery();
  Amplify.configure(awsExports, { ssr: true });
  
  useEffect(() => {
    checkForceAuth();
  }, []);

  return (
    <EnvironmentContext.Provider
      value={{
        Link: NextJSLinkWrapper,
        Image: NextJSCustomImage,
        SEO: NextJSSEOWrapper,
      }}
    >
      <AuthProvider awsExports={awsExports}>
        <GenericActionContext.Provider
          value={{
            API: {
              KONTENT: api.kontent,
            },
            VIDEO_API: {
              ENDEAVOR: api.endeavor,
              ENDEAVOR_CONFIG: getEndeavorConfig(siteSettings.data)
            },
          }}
        >
          <MembershipConfigContext.Provider
            value={{
              memberRequiredAttributes: pageProps?.memberRequiredAttributes
            }}
          >
            <MembershipActionsContext.Provider
              value={{
                API: api.membershipFormAPI,
                FormCTAConfig: formCtaConfig,
              }}
            >
              <ComponentConfigContext.Provider
                value={{
                  cmsBasedDataMapping: {
                    ['KONTENT']: config,
                  },
                  CURRENT_CMS: SUPPORTED_CMS.KONTENT,
                }}
              >
                <Component {...pageProps} />
              </ComponentConfigContext.Provider>
            </MembershipActionsContext.Provider>
          </MembershipConfigContext.Provider>
        </GenericActionContext.Provider>
      </AuthProvider>
      <MembershipConfiguration siteSettings={siteSettings?.data} />
      <NotificationContainer />
    </EnvironmentContext.Provider>
  );
};

export default api.withTRPC(CustomApp);
