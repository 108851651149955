import { Service } from 'typedi';
import NodeCache from 'node-cache';
import { CacheConfig } from '../constants/cache-config';
import { ICache } from '../types/ICache';

@Service({
  global: true,
})
export class NodeCacheService implements ICache {
  private cache: NodeCache;
  private keyPrefix: string = CacheConfig.keyPrefix;
  public isAvailable: boolean;
  defaultCacheTTL: number = CacheConfig.TTL.Default;

  constructor() {
    this.cache = new NodeCache({ stdTTL: CacheConfig.TTL.Default });
    this.isAvailable = true;
  }

  get<T>(key: string): T | undefined {
    return this.cache.get<T>(`${this.keyPrefix}${key}`);
  }

  del(keys: string | string[]): void {
    this.cache.del(keys);
  }

  delStartsWith(startsWith: string): void {
    const keys = this.cache.keys();
    const keysToDelete = keys.filter(key => key.startsWith(`${this.keyPrefix}${startsWith}`));
    this.cache.del(keysToDelete);
  }

  set<T>(key: any, value: T, ttl: number = CacheConfig.TTL.Default): void {
    if (typeof key !== 'string') {
      key = JSON.stringify(key);
    }
    this.cache.set(`${this.keyPrefix}${key}`, value, ttl);
  }

  flush(): void {
    this.cache.flushAll();
  }
}
