import { IContentItem } from '@kontent-ai/delivery-sdk';
import { deliveryClient } from '../../config/kontent-delivery-client';
import { getCachedItem, setCacheItem } from '@r-and-a-shared-ui/core-caching';

export const getAllItemsFromFeed = async (
  preview = false,
  cache = true,
  cacheTTL?: number
): Promise<IContentItem[]> => {
  const cacheKey = `getAllItemsFromFeed-${preview}`;

  try {
    const cachedItem = getCachedItem<IContentItem[]>(cacheKey, cache);

    if (cachedItem) {
      return cachedItem;
    }

    const query = await deliveryClient
      .itemsFeed()
      .queryConfig({
        usePreviewMode: preview,
      })
      .toAllPromise();
    if (!query.data.items) {
      return [];
    }
    const response = query.data.items;

    setCacheItem<IContentItem[]>(cacheKey, response, cacheTTL, cache);

    return response;
  } catch (error) {
    console.error('Error in getAllItemsFromFeed', error);
    throw error;
  }
};
