import React, { useMemo } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import FilterPanel from '../FilterPanel/FilterPanel';
import useFilter from './useFilter';
import styles from './FilterBlock.module.scss';
import classnames from 'classnames';
import Image, { ImageProps } from '../Image/Image';

export interface FilterBlockOptions {
  [category: string]: string[];
}

export interface FilterBlockCategory {
  name: string;
  options: string[];
}

export interface FilterProps {
  filterCategories: FilterBlockCategory[];
  activeFilters: FilterBlockOptions;
  onChange?: (activeFilters: FilterBlockOptions) => void;
  onApply?: (activeFilters: FilterBlockOptions) => void;
  onRemove?: (filters: FilterBlockOptions) => void;
  backgroundImage: ImageProps | null;
}

export function FilterBlock({
  filterCategories,
  activeFilters,
  onChange,
  onRemove,
  onApply,
  backgroundImage
}: FilterProps) {
  const {
    selectedTags,
    selectedOptions,
    handleSelect,
    handleRemove,
    handleRemoveAll,
    handleApply,
    handleClearFilters,
    toggleDropdown,
    dropdownVisible,
  } = useFilter({
    initialFilters: activeFilters,
    onChange,
    onRemove,
    onApply,
  });

  const hasActiveFilters = useMemo(
    () =>
      Object.keys(selectedTags).some(
        category => selectedTags[category].length > 0,
      ),
    [selectedTags],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div
          className={classnames(
            styles.filter__button,
            styles.btn,
            styles['btn--primary'],
          )}
          onClick={toggleDropdown}
        >
          <span className={styles['filter__button--label']}>Filter</span>
          <span className={styles['filter__button--icon']}>
            <span></span>
          </span>
        </div>
        {dropdownVisible && (
          <div className={styles.dropdown}>
            {filterCategories.map(({ name, options }) => {
              const dropdownOptions = options.map(option => ({
                label: option,
                value: option,
              }));
              return (
                <Dropdown
                  key={name}
                  label={name}
                  options={dropdownOptions}
                  onChange={(selectedValues: string[]) =>
                    handleSelect(name, selectedValues)
                  }
                  defaultSelected={selectedOptions[name]}
                />
              );
            })}
            {backgroundImage && (
              <div className={styles.background}>
                <Image
                  imageUrl={backgroundImage.imageUrl}
                  width={backgroundImage.width}
                  license={backgroundImage.license}
                  height={backgroundImage.height}
                  alt={backgroundImage.alt || 'Background'}
                />
              </div>
            )}
            <div className={styles.buttons}>
              <div
                className={classnames(styles.btn, styles['btn--primary'])}
                onClick={handleClearFilters}
              >
                Clear filters
              </div>
              <div
                className={classnames(styles.btn, styles['btn--primary'])}
                onClick={handleApply}
              >
                Apply filters
              </div>
            </div>
          </div>
        )}
        {!dropdownVisible && (
          <div className={styles.selected__filters}>
            {filterCategories.map(({ name }) => {
              const selectedTagsFilter =
                selectedTags[name]?.map(tag => ({ label: tag, value: tag })) ||
                [];
              return (
                <FilterPanel
                  key={name}
                  selectedTags={selectedTagsFilter}
                  onRemoveTag={tagValue => handleRemove(name, tagValue)}
                />
              );
            })}
            {hasActiveFilters && (
              <button
                className={classnames(
                  styles.button,
                  styles.btn,
                  styles['btn--secondary'],
                )}
                onClick={handleRemoveAll}
              >
                Clear all
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterBlock;
