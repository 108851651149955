import {
  MEMBERSHIP_MODELS,
  SSO_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';

export const text_item = (
  entry: MEMBERSHIP_MODELS.TextItem | SSO_MODELS.TextItem,
) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  return {
    value: elements?.value.value,
  };
};

export default text_item;
