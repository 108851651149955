import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const county = (data: SSO_MODELS.County) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    key: data?.system?.codename,
    label: elements?.name?.value,
    value:  elements?.name?.value,
  };
};

export default county;
