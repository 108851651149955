import * as kontentAIComponentMapping from '../kontent_ai_mapping';

export type ComponentMapping = typeof kontentAIComponentMapping;
export const KONTENT = 'KONTENT';
export const CONTENTFUL = 'CONTENTFUL';
export type SUPPORTED_CMS_TYPE = typeof KONTENT | typeof CONTENTFUL;
export const SUPPORTED_CMS = {
  KONTENT: KONTENT as SUPPORTED_CMS_TYPE,
  CONTENTFUL: CONTENTFUL as SUPPORTED_CMS_TYPE,
};

export type CMSBasedDataMapping = {
  [k in SUPPORTED_CMS_TYPE]?: ComponentMapping;
};
