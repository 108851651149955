import clone from 'just-clone';

export const isNullOrUndefined = (
  element: unknown,
): element is undefined | null => {
  return element === null || element === undefined;
};

export const hasValue = (object: unknown) => {
  if (typeof object === 'number') {
    return isNumber(object);
  }

  if (typeof object === 'function') {
    return isFunction(object);
  }

  return !isNullOrUndefined(object) && !isEmpty(object);
};

export const isNumber = (value: any): boolean => {
  return !isNullOrUndefined(value) && !isNaN(value as number) && value !== '';
};

export const isBoolean = (value: any): value is boolean => {
  return typeof value == 'boolean';
};

export const isFunction = (fn: any): fn is (...args: any[]) => any => {
  return fn && typeof fn === 'function';
};

export const safeParseRegex = (
  expression: string | undefined,
): RegExp | undefined => {
  if (isNullOrUndefined(expression)) {
    return undefined;
  }

  try {
    const regExp = new RegExp(expression);

    return regExp;
  } catch (e) {
    return undefined;
  }
};

export const safeParseJSON = (expression: string | undefined | null) => {
  if (isNullOrUndefined(expression)) {
    return undefined;
  }

  try {
    const parsedJson = JSON.parse(expression);

    return parsedJson;
  } catch (e) {
    return undefined;
  }
};

export const stringifyJSON = (expression: object) => {
  if (isNullOrUndefined(expression)) {
    return undefined;
  }

  try {
    const stringifiedJSON = JSON.stringify(expression);

    return stringifiedJSON;
  } catch (e) {
    return undefined;
  }
};

export const safeParseToInt = (expression: string | undefined) => {
  if (!expression) {
    return NaN;
  }

  try {
    return parseInt(expression);
  } catch (e) {
    return NaN;
  }
}

export const findKey = (
  obj: object,
  predicate: (...args: any[]) => boolean,
): string | undefined => {
  if (!hasValue(obj)) {
    return undefined;
  }

  if (!isFunction(predicate)) {
    return undefined;
  }

  return Object.keys(obj)?.find(key => predicate(key));
};

export const mapObjects = (
  source: object | undefined,
  mapping: (sourceKey: string) => string,
): object | undefined => {
  if (isNullOrUndefined(source)) {
    return undefined;
  }

  const result: object = {};

  Object.keys(source).forEach(sourceKey => {
    const targetKey = mapping(sourceKey);
    result[targetKey as keyof typeof result] =
      source[sourceKey as keyof typeof source];
  });

  return result;
};

export const toLowerCaseKeyObject = (
  obj: object | undefined,
): object | undefined => {
  if (isNullOrUndefined(obj)) {
    return undefined;
  }

  return mapObjects(obj, (sourceKey: string) => sourceKey.toLowerCase());
};

export const isEmpty = (value: any): boolean => {
  // Check for null or undefined
  if (value == null) {
    return true;
  }

  //Checkf for empty array
  if (isArrayLike(value) && value.length === 0) {
    return true;
  }

  // Check for empty object
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

function isArrayLike(value: any) {
  return value != null && typeof value !== 'function' && isLength(value.length);
}

function isLength(value: any) {
  return (
    typeof value === 'number' &&
    value > -1 &&
    value % 1 === 0 &&
    value <= MAX_SAFE_INTEGER
  );
}

/** Used as references for various `Number` constants. */
const MAX_SAFE_INTEGER = 9007199254740991;

/**
mapByNestedKeys function docs
src: {
  'email': 'test',
  'preferences{separator}channels{separator}email': true
}
and will map to the destination object as following:
dest: {
  'email': 'test',
  'preferences': {
    'channels': {
      'email': {
        true
      }
    }
  }
}
This also works for arrays {addresses_0_city: 'test'} -> {addresses : [{city: 'test'}]}
*/
export const mapByNestedKeys = (
  src: any | undefined,
  dest: any | undefined,
  separator: string,
): object => {
  if (isNullOrUndefined(src)) {
    return dest;
  }

  if (isNullOrUndefined(dest)) {
    return src;
  }

  const srcKeys = Object.keys(src);

  srcKeys.forEach(srcKey => {
    const srcValue = src[srcKey];

    const updatedDest = clone(dest);
    let currentDest = updatedDest;

    srcKey.split(separator).forEach((key, index, array) => {
      if (index !== array.length - 1) {
        currentDest[key] = currentDest[key] || {};
        currentDest = currentDest[key];
        return;
      }
      const value = srcValue?.value || srcValue;

      if (typeof currentDest[key] === 'boolean') {
        if (isArrayLike(value)) {
          currentDest[key] = hasValue(value);
        } else {
          currentDest[key] = !!value;
        }
        
        return;
      }
      if (Array.isArray(currentDest[key])) {
        if (!value) {
          currentDest[key] = [];
          return;
        }
        if (Array.isArray(value)) {
          currentDest[key] = value.filter((elem) => elem !== null);
          return;
        }
        currentDest[key] = [value].flat();
        return;
      }
      currentDest[key] = value;
    });

    Object.assign(dest, updatedDest);
  });

  return dest;
};

export const flattenObjectWithChar = (object: any, separator: string): any => {
  const result: any = {};

  if (isNullOrUndefined(object)) {
    return object;
  }

  if (isArrayOfStrings(object)) {
    return object;
  }

  Object.keys(object).forEach(key => {
    if (!isNullOrUndefined(object[key]) && typeof object[key] === 'object') {
      const temp = flattenObjectWithChar(object[key], separator);
      //Add them for the validation as fields not displayed on the form cause the schema validation to fail
      if (isArrayOfStrings(temp)) {
        result[key] = temp;
      } else {
        Object.keys(temp).forEach(tempKey => {
          result[key + separator + tempKey] = temp[tempKey];
        });
      }
    } else {
      result[key] = object[key];
    }
  });

  return result;
};

export function unFlattenObject(data: any, separator: string) {
  const result = {};
  for (const i in data) {
    const keys: any = i.split(separator);
    keys.reduce(function (r: any, e: any, j: any) {
      return (
        r[e] ||
        (r[e] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 === j
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
}

export const generateUniqueKey = (length = 5): string => {
  const dictionary =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  let key = '';
  for (let i = 0; i < length; i++) {
    key += dictionary.charAt(Math.floor(Math.random() * dictionary.length));
  }
  return key;
};

export const getBooleanValue = (value?: string) => value === 'true';

export const stringToBoolean = (str?: string) => {
  if (!hasValue(str)) {
    return false;
  }

  return String(str).toLowerCase() === 'true';
};

export const isItemObject = (item: any): boolean => {
  return typeof item === 'object' && item !== null;
};

const isArrayOfStrings = (obj: any): boolean => {
  return Array.isArray(obj) && obj.every(element => typeof element === 'string');
};
