/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const assetFolders = {
    /**
     * Articles
     */
    articles: {
        id: 'd47a488d-c8b4-4d31-9feb-f546e1c78147',
        name: 'Articles',
        externalId: undefined,
        folders: {}
    },

    /**
     * Attend Panels
     */
    attendPanels: {
        id: '6649a5b1-d55b-4be5-ae0b-eaa4be98318e',
        name: 'Attend Panels',
        externalId: undefined,
        folders: {}
    },

    /**
     * Call Out Panel
     */
    callOutPanel: {
        id: 'f5ea132e-099b-4af8-992e-eb218d38dba9',
        name: 'Call Out Panel',
        externalId: undefined,
        folders: {}
    },

    /**
     * Carousel Heroes
     */
    carouselHeroes: {
        id: '1daa0ce5-f3bd-492d-9441-a441610f82c4',
        name: 'Carousel Heroes',
        externalId: undefined,
        folders: {
            /**
             * Mobile
             */
            mobile: {
                id: '36dd089b-e84a-4bb3-bd01-8e0554369193',
                name: 'Mobile',
                externalId: undefined,
                folders: {}
            }
        }
    },

    /**
     * Event Hero
     */
    eventHero: {
        id: 'dba6b8cb-b7f7-48e4-958f-823c9e9db6f5',
        name: 'Event Hero',
        externalId: undefined,
        folders: {}
    },

    /**
     * event slider
     */
    eventSlider: {
        id: 'b60981ea-5b9a-4fbd-9f5e-36c24a192451',
        name: 'event slider',
        externalId: undefined,
        folders: {}
    },

    /**
     * explore benefits
     */
    exploreBenefits: {
        id: 'ee456bfb-ee61-4368-8b41-f5068098e154',
        name: 'explore benefits',
        externalId: undefined,
        folders: {}
    },

    /**
     * footer
     */
    footer: {
        id: 'c18fff99-f7ae-4af4-8016-a5d3d69de38f',
        name: 'footer',
        externalId: undefined,
        folders: {}
    },

    /**
     * PDF Assets
     */
    pDFAssets: {
        id: '7f518d1e-086e-4927-aea2-db2b150bb6e2',
        name: 'PDF Assets',
        externalId: undefined,
        folders: {}
    },

    /**
     * Primary Images
     */
    primaryImages: {
        id: '0e4f8734-3f64-41a5-8fdd-9f7f71142594',
        name: 'Primary Images',
        externalId: undefined,
        folders: {}
    },

    /**
     * Promo Card Highlight
     */
    promoCardHighlight: {
        id: '23a07130-95e4-4017-af47-48c462793417',
        name: 'Promo Card Highlight',
        externalId: undefined,
        folders: {
            /**
             * Mobile
             */
            mobile: {
                id: '2b5b5564-5d2c-4f3f-81ab-0e19d3690648',
                name: 'Mobile',
                externalId: undefined,
                folders: {}
            }
        }
    },

    /**
     * Promo Card Lists
     */
    promoCardLists: {
        id: '54e00569-7c6c-4002-a88f-fe5b16d224d1',
        name: 'Promo Card Lists',
        externalId: undefined,
        folders: {}
    },

    /**
     * test
     */
    test: {
        id: '63e05720-f1d7-4623-8b8c-6d097a1fa5a9',
        name: 'test',
        externalId: undefined,
        folders: {}
    },

    /**
     * video
     */
    video: {
        id: 'e1085ea5-2423-4f87-bd26-e46faf5fbcee',
        name: 'video',
        externalId: undefined,
        folders: {}
    },

    /**
     * Web Banners
     */
    webBanners: {
        id: '335dff6d-c5e0-43c8-81ba-d74bc54d4ebe',
        name: 'Web Banners',
        externalId: undefined,
        folders: {}
    }
} as const;
