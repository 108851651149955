import { ResponseModel } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { VerifyEmailInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";

const defaultValues = {
  user : {
    email: ''
  },
  kontentItemCodename: '',
};

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {

  return await genericMutationHandlerWithFormValidation(
    params,
    VerifyEmailInputSchema,
    defaultValues
  );
};


export default handle;