import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';
import React from 'react';

export const form_date_field = (data: SSO_MODELS.FormDateField) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  const formField = getFirstMappedLinkedItemOrDefault(elements?.form_field);

  return {
    yearOffset: elements?.year_offset?.value ?? undefined,
    minimumYear: elements?.minimum_year?.value ?? undefined,
    key: data?.system?.codename,
    name: formField?.name,
    fieldRef: React.createRef(),
    fieldType: formField?.fieldType,
    subfields: formField?.subfields,
    label: formField?.label,
    validationRegex: formField?.validationRegex,
    validationMessage: formField?.validationMessage,
    isRequired: formField?.isRequired,
  };
};

export default form_date_field;
