import { FC } from 'react';
import classnames from 'classnames';
import { useSSOCta } from './useSSOCta';

import styles from './SSOCta.module.scss';
import { RenderOn } from '../RenderOn/RenderOn';
import { SSOCtaAction, useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { renderOnBySSOCtaAction } from '../../helpers/renderOnBySSOCtaAction';

export type SSOCtaType = 'button' | 'link';

export type SSOCtaProps = {
  label?: string;
  action: string;
  type: SSOCtaType;
};

const actionsToLabelsMap = {
  [SSOCtaAction.LOGIN]: 'Login',
  [SSOCtaAction.REGISTER]: 'Register',
  [SSOCtaAction.LOGOUT]: 'Logout',
  [SSOCtaAction.UPGRADE]: 'Upgrade',
  [SSOCtaAction.UPDATE]: 'Update',
  [SSOCtaAction.CHANGE_PASSWORD]: 'Change Password',
  [SSOCtaAction.DELETE_ACCOUNT]: 'Delete Account'
};

export const SSOCta: FC<SSOCtaProps> = ({ label, action, type }) => {
  const { onClick } = useSSOCta({ action });
  const isButton = type === 'button';
  const {
    userState: { isAuthenticated, membership },
  } = useAuthContext();
  
  const renderOn = renderOnBySSOCtaAction(action, {isAuthenticated, membershipType: membership?.type});
  return (
    <RenderOn condition={renderOn}>
      <span
        className={classnames(
          isButton ? [styles['btn'], styles['btn--primary']] : styles['link'],
        )}
        onClick={onClick}
      >
        {label || actionsToLabelsMap[action as keyof typeof actionsToLabelsMap]}
      </span>
    </RenderOn>
  );
};

