import { MyAccount } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: () => MyAccount,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.MyAccountPage) =>
    kontentAiDataMapping.my_account_page(entry),
};
