import React, { useState, useEffect } from 'react';
import styles from './Dropdown.module.scss';

export interface Option {
  label: string;
  value: string;
}

export interface DropdownProps {
  label: string;
  options: Option[];
  onChange: (selectedValues: string[]) => void;
  defaultSelected?: string[];
}

export function Dropdown({
  label,
  options,
  onChange,
  defaultSelected,
}: DropdownProps) {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    defaultSelected || [],
  );

  useEffect(() => {
    setSelectedValues(defaultSelected || []);
  }, [defaultSelected]);

  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter(selectedValue => selectedValue !== value)
      : [...selectedValues, value];
    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{label}</div>
      <div className={styles.list}>
        {options.map(option => (
          <label key={option.value} className={styles.checkbox}>
            <input
              type="checkbox"
              value={option.value}
              checked={selectedValues.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
            />
            <span>{option.label}</span>
            <span className={styles.checkbox__icon}></span>
          </label>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;
