import React from 'react';
import styles from './WebBanner.module.scss';
import { Link, LinkProps } from '../Link/Link';
import { Image, ImageProps } from '../Image/Image';
import { useScreenDetector } from '../../hooks';
import { sendGAEvent } from '@next/third-parties/google';
import { getPageTrackingDetails } from '@r-and-a-shared-ui/utils';

export interface WebBannerProps {
  mobile_image?: ImageProps;
  window_image?: ImageProps;
  portrait_image?: ImageProps;
  link?: LinkProps;
}

export function WebBanner({ mobile_image, window_image, link }: WebBannerProps) {
  const { isMobile } = useScreenDetector();
  const selectedImage = !isMobile ? window_image : mobile_image;

  const handleClick = () => {
    sendGAEvent({event: "banner_ad_click", value: {
      ...getPageTrackingDetails(),
      bannerName: selectedImage?.alt
    }});
  };

  return (
    <div className={styles.content} onClick={handleClick}>
      {selectedImage && link && (
        <Link
          codename={link.codename}
          href={link?.href}
          title={link?.title}
          internal={link?.internal}
          icon={link?.icon}
        >
          <Image
            imageUrl={selectedImage.imageUrl}
            license={selectedImage.license}
            width={selectedImage.width}
            height={selectedImage.height}
            alt={selectedImage.alt || "Web Banner Image"}
            schemaOrgContent={selectedImage.schemaOrgContent}
          />
        </Link>
      )}
    </div>
  );
  
}

export default WebBanner;