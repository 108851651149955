import {
  AsyncComponent,
  ConfiguredComponents,
  DynamicComponent,
} from '@r-and-a-shared-ui/core-component-config';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { RenderOn } from '../RenderOn/RenderOn';

import type { MyAccountProps } from './types';

import styles from './MyAccount.module.scss';

export const MyAccountBodyContent = ({
  body,
  sidebar,
}: Pick<MyAccountProps, 'body' | 'sidebar'>) => {
  return (
    <div data-testid={'body-content'} className={styles.content}>
      <div className={styles['content__left']}>
        <RenderOn condition={!!body.length}>
          {body.map((x: IContentItem<any> & { dynamic: boolean }) => {
            // if (x.dynamic) {
            //   return (
            //     <DynamicComponent
            //       key={x.system.id}
            //       codename={x.system.codename}
            //       contentType={x.system.type}
            //       locale={x.system.language}
            //     />
            //   );
            // }
            return (
              <AsyncComponent
                kontentItem={x}
                contentType={x.system.type as ConfiguredComponents}
                key={x.system.id}
              />
            );
          })}
        </RenderOn>
      </div>
      <div className={styles['content__right']}>
        <RenderOn condition={!!sidebar.length}>
          {sidebar.map((x: IContentItem<any>) => {
            return (
              <AsyncComponent
                kontentItem={x}
                contentType={x.system.type as ConfiguredComponents}
                key={x.system.id}
              />
            );
          })}
        </RenderOn>
      </div>
    </div>
  );
};
