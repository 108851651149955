import classnames from 'classnames';
import { RestrictionLevel } from './constants';
import Image from '../Image/Image';
import StandardIconLightBlue from '../../assets/OneClubIconLightBlue.svg';
import StandardIconOrange from '../../assets/StandardIcon.svg';
import AdvantageIcon from '../../assets/Mastercard.svg';

import styles from './MembershipTypeLogos.module.scss';

type MembershipTypeLogoProps = {
  restrictionLevel: keyof typeof RestrictionLevel;
  className?: string;
};

export const MembershipTypesLogos = ({
  restrictionLevel,
  className,
}: MembershipTypeLogoProps) => {
  if (restrictionLevel === 'none') {
    return null;
  }

  if (restrictionLevel === RestrictionLevel.oneClub) {
    return (
      <div className={classnames(styles['icon-group'], className)}>
        <Image
          width={37}
          height={40}
          imageUrl={StandardIconLightBlue}
          alt="Standard"
          className={styles['icon-group__item']}
        />
      </div>
    );
  }

  if (restrictionLevel === RestrictionLevel.oneClubAdvantage) {
    return (
      <div className={classnames(styles['icon-group'], className)}>
        <div className={classnames(styles['icon-group__item'], className)}>
          <Image
            width={37}
            height={40}
            imageUrl={StandardIconOrange}
            alt="OneClubIcon"
          />
        </div>
        <div className={classnames(styles['icon-group__item'], className)}>
          <Image
            imageUrl={AdvantageIcon}
            alt="Mastercard Logo"
            width={40}
            height={40}
          />
        </div>
      </div>
    );
  }
};

export default MembershipTypesLogos;
