import { useController, useFormContext } from 'react-hook-form';
import styles from '../FormCheckboxesGroup/FormCheckboxesGroup.module.scss';
import { FormFieldProps } from '../FormField/FormField';
import { hasValue } from '@r-and-a-shared-ui/utils';
import { defaultValidationMessage } from '../config/validators/validators';

export function FormRadioButtons(props: FormFieldProps) {
  const { name, label, isDisabled, validationMessage, options } = props;

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const { field } = useController({ name, control });
  const { value, onChange: selectOnChange, ...restField } = field;

  return (
    <div
      className={`${styles['form-element']} ${
        errors?.[name] ? styles['form-element__has-error'] : ''
      }`}
    >
      {hasValue(label) && (
        <label className={styles['form-element__label']} htmlFor={name}>
          {label}
        </label>
      )}
      {options?.map((option, index) => (
        <div
          key={`${name}_${index}`}
          className={`${styles['form-element__radio']}`}
        >
          <label>
            {option.label}
            <input
              id={`${name}_${index}`}
              type="radio"
              value={option.value}
              {...restField}
              {...register(name)}
              disabled={isDisabled}
            />
            <span className={styles['form-element__checkmark']}></span>
          </label>
        </div>
      ))}
      {errors?.[name] ? (
        <small className={styles['form-element__hint']}>
          {validationMessage || defaultValidationMessage}
        </small>
      ) : null}
    </div>
  );
}

export default FormRadioButtons;
