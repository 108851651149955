import { AssetProps, RichTextParser } from '@r-and-a-shared-ui/common-ui';
import styles from './RichTextWithAsset.module.scss';
import Image from 'next/image';
import { redirectTo } from '@r-and-a-shared-ui/utils';

export interface RichTextWithAssetProps {
  key: string,
  content?: string,
  desktopAsset?: AssetProps,
  mobileAsset?: AssetProps,
  url?:string
}

export function RichTextWithAsset(props: RichTextWithAssetProps) {
  const { desktopAsset, mobileAsset } = props;
  return (
    <div className={styles['container']}>
      {props.content && <RichTextParser richText={props.content} />}
      <div className={`${styles['image__desktop']}`}>
        {desktopAsset?.url &&
        <Image
          src={desktopAsset?.url}
          onClick={()=>{
            redirectTo(props.url, false, true);
          }}
          alt={desktopAsset?.description}
          width={desktopAsset?.width || 300}
          height={desktopAsset?.height || 200}
        />
        }
      </div>
      <div className={`${styles['image__mobile']}`}>
        {mobileAsset?.url &&
        <Image
          src={mobileAsset?.url}
          alt={mobileAsset?.description}
          width={mobileAsset?.width || 300}
          height={mobileAsset?.height || 200}
        />}
      </div>
    </div>
  );
}

export default RichTextWithAsset;
