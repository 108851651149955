import { TextItemProps } from '../TextItem/TextItem';
import styles from './Tag.module.scss';
import React from 'react';

export interface TagProps {
  label?: TextItemProps;
  backgroundColor?: string;
  textColor?: string;
}

export function Tag(props: TagProps) {
  const { label, backgroundColor, textColor } = props;

  return (
    <div className={styles['container']}>
      <span
        className={styles['label']}
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        {label?.value}
      </span>
    </div>
  );
}

export default Tag;
