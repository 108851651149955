/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const roles = {
    /**
     * Content Editor
     */
    contentEditor: {
        codename: undefined,
        id: '9347d4f1-6f77-43ef-85a3-7d57ba216c45',
        name: 'Content Editor'
    },

    /**
     * Project manager
     */
    projectManager: {
        codename: 'project-manager',
        id: '79f3b4d0-f6f5-4ef7-addd-e8be480e424f',
        name: 'Project manager'
    }
} as const;
