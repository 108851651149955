import React from 'react';
import { FormFieldTypes } from "./form-field-config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { isFunction } from '@r-and-a-shared-ui/utils';

export interface FormFieldIconParams {
    fieldHasError?: boolean,
    toggleShowPassword?: (...args: unknown[]) => unknown,
    showPassword?: boolean,
    className?: string
}

export const formFieldIcons: Record<FormFieldTypes, (params: FormFieldIconParams) => React.JSX.Element | undefined > = {
  text: () => undefined,
  email: (params) => {
    if (params?.fieldHasError) {
      return undefined;
    }

    return (
      <FontAwesomeIcon
        className={`${params?.className}`}
        icon={faCheck}
        size="xl" />
    );
  },
  password: (params) => {
    const icon: IconDefinition = params?.showPassword ? faEye : faEyeSlash;

    const handleOnClick = () => {
      if (isFunction(params?.toggleShowPassword)) {
        params?.toggleShowPassword();
      }
    };

    return (
      <FontAwesomeIcon
        className={`${params?.className}`}
        icon={icon}
        size="xl"
        onClick={handleOnClick} />
    );
  },
  password_confirmation: () => undefined,
  dropdown: () => undefined,
  radio_buttons: () => undefined,
  checkboxes: () => undefined,
  number: () => undefined,
  phone_number: () => undefined,
  country: () => undefined,
  favorite_player: () => undefined,
  date: () => undefined,
  mastercard: () => undefined,
  undefined: () => undefined,
  one_club_free_text: () => undefined,
};