import { deliveryClient } from '../config/kontent-delivery-client';
import type { Article } from '../models/MEMBERSHIP';
import { ArticlesFiltersMap, Pagination } from '@r-and-a-shared-ui/utils';
import { IPagination, MultipleItemsQuery } from '@kontent-ai/delivery-sdk';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { adjustSystemProperties } from '../utils';

type GetFilteredArticlesResponse = {
  queryUrl: string;
  items: Article[];
  pagination: IPagination;
};

type GetFilteredArticlesProps = {
  [key: string]: string[] | Pagination | boolean;
};

const addFiltersToQuery = (
  query: MultipleItemsQuery<Article>,
  props: GetFilteredArticlesProps,
) => {
  for (const [key, value] of Object.entries(props)) {
    if(key === 'pagination') {
      return;
    }

    const valueArray = value as string[];
    if (key in ArticlesFiltersMap && valueArray.length > 0)
      query = query.anyFilter(
        `elements.${ArticlesFiltersMap[key as keyof typeof ArticlesFiltersMap]
        }`,
        valueArray,
      );
  }
  return query;
};

export const getFilteredArticles = async (
  props: GetFilteredArticlesProps,
  cache = true,
  cacheTTL = CacheConfig.TTL.PageElement.valueOf(),
  cacheStatus?: CacheStatus
) => {
  try {
    const cacheKey = `getFilteredArticles-${JSON.stringify(props)}`;
    const cachedItem = getCachedItem<GetFilteredArticlesResponse>(cacheKey);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }
    const pageSize =  props['pagination']
      ? (props['pagination'] as Pagination).pageSize
      : 0;
    const pageNumber =  props['pagination']
      ? (props['pagination'] as Pagination).pageNumber
      : 10;

    const query = deliveryClient
      .items<Article>()
      .type('article')
      .depthParameter(1);

    addFiltersToQuery(query, props);

    query.orderParameter('elements.publish_date', 'asc');

    const queryText = query.getUrl();

    const data = await query
      .limitParameter(pageSize)
      .skipParameter(pageNumber * pageSize)
      .includeTotalCountParameter()
      .toPromise().then(r => r.data);

    adjustSystemProperties(data);

    const { items, ...rest } = data;

    const response = {
      queryUrl: queryText,
      items,
      ...rest,
    };

    setCacheItem<GetFilteredArticlesResponse>(cacheKey, response, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return response;
  } catch (error) {
    console.error('Error in getFilteredArticles', error);
    throw error;
  }
};
