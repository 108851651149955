import { ResponseModel } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { execAddUserToAddressBookMutation } from "../form-action-helpers";

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {
  return await execAddUserToAddressBookMutation(params);
};


export default handle;