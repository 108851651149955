import {
  createContext,
  useContext
} from 'react';
import { SiteConfigContextType } from './SiteConfigContext';

export type SSOConfigContextType = SiteConfigContextType

export const SSOConfigContext = createContext<SSOConfigContextType | undefined>(undefined);

export const useSSOConfigContext = () => {
  const context = useContext(SSOConfigContext);
  return context;
};
