import React from 'react';
import ArticleFilter from './ArticleFilter';
import { ImageProps } from '../Image/Image';

export interface DynamicFilterProps {
  component: string;
  backgroundImage: ImageProps | null;
}

export function DynamicFilter({ component, backgroundImage }: DynamicFilterProps) {
  switch (component) {
  case 'article': {
    return <ArticleFilter backgroundImage={backgroundImage}  />;
  }
  default:
    <p>The filter does not exist for this component</p>;
  }
}

export default DynamicFilter;
