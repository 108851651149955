import { useFormContext } from 'react-hook-form';
import FormDropdown from '../FormDropdown/FormDropdown';
import { FormFieldProps, OptionProps } from '../FormField/FormField';
import { useEffect, useState } from 'react';
import { COUNTY_IDENTIFIER, STATE_IDENTIFIER } from '@r-and-a-shared-ui/utils';

export function CountryFormField(props: FormFieldProps) {
  const { watch, setValue,
    formState: { errors } } = useFormContext();
  const { name: countryName, options } = props;
  const countyField = props.subfields?.find((field) => field?.name?.includes(COUNTY_IDENTIFIER)) as FormFieldProps;
  const stateField = props.subfields?.find((field) => field?.name?.includes(STATE_IDENTIFIER)) as FormFieldProps;
  
  const countrySelected = watch(countryName);

  const [countyOptions, setCountyOptions] = useState<OptionProps[] | undefined>(
    undefined,
  );

  const [stateOptions, setStateOptions] = useState<OptionProps[] | undefined>(
    undefined,
  );

  useEffect(() => {
    const countryOption = options?.find(
      country => country.value === countrySelected?.value || country.value === countrySelected,
    );
    if (countryOption?.counties) {
      setCountyOptions(countryOption?.counties as OptionProps[]);
      setStateOptions(undefined);
      setValue(stateField?.name, undefined);
      return;
    }
    if (countryOption?.states) {
      setStateOptions(countryOption?.states as OptionProps[]);
      setCountyOptions(undefined);
      setValue(countyField?.name, undefined);
      return;
    }

    setCountyOptions(undefined);
    setStateOptions(undefined);
    setValue(countyField?.name, undefined);
    setValue(stateField?.name, undefined);
  }, [countrySelected, options]);

  return (
    <>
      <FormDropdown {...props} entireObjectSelection menuPlacement="top" />
      {countyField && countyOptions && (
        <FormDropdown
          {...countyField}
          options={countyOptions}
          entireObjectSelection
          menuPlacement="top"
          triggerError={!!errors?.[countyField.name]}
        />
      )}
      {stateField && stateOptions && (
        <FormDropdown
          {...stateField}
          options={stateOptions}
          entireObjectSelection
          menuPlacement="top"
          triggerError={!!errors?.[stateField.name]}
        />
      )}
    </>
  );
}

export default CountryFormField;
