import { CSSProperties } from 'react';
import parse from 'html-react-parser';
import styles from './AlertBar.module.scss';
import { calculateRemainingTime, getTimeText } from '@r-and-a-shared-ui/utils';

export interface AlertBarProps {
  text?: string;
  countdown?: string | null;
  daysLabel?: string;
  dayLabel?: string;
  hoursLabel?: string;
  hourLabel?: string;
  minutesLabel?: string;
  minuteLabel?: string;
  backgroundColor?: string;
  textColor?: string;
  textFont?: string;
  backgroundImage?: string;
}

export function AlertBar(props: AlertBarProps) {
  const {
    text,
    countdown,
    daysLabel,
    dayLabel,
    minutesLabel,
    minuteLabel,
    hoursLabel,
    hourLabel,
    backgroundColor,
    textColor,
    textFont,
    backgroundImage,
  } = props;

  const inlineStyle: CSSProperties = {
    backgroundColor,
    color: textColor,
    fontFamily: textFont,
    backgroundImage: backgroundImage && `url(${backgroundImage})`,
  };

  const {
    days = undefined,
    hours = undefined,
    minutes = undefined,
  } = countdown ? calculateRemainingTime(countdown) : {};

  const daysTextValue = getTimeText(days, dayLabel, daysLabel);
  const hoursTextValue = getTimeText(hours, hourLabel, hoursLabel);
  const minutesTextValue = getTimeText(minutes, minuteLabel, minutesLabel);

  return (
    <div className={styles['alert-bar']}
      style={inlineStyle}
      suppressHydrationWarning
    >
      {text && parse(text, { trim: true })}
      {props.countdown ? (
        <b>
          {daysTextValue} {hoursTextValue} {minutesTextValue}
        </b>
      ) : null}
    </div>
  );
}

export default AlertBar;
