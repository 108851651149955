import { FeatureCardProps } from '../FeatureCard.types';
import { replacePlaceholders } from '@r-and-a-shared-ui/utils';
import dayjs from 'dayjs';
import { Attributes, useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { useMemo } from 'react';

export const useFeatureCardTOC = ({
  title,
  description,
}: Omit<FeatureCardProps, 'ctas' | 'image' | 'theme'>) => {
  const {
    userState: { user, membership },
  } = useAuthContext();
  const memberName = user?.[Attributes.GivenName] || '';
  const formattedDateSinceMember = membership.startedAt ? dayjs(membership.startedAt).format(
    'MMMM YYYY',
  ) : '2024';

  const parsedTitle = useMemo(
    () =>
      replacePlaceholders(title, {
        memberName,
        formattedDateSinceMember,
      }),
    [title, memberName, formattedDateSinceMember],
  );

  const parsedDescription = useMemo(
    () =>
      replacePlaceholders(description.html, {
        memberName,
        formattedDateSinceMember,
      }) || '',
    [description.html, memberName, formattedDateSinceMember],
  );

  return {
    parsedTitle,
    parsedDescription,
  };
};
