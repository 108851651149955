import z from 'zod';
export const userProfileZodObject = {
  userJourneyType: z.number().optional(),
  kontentItemCodename: z.string(),
  user: z.object({
    interests: z.object({
      favoritePlayer: z.any().nullish(),
      favoritePlayerOther: z.any().nullish(),
      venues: z.array(z.string()),
    }),
    title: z.string().nullable().optional(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    addresses: z.array(
      z.object({
        addressLine1: z.string().nullable().optional(),
        addressLine2: z.string().nullable().optional(),
        addressLine3: z.string().nullable().optional(),
        postcode: z.string().nullable().optional(),
        city: z.string().nullable().optional(),
        country: z.string().nullable().optional(),
        county: z.string().nullable().optional(),
        state: z.string().nullable().optional(),
        main: z.boolean().optional(),
        billingAddress: z.boolean().optional(),
        shippingAddress: z.boolean().optional(),
      }),
    ),
    membership: z.string(),
    raMemberId: z.string().nullable(),
    ticketingAccountId: z.string().nullable(),
    isActive: z.boolean(),
    currentWebsite: z.number(),
    dateOfBirth: z
      .date()
      .nullable()
      .optional()
      .or(z.string().nullable().optional()),
    optIns: z.object({
      optedTheOpenOffersByEmail: z.boolean(),
      optedPartnerOffersByEmail: z.boolean(),
      agreedToTermsAndConditions: z.boolean(),
      agreedToTermsAndConditionsLastUpdated: z.string().nullable(),
      agreedToPrivacyPolicy: z.boolean(),
      theOpenOffersUpdated: z.string().nullable(),
      partnerOffersUpdated: z.string().nullable(),
    }),
    preferences: z.object({
      channelsValues: z.array(z.string()).optional(),
      championshipValues: z.array(z.string()).optional(),
      interestsValues: z.array(z.string()).optional(),
    }),
    ssoUserId: z.string().nullable(),
    preferredUserName: z.string().nullable(),
    mobilePhone: z.string().optional(),
    countryCodeMobilePhonePrefix: z.string().nullable(),
    zoneInfo: z.string().nullable(),
    id: z.string().nullable(),
    isEmailVerified: z.string().nullable(),
    platform: z.string().nullable(),
    createdDate: z.date().nullable().optional(),
    ballotCriteria: z.string().nullable(),
    becameMasterCard: z.string().nullable(),
    lastLoggedInDate: z.string().nullable(),
    lastLoggedInDevice: z.string().nullable(),
    lastLoggedInPlatform: z.string().nullable(),
  }),
};
