import React from 'react';
import styles from './FilterPanel.module.scss';

export interface TagProps {
  label: string;
  value: string;
}

export interface FilterPanelProps {
  selectedTags: TagProps[];
  onRemoveTag: (value: string) => void;
}

export function FilterPanelTag({
  label,
  value,
  onRemoveTag,
}: TagProps & { onRemoveTag: (value: string) => void }) {
  return (
    <div className={styles.tag}>
      {label}
      <button onClick={() => onRemoveTag(value)} className={styles.remove}>
        ✖
      </button>
    </div>
  );
}

export function FilterPanel({ selectedTags, onRemoveTag }: FilterPanelProps) {
  return (
    <div className={styles.wrapper}>
      {selectedTags.map((tag, index) => (
        <FilterPanelTag
          key={index}
          label={tag.label}
          value={tag.value}
          onRemoveTag={onRemoveTag}
        />
      ))}
    </div>
  );
}

export default FilterPanel;
