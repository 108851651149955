import type { MEMBERSHIP_MODELS, SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getColorValue,
  getFirstLinkedItemOrDefault,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';

export const form_header = (data: SSO_MODELS.FormHeader & MEMBERSHIP_MODELS.FormHeader) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  return {
    headerTitle: elements?.title?.value,
    headerSubTitle: elements?.subtitle?.value,
    titleColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.title_color),
    ),
    subTitleColor: getColorValue(
      getFirstLinkedItemOrDefault(elements?.subtitle_color),
    ),
    headerCTAs: getMappedLinkedItems(elements?.ctas),
  };
};

export default form_header;
