import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { deliveryClient } from '../config/kontent-delivery-client';
import type { Article } from '../models/MEMBERSHIP';
import { adjustSystemProperties } from '../utils';

export const getLatestArticles = async (
  cache = true,
  cacheTTL = CacheConfig.TTL.LatestArticles.valueOf(),
  cacheStatus?: CacheStatus,
  limit = 6
) => {
  try {
    const cacheKey = 'getLatestArticles';

    const cachedItem = getCachedItem<Article[]>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL,
      });
      return {
        articles: cachedItem,
      };
    }

    const latestArticlesResponse = await deliveryClient
      .items<Article>()
      .type('article')
      .orderParameter('elements.publish_date', 'desc')
      .limitParameter(limit)
      .toPromise()
      .then(r => r.data);

    adjustSystemProperties(latestArticlesResponse.items);

    setCacheItem(cacheKey, latestArticlesResponse.items, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL,
    });

    return {
      articles: latestArticlesResponse.items,
    };
  } catch (error) {
    console.error('Error in getLatestArticles', error);
    throw error;
  }
};
