import { hasValue, isNullOrUndefined } from "./general-utils";

export interface SearchParam {
  key?: string,
  value?: string,
}

export const addQueryParam = (
  url?: string,
  key?: string,
  value?: string,
): string | undefined => {
  if (!url || !key || !value) {
    return;
  }

  const urlObject = new URL(url);

  urlObject.searchParams.set(key, value);

  const modifiedUrl: string = urlObject.toString();

  return modifiedUrl;
};
export const ensureAbsoluteUri = (uriString: string | undefined): string => {
  if (!uriString || !hasValue(uriString)) {
    return window.location.origin;
  }

  let decodedUri = decodeURIComponent(uriString);

  const isAbsolute = decodedUri.startsWith('http') || decodedUri.includes('://');
  if (!isAbsolute) {
    decodedUri = "https://" + decodedUri;
  }

  return decodedUri;
};

export const ensureValidUri = (uriString: string | undefined, base?: string): string => {
  const origin = ensureAbsoluteUri(base)?.replace(/\/+$/, '');

  if (isNullOrUndefined(uriString)) {
    return origin;
  }

  const isAbsolute: boolean = /:\/\//.test(uriString);

  if (isNullOrUndefined(uriString)){
    return uriString;
  }

  if (!isAbsolute) {
    if (!uriString.startsWith('/')) {
      return `${origin}/${uriString}`;
    }

    return `${origin}${uriString}`;
  }

  return uriString;
};

export const addQueryParams = (
  url?: string,
  params?: SearchParam[],
): string | undefined => {
  if (!url || !hasValue(params)) {
    return url;
  }

  params?.forEach((param) => {
    url = addQueryParam(url, param.key, param.value) || url;
  });

  return url;
};

export const ensureRelativePath = (path: string | undefined): string => {
  if (!path || !path.length) {
    return "";
  }
    
  return path[0]==='/' ? path : `/${path}`;
};

export const urlSafeEncode = (str: string) => {
  if (!hasValue(str)) {
    return str;
  }

  return str
    .split('')
    .map(char => char.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('');
};

export const urlSafeDecode = (hex: string) => {
  if (!hasValue(hex)) {
    return hex;
  }
  
  const matchArr = hex.match(/.{2}/g) || [];
  return matchArr.map(char => String.fromCharCode(parseInt(char, 16))).join('');
};
