import * as kontentAIComponentMapping from '../kontent_ai_mapping';
import { CMSBasedDataMapping, SUPPORTED_CMS } from '../types';

export class GlobalDataMapping {
  public dataMapping: CMSBasedDataMapping;
  public getDataMapping: () => CMSBasedDataMapping;
  private static instance: GlobalDataMapping;

  private constructor() {
    this.dataMapping = {
      [SUPPORTED_CMS.KONTENT]: kontentAIComponentMapping,
    };
    this.getDataMapping = () => this.dataMapping;
  }

  public static getInstance(): GlobalDataMapping {
    if (!GlobalDataMapping.instance) {
      GlobalDataMapping.instance = new GlobalDataMapping();
    }
    return GlobalDataMapping.instance;
  }
}

/// make the above class a singleton

export const globalDataMapping = GlobalDataMapping.getInstance();
