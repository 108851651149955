import { createRichTextHtmlResolver, Elements } from '@kontent-ai/delivery-sdk';
import * as KontentDelivery from '@kontent-ai/delivery-sdk';
import RichTextElement = Elements.RichTextElement;
// import ReactDOMServer from 'react-dom/server';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
// import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';

export const resolveRichText = (richText: RichTextElement) => {
  const resolver = createRichTextHtmlResolver(nodeParser);
  if(!richText) {
    return undefined;
  }
  return resolver.resolveRichText({
    element: richText,
    linkedItems: KontentDelivery.linkedItemsHelper.convertLinkedItemsToArray(
      richText?.linkedItems as any || {},
    ),
    urlResolver: (linkId, linkText, link) => {
      if (!link) {
        return {
          linkHtml: undefined,
        };
      }

      if (link.type === 'article') {
        const url = `articles/${link.urlSlug}`;
        return {
          linkHtml: `<a href="url">${linkText}</a>`,
          linkUrl: url,
        };
      }

      if (link.type === 'page') {
        return {
          linkHtml: `<a href="/${link.urlSlug}">${linkText}</a>`,
        };
      }

      return {
        linkHtml: undefined,
      };
    },
  });
};
