import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const article_author = (entry: MEMBERSHIP_MODELS.ArticleAuthor) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  return {
    name: elements.name.value,
    surname: elements.surname.value,
  };
};

export default article_author;
