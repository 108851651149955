import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';

export const sso_cta = (entry: MEMBERSHIP_MODELS.SSOCTA) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry || {};

  return {
    label: elements.label.value,
    action: getSingleChoiceValue(elements.action_type),
    type: getSingleChoiceValue(elements.type),
  };
};
