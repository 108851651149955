import { Elements } from '@kontent-ai/delivery-sdk/lib/elements';
import {
  PortableText,
  PortableTextMarkComponentProps,
  PortableTextReactComponents,
  PortableTextTypeComponentProps,
  toPlainText,
} from '@portabletext/react';
import {
  browserParse,
  nodeParse,
  transformToPortableText,
  PortableTextComponent,
  PortableTextImage,
  PortableTextExternalLink,
  PortableTextInternalLink,
  resolveTable,
  PortableTextTable,
  PortableTextObject,
} from '@kontent-ai/rich-text-resolver';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';
import { ImageSchemaOrgModel, SchemaOrgContext, SchemaOrgTypes, getPrefixedMembershipInternalLink } from '@r-and-a-shared-ui/utils';
import { Link } from '../Link/Link';
import { Image } from '../Image/Image';

import styles from './RichText.module.scss';

export interface RichTextProps {
  content?: Elements.RichTextElement;
}

const RichTextComponentBuilder =
  (richTextElement: Elements.RichTextElement) =>
  // eslint-disable-next-line react/display-name
  ({ value }: PortableTextTypeComponentProps<PortableTextComponent>) => {
    const item = richTextElement?.linkedItems?.find(
      item => item.system.codename === value.component._ref,
    );

    return item ? (
      <AsyncComponent
        kontentItem={item}
        contentType={item.system.type as any}
        key={item.system.id}
      />
    ) : (
      ''
    );
  };

const createPortableTextComponent = (
  richTextElement: Elements.RichTextElement,
): Partial<PortableTextReactComponents> => {
  return {
    types: {
      component: RichTextComponentBuilder(richTextElement),
      image: ({ value }: PortableTextTypeComponentProps<PortableTextImage>) => {
        
        const schemaOrgContent: ImageSchemaOrgModel = {
          "@type": SchemaOrgTypes.IMAGE_OBJECT,
          "@context": SchemaOrgContext,
          contentUrl: value?.asset?.url
        };

        return (
          <Image
            imageUrl={value?.asset?.url}
            alt={value?.asset?.alt || 'Image'}
            width={value?.asset?.width ?? 1400}
            height={value?.asset?.height ?? 768}
            schemaOrgContent={schemaOrgContent}
          />
        );
      },
      table: ({ value }: PortableTextTypeComponentProps<PortableTextTable>) => {
        const tableString = resolveTable(value, toPlainText);
        return (
          <div
            className={styles['table-responsive']}
            dangerouslySetInnerHTML={{ __html: tableString }}
          />
        );
      },
    },
    marks: {
      link: ({
        value,
        children,
      }: PortableTextMarkComponentProps<PortableTextExternalLink>) => {
        const target = value?.target ? (value?.target as string) : undefined;

        return (
          <a
            href={value?.href}
            target={target}
            rel={value?.rel}
            title={value?.title}
            data-new-window={value?.['data-new-window']}
          >
            {children}
          </a>
        );
      },
      internalLink: ({
        value,
        children,
      }: PortableTextMarkComponentProps<PortableTextInternalLink>) => {
        const item = richTextElement.links.find(
          item => item.linkId === value?.reference._ref,
        );
        const href = getPrefixedMembershipInternalLink(
          item?.type,
          item?.urlSlug,
        );

        return (
          <Link href={href || ''} internal title="">
            {children}
          </Link>
        );
      },
    },
  };
};

const useParseTree = (richText?: Elements.RichTextElement) => {
  if (!richText)
    return {
      portableText: null,
      portableTextComponent: null,
    };
  const treeParser = typeof window !== 'undefined' ? browserParse : nodeParse;
  const parsedTree = treeParser(richText.value);
  const portableText = parsedTree ? transformToPortableText(parsedTree) : null;
  const portableTextComponents = createPortableTextComponent(richText);

  return {
    portableText,
    portableTextComponents,
  };
};

const adjustPortableTextMarks = (portableText: PortableTextObject[] | null) => {
  if (!portableText || !portableText.length) {
    return;
  }

  portableText = portableText.map(portableTextElem => {
    if (!portableTextElem?.markDefs?.length) {
      return portableTextElem;
    }

    portableTextElem.children = portableTextElem.children.map(
      (child: PortableTextObject) => {
        if (!child?.marks?.length) {
          return child;
        }
        const lastMark = [child.marks[child.marks.length - 1]];
        return { ...child, marks: lastMark };
      },
    );
    return portableTextElem;
  });
};
export function RichText(props: RichTextProps) {
  const { content } = props;

  const { portableText, portableTextComponents } = useParseTree(content);
  adjustPortableTextMarks(portableText);

  return (
    <div className={styles['container']}>
      {portableText && (
        <PortableText
          value={portableText}
          components={portableTextComponents}
        />
      )}
    </div>
  );
}

export default RichText;
