import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';
import { IContentItem } from '@kontent-ai/delivery-sdk';

import { deliveryClient } from '../config/kontent-delivery-client';
import { adjustSystemProperties } from '../utils';


export const getPageCodeNameBySlug = async <Type extends IContentItem> (
  slug: string,
  type = 'page',
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf() - 1,
  cacheStatus?: CacheStatus
): Promise<Type | undefined> => {
  const cacheKey = `getPageCodeNameBySlug-${slug}`;

  try {
    const cachedItem = getCachedItem<Type>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const fetchItem = async (filterField: string): Promise<Type | undefined> => {
      const response = await deliveryClient
        .items<Type>()
        .type(type)
        .equalsFilter(filterField, slug)
        .depthParameter(0)
        // Adjusted to minimize depth as you're only interested in the codename
        // Add method to limit fields, pseudo-code below
        .toPromise()
        .then(r => r.data.items[0]);

      adjustSystemProperties(response);
      if (response) {
        return response as Type;
      }

      return undefined;
    };
  
    let result = await fetchItem('elements.url_slug');
  
    if (!result) {
      result = await fetchItem('elements.special_url');
    }

    if (result) {
      setCacheItem<Type>(cacheKey, result, cacheTTL, cache);
    }

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return result;
  } catch (error) {
    console.error('Error in getPageCodeNameBySlug', error);
    throw error;
  }
};
