import { isNullOrUndefined } from "@r-and-a-shared-ui/utils";
import { OptionProps } from "../FormField/FormField";


export const generateOptions = (
  start: number | undefined,
  end: number | undefined,
  labelFormatter?: (index: number) => string,
) => {
  const options: OptionProps[] = [];

  if (isNullOrUndefined(start) || isNullOrUndefined(end)) {
    return options;
  }

  for (let i = start; i <= end; i++) {
    const label = labelFormatter ? labelFormatter(i) : i.toString();
    options.push({ label, value: i.toString() });
  }

  return options;
};