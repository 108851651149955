import type { FC } from 'react';
import classnames from 'classnames';
import { useAuthContext } from '@r-and-a-shared-ui/auth-client';
import { RenderOn } from '../lib/RenderOn/RenderOn';
import styles from './SSOButtons.module.scss';
import { sendGAEvent } from '@next/third-parties/google';
import { getPageTrackingDetails } from '@r-and-a-shared-ui/utils';

export const SSOSignInButton: FC = () => {
  const {
    methods: { federatedSignIn },
    userState: { isAuthenticated },
  } = useAuthContext();

  const handleClick = () => {
    sendGAEvent({ event: 'log_in_click', value: getPageTrackingDetails() });
    federatedSignIn();
  };

  return (
    <RenderOn condition={!isAuthenticated} key={'sign-in-button'}>
      <span
        onClick={handleClick}
        data-testid={'RestrictedContentLoginButton'}
        className={classnames(styles.btn, styles['btn--primary'])}
      >
        Log In
      </span>
    </RenderOn>
  );
};
