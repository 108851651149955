import { isNullOrUndefined } from "./general-utils";

export const reloadToBasePage = () => {
  window.location.href = window.location.origin;
};

export const redirectTo = (address: string | undefined, preserveQuery?: boolean, openInNewTab = false) => {
  if (isNullOrUndefined(address)) {
    return;
  }

  preserveQuery = preserveQuery || false;

  const isRelative = !address.startsWith('http');

  // Create a URL object for the target URL
  let targetUrl: URL;
  if (isRelative) {
    // If the URL is relative, create an absolute URL
    const currentUrl = new URL(window.location.href);
    targetUrl = new URL(address, currentUrl.origin);
  } else {
    // If the URL is absolute, use it directly
    targetUrl = new URL(address);
  }

  if (preserveQuery) {
    // Get the current URL's query parameters
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.forEach((value, key) => {
      targetUrl.searchParams.append(key, value);
    });
  }

  if(openInNewTab)
  {
    window.open(targetUrl.toString(), '_blank', 'noopener,noreferrer');
  }else{
  // Perform the redirect
    window.location.href = targetUrl.toString();
  }

};

export const reloadPage = () => {
  window.location.reload();
};

export function reloadPageWithoutQueryParams() {
  window.location.href = window.location.pathname;
}

export function getPageUrlWithoutQueryParams() {
  return window.location.href.split('?')[0];
}
