import React from 'react';
import type { ReactNode } from 'react';
import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { LogoProps } from '../Logo/Logo';
import { useScreenDetector } from '../../hooks';
import { RenderOn } from '../RenderOn/RenderOn';

import styles from './Header.module.scss';
import { HeaderSignInButton } from './HeaderSignInButton';
import { HeaderSignOutButton } from './HeaderSignOutButton';
import { HeaderUpgradeButton } from './HeaderUpgradeButton';
import { HeaderUserLogo } from './HeaderUserLogo';
import { HeaderLogo } from './HeaderLogo';
import { HeaderContent } from './HeaderContent';
import { HeaderSignUpButton } from './HeaderSignUpButton';
import { LinkProps } from '../Link/Link';

export type HeaderProps = {
  children?: ReactNode | ReactNode[];
  mobileLogo?: LogoProps;
  desktopLogo?: LogoProps;
  content?: IContentItem[];
  myAccountLink?: LinkProps;
};

export const Header: React.FC<HeaderProps> = ({ content, mobileLogo, desktopLogo, myAccountLink }) => {
  const { isMobile, isClient } = useScreenDetector();

  if (!isClient) {
    return null;
  }

  return (
    <header className={styles.wrapper} data-testid={'Header'} id="header">
      <div className={styles.container}>
        <div className={styles.content}>
          <RenderOn condition={!isMobile}>
            <div className={styles['buttons-container']}>
              <HeaderUserLogo myAccountLink={myAccountLink} />
              <HeaderUpgradeButton />
              <HeaderSignInButton />
              <HeaderSignUpButton />
              <HeaderSignOutButton />
            </div>
          </RenderOn>

          <HeaderLogo mobileLogo={mobileLogo} desktopLogo={desktopLogo} />

          <RenderOn condition={isMobile}>
            <div className={styles['account-icon']}>
              <HeaderUserLogo myAccountLink={myAccountLink} />
            </div>
          </RenderOn>

          <HeaderContent content={content} />
        </div>
      </div>
    </header>
  );
};
