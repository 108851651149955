import { sendGAEvent } from '@next/third-parties/google';
import { ActionHandlerParams } from '../types';
import {
  registerHandler,
  signInHandler,
  updateHandler,
  signIn,
  forgotPasswordHandler,
  resetPasswordHandler,
  newPasswordRequiredHandler,
  confirmSignInWithNewPasswordRequired,
  changePasswordHandler,
  confirmSignInWithChangePassword,
  verifyEmailExistsHandler,
  addUserToAddressBook,
  adminResetPassword,
} from './form-action-handlers';
import { addToCurrentStep, playAudioFromStep, validateForm, checkAllFieldsHaveValues, getFieldsCurrentStep , skipValidationForm} from '../helpers/config-helper';
import { redirectTo } from '@r-and-a-shared-ui/utils';
import { handleOauth2Redirect } from '@r-and-a-shared-ui/auth-client';
import  oneClubQuestionHandler  from './form-action-handlers/one-club-question-handler';

export type FormCtaActionTypes =
  | 'oauth_redirect'
  | 'redirect_to'
  | 'redirect_to_preserve_query'
  | 'sign_in'
  | 'next_step'
  | 'skip_step'
  | 'previous_step'
  | 'register'
  | 'register_next_step'
  | 'update'
  | 'update_next_step'
  | 'update_redirect'
  | 'forgot_password'
  | 'reset_password'
  | 'new_password_required'
  | 'change_password'
  | 'verify_email_exists'
  | 'verify_email_exists_next_step'
  | 'add_user_to_address_book'
  | 'update_next_step_add_to_address_book'
  | 'one_club_question'
  | 'skip_with_no_validation'
  | 'admin_reset_password'
  | 'admin_reset_password_next_step'
  | 'undefined';

export interface CtaConfig {
  trpcRoute?: string;
  actionHandler: (params: ActionHandlerParams) => any;
  clientSideMutation?: (...args: any[]) => any;
}

export const formCtaConfig: Record<FormCtaActionTypes, CtaConfig> = {
  oauth_redirect:  {
    actionHandler: params =>
    {
      const { useWholeURL } = params;
      handleOauth2Redirect({useWholeURL:useWholeURL});
    }
  },
  redirect_to: {
    actionHandler: params => {
      const { resource: url } = params;
      redirectTo(url);
    },
  },
  redirect_to_preserve_query: {
    actionHandler: params => {
      const { resource: url } = params;
      redirectTo(url, true);
    },
  },
  sign_in: {
    actionHandler: signInHandler,
    clientSideMutation: signIn,
  },
  next_step: {
    actionHandler: async params => {
      const passedCheck = await validateForm(params);
      if (!passedCheck) {
        return;
      }

      const steps = params.formContentProps?.steps;
      const fields = getFieldsCurrentStep(steps);
      const allFieldsHaveValues = checkAllFieldsHaveValues(fields, params.submitData);

      addToCurrentStep(1);

      if (allFieldsHaveValues) {
        playAudioFromStep(steps);
      }
    },
  },
  skip_step: {
    actionHandler: async params => {
      const passedCheck = await validateForm(params);

      if (!passedCheck) {
        return;
      }
      addToCurrentStep(1);
    },
  },
  skip_with_no_validation: {
    actionHandler: params => {
      skipValidationForm(params);
      addToCurrentStep(1);
    },
  },
  previous_step: {
    actionHandler: () => {
      addToCurrentStep(-1);
    },
  },
  register: {
    trpcRoute: 'register',
    actionHandler: registerHandler,
  },
  register_next_step: {
    trpcRoute: 'register',
    actionHandler: async params => {
      const result = await formCtaConfig['register'].actionHandler(params);
      if (result.hasErrors) {
        return;
      }
      formCtaConfig['next_step'].actionHandler(params);
    },
  },
  update: {
    trpcRoute: 'update',
    actionHandler: updateHandler,
  },
  update_next_step: {
    trpcRoute: 'update',
    actionHandler: async params => {
      const result = await formCtaConfig['update'].actionHandler(params);
      if (result.hasErrors) {
        return;
      }
      formCtaConfig['next_step'].actionHandler(params);
    },
  },
  update_redirect: {
    trpcRoute: 'update',
    actionHandler: async params => {
      const result = await formCtaConfig['update'].actionHandler(params);
      if (result.hasErrors) {
        return;
      }
      const actionHP = {

        ...params,
        useWholeURL: true
      };
      formCtaConfig['oauth_redirect'].actionHandler(actionHP);
    },
  },
  forgot_password: {
    trpcRoute: 'forgot_password',
    actionHandler: forgotPasswordHandler,
  },
  reset_password: {
    trpcRoute: 'reset_password',
    actionHandler: resetPasswordHandler,
  },
  new_password_required: {
    actionHandler: newPasswordRequiredHandler,
    clientSideMutation: confirmSignInWithNewPasswordRequired
  },
  change_password: {
    actionHandler: changePasswordHandler,
    clientSideMutation: confirmSignInWithChangePassword
  },
  verify_email_exists: {
    trpcRoute: 'verify_email_exists',
    actionHandler: verifyEmailExistsHandler,
  },
  verify_email_exists_next_step: {
    trpcRoute: 'verify_email_exists',
    actionHandler: async params => {
      const result = await formCtaConfig['verify_email_exists'].actionHandler(params);
      if (result.hasErrors) {
        return;
      }
      sendGAEvent({ event: 'sso_registration_start', value: '/member-register' });

      // set error on email field + scroll to top
      formCtaConfig['next_step'].actionHandler(params);
    },
  },
  add_user_to_address_book: {
    trpcRoute: 'add_user_to_address_book',
    actionHandler: addUserToAddressBook,
  },
  update_next_step_add_to_address_book: {
    trpcRoute: 'update',
    actionHandler: async params => {
      const result = await formCtaConfig['update'].actionHandler(params);
      if (result.hasErrors) {
        return;
      }

      await formCtaConfig['add_user_to_address_book'].actionHandler(params);

      formCtaConfig['next_step'].actionHandler(params);
    },
  },
  admin_reset_password: {
    trpcRoute: 'admin_reset_password',
    actionHandler: adminResetPassword,
  },
  admin_reset_password_next_step: {
    trpcRoute: 'admin_reset_password',
    actionHandler: async params => {
      const result = await formCtaConfig['admin_reset_password'].actionHandler(params);
      if (result.hasErrors) {
        return;
      }

      formCtaConfig['next_step'].actionHandler(params);
    },
  },
  one_club_question: {
    trpcRoute: 'one_club_question',
    actionHandler: oneClubQuestionHandler,
  },
  undefined: {
    actionHandler: () => undefined,
  },
};

export default formCtaConfig;
