export const CustomPrefix = 'custom:';

export const Attributes = {
  Address: 'address',
  BirthDate: 'birthdate',
  Email: 'email',
  EmailVerified: 'email_verified',
  FamilyName: 'family_name',
  Gender: 'gender',
  GivenName: 'given_name',
  Locale: 'locale',
  MiddleName: 'middle_name',
  Name: 'name',
  NickName: 'nickname',
  PhoneNumber: 'phone_number',
  PhoneNumberVerified: 'phone_number_verified',
  Picture: 'picture',
  PreferredUsername: 'preferred_username',
  Profile: 'profile',
  ZoneInfo: 'zoneinfo',
  UpdatedAt: 'updated_at',
  Website: 'website',
  SsoUserId: 'sub',
  Platform: `${CustomPrefix}platform`,
  OptIns: `${CustomPrefix}OptIns`,
  Preferences: `${CustomPrefix}Preferences`,
  IsOneClubMember: `${CustomPrefix}isOneClub`,
  IsActive: `${CustomPrefix}plaisActivetform`,
  Membership: `${CustomPrefix}Membership`,
  RaMemberId: `${CustomPrefix}RAMemberId`,
  TicketingAccountId: `${CustomPrefix}TicketingAccountId`,
  Title: `${CustomPrefix}Title`,
  Interests: `${CustomPrefix}Interests`,
  AutoConfirm: `${CustomPrefix}AutoConfirm`,
  CountryCodeMobilePhonePrefix: `${CustomPrefix}PhoneCountryCode`,
  Language: `${CustomPrefix}lang`,
  AddressLine1: `${CustomPrefix}addressLine1`,
  AddressLine2: `${CustomPrefix}addressLine2`,
  AddressLine3: `${CustomPrefix}addressLine3`,
  PostalCode: `${CustomPrefix}postalCode`,
  CountryCode: `${CustomPrefix}countryCode`,
  City: `${CustomPrefix}city`,
  BallotCriteria: `${CustomPrefix}ballotCriteria`,
  BecameMasterCard: `${CustomPrefix}becameMasterCard`,
  LastLoggedInDate: `${CustomPrefix}lastLoggedInDate`,
  LastLoggedInDevice: `${CustomPrefix}lastLoggedInDevice`,
  LastLoggedInPlatform: `${CustomPrefix}lastLoggedInPlatform`,
  TheOpenOffersUpdated: `${CustomPrefix}theOpenOffersUpdated`,
  PartnerOffersUpdated: `${CustomPrefix}partnerOffersUpdated`,
};

export type CognitoUser = {
    [K in typeof Attributes[keyof typeof Attributes]]: string;
};

export const enum UserAddressType {
    MAIN = 'main',
    BILLING = 'billingAddress',
    SHIPPING = 'shippingAddress',
}