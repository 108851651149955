/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createDeliveryClient, DeliveryClient  } from '@kontent-ai/delivery-sdk';
import { config } from 'dotenv';

config();

class DeliveryClientSingleton {
  private static instance: DeliveryClient | null = null;

  private constructor() {
    // Private constructor for Singleton
  }

  public static getInstance(): DeliveryClient {
    if (!this.instance) {
      this.instance = createDeliveryClient({
        //@ts-ignore !!!Remove after fixes on env variables
        environmentId: process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID || '1946deb0-47d6-0046-cbe1-246c241862f3',
        defaultQueryConfig: {
          useSecuredMode: true, // Queries the Delivery API using secure access.
        },
        //@ts-ignore
        secureApiKey: process.env.NEXT_PUBLIC_KONTENT_MANAGEMENT_API_KEY || '',
        // Optionally include other settings, like custom headers, custom fetch client, etc.
        // proxy:{
        //   baseUrl: 'https://content.oneclub.golf'
        // }
      });
    }
    return this.instance;
  }
}

export const deliveryClient = DeliveryClientSingleton.getInstance();