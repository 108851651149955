import { ResponseModel, hasValue, safeParseToInt } from "@r-and-a-shared-ui/utils";
import { fetchUserAttributes } from "aws-amplify/auth";
import { ActionHandlerParams, TokensResponse } from "../types";
import clone from "just-clone";
import { genericMutationHandler, sendOneClubQuestionEmail } from "./form-action-mutation-handlers";
import { AddUserToAddressBookInputSchema, StoreTokensInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { AddUserToAddressBookRequest, ApiContactData, ContactDataKeys } from '@r-and-a-shared-ui/dotdigital-support';
import { signIn } from './form-action-handlers';
import { updateUserAttributes } from "aws-amplify/auth";
import { Attributes,
  getOAuth2QueryParams,
  getDeviceType,
  getPlatformByClientId,
  getUserCountry,
  UserAddressType,
  hasMembership,
  MembershipTypes,
  CognitoUser,
  getUserFavoritePlayer }
  from "@r-and-a-shared-ui/auth-client";
import { tryGetDictionaryItemValue } from "../../utils";


export const execStoreTokensMutation = async (
  params: ActionHandlerParams,
  tokens?: TokensResponse
): Promise<ResponseModel | undefined> => {
  const { storeTokensMutation } = params;

  const { qsAuthorizationCode } = getOAuth2QueryParams();
  if (!hasValue(tokens)) {
    tokens = await signIn(params);
  }

  const storeTokensMutationParams = clone(params);

  storeTokensMutationParams.mutation = storeTokensMutation;
  storeTokensMutationParams.resource = undefined;
  storeTokensMutationParams.submitData = {
    authorizationCode: qsAuthorizationCode || '',
    accessToken: tokens?.accessToken || '',
    idToken: tokens?.idToken || '',
    refreshToken: tokens?.refreshToken || '',
  };

  return await genericMutationHandler(
    storeTokensMutationParams,
    StoreTokensInputSchema,
  );
};

export const execAddUserToAddressBookMutation = async (
  params: ActionHandlerParams,
  ignoreError = true,
): Promise<ResponseModel> => {
  let cognitoUser: CognitoUser | undefined;

  try {
    cognitoUser = await fetchUserAttributes()
      .then(user => user as CognitoUser);
  } catch (error) {
    cognitoUser = undefined;
  }

  const {
    addUserToAddressBookMutation
  } = params;

  const addUserToAddressBookParams = clone(params);

  addUserToAddressBookParams.mutation = addUserToAddressBookMutation;

  const submitData: AddUserToAddressBookRequest = {
    UserDetails: buildUserDetails(cognitoUser),
    Email: cognitoUser?.[Attributes.Email] || '',
    AddressBookID: 0
  };

  addUserToAddressBookParams.submitData = submitData;
  addUserToAddressBookParams.resource = undefined;

  return await genericMutationHandler(
    addUserToAddressBookParams,
    AddUserToAddressBookInputSchema,
    undefined,
    ignoreError
  );
};

const buildUserDetails = (userData: CognitoUser | undefined): ApiContactData[] => {
  const userDetails: ApiContactData[] = [];

  if (!userData) {
    return userDetails;
  }

  return addUserAttributes(userDetails, [
    {
      Key: ContactDataKeys.FIRSTNAME,
      Value: userData[Attributes.GivenName]
    },
    {
      Key: ContactDataKeys.LASTNAME,
      Value: userData[Attributes.FamilyName]
    },
    {
      Key: ContactDataKeys.COUNTRY,
      Value: getUserCountry(userData[Attributes.Address], UserAddressType.MAIN),
    },
    {
      Key: ContactDataKeys.ONECLUBPLUS,
      Value: hasMembership(userData[Attributes.Membership], MembershipTypes.OneClubAdvantage) ?
        'Yes' :
        'No'
    },
    {
      Key: ContactDataKeys.USER_JOURNEY,
      Value: userData[Attributes.Website]
    },
    {
      Key: ContactDataKeys.FAVORITEPLAYER,
      Value: getUserFavoritePlayer(userData)
    },
    {
      Key: ContactDataKeys.SSOUSERID,
      Value: userData[Attributes.SsoUserId]
    },
  ]);
};

const addUserAttributes = (userAttributes: ApiContactData[], attributesToAdd: ApiContactData[] | undefined) => {
  if (!hasValue(attributesToAdd) || !userAttributes) {
    return userAttributes;
  }

  attributesToAdd?.forEach(attr => {
    const { Key, Value } = attr;
    if (hasValue(Key) && hasValue(Value)) {
      userAttributes?.push({
        Key,
        Value
      });
    }
  });

  return userAttributes;
};

export const execOneClubQuestionMutation = async (
  params: ActionHandlerParams,
  
): Promise<ResponseModel> => {
  const {
    successMessagesDictionary,
    setSubmitSuccessMessage,
  } = params;

  try {

    const { fromEmail, toEmail, subject, emailKey, submitData, mutation, userEmail } = params;

    const result = await sendOneClubQuestionEmail({
      submitData,
      fromEmail,
      toEmail,
      subject,
      emailKey,
      mutation,
      userEmail,
    });

    const successMessage = tryGetDictionaryItemValue(
      successMessagesDictionary,
      result.successCode,
    );

    if (setSubmitSuccessMessage) {
      setSubmitSuccessMessage(successMessage);
    }

    return { hasErrors: false, data: result.data };
  } catch (error) {
    console.error('Error in execOneClubQuestionMutation:', error);

    return { hasErrors: true };
  }
};

export const handleUpdateLastLoggedInAttributes = async () => {
  try {

    await updateUserAttributes({
      userAttributes: {
        [Attributes.LastLoggedInDate]: new Date().toISOString(),
        [Attributes.LastLoggedInDevice]: getDeviceType(),
        [Attributes.LastLoggedInPlatform]: getPlatformByClientId(),
      }
    });
  } catch (error) {
    console.log(error);
  }
};