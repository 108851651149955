import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getFirstLinkedItemOrDefault } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { default as mapHeader } from './map-header';
import { default as mapFooter } from './map-footer';
export const page_template = (entry: MEMBERSHIP_MODELS.PageTemplate) => {
  const headerKontentItem = getFirstLinkedItemOrDefault(
    entry?.elements?.header,
  ) as MEMBERSHIP_MODELS.Header;
  const footerKontentItem = getFirstLinkedItemOrDefault(
    entry?.elements?.footer,
  ) as MEMBERSHIP_MODELS.Footer;
  if(!entry) {
    return undefined;
  }
  return {
    templateSettings: {
      base_style: entry.system?.codename,
    },
    header: mapHeader(headerKontentItem),
    footer: mapFooter(footerKontentItem),
  };
};

export default page_template;
