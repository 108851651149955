import { CSSProperties } from 'react';
import parse from 'html-react-parser';
import ContentList, { ContentListProps } from '../ContentList/ContentList';
import { ListDisplay } from '../ContentList/ContentList.types';
import styles from './CallOutPanelList.module.scss';

export interface CallOutPanelListProps {
  backgroundImage?: string;
  backgroundColor?: string;
  calloutItems?: ContentListProps;
  description?: string;
  title?: string;
}

export function CallOutPanelList(props: CallOutPanelListProps) {
  const { backgroundImage, backgroundColor, calloutItems, description, title } =
    props;

  const inlineStyle: CSSProperties = {
    backgroundColor,
    backgroundImage: backgroundImage && `url(${backgroundImage})`,
  };

  return (
    <div className={styles['wrapper']} style={inlineStyle}>
      {title && <h2>{parse(title, { trim: true })}</h2>}
      {description && (
        <div className={styles['description']}>
          {parse(description, { trim: true })}
        </div>
      )}
      {calloutItems?.elements?.length && (
        <ContentList
          {...calloutItems}
          listDisplay={ListDisplay.CALLOUT_PANEL_LIST}
        />
      )}
    </div>
  );
}

export default CallOutPanelList;
