import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { getParameterByName, hasValue } from '@r-and-a-shared-ui/utils';
import styles from './FreeText.module.scss';
import { BaseFormFieldProps } from '../FormField/FormField';
import { useFormContext } from 'react-hook-form';
import { getErrorMessageForCurrentField } from '../../helpers/form-helpers';

export type FormTextInputProps = BaseFormFieldProps;

export const FreeText = (props: FormTextInputProps) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [charCount, setCharCount] = useState(0);
  const maxChars = 500;

  const {
    name,
    fieldRef,
    placeholder,
    label,
    isDisabled,
    queryParamKey,
    isHidden,
    isMembership,
    isSubmittedSuccessfully,
  } = props;

  const {
    register,
    formState: { errors },
    setValue,
    getFieldState,
    watch,
  } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    if (hasValue(queryParamKey)) {
      setValue(name, getParameterByName(queryParamKey));
    }
  }, [name, queryParamKey, setValue]);

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.opacity = '1';
      setCharCount(inputRef.current.value.length);
    }
  }, []);

  const { ref, ...rest } = register(name);

  if (isHidden) {
    return null;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setCharCount(value.length);
    setValue(name, value);
  };

  return (
    <div
      ref={fieldRef}
      className={`${styles.formElement} ${styles.formInput} ${
        isSubmittedSuccessfully ? styles.isSubmitted : ''
      } ${errors?.[name] ? styles.formElementHasError : ''} ${
        isMembership ? styles.formElementMembership : ''
      }`}
    >
      {hasValue(label) && (
        <label className={styles.formElementLabel} htmlFor={name}>
          {label}
        </label>
      )}
      {isSubmittedSuccessfully ? (
        <div className={styles.formElementSuccessContainer}>
          <div className={styles.formElementMessage}>
            Thank you for your submission!
          </div>
          <div className={styles.formElementCheckmark}>
            <FontAwesomeIcon icon={faCheckCircle} className={styles.checkmarkSvg} width={50} height={50} />
          </div>
        </div>
      ) : (
        <>
          <textarea
            id={name}
            className={styles.formElementTextarea}
            value={value}
            placeholder={placeholder}
            disabled={isDisabled}
            maxLength={maxChars}
            {...rest}
            ref={(e) => {
              ref(e);
              inputRef.current = e;
            }}
            onChange={handleInputChange}
          ></textarea>
          <div className={styles.formElementBar}></div>
          {errors?.[name] && (
            <small className={styles.formElementHint}>
              {getErrorMessageForCurrentField(getFieldState, props)}
            </small>
          )}
          <div className={styles.formElementCountdown}>
            {charCount}/{maxChars}
          </div>
        </>
      )}
    </div>
  );
};

export default FreeText;
