import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import mapImage from './map-image';
import { getFirstLinkedItemOrDefault, getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import mapLink from './map-link';
import mapDotdigital_form_iframe from './map-dotdigital_form_iframe';

export const reward = (entry: MEMBERSHIP_MODELS.Reward) => {
  const { elements } = entry;
  const seo = {
    title: elements?.seo__meta_title?.value,
    description: elements?.seo__meta_description?.value,
    keywords: elements?.seo__key_words?.value,
    noindex: elements?.seo__no_index?.value,
    nofollow: elements?.seo__no_follow?.value,
    urlSlug: elements?.url_slug?.value,
  };

  return {
    title: elements?.title?.value,
    subtitle: elements?.subtitle?.value,
    enterButtonLink: mapLink(
      getFirstLinkedItemOrDefault(
        elements?.enter_button_link,
      ) as MEMBERSHIP_MODELS.Link,
    ),
    termsAndConditionsLink: mapLink(
      getFirstLinkedItemOrDefault(
        elements?.term_and_conditions_link,
      ) as MEMBERSHIP_MODELS.Link,
    ),
    dotDigitalFormIframe: mapDotdigital_form_iframe(
      getFirstLinkedItemOrDefault(
        elements?.form,
      ) as MEMBERSHIP_MODELS.DotdigitalFormIFrame,
    ),
    primaryImage: mapImage(
      getFirstLinkedItemOrDefault(
        elements?.primary_image,
      ) as MEMBERSHIP_MODELS.Image,
    ),
    primaryMobileImage: mapImage(
      getFirstLinkedItemOrDefault(
        elements?.primary_mobile_image,
      ) as MEMBERSHIP_MODELS.Image,
    ),
    body: elements?.more_content?.linkedItems || [],
    sidebar: elements?.sidebar?.linkedItems || [],
    subheader: elements?.subheader_elements.linkedItems || [],
    access: getSingleChoiceValue(
      elements?.membership_based_access__select_access,
    ),
    seo,
  };
};

export default reward;
