import { isNullOrUndefined, setHrefWithQueryOnAnchor } from '@r-and-a-shared-ui/utils';
import styles from './FormFooter.module.scss';

export interface FormFooterProps {
  termsAndConditionsLink?: string,
  privacyPolicyLink?: string,
  forgotPasswordLink?: string,
  footerTextColor?: string,
  isMembership?: boolean;
}

export function FormFooter(props: FormFooterProps) {
  const {
    termsAndConditionsLink,
    privacyPolicyLink,
    forgotPasswordLink,
    footerTextColor,
    isMembership,
  } = props;

  const forgotPasswordHtmlWithQuery = setHrefWithQueryOnAnchor(forgotPasswordLink, window.location.search);
  
  return (
    <div
      style={{ color: footerTextColor }}
      className={`${styles['form__footer']} ${isMembership ? styles['is-membership'] : ''}`}
    >
      <div className={`${styles['form__footer--left']}`}>
        {
          !isNullOrUndefined(termsAndConditionsLink) &&
          <div className={`${styles['form__footer-element']}`} dangerouslySetInnerHTML={{ __html: termsAndConditionsLink }}/>
        }
        {
          !isNullOrUndefined(privacyPolicyLink) &&
          <div className={`${styles['form__footer-element']}`} dangerouslySetInnerHTML={{ __html: privacyPolicyLink }}/>
        }
      </div>
      <div className={`${styles['form__footer--right']}`}>
        {
          !isNullOrUndefined(forgotPasswordHtmlWithQuery) &&
          <div className={`${styles['form__footer-element']}`} dangerouslySetInnerHTML={{ __html: forgotPasswordHtmlWithQuery?.outerHTML }}/>
        }
      </div>
    </div>
  );
}

export default FormFooter;
