import { hasValue } from "./general-utils";

export type StringComparisonCriteria =
  | 'equals'
  | 'equals_ignore_case'
  | 'not_equals'
  | 'not_equals_ignore_case'
  | 'includes'
  | 'includes_ignore_case'
  | 'not_includes'
  | 'not_includes_ignore_case'
  | 'undefined'

export const stringComparators: Record<StringComparisonCriteria, (...args: any[]) => boolean> = {
    equals: (str1: string, str2: string) => str1 === str2,
    equals_ignore_case: (str1: string, str2: string) => str1?.toLocaleLowerCase() === str2?.toLocaleLowerCase(),
    not_equals:  (str1: string, str2: string) => !stringComparators.equals(str1, str2),
    not_equals_ignore_case:  (str1: string, str2: string) => !stringComparators.equals_ignore_case(str1, str2),
    includes:  (str1: string, strToInclude: string) => {
        if(!hasValue(strToInclude)){
            return false;
        }
        
        return str1?.includes(strToInclude) || str1 === strToInclude;
    },
    includes_ignore_case:  (str1: string, strToInclude: string) => {
        if(!hasValue(strToInclude)){
            return false;
        }
        
        return str1?.toLocaleLowerCase()?.includes(strToInclude?.toLocaleLowerCase())
        || str1?.toLocaleLowerCase() === strToInclude?.toLocaleLowerCase();
    },
    not_includes:  (str1: string, strToInclude: string) => !stringComparators.includes(str1, strToInclude),
    not_includes_ignore_case:  (str1: string, strToInclude: string) => !stringComparators.includes_ignore_case(str1, strToInclude),
    undefined: () => false
};