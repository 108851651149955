import { deliveryClient } from '../config/kontent-delivery-client';
import { MEMBERSHIP_MODELS } from '../models';
import { getItemWithLinkedItemsByCodename } from './get-item-with-linked-items-by-codename';
import { adjustSystemProperties, getDynamicThreshold } from '../utils';
import { CacheConfig, CacheStatus, getCachedItem, setCacheItem, setCacheStatus } from '@r-and-a-shared-ui/core-caching';

export const getFullCoachBySlug = async (
  slug: string,
  cache = true,
  cacheTTL = CacheConfig.TTL.Page.valueOf(),
  cacheStatus?: CacheStatus
  ) => {
  const cacheKey = `getFullCoachBySlug-${slug}`;

  try {
    const cachedItem = getCachedItem<MEMBERSHIP_MODELS.Coach>(cacheKey, cache);

    if (cachedItem) {
      setCacheStatus(cacheStatus, {
        cacheable: cache,
        xInMemoryCache: CacheConfig.Hit,
        cacheTTL
      });
      return cachedItem;
    }

    const item = await deliveryClient
      .items<MEMBERSHIP_MODELS.Coach>()
      .type('coach')
      .equalsFilter('elements.url_slug', slug)
      .depthParameter(0)
      .toPromise()
      .then(r => r.data.items[0]);

    adjustSystemProperties(item);

    if (!item || !item?.system.codename) {
      return undefined;
    }

    const coach = await getItemWithLinkedItemsByCodename<MEMBERSHIP_MODELS.Coach>({
        codename: item.system.codename,
        depthParameter: 3,
        cache,
        cacheTTL: cacheTTL - 1
    });

    const dynamicThreshold = getDynamicThreshold();

    const bodyPromises: Promise<any>[] = [];

    const bodyItems = coach?.elements?.description?.linkedItems;
    bodyItems?.forEach((bodyItem, index) => {
      if (index < dynamicThreshold) {
        bodyPromises.push(
          getItemWithLinkedItemsByCodename({
            codename: bodyItem.system.codename,
            depthParameter: 3,
            cache,
            cacheTTL: CacheConfig.TTL.PageContent
          }),
        );
        return;
      }
      bodyPromises.push(Promise.resolve(bodyItem));
    });
    const body = await Promise.all(bodyPromises);

    const parsedCoach = {
      ...coach,
      elements: {
        ...coach?.elements,
        description: {
          ...coach?.elements?.description,
          linkedItems: body,
        },
      },
    } as MEMBERSHIP_MODELS.Coach;

    setCacheItem(cacheKey, parsedCoach, cacheTTL, cache);

    setCacheStatus(cacheStatus, {
      cacheable: cache,
      xInMemoryCache: CacheConfig.Miss,
      cacheTTL
    });

    return parsedCoach;
  } catch (error) {
    console.error('Error in getFullCoachBySlug', error);
    throw error;
  }
};
