import {
  Elements,
  IContentItem,
  IContentItemElements,
} from '@kontent-ai/delivery-sdk';
import { hasValue, isNullOrUndefined } from '@r-and-a-shared-ui/utils';
import {
  getCallbackURLValue,
  getFirstLinkedItemOrDefault,
  isItemOfType,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import {
  SSO_MODELS,
  MEMBERSHIP_MODELS,
} from '@r-and-a-shared-ui/common-kontent-client';
import { globalDataMapping } from '../../components/GlobalDataMapping';

const { KONTENT: dataMapping } = globalDataMapping.getDataMapping();

type DataMappingKeys = keyof typeof dataMapping;

export const getMappedItem = <
  TypeIn extends IContentItem<IContentItemElements> | undefined,
  TypeOut,
>(
    kontentItem: TypeIn,
  ) => {
  if (isNullOrUndefined(kontentItem)) {
    return undefined;
  }

  const mappingConfig =
    dataMapping?.[kontentItem?.system?.type as DataMappingKeys];

  if (!mappingConfig) {
    console.warn(`No mapping found for ${kontentItem?.system?.type}`);
  }
  return (mappingConfig as any)?.(kontentItem as any) as TypeOut;
};
export const getMappedLinkedItems = <TypeIn extends IContentItem, TypeOut>(
  data: Elements.LinkedItemsElement<TypeIn>,
) => {
  const linkedItems: TypeIn[] = data?.linkedItems;

  if (!hasValue(linkedItems)) {
    return undefined;
  }

  const mappedItems = linkedItems.map(item => {
    const mappingConfig = dataMapping?.[item?.system?.type as DataMappingKeys];

    return (mappingConfig as any)?.(item as never);
  });

  return mappedItems as TypeOut[];
};

export const getLinkedItemsWithMapping = <T extends IContentItem>(
  data: Elements.LinkedItemsElement<T>,
):
  | Array<{ mapping: (entry: any)=> any, item: T }>
  | undefined => {
  const linkedItems: T[] = data?.linkedItems;

  if (!hasValue(linkedItems)) {
    return undefined;
  }

  const result = linkedItems.map(item => {
    const mappingConfig = dataMapping?.[item?.system?.type as DataMappingKeys];

    return {
      mapping: mappingConfig as unknown as (entry: any) => any,
      item,
    };
  });

  return result;
};

export const getFirstMappedLinkedItemOrDefault = <TypeIn extends IContentItem>(
  data: Elements.LinkedItemsElement<TypeIn>,
) => {
  const kontentItem = getFirstLinkedItemOrDefault(data);

  if (isNullOrUndefined(kontentItem)) {
    return undefined;
  }

  const mappingConfig =
    dataMapping?.[kontentItem?.system?.type as DataMappingKeys];

  return (mappingConfig as any)?.(kontentItem as any);
};

export const getCtaHrefValue = (kontentItem: IContentItem | undefined) => {
  if (
    isItemOfType<MEMBERSHIP_MODELS.CallbackURL | SSO_MODELS.CallbackURL>(
      kontentItem,
      MEMBERSHIP_MODELS.contentTypes.callback_url?.codename,
    )
  ) {
    return getCallbackURLValue(kontentItem);
  }

  return undefined;
};

export const getSlugUrlForFormPage = (
  kontentItem: IContentItem | undefined,
) => {
  if (
    isItemOfType<SSO_MODELS.FormPage>(
      kontentItem,
      SSO_MODELS.contentTypes.form_page?.codename,
    )
  ) {
    const seoDataKontentItem = getFirstLinkedItemOrDefault(
      kontentItem?.elements?.seo_data,
    ) as SSO_MODELS.SEOData;
    return seoDataKontentItem?.elements?.url_slug;
  }

  return undefined;
};
