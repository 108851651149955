import { CacheConfig } from "../constants/cache-config";
import { RedisConfig } from "../constants/redis-config";
import { RedisClientType, createClient } from 'redis';

export type InitRedisClientResult = {
  client: RedisClientType,
  keyPrefix: string | undefined,
  useTtl: (ttl: number) => number,
  timeoutMs: number
} | undefined;

export const initRedisClient = async (): Promise<InitRedisClientResult> => {
  if (RedisConfig.REDIS_AVAILABLE) {
    console.log(`Creating cache with ${RedisConfig.REDIS_URL}`);
    const client = createClient({
      socket: {
        host: RedisConfig.REDIS_URL,
        tls: RedisConfig.REDIS_SSL,
        port: RedisConfig.REDIS_PORT,
        reconnectStrategy: function(retries) {
          if (retries > 5) {
              console.log("Too many attempts to reconnect. Redis connection was terminated");
              return new Error("Too many retries.");
          } else {
              return retries * 500;
          }
      }
      },
      password: RedisConfig.REDIS_PASSWORD,
      username: RedisConfig.REDIS_USER,
    }) as RedisClientType;

    client.on('error', (error) => {
      console.error('Redis error:', error.message);
    });

    await client.connect();

    return {
      client,
      keyPrefix: CacheConfig.keyPrefix,
      useTtl,
      timeoutMs: 5000, //default value
    };
  }

  return undefined;
}

export const useTtl = (ttl: number) => {
  const evictionAge = ttl * 1.5;
  
  return evictionAge;
}