import SimplePopUp from 'libs/common-ui/src/lib/SimplePopUp/SimplePopUp';
import { getCookieConfig } from './CookieConfigBuilder';
import { isEmpty } from '@r-and-a-shared-ui/utils';
import { useContext, useEffect, useState } from 'react';
import styles from './CookiesPopup.module.scss';
import classnames from 'classnames';
import { Link } from '../Link/Link';
import { CookiePopupProps, CookieSettingsProps } from './CookiesTypes';
import { EnvironmentContext } from '../../contexts';
export interface CustomWindow extends Window {
  CookieControl: any;
}
declare let window: CustomWindow;
export const loadCookiesConfig = (cookiesSettings: any) => {
  if (isEmpty(cookiesSettings) || cookiesSettings.isEmpty) {
    return;
  }

  const config = getCookieConfig(cookiesSettings);

  if (isEmpty(window?.CookieControl) || isEmpty(config)) {
    return;
  }

  window.CookieControl.load(config);
};

export function CookiesPopup({
  cookiesPopup,
  cookiesSettings,
}: {
  cookiesPopup: CookiePopupProps;
  cookiesSettings: CookieSettingsProps;
}) {
  const { Image } = useContext(EnvironmentContext);
  const [showCookiePopUp, setShowCookiePopUp] = useState(false);

  useEffect(() => {
    loadCookiesConfig(cookiesSettings);
  }, [cookiesSettings]);

  useEffect(() => {
    if (!window.CookieControl) {
      return;
    }

    loadCookiesConfig(cookiesSettings);

    const cookieControl = window.CookieControl?.getCookie('CookieControl');
    setShowCookiePopUp(isEmpty(cookieControl));
  }, []);

  const handleAcceptAllCookiesClick = (event: any) => {
    event.preventDefault();
    setShowCookiePopUp(false);
    window.CookieControl.acceptAll();
  };

  const handleSettingsClick = (event: any) => {
    event.preventDefault();

    setShowCookiePopUp(false);
    window.CookieControl.open();
  };

  const handleClose = () => {
    setShowCookiePopUp(false);
  };

  if (isEmpty(cookiesSettings) || isEmpty(cookiesPopup)) {
    return null;
  }

  return (
    <SimplePopUp
      backgroundColor={`#041E42`}
      overlayBackgroundColor={`rgba(0,0,0,0.75)`}
      isVisible={showCookiePopUp}
      onClose={handleClose}
    >
      <div className={styles.content}>
        {cookiesPopup?.logoImage && (
          <div className={styles.logo}>
            <Image alt="Logo" src={cookiesPopup?.logoImage} fill />
          </div>
        )}
        {cookiesPopup?.title && (
          <div className={styles.title}>{cookiesPopup?.title}</div>
        )}
        {cookiesPopup?.description && (
          <div className={styles.copy}>{cookiesPopup?.description}</div>
        )}
        {cookiesPopup?.buttonLabel && (
          <div
            onClick={handleAcceptAllCookiesClick}
            className={classnames(styles.btn, styles['btn--primary'])}
            role="button"
            aria-label="Accept all cookies"
            tabIndex={0}
          >
            {cookiesPopup?.buttonLabel}
          </div>
        )}
        <div className={styles.subscription}>
          {cookiesPopup?.detailsSubtext && (
            <div className={styles.subscription__copy}>
              {cookiesPopup?.detailsSubtext}
            </div>
          )}
          {cookiesPopup?.detailsSubscription && (
            <div
              className={styles.link}
              onClick={handleSettingsClick}
              role="button"
              aria-label="Cookies settings"
              tabIndex={0}
            >
              {cookiesPopup?.detailsSubscription}
            </div>
          )}
        </div>
        <div className={styles.privacy}>
          {cookiesPopup?.cookiesPageLink && (
            <Link
              className={styles.link}
              {...cookiesPopup?.cookiesPageLink}
              internal={false}
            >
              {cookiesPopup?.cookiesPageLink?.title}
            </Link>
          )}
        </div>
      </div>
    </SimplePopUp>
  );
}
export default CookiesPopup;
