import { MEMBERSHIP_MODELS, SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { getSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';

export const form_cta = (data: SSO_MODELS.FormCTA & MEMBERSHIP_MODELS.FormCTA) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;

  const actionType = getSingleChoiceValue(
    elements?.form_cta_action_types__action_type,
  );

  const ctaProps = {
    ...getFirstMappedLinkedItemOrDefault(elements?.cta),
    successMessage: elements?.success_message?.value,
    errorMessage: elements?.error_message?.value,
  };

  return {
    key: data?.system?.codename,
    ctaProps: ctaProps,
    actionType: actionType,
  };
};

export default form_cta;
