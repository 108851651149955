import { getTrueFalseSingleChoiceValue } from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getFirstMappedLinkedItemOrDefault } from './utils/component-mapping-helpers';
import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export const event_page = (entry: MEMBERSHIP_MODELS.EventPage) => {
  if (!entry || !entry?.elements) {
    return undefined;
  }
  const { elements } = entry;

  const seo = {
    title: elements?.seo__meta_title?.value,
    description: elements?.seo__meta_description?.value,
    keywords: elements?.seo__key_words?.value,
    noindex: getTrueFalseSingleChoiceValue(elements?.seo__no_index),
    nofollow: getTrueFalseSingleChoiceValue(elements?.seo__no_follow),
    urlSlug: elements?.url_slug?.value,
  };

  const eventHero = getFirstMappedLinkedItemOrDefault(elements?.event_hero);

  return {
    eventHero,
    eventSlider: elements?.event_slider,
    template: getFirstMappedLinkedItemOrDefault(elements?.template),
    body: elements?.body?.linkedItems ?? [],
    subheaderElements: elements?.subheader_elements?.linkedItems ?? [],
    sidebar: elements?.sidebar?.linkedItems ?? [],
    seo,
  };
};

export default event_page;
