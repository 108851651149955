import React from 'react';
import { MEMBERSHIP_MODELS, SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getSingleChoiceValue,
  getTrueFalseSingleChoiceValue,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import {
  getFirstMappedLinkedItemOrDefault,
  getMappedLinkedItems,
} from './utils/component-mapping-helpers';

export const form_field = (data: SSO_MODELS.FormField & MEMBERSHIP_MODELS.FormField) => {
  if (!data || !data.elements) {
    console.error('Data or data.elements is undefined:', data);
    return undefined;
  }

  const { elements } = data;

  return {
    fieldType: getSingleChoiceValue(elements?.type) ?? 'text',
    key: data?.system?.codename,
    name: elements?.id?.value || '',
    queryParamKey: elements?.value_from_query_param?.value,
    fieldRef: React.createRef(),
    placeholder: elements?.placeholder?.value,
    label: elements?.label?.value,
    isRequired: getTrueFalseSingleChoiceValue(elements?.is_required),
    isDisabled: getTrueFalseSingleChoiceValue(elements?.is_readonly),
    validationMessage: elements?.validation_message?.value,
    validationRegex: elements?.validation_regex?.value,
    options: getMappedLinkedItems(elements?.options),
    dependsOn: getFirstMappedLinkedItemOrDefault(elements?.depends_on)?.name,
    dependencyCriteria: getSingleChoiceValue(elements?.dependency_criteria),
    dependencyValidationMessage: elements?.dependency_validation_message?.value,
    subfields: getMappedLinkedItems(elements?.subfields),
    preferredOptionsList: getSingleChoiceValue(elements?.predefined_options_list),
    fieldReplacement: getFirstMappedLinkedItemOrDefault(elements?.field_replacement),
  };
};

export default form_field;
