import { FC, useEffect } from 'react';
import { PageTemplate } from '../templates';
import SEOWrapper from '../SEOWrapper/SEOWrapper';
import { HomePageProps } from './types';
import { useScreenDetector } from '../../hooks';
import { HomePageMobile } from './HomePageMobile';
import { HomePageDesktop } from './HomePageDesktop';
import { RenderOn, notifications } from '@r-and-a-shared-ui/common-ui';
import { OauthQuery } from '@r-and-a-shared-ui/auth-client';
import { getQueryParamByKey, removeParameterByName } from '@r-and-a-shared-ui/utils';

const PageContent: FC<HomePageProps> = ({
  seo,
  body,
  sidebar,
  subheader,
  preFooter,
  mobile,
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenDetector();

  useEffect(() => {
    const queryParam = getQueryParamByKey(OauthQuery.UNSUCCESSFUL_DELETE);

    if(queryParam) {
      //TO_DO take message from membership configuration kontent.ai
      notifications
        .errorSmall("Your account could not be deleted! Please be aware that if you have any pending tickets you won't be able to delete your account.");
      removeParameterByName(OauthQuery.UNSUCCESSFUL_DELETE);
    }
  }, []);

  return (
    <>
      <SEOWrapper
        title={seo?.title}
        description={seo?.description}
        keywords={seo?.keywords}
        noindex={seo?.noindex}
        nofollow={seo?.nofollow}
        urlSlug={seo?.urlSlug}
      />
      <RenderOn condition={(isMobile || isTablet) && !!mobile.length}>
        <HomePageMobile mobile={mobile} />
      </RenderOn>
      <RenderOn condition={isDesktop}>
        <HomePageDesktop
          subheader={subheader}
          sidebar={sidebar}
          body={body}
          preFooter={preFooter}
        />
      </RenderOn>
    </>
  );
};

export const HomePage: FC<HomePageProps> = ({
  seo,
  pageTemplate,
  body,
  sidebar,
  subheader,
  preFooter,
  mobile,
}) => {
  if (pageTemplate) {
    return (
      <PageTemplate header={pageTemplate.header} footer={pageTemplate.footer}>
        <PageContent
          seo={seo}
          pageTemplate={pageTemplate}
          body={body}
          sidebar={sidebar}
          subheader={subheader}
          preFooter={preFooter}
          mobile={mobile}
        />
      </PageTemplate>
    );
  }
  return (
    <PageContent
      seo={seo}
      pageTemplate={pageTemplate}
      body={body}
      sidebar={sidebar}
      subheader={subheader}
      preFooter={preFooter}
      mobile={mobile}
    />
  );
};
