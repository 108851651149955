import { FC } from 'react';
import styles from './MyAccount.module.scss';
import {
  AsyncComponent,
  ConfiguredComponents,
  DynamicComponent,
} from '@r-and-a-shared-ui/core-component-config';
import type { MyAccountProps } from './types';

export const MyAccountMobile: FC<Pick<MyAccountProps, 'mobile'>> = ({
  mobile,
}) => {
  return (
    <main data-testid={'myAccountMobile'} className={styles.wrapper}>
      <div
        data-testid={'body-content'}
        className={styles.container}
        key={`my-account-mobile`}
      >
        <div className={styles['body_left']}>
          {mobile.map(x => {
            // if (x.dynamic) {
            //   return (
            //     <DynamicComponent
            //       key={x.system.id}
            //       codename={x.system.codename}
            //       contentType={x.system.type}
            //       locale={x.system.language}
            //     />
            //   );
            // }
            return (
              <AsyncComponent
                kontentItem={x}
                contentType={x.system.type as ConfiguredComponents}
                key={x.system.id}
              />
            );
          })}
        </div>
      </div>
    </main>
  );
};
