import { useCallback } from 'react';
import AlertBar from '../AlertBar/AlertBar';
import { ListDisplay } from './ContentList.types';
import CallOutPanel from '../CallOutPanel/CallOutPanel';
import EventCard from '../EventCard/EventCard';
import HeroTile from '../CarouselHero/HeroTile/HeroTile';
import { PlaylistTile } from '../Playlist/Playlist';
import UpgradeExperienceCard from '../UpgradeExperienceCard/UpgradeExperienceCard';

interface ContentListItemProps {
  element: any;
  listDisplay?: string | ListDisplay;
}

export function ContentListItem(props: ContentListItemProps) {
  const { element, listDisplay } = props;
  const getDisplayListComponent = useCallback(() => {
    switch (listDisplay) {
    case ListDisplay.ALERT_BAR_LIST:
      return AlertBar;
    case ListDisplay.CALLOUT_PANEL_LIST:
      return CallOutPanel;
    case ListDisplay.EVENTS_LIST:
      return EventCard;
    case ListDisplay.HERO_LIST:
      return HeroTile;
    case ListDisplay.PLAY_LIST:
      return PlaylistTile;
    case ListDisplay.UPGRADE_EXPERIENCE:
      return UpgradeExperienceCard;
    default:
      return function EmptyDiv() {
        return <div></div>;
      };
    }
  }, [listDisplay]);

  const ItemComponent = getDisplayListComponent();

  return <ItemComponent {...element} />;
}

export default ContentListItem;
