import { IContentItem, IContentItemElements } from '@kontent-ai/delivery-sdk';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';
import { useScreenDetector } from '../../hooks';
import EventHeroAsset from '../EventHero/EventHeroAsset/EventHeroAsset';
import EventHeroBody from '../EventHero/EventHeroBody/EventHeroBody';
import SEOWrapper, { SEOWrapperProps } from '../SEOWrapper/SEOWrapper';
import { EventHeroProps } from '../EventHero/EventHero';
import { RenderOn } from '../RenderOn/RenderOn';
import { SubHeader } from '../Page/SubHeader';

import styles from './EventPage.module.scss';

export interface EventPageProps {
  eventHero?: EventHeroProps;
  eventSlider?: IContentItem<IContentItemElements> | null | undefined;
  subheaderElements: IContentItem<any>[];
  body?: any[];
  sidebar?: any[];
  seo?: SEOWrapperProps;
  schemaOrgContent?: any;
}

export const EventPage = ({
  eventHero,
  eventSlider,
  subheaderElements,
  body,
  sidebar,
  seo,
}: EventPageProps) => {
  const { isTablet } = useScreenDetector();


  return (
    <main className={styles.wrapper}>
      <SEOWrapper
        title={seo?.title}
        description={seo?.description}
        keywords={seo?.keywords}
        noindex={seo?.noindex}
        nofollow={seo?.nofollow}
        urlSlug={seo?.urlSlug}
      />
      <SubHeader subheader={subheaderElements} />
      {eventSlider && (
        <AsyncComponent
          kontentItem={eventSlider}
          contentType={'content_list___fixed_length'}
        />
      )}
      <div className={styles.container}>
        {eventHero && (
          <EventHeroAsset
            backgroundAsset={eventHero?.backgroundAsset}
            mobileImgVideoPreview={eventHero?.mobileImgVideoPreview}
            playVideoText={eventHero?.playVideoText}
          />
        )}

        <div className={styles.content}>
          <div className={styles.content__left}>
            {eventHero && (
              <EventHeroBody
                title={eventHero?.title}
                description={eventHero?.description}
                eventLocation={eventHero?.eventLocation}
                eventStartDate={eventHero?.eventStartDate}
                eventEndDate={eventHero?.eventEndDate}
                viewEventLink={eventHero?.viewEventLink}
              />
            )}
            <RenderOn condition={!!body?.length}>
              {body?.map(x => {
                return (
                  <AsyncComponent
                    key={x.system.id}
                    kontentItem={x}
                    contentType={x.system.type}
                  />
                );
              })}
            </RenderOn>
          </div>
          <RenderOn condition={!!sidebar?.length}>
            <div className={styles.content__right}>
              {sidebar
                ?.map(x => {
                  if (isTablet && x.system.type !== 'web_banner_placeholder') {
                    return null;
                  }
                  return (
                    <AsyncComponent
                      key={x.system.id}
                      kontentItem={x}
                      contentType={x.system.type}
                    />
                  );
                })
                .filter(Boolean)}
            </div>
          </RenderOn>
        </div>
      </div>
    </main>
  );
};

export default EventPage;
