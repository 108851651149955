import SlickSlider from 'react-slick';
import SimplePopUp from '../../SimplePopUp/SimplePopUp';
import SliderCustomDots from '../../ContentList/Slider/SliderCustomDots';
import type { IContentItem } from '@kontent-ai/delivery-sdk';
import { AsyncComponent } from '@r-and-a-shared-ui/core-component-config';

import styles from './Popup.module.scss';

const PopupItem = (p: { content: IContentItem[]; membershipId: string }) => {
  const content = p.content;
  const id = `pop-up-item-content-${p.membershipId}`;
  return (
    <div id={id} key={id}>
      {content.map((e: IContentItem<any>) => {
        return (
          <AsyncComponent
            kontentItem={e}
            key={`${e.system.id}`}
            contentType={e.system.type as any}
          />
        );
      })}
    </div>
  );
};

export const Popup = ({
  popupElements,
  onClose,
  beforeChange,
  activePopupIndex,
  isVisible,
}: {
  popupElements: any[] | undefined | null;
  onClose: () => void;
  beforeChange: (_: any, nextIndex: number) => void;
  activePopupIndex: number;
  isVisible: boolean;
}) => {
  if (!popupElements || !isVisible) return null;
  return (
    <SimplePopUp
      backgroundColor={`#041E42`}
      overlayBackgroundColor={`rgba(0,0,0,0.75)`}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className={styles['slider']}>
        <SlickSlider
          slidesToShow={1}
          dots
          appendDots={dots => (
            <SliderCustomDots dots={dots} activeIndex={activePopupIndex} />
          )}
          swipe={true}
          autoplay={false}
          arrows={false}
          infinite={false}
          adaptiveHeight={false}
          initialSlide={activePopupIndex}
          beforeChange={beforeChange}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {popupElements.map(p => (
            <PopupItem
              membershipId={p.membershipId}
              content={p.content}
              key={`pop-up-item-${p.membershipId}`}
            />
          ))}
        </SlickSlider>
      </div>
    </SimplePopUp>
  );
};
