/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Auth from 'aws-amplify/auth';
import {
  ResponseModel,
  getQueryParamByKey,
  hasValue,
  isNullOrUndefined,
  setStringLocalStorage,
} from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams, SignInResponse } from "../../types";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { SignInInputSchema } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { NextStepsAfterSignIn } from "../../constants";
import { execStoreTokensMutation, handleUpdateLastLoggedInAttributes } from "../form-action-helpers";
import { OauthQuery, USER_ID, handleOauth2Redirect, hasRequiredFieldsForMember, isSameUser, tryGetRefreshToken } from '@r-and-a-shared-ui/auth-client';
import { handleSignInNextSteps } from '../../helpers/sign-in-helpers';
import { sendGAEvent } from '@next/third-parties/google';
import { Attributes, CognitoUser } from '../../../../../auth-client/src/types';
import { fetchUserAttributes } from 'aws-amplify/auth';

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {

  const clientId = getQueryParamByKey(OauthQuery.CLIENT_ID);

  const updatedParams = {
    ...params,
    clientId
  };

  const resultData = await genericMutationHandlerWithFormValidation(
    updatedParams,
    SignInInputSchema,
  );

  if (resultData?.hasErrors) {
    return resultData;
  }

  const signInResponse = resultData?.data as SignInResponse;

  if (
    signInResponse?.accessToken &&
    signInResponse?.idToken &&
    signInResponse?.refreshToken &&
    !await hasRequiredFieldsForMember(params?.memberRequiredAttributes)
  ) {
    signInResponse.nextStepAfterSignIn = NextStepsAfterSignIn.MISSING_REQUIRED_MEMBER_FIELDS;
  }

  if (handleSignInNextSteps(params, signInResponse.nextStepAfterSignIn)) {
    return resultData;
  }

  const storeTokensMutationResult = await execStoreTokensMutation(params, signInResponse);
  if (storeTokensMutationResult?.hasErrors || !hasValue(storeTokensMutationResult)) {
    return resultData;
  }

  sendGAEvent({ event: 'sso_sign_in', value: '/sign-in' });

  handleOauth2Redirect();

  return !isNullOrUndefined(storeTokensMutationResult) ? storeTokensMutationResult : resultData;
};

export const signIn = async (params: ActionHandlerParams) => {
  if (!hasValue(params)) {
    return;
  }
  // Check if user already logged in
  let session = await Auth.fetchAuthSession({ forceRefresh: true });

  const command = (params.data || params) as Auth.SignInInput;

  // User already logged in -> check if same user
  if (session?.tokens != null) {
    if (await !isSameUser(session?.tokens, command)) {
      // Different user -> log out
      await Auth.signOut({ global: true });
    } else {
      // Return user tokens
      return {
        accessToken: session?.tokens.accessToken.toString(),
        idToken: session?.tokens.idToken?.toString(),
        refreshToken: await tryGetRefreshToken(),
      } as SignInResponse;
    }
  }

  // No user logged in -> log in user
  if (session?.tokens == null) {

    // Adjust the client id used by AWS Amplify if it is not set
    // checkIfClientIdIsSetForAwsAmplify((params.data as { clientId?: string })?.clientId);

    const signInOutput = await Auth.signIn(command);

    if (
      signInOutput?.nextStep != null &&
      signInOutput?.nextStep?.signInStep.toLowerCase().trim() !==
      'DONE'.toLowerCase().trim()
    ) {
      return {
        nextStepAfterSignIn: signInOutput?.nextStep?.signInStep,
      } as SignInResponse;
    }

    session = await Auth.fetchAuthSession();
    if (session?.tokens != null) {
      const refreshToken = await tryGetRefreshToken();
      const accessToken = session?.tokens.accessToken.toString();
      await handleUpdateLastLoggedInAttributes();
      const ssoUserId = session?.tokens?.idToken?.payload?.[Attributes.SsoUserId];
      
      if (ssoUserId) {
        setStringLocalStorage(USER_ID, ssoUserId);
      }

      return {
        accessToken,
        idToken: session?.tokens.idToken?.toString(),
        refreshToken,
      } as SignInResponse;
    }
  }
};

export default handle;
