import { sendGAEvent } from '@next/third-parties/google';

import { ResponseModel, getParameterByName, stringToBoolean } from "@r-and-a-shared-ui/utils";
import { ActionHandlerParams } from "../../types";
import { genericMutationHandlerWithFormValidation } from "../form-action-mutation-handlers";
import { UpdateInputSchema, userDefaultValuesUpdate } from "@r-and-a-shared-ui/randa-trpc-server/input-schemas";
import { OauthQuery, getBearerToken, getMembership } from "@r-and-a-shared-ui/auth-client";
import { execStoreTokensMutation } from "../form-action-helpers";

export const handle = async (
  params: ActionHandlerParams,
): Promise<ResponseModel> => {

  params.bearerToken = await getBearerToken();
  const resultData = await genericMutationHandlerWithFormValidation(
    params,
    UpdateInputSchema,
    userDefaultValuesUpdate,
  );

  if (resultData?.hasErrors) {
    return resultData;
  }

  sendGAEvent({ event: 'one_club_update', value: { membership: getMembership(params) } });

  const storeTokens = stringToBoolean(getParameterByName(OauthQuery.STORE_TOKENS));

  if (storeTokens) {
    await execStoreTokensMutation(params);
  }

  return resultData;
};

export default handle;