import styles from './PageTemplate.styles.module.scss';
import { ReactNode } from 'react';
import { Header, HeaderProps } from '../Header/Header';
import { Footer, FooterProps } from '../Footer/Footer';

type PageTemplateProps = {
  children?: ReactNode | ReactNode[];
  styles?: any;
  header?: HeaderProps;
  footer?: FooterProps;
};

export const PageTemplate = ({
  children,
  header,
  footer = undefined,
}: PageTemplateProps) => {
  return (
    <div data-testid="PageTemplate" className={styles.wrapper}>
      <Header {...header} />
      {children}
      <Footer {...footer} />
    </div>
  );
};
