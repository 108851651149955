import { createContext } from 'react';
import { GlobalDataMapping, globalDataMapping } from './GlobalDataMapping';
import { SUPPORTED_CMS, SUPPORTED_CMS_TYPE } from '../types';

export const DataMapperContext = createContext<{
  cmsBasedDataMapping: GlobalDataMapping['dataMapping'];
  CURRENT_CMS: SUPPORTED_CMS_TYPE;
}>({
  cmsBasedDataMapping: globalDataMapping.dataMapping,
  CURRENT_CMS: SUPPORTED_CMS.KONTENT,
});
