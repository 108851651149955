import styles from './SectionTitle.module.scss';
import Image from '../Image/Image';

export interface SectionTitleProps {
  title?: string;
  backgroundImage?: string;
}

export function SectionTitle(props: SectionTitleProps) {
  const { title, backgroundImage } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          {backgroundImage && (
            <div className={styles.background}>
              <Image
                imageUrl={backgroundImage}
                alt={title || 'SectionTitle'}
                width={0}
                height={0}
              />
            </div>
          )}
          {title && (
            <div className={styles.title}>
              <h1>{title}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionTitle;
