/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const taxonomies = {
    /**
     * Common components
     */
    common_components: {
        codename: 'common_components',
        id: 'e66e1751-64ef-442d-9791-4b32c1b990b9',
        externalId: undefined,
        name: 'Common components',
        terms: {
            common: {
                codename: 'common',
                id: '9252ec98-9eb8-42db-8948-b1b506e29dce',
                externalId: undefined,
                name: 'Common',
                terms: {}
            }
        }
    }
} as const;
