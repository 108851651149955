import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import {
  getFirstLinkedAssetOrDefault,
  getMultipleChoiceValues,
} from '@r-and-a-shared-ui/common-kontent-client/utils';
import { getMappedLinkedItems } from './utils/component-mapping-helpers';

export const country = (data: SSO_MODELS.Country) => {
  if (!data || !data?.elements) {
    return undefined;
  }

  const { elements } = data;
  const flagAsset = getFirstLinkedAssetOrDefault(elements?.flag);

  return {
    key: data?.system?.codename,
    label: elements?.nationality_name?.value,
    value: elements?.code?.value,
    flagUrl: flagAsset?.url,
    code: elements?.code?.value,
    internationalCodePrefix: elements?.international_code_prefix?.value,
    config: getMultipleChoiceValues(elements?.config),
    isoCountryCode: elements?.iso_country_code?.value,
    aliases: elements?.aliases?.value,
    counties: getMappedLinkedItems(elements?.counties),
    states: getMappedLinkedItems(elements?.states),
  };
};

export default country;
