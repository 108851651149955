import type { CSSProperties, FC } from 'react';
import classnames from 'classnames';
import Image from '../Image/Image';
import GraphicSquareLiliacBg from '../../assets/GraphicSquareLiliacBg.svg';

import { ImageProps } from '../Image/Image';
import { RenderOn } from '../RenderOn/RenderOn';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';

import { RestrictedContentPromptLogos } from './RestrictedContentPromptLogos';
import { useRestrictedContentPrompt } from './useRestrictedContentPrompt';

import styles from './RestrictedContentPrompt.module.scss';
import { SSOCTAs } from '../../SSObuttons/SSOCTAs';

export type RestrictedContentPromptProps = {
  className?: string;
  style?: CSSProperties;
  logo?: ImageProps;
  access?: keyof typeof RestrictionLevel;
  isMembership?: boolean;
};

export const RestrictedContentPrompt: FC<RestrictedContentPromptProps> = ({
  className,
  style,
  access,
  isMembership,
}) => {
  const { subtitleText, titleText, userHasAccess } = useRestrictedContentPrompt(
    { access },
  );

  const wrapperClassNames = classnames(
    styles.wrapper,
    className,
    {
      [styles.noFadedBackground]: isMembership,
    }
  );

  return (
    <RenderOn condition={!userHasAccess}>
      <div className={wrapperClassNames} style={style}>
        <div className={styles.background}>
          <Image
            width={900}
            height={900}
            imageUrl={GraphicSquareLiliacBg}
            alt="Graphic Square background"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 className={styles.title}>{titleText}</h2>
            <p className={styles.description}>{subtitleText}</p>
          </div>
          <SSOCTAs />
          <RestrictedContentPromptLogos />
        </div>
      </div>
    </RenderOn>
  );
};
