import { CallOutBox } from '@r-and-a-shared-ui/common-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';

export default {
  component: () => CallOutBox,
  mapEntryToProps: (entry: MEMBERSHIP_MODELS.CallOutBox) =>
    kontentAiDataMapping.call_out_box(entry),
};
