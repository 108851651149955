import { useScreenDetector } from '../../hooks';
import { Logo, LogoProps } from '../Logo/Logo';
import { RenderOn } from '../RenderOn/RenderOn';
import styles from './MenuFooter.module.scss';

export interface MenuFooterProps {
  logos: LogoProps[];
}

export function MenuFooter(props: MenuFooterProps) {
  const { logos } = props;
  const { isMobile } = useScreenDetector();

  return (
    <RenderOn condition={!!logos.length && !isMobile}>
      <div className={styles.item}>
        <div className={styles.logo}>
          {logos.map(logosItem => (
            <Logo {...logosItem} key={logosItem.resource?.title} />
          ))}
        </div>
      </div>
    </RenderOn>
  );
}

export default MenuFooter;
