import { FormPage } from '@r-and-a-shared-ui/common-forms-ui';

import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';
import type { SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
export default {
  component: ()=>FormPage,
  mapEntryToProps: (entry: SSO_MODELS.FormPage) =>
    kontentAiDataMapping.form_page(entry),
  depthParameter: 5
};
