import { Form } from '@r-and-a-shared-ui/common-forms-ui';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';

import type { MEMBERSHIP_MODELS, SSO_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
export default {
  component: ()=>Form,
  mapEntryToProps: (entry: SSO_MODELS.Form & MEMBERSHIP_MODELS.Form) =>
    kontentAiDataMapping.form(entry),
  depthParameter: 3,
};
