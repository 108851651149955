import { MembershipTypes, OauthQuery, getUserJourneyTypeIndex } from '@r-and-a-shared-ui/auth-client';
import { STEP } from '../constants/configConstants';
import { ActionHandlerParams, FormStepProps } from '../types';
import {
  hasValue,
  isFunction,
  isNullOrUndefined,
  safeParseZod,
  getNumberFromLocalStorage,
  setNumberInLocalStorage,
  mapByNestedKeys,
  getQueryParamByKey,
  ensureValidUri,
  getSSOUrl,
  isValidDateString,
} from '@r-and-a-shared-ui/utils';
import { FieldValues, UseFormTrigger } from 'react-hook-form';
import z from 'zod';

export const addToCurrentStep = (valueToAdd: number) => {
  const currentStep = getNumberFromLocalStorage(STEP);
  const newStep = currentStep + valueToAdd;

  setNumberInLocalStorage(STEP, newStep);
  window.dispatchEvent(new Event('storage'));
};

export const getFieldsCurrentStep = (
  steps: FormStepProps[] | undefined,
): string[] | undefined => {
  const currentStep = getNumberFromLocalStorage(STEP);
  if (isNullOrUndefined(steps) || steps.length <= currentStep) {
    return undefined;
  }
  const fieldsNames: string[] = [];

  steps[currentStep].fieldsData?.forEach((field) => {
    fieldsNames.push(field?.name);
    if(field.subfields?.length) {
      field.subfields?.forEach((subfield) => fieldsNames.push(subfield.name));
    }
  });
  
  return fieldsNames;
};

const validate = async (
  trigger: UseFormTrigger<FieldValues> | undefined,
  fields: string[] | undefined,
): Promise<boolean> => {
  if (!isFunction(trigger) || isNullOrUndefined(fields)) {
    return false;
  }
  const isValid = await trigger(fields);
  return isValid;
};

export const validateForm = async (
  params: ActionHandlerParams,
): Promise<boolean> => {
  const { trigger, formContentProps } = params;

  const isValid = await validate(
    trigger,
    getFieldsCurrentStep(formContentProps?.steps),
  );

  return isValid;
};
export const skipValidationForm =  (
  params: ActionHandlerParams,
)=> {
  const { initialFormState, formContentProps, setValue, clearErrors } = params;

  const currentStepFields= getFieldsCurrentStep(formContentProps?.steps);

  setCurrentStepValuesToDefault(currentStepFields, initialFormState, setValue, clearErrors);
};

export const setCurrentStepValuesToDefault = (
  currentStepFields: string[] | undefined,
  initialFormState?: any,
  setValue?: any,
  clearErrors?: any,) =>{

  if(isNullOrUndefined(currentStepFields)) {
    return;
  }
  
  for (let i = 0; i < currentStepFields.length; i++) {
    setValue(currentStepFields[i], initialFormState[currentStepFields[i]]);
    clearErrors(currentStepFields[i]);
  }
};

const setConfigParamsOnSubmitData = (submitData: any) => {
  if (isNullOrUndefined(submitData)) {
    return;
  }
  submitData.clientId = getQueryParamByKey(OauthQuery.CLIENT_ID);
  submitData.userJourneyType = getUserJourneyTypeIndex(
    getQueryParamByKey(OauthQuery.USER_JOURNEY_TYPE)
  );
  if(submitData.user_becameMasterCard && !isValidDateString(submitData.user_becameMasterCard)){
    submitData.user_becameMasterCard = (new Date()).toDateString();
    submitData.user_membership = `${MembershipTypes.OneClub};${MembershipTypes.OneClubAdvantage}`;
  }
};

export const preExecMutationCheckWithFormValidation = async <
  Type extends z.ZodSchema,
>(
  params: ActionHandlerParams,
  schema: Type,
  defaultValuesObj?: unknown,
): Promise<z.TypeOf<Type> | undefined> => {
  const isValid = await validateForm(params);

  if (!isValid) {
    return undefined;
  }

  return preExecMutationCheck(params, schema, defaultValuesObj);
};

export const preExecMutationCheck = async <Type extends z.ZodSchema>(
  params: ActionHandlerParams,
  schema: Type,
  defaultValuesObj?: unknown,
): Promise<z.TypeOf<Type> | undefined> => {
  const { resource, submitData, bearerToken } = params;
  
  let objectToSubmit:any = mapByNestedKeys(submitData, defaultValuesObj, '_');

  setConfigParamsOnSubmitData(submitData);
  if(!hasValue(resource)) {
    return safeParseZod(schema, objectToSubmit);
  }

  objectToSubmit = {
    callBackUrl: ensureValidUri(resource, getSSOUrl()),
    data: mapByNestedKeys(submitData, defaultValuesObj, '_'),
  };

  if(bearerToken) {
    objectToSubmit.bearerToken = bearerToken;
  }
  return safeParseZod(schema, objectToSubmit);
};

export const playAudioFromStep = (steps: FormStepProps[] | undefined) => {
  if(isNullOrUndefined(steps)) {
    return;
  }
  const currentStep = getNumberFromLocalStorage(STEP);
  const audioProps = steps[currentStep - 1]?.audio;
  if(isNullOrUndefined(audioProps)) {
    return;
  }
  const audio = new Audio(audioProps.url);
  audio.play();
};

export const checkAllFieldsHaveValues = (fieldNames: string[] | undefined, submitData: FieldValues) => {
  if(isNullOrUndefined(fieldNames)) {
    return true;
  }

  for (let i = 0; i < fieldNames.length; i++) {
    if(!submitData[fieldNames[i]]) {
      return false;
    }
  }
  return true;
};