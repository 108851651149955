import CryptoJS from 'crypto-js';
import { hasValue } from './general-utils';

const secretKey = process.env['NEXT_PUBLIC_AES_ENCRYPT_SECRET']

export const AesEncrypt = (val?: string) => {
    if (!val || !hasValue(val) || !secretKey || !hasValue(secretKey)) {
        return undefined;
    }

    try {
        const iv = CryptoJS.lib.WordArray.random(16);
    
        const encrypted = CryptoJS.AES.encrypt(val, CryptoJS.enc.Base64.parse(secretKey), {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
    
        // Combine IV and encrypted data, encode as Base64
        const ivAndEncryptedData = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
        return ivAndEncryptedData;
    }
    catch {
        return undefined;
    }
};