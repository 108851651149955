import React from 'react';
import { Link, LinkProps } from '../Link/Link';
import styles from './ExploreBenefits.module.scss';
import classNames from 'classnames';
import Image, { ImageProps } from '../Image/Image';

export interface ExploreBenefitsProps {
  title: string;
  link?: LinkProps[];
  background_image?: ImageProps;
}

export function ExploreBenefits(props: ExploreBenefitsProps) {
  const { title, link, background_image } = props;

  return (
    <div className={styles.wrapper}>
      {background_image && (
        <div className={styles.background}>
          <Image
            imageUrl={background_image.imageUrl}
            width={50}
            height={50}
            alt={background_image.alt || 'Explore Background'}
            schemaOrgContent={background_image.schemaOrgContent}
          />
        </div>
      )}
      <div className={styles.content}>
        <h2 className={styles.content__title}>{title}</h2>
        {link && link.length > 0 && (
          <div
            className={classNames(
              styles['content__tiles'],
              link.length > 3 && styles['content__tiles--split'],
            )}
          >
            {link.map((linkItem, i) => (
              <Link
                key={`${linkItem.title} ${i}`}
                {...linkItem}
                className={styles.content__tiles__item}
              >
                {linkItem.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ExploreBenefits;
