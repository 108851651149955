import { FormFieldProps } from "../../FormField/FormField";
import * as z from 'zod';
import { RefineParams, superRefineHelper } from "./helpers/superRefineHelpers";
import { getDateWithOffset, safeParseRegex } from "@r-and-a-shared-ui/utils";
import { FormDateFieldProps } from "../../FormDateField/FormDateField";

export const defaultValidationMessage = 'Please input a value.';

export const defaultValidationRegex = /^((.|\n)*|^(?=\s*$))$/; // matches anything
export const defaultNumberRegex = new RegExp("^[0-9]*$"); // matches any number


export const validateInputField = (
  fieldData: FormFieldProps,
  fieldToCompare?: FormFieldProps,
  dependencyCriteria?: string | undefined,
  defaultPattern?: RegExp,): z.ZodEffects<z.ZodOptional<z.ZodNullable<z.ZodString>>, string | null | undefined, string | null | undefined>
  | z.ZodEffects<z.ZodString, string, string> => {

  const pattern = fieldData.validationRegex ? safeParseRegex(fieldData.validationRegex) || defaultPattern || defaultValidationRegex : defaultValidationRegex;

  const refineParams: RefineParams = {
    fieldData,
    fieldToCompare,
    dependencyCriteria,
    pattern
  };

  const defaultValidator = z.string()
    .min(fieldData.isRequired ? 1 : 0)
    .superRefine((value, ctx) => superRefineHelper({
      value,
      ctx
    }, refineParams
    ));

  if (fieldData.isRequired) {
    return defaultValidator;
  }

  return z.string().nullish()
    .superRefine((value, ctx) => {
      const refinedValue = !value ? "" : value;

      return superRefineHelper({
        value: refinedValue,
        ctx
      }, refineParams
      );
    });

};

export const validateTextField = (
  fieldData: FormFieldProps,
  fieldToCompare?: FormFieldProps,
  dependencyCriteria?: string | undefined): z.ZodEffects<z.ZodOptional<z.ZodNullable<z.ZodString>>, string | null | undefined, string | null | undefined> | z.ZodEffects<z.ZodString, string, string> => validateInputField(
  fieldData, fieldToCompare, dependencyCriteria, defaultValidationRegex);

export const validateNumberField = (
  fieldData: FormFieldProps,
  fieldToCompare?: FormFieldProps,
  dependencyCriteria?: string | undefined): z.ZodEffects<z.ZodOptional<z.ZodNullable<z.ZodString>>, string | null | undefined, string | null | undefined> | z.ZodEffects<z.ZodString, string, string> => validateInputField(
  fieldData, fieldToCompare, dependencyCriteria, defaultNumberRegex);

export const validateMultipleChoiceField = (fieldData: FormFieldProps): z.ZodEffects<z.ZodAny, any, any> =>
  z.any().superRefine((val, ctx) => {
    const htmlElement = document.getElementById(fieldData.name);

    if (!fieldData.isRequired || !htmlElement) {
      return z.string().optional();
    }

    if (!val || (Array.isArray(val) && !val.length)) {
      ctx.addIssue({
        path: [fieldData.name],
        code: z.ZodIssueCode.custom,
        fatal: true
      });
      return;
    }

    if (fieldData.entireObjectSelection) {
      return;
    }
    return z.string().min(fieldData.isRequired ? 1 : 0);
  });

export const validateDateField = (fieldData: FormDateFieldProps): z.ZodEffects<z.ZodAny, any, any> =>
  z.any().superRefine((val, ctx) => {
    const dateWithOffset = getDateWithOffset(fieldData.yearOffset);
    if (val instanceof Date && val.getTime() <= dateWithOffset) {
      return;
    }
    if (val === undefined && !fieldData.isRequired) {
      return;
    }

    ctx.addIssue({
      path: [fieldData.name],
      code: z.ZodIssueCode.custom,
      fatal: true,
      message: fieldData.validationMessage,
    });
    return;
  });
  