import { Link, LinkProps } from '../Link/Link';
import styles from './UpgradeExperienceCard.module.scss';
import { useContext, useEffect, useState } from 'react';
import { EnvironmentContext } from '../../contexts';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import MembershipTypesLogos from '../MembershipTypeLogos/MembershipTypesLogos';
import Tag, { TagProps } from '../Tag/Tag';
import { RenderOn } from '../RenderOn/RenderOn';
import { useScreenDetector } from '../../hooks';
import classNames from 'classnames';
import { MEMBERSHIP_MODELS } from '@r-and-a-shared-ui/common-kontent-client';
import { kontentAiDataMapping } from '@r-and-a-shared-ui/core-data-mapping';
import { api } from '@r-and-a-shared-ui/randa-trpc-client';
import { hasValue } from '@r-and-a-shared-ui/utils';

type ImageType = {
  url?: string;
  alt?: string | null;
};

export interface UpgradeExperienceCardProps {
  title?: string;
  description?: string;
  backgroundImage?: ImageType;
  mobileBackgroundImage?: ImageType;
  link?: LinkProps;
  ctaText?: string;
  access?: keyof typeof RestrictionLevel;
  tags?: TagProps[];
  codename: string;
}
export function UpgradeExperienceCard({
  tags,
  backgroundImage,
  mobileBackgroundImage,
  title,
  description,
  link,
  ctaText,
  access = 'none',
  codename,
}: UpgradeExperienceCardProps) {
  const { Image } = useContext(EnvironmentContext);
  const { isMobile } = useScreenDetector();
  const [loadedLink, setLoadedLink] = useState<any>();
  const [ctaLink, setCtaLink] = useState<any>();
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);  

  const { data } = api.kontent.itemWithLinkedItems.useQuery({
    codename: isDataFetched ? '' : codename ?? ''
  });

  if (hasValue(data)) {
    const mappedCard = kontentAiDataMapping
      .upgrade_experience_card(data as MEMBERSHIP_MODELS.UpgradeExperienceCard);
    setLoadedLink(mappedCard?.link);
    setIsDataFetched(true);
  }
  useEffect(() => {
    if(link?.href || ctaLink?.href) {
      return;
    }
    setIsDataFetched(false);
  }, [link, codename, ctaLink?.href]);

  useEffect(() => {
    setCtaLink({
      title: ctaText || link?.title || loadedLink?.title || '',
      internal: !!link?.internal || !!loadedLink?.internal,
      href: link?.href || loadedLink?.href || '',
    });
  }, [loadedLink, codename, link, ctaText]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        {!isMobile && (
          <UpgradeBackgroundImageComponent
            className="card__background"
            backgroundImage={backgroundImage}
          />
        )}
        {isMobile && (
          <div className={styles.card__background}>
            <Image
              src={mobileBackgroundImage?.url || ''}
              alt={mobileBackgroundImage?.alt || ''}
              width={390}
              height={546}
              sizes="100vw"
            />
          </div>
        )}
        <div className={styles.card__membership}>
          <MembershipTypesLogos
            restrictionLevel={access as keyof typeof RestrictionLevel}
          />
          <RenderOn condition={!!tags?.length}>
            <div className={styles.card__membership__tags}>
              {tags?.map(tag => (
                <Tag key={tag.label?.value} {...tag} />
              ))}
            </div>
          </RenderOn>
        </div>
        <div className={styles.card__content}>
          {title && <h3>{title}</h3>}
          {!isMobile && description && (
            <p className={styles.card__content__description}>{description}</p>
          )}
          {(!!ctaLink?.href?.length || !!ctaLink?.title?.length) && (
            <div className={styles.card__content__link}>
              <Link
                className={classNames(styles.btn, styles['btn--secondary'], {
                  [styles['inactive-link']]: !ctaLink?.href?.length,
                })}
                {...ctaLink}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpgradeExperienceCard;

const UpgradeBackgroundImageComponent = ({
  className,
  backgroundImage,
}: {
  className: string;
  backgroundImage?: ImageType;
}) => {
  const { Image } = useContext(EnvironmentContext);

  return backgroundImage ? (
    <div className={styles[className]}>
      <Image
        src={backgroundImage?.url || ''}
        alt={backgroundImage?.alt || ''}
        width={762}
        height={316}
        sizes="100vw"
      />
    </div>
  ) : null;
};
