import type { FormFieldTypes } from "./form-field-config";
import {
  validateDateField,
  validateMultipleChoiceField,
  validateNumberField,
  validateTextField,
} from "./validators/validators";
export const formFieldValidators: Record<FormFieldTypes, unknown> = {
  text: validateTextField,
  email: validateTextField,
  password: validateTextField,
  password_confirmation: validateTextField,
  dropdown: validateMultipleChoiceField,
  checkboxes: validateMultipleChoiceField,
  radio_buttons: validateTextField,
  number: validateNumberField,
  phone_number: validateTextField,
  country: validateMultipleChoiceField,
  date: validateDateField,
  mastercard: validateTextField,
  favorite_player: validateMultipleChoiceField,
  one_club_free_text: undefined,
  undefined: undefined,
};