import Image, { ImageProps } from '../Image/Image';
import styles from './ArticleListicle.module.scss';
import { RestrictionLevel } from '../MembershipTypeLogos/constants';
import MembershipTypesLogos from '../MembershipTypeLogos/MembershipTypesLogos';
import { Link } from '../Link/Link';
import { useScreenDetector } from '../../hooks';

export interface ArticleListicleProps {
  image?: ImageProps;
  title: string;
  mobileTitle?: string;
  description?: string;
  access: keyof typeof RestrictionLevel;
  urlSlug?: string;
}

export function ArticleListicle({
  image,
  title,
  mobileTitle,
  description,
  access,
  urlSlug,
}: ArticleListicleProps) {

  const { isMobile } = useScreenDetector();
  const displayTitle = isMobile ? (mobileTitle || title) : (title || mobileTitle);
  return (
    <div className={styles.thumbnail}>
      <div className={styles.thumbnail__visuals}>
        <div className={styles.thumbnail__image}>
          {image && <Image {...image} />}
        </div>
        <MembershipTypesLogos restrictionLevel={access} />
      </div>

      <div className={styles.thumbnail__content}>
        <Link internal href={`/articles/${urlSlug}` || ''} title="">
          <h4 className={styles.thumbnail__title}>{displayTitle}</h4>
        </Link>
        <p>{description && description}</p>
      </div>
    </div>
  );
}

export default ArticleListicle;
